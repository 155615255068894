import React from 'react';

const Loader = () => {
  return (
    <div
      id='loading-indicator'
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        background: '#eef1f8',
        zIndex: 99999,
        opacity: 0.6,
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={require('../../assets/img/loading.gif')} width='100px'></img>
    </div>
  );
};

export default Loader;
