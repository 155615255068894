// import { db } from 'MY_LOGIN_AND_SIGNUP/db/config';
import React, { useEffect } from "react";
import { Button } from "reactstrap";
import {
  deleteAssignmentAction,
  getAssignmentAction,
} from "store/action/assignmentAction/assignmentAction";

import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";

function DeleteAssignment({ dataToDelete, notify, setUserArr, onClick, ...props }) {
  const handleDelete = () => {
    let url = `api/assignment?id=${dataToDelete.id}&fileId=${dataToDelete.fileId}`;
    props.deleteAssignment(url);
  };

  useEffect(() => {
    if (props?.deleteAssignmentRes?.deleteAssignmentData) {
      notify("Data deleted successfully", false);
      props.clearRes("DELETE_ASSIGNMENT_RESET");
      props.getAssignmentList();
      setUserArr([])
      onClick();
    }
    if (props?.deleteAssignmentRes?.error) {
      notify(props?.deleteAssignmentRes?.error?.message, true);
      props.clearRes("DELETE_ASSIGNMENT_RESET");
      onClick();
    }
  }, [props?.deleteAssignmentRes]);

  return (
    <div>
      <p>Do you want to delete these assignment?</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",

        }}
      >
        <Button
          title="Yes"
          color="info"
          onClick={() => {
            handleDelete();
          }}
        >
          Yes
        </Button>

        <Button
          title="No"
          onClick={() => {
            onClick();
          }}
        >
          No
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteAssignmentRes: state.deleteAssignment,
  getAssignmentRes: state.getAssignment,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAssignment: (url) => {
    dispatch(deleteAssignmentAction(url));
  },
  getAssignmentList: () => {
    dispatch(getAssignmentAction());
  },
  clearRes: (type) => {
    dispatch(clearResponse(type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAssignment);
