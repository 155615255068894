/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Edit, Delete, Add } from "@material-ui/icons";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import SecurityIcon from "@material-ui/icons/Security";
import * as Firebase from "firebase";

import { connect } from "react-redux";
import axios from "axios";

import { adminBulkDeleteAction } from "store/action/bulkDeleteAction/adminBulkDeleteAction";

//import { Modal } from '@material-ui/core';
// import { db } from '../MY_LOGIN_AND_SIGNUP/db/config';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Progress,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import SearchIcon from "@material-ui/icons/Search";
import CreateAdmin from "./CreateAdmin";
import DeleteAdmin from "./DealeteAdmin";
import BulkDeleteAdmin from "./BulkDeleteAdmin";
import EditAdmin from "./EditAdmin";

import { adminList } from "../../Utilities/const";
//added this file
import styles from "./tableList.module.css";
import { clearResponse } from "store/action/clearResponseAction";
import { getAdminsAction } from "store/action/adminActions/adminActions";
import { getSecurityGroupAction } from "store/action/SecurityGroupAction/SecurityGroupAction";

import Loader from "components/Loader/Loader";

import { actionBtnStyle } from "Utilities/common/commonStyle";

import { getAllStudents } from "Utilities/apiService/getAllStudents";

function AdminTable(props) {
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [userData, setUserData] = useState([]);
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [myUser, setMyUser] = useState();
  const [reload, setreload] = useState(true);
  const [search, setSearch] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [userArr, setUserArr] = useState([]);

  const [securityGroup, setSecurityGroup] = useState("");

  const history = useHistory();

  const notifi = useRef();

  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  useEffect(() => {
    document.title = "Admin Management";
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const config = {
    headers: { Authorization: `Bearer ${user?.token}` },
  };

  useEffect(() => {
    axios
      .get("https://atriev-lms.dt.r.appspot.com/api/cron", config)
      .then((data) => {})
      .catch((err) => {});
  }, []);

  useEffect(() => {
    // const user = localStorage.getItem('user') || '';
    // if (!user) {
    //   // notify("Login Expired !!! Please login again.",false)
    //   history.push('/admin/login');
    //   return;
    // }
    props.getAdminList();
    props.getSecurityGroupList();
    getAllStudentList();
  }, []);
  const getAllStudentList = async () => {
    const students = await getAllStudents.getStudents();
  };

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  useEffect(() => {
    if (props.getAdminData.adminList) {
      if (props.getAdminData.adminList.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_ADMIN_RESET");
        setUserData("");
      } else {
        setUserData(props.getAdminData.adminList.data);
      }
    }
    if (props.getAdminData?.error) {
      notify(props.getAdminData?.error?.message, true);
      props.clearResponse("GET_ADMIN_RESET");
    }
  }, [props.getAdminData]);

  useEffect(() => {
    if (props?.getSecurityGroupRes?.securityGroupList) {
      const groupList = props?.getSecurityGroupRes?.securityGroupList?.data;
      if (props?.getSecurityGroupRes.securityGroupList?.data.length == 0)
        notify("No data found.", true);

      setSecurityGroup(groupList);
    }
    if (props.getSecurityGroupRes?.error)
      notify(props.getSecurityGroupRes?.error?.message, true);
  }, [props?.getSecurityGroupRes]);

  // const user = localStorage.getItem('user') || '';
  // if (!user) {
  //   history.push('/admin/login');
  //   return;
  // }

  const getUsers = (orderBy) => {};

  const emptyStudent = () => {
    getUsers();
  };

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getAdminList();
    } else {
      setSearch(true);
      props.getAdminList(studentSearchValue);
    }
  };
  if (!localStorage.getItem("user")) history.push("/admin/login");

  return (
    <>
      {/* change for the modal border */}
      {
        // progressVisible ? (
        //   <Progress value={progressValue} color='info' style={{ height: 5 }} />
        // ) :
        <main id="main" className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notifi} />
          </div>

          <Modal
            isOpen={isModalVisible}
            style={{ display: "flex", flex: 1, top: -100 }}
            size="lg"
          >
            <ModalBody className={styles.create__user_modal_body} size="sm">
              <CreateAdmin
                setUserData={setUserData}
                notify={notify}
                onClick={() => {
                  setIsModalVisible(false);
                }}
              ></CreateAdmin>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={deleteVisible}
            color="primary"
            style={{
              display: "flex",
              flex: 1,
              top: 0,
              width: 250,
              backgroundColor: "#132639",
              objectFit: "fill",
            }}
          >
            <ModalBody style={{ width: "100%" }} size="sm">
              <DeleteAdmin
                setUserArr={setUserArr}
                uniqueId={myUser !== undefined && myUser._uniqueID}
                myUser={myUser !== undefined && myUser}
                setUserData={setUserData}
                notify={notify}
                onClick={() => {
                  setDeleteVisible(false);
                }}
              ></DeleteAdmin>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={bulkDeleteVisible}
            color="primary"
            style={{
              display: "flex",
              flex: 1,
              top: 0,
              width: 250,
              backgroundColor: "#132639",
              objectFit: "fill",
            }}
          >
            <ModalBody style={{ width: "100%" }} size="sm">
              <BulkDeleteAdmin
                userArr={userArr !== undefined && userArr}
                setUserArr={setUserArr}
                notify={notify}
                onClick={() => {
                  setBulkDeleteVisible(false);
                }}
              ></BulkDeleteAdmin>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={editVisible}
            style={{ display: "flex", flex: 1, top: -100 }}
            size="lg"
          >
            <ModalBody className={styles.create__user_modal_body}>
              <EditAdmin
                myUser={myUser !== undefined && myUser}
                setUserData={setUserData}
                notify={notify}
                onClick={() => {
                  setEditVisible(false);
                  // setreload(false)
                }}
              ></EditAdmin>
            </ModalBody>
          </Modal>

          <Row>
            <Col md="12">
              <Card
                style={{
                  fontSize: font?.fontSize || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  backgroundColor: colorTheme?.bodyColor || "all",
                }}
              >
                <CardHeader
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h1
                    style={{
                      fontSize: font?.fontSize + 4 || "inherit",
                      fontWeight: font?.fontWeight || "inherit",
                      margin: "0 auto 0 0",
                      textTransform: "capitalize",
                    }}
                  >
                    ADMIN MANAGEMENT
                  </h1>

                  <Button
                    title="Create User"
                    color="info"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Create User <Add />
                  </Button>
                </CardHeader>
                <Row style={{ marginLeft: "5px" }}>
                  <Col sm={4}>
                    <Input
                      title="Search Admin"
                      type="search"
                      name="search"
                      placeholder="Search Admin"
                      onChange={(e) => (
                        setStudentSearchValue(e.target.value),
                        e.target.value.length === 0 && emptyStudent(),
                        setSearch(false)
                      )}
                    />
                  </Col>
                  <Col style={{ paddingLeft: "0px" }} sm={2}>
                    <Button
                      title="Search"
                      style={{ margin: "0px", padding: "6px 10px" }}
                      color="info"
                      onClick={studentSearch}
                    >
                      <SearchIcon />
                    </Button>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    sm={6}
                  >
                    {userArr.length > 0 ? (
                      <Button
                        title="Delete Marked"
                        aria-label="Delete Marked"
                        style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                        color="danger"
                        onClick={() => {
                          setBulkDeleteVisible(true);
                        }}
                      >
                        Delete Marked
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <CardBody>
                  <Table className="tablesorter">
                    <thead className="text-primary">
                      <tr>
                        <th></th>
                        <th>User Name</th>
                        <th style={{ textAlign: "center" }}>Email</th>
                        <th style={{ textAlign: "center" }}>Mobile</th>
                        <th style={{ textAlign: "center" }}>Permission</th>
                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.length ? (
                        userData.map((user) => {
                          // let status = user.status && [];
                          return (
                            <tr
                              key={user.username}
                              style={{
                                borderBottom: `1px solid ${
                                  colorTheme?.borderColor ||
                                  "rgba(255, 255, 255, 0.1)"
                                }`,
                              }}
                            >
                              <td
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px",
                                  color: "white",
                                  marginTop: "1px",
                                }}
                              >
                                <Input
                                  id={user?.id}
                                  defaultChecked={false}
                                  type="checkbox"
                                  key={user?.id}
                                  value={user?.id}
                                  onChange={handleCheckbox}
                                />
                              </td>
                              <th>
                                <label
                                  style={{
                                    fontSize: font?.fontSize || "inherit",
                                    fontWeight: font?.fontWeight || "inherit",
                                    color: "#FFFFFFB3",
                                  }}
                                  htmlFor={user?.id}
                                >{`${user.firstName} ${user.lastName}`}</label>
                              </th>
                              <td style={{ textAlign: "center" }}>
                                {user.email}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {user.mobile}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {securityGroup &&
                                  securityGroup.map((secGrp) => {
                                    if (secGrp.id === user.secGrpId) {
                                      return <span>{secGrp.name}</span>;
                                    }
                                  })}
                              </td>
                              <td
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  title="Edit"
                                  aria-label="Edit"
                                  style={actionBtnStyle}
                                  onClick={() => {
                                    setMyUser(user);
                                    setEditVisible(true);
                                  }}
                                >
                                  <Edit style={{ color: "#247cf7" }} />
                                </button>

                                {user.disabled === false ? (
                                  <button
                                    title="Delete"
                                    aria-label="Delete"
                                    style={actionBtnStyle}
                                    onClick={() => {
                                      setMyUser(user);
                                      setDeleteVisible(true);
                                    }}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </button>
                                ) : (
                                  <button
                                    title="Delete"
                                    aria-label="Delete"
                                    style={actionBtnStyle}
                                    onClick={() => {
                                      setMyUser(user);
                                      setDeleteVisible(true);
                                    }}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </button>
                                )}

                                {/* <Delete style={{ color: user.status === 'disable' ? '#ffb3b3' : 'red' }} onClick={() => {
                            setMyUser(user)
                            setDeleteVisible(true)
                          }} /> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>No data to display</tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </main>
      }
      {props?.getAdminData?.isLoading ||
      props?.createAdminRes?.isLoading ||
      props?.editAdminRes?.isLoading ||
      props?.getSecurityGroupRes?.isLoading ||
      props?.deleteAdminRes?.isLoading ||
      props?.adminBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getAdminData: state.getAdmin,
  getSecurityGroupRes: state.getSecurityGroup,
  createAdminRes: state.createAdmin,
  editAdminRes: state.editAdmin,
  deleteAdminRes: state.deleteAdmin,
  adminBulkDeleteRes: state.adminBulkDelete,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getAdminList: (payload) => {
    dispatch(getAdminsAction(payload));
  },
  adminBulkDelete: (payload) => {
    dispatch(adminBulkDeleteAction(payload));
  },
  getSecurityGroupList: () => {
    dispatch(getSecurityGroupAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTable);
