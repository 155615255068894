import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import Loader from "components/Loader/Loader";
import NotificationAlert from "react-notification-alert";
import { notify } from "Utilities/common/commonFn";
import TabularContainer from "components/TabularContainer/TabularContainer";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import {
  getTrainersModuleAction,
  getTrainersTraineeAction,
} from "store/action/trainerActions/trainerProfileActions";
import { clearResponse } from "store/action/clearResponseAction";

import {
  tableDivStyle,
  paraStyle,
  actionBtnStyle,
} from "Utilities/common/commonStyle";

import { mapToCssModules } from "reactstrap/lib/utils";

const TrainerBatchDetails = (props) => {
  const [moduleList, setModuleList] = useState("");
  const [traineeList, setTraineeList] = useState("");
  const [batchName, setBatchName] = useState("");
  const [batchId, setBatchId] = useState("");
  const [moduleId, setModuleId] = useState("");
  // const [traineeList, setTraineeList] = useState('');

  const [isUpdatingModProgress, setIsUpdatingModProgress] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const history = useHistory();
  const notifi = useRef();

  // const handleModDropDown = (mod) => {
  //   setModuleId(mod?.batchModuleRelation?.moduleId);
  //   if (traineeList) setTraineeList('');
  // };

  useEffect(() => {
    if (localStorage.getItem("batch")) {
      let batch = JSON.parse(localStorage.getItem("batch"));
      setBatchId(batch.bId);
      setBatchName(batch.bTitle);
    }
  }, []);

  useEffect(() => {
    if (batchId) {
      let url = `batchId=${batchId}`;
      props.getTrainersModuleList(url);
    }
  }, [batchId]);

  useEffect(() => {
    if (
      props?.getTrainersTraineeRes?.trainersTraineeist?.data[0]
        ?.batchModuleRelation &&
      !props.task
    ) {
      if (
        props?.getTrainersTraineeRes?.trainersTraineeist?.data?.length === 0 ||
        props?.getTrainersTraineeRes?.trainersTraineeist?.data[0]
          ?.batchModuleRelation?.studentBatchRelations?.length === 0
      ) {
        notify("List is empty", true, notifi);
      }
    }
    if (props.getTrainersTraineeRes?.error)
      notify("Something went wrong", true, notifi);
  }, [props?.getTrainersTraineeRes]);

  useEffect(() => {
    if (props?.updateModprogressRes?.modProgressData?.data) {
      props.clearResponse("UPDATE_MODULE_PROGRESS_RESET");
      setIsUpdatingModProgress(false);
      notify("Module progress updated", false, notifi);
      setModuleList("");
      props.getTrainersModuleList(`batchId=${batchId}`);
    }
    if (props.updateModprogressRes?.error)
      notify(props.updateModprogressRes?.error?.message, true, notifi);
  }, [props?.updateModprogressRes]);

  useEffect(() => {
    if (props?.updateTraineeProgressRes?.traineeProgressData?.data) {
      props.clearResponse("UPDATE_TRAINEE_PROGRESS_RESET");
      notify("Trainee progress updated", false, notifi);

      if (moduleId) props.getTrainersTraineeList(batchId, moduleId);
      setTraineeList("");
    }
    if (props.updateTraineeProgressRes?.error)
      notify(props?.updateTraineeProgressRes?.error?.message, true, notifi);
  }, [props?.updateTraineeProgressRes]);
  useEffect(() => {
    if (props?.updateTraineeGradeRes?.traineeGradeData?.data) {
      props.clearResponse("UPDATE_TRAINEE_GRADE_RESET");
      notify("Trainee grade updated", false, notifi);

      if (moduleId) props.getTrainersTraineeList(batchId, moduleId);
      setTraineeList("");
    }
    if (props?.updateTraineeGradeRes?.error)
      notify(props.updateTraineeGradeRes?.error?.message, true, notifi);
  }, [props?.updateTraineeGradeRes]);

  useEffect(() => {
    if (props?.getTrainersModuleRes?.trainersModuleList?.data) {
      props.clearResponse("GET_TRAINERS_MODULE_RESET");
      setModuleList(props?.getTrainersModuleRes?.trainersModuleList?.data);
    }
  }, [props?.getTrainersModuleRes]);
  useEffect(() => {
    if (props?.uploadGradesRes?.uploadGradeData?.data)
      notify("File uploaded", false, notifi);
    if (props.uploadGradesRes?.error)
      notify(props.uploadGradesRes?.error?.message, true, notifi);
  }, [props?.uploadGradesRes]);
  useEffect(() => {
    if (props?.uploadAudioRes?.uploadAudioData?.data)
      notify("Recording uploaded", false, notifi);
    if (props.uploadAudioRes?.error)
      notify(props.uploadAudioRes?.error?.message, true, notifi);
  }, [props?.uploadAudioRes]);
  useEffect(() => {
    if (props?.deleteGrade?.deleteGradeData?.data) {
      notify("Grade deleted", false, notifi);
      props.clearResponse('DELETE_GRADE_RESET')
      if (moduleId) props.getTrainersTraineeList(batchId, moduleId);
    }
    if (props.deleteGrade?.error)
      notify(props.deleteGrade?.error?.message, true, notifi);
  }, [props?.deleteGrade]);
  useEffect(() => {
    if (props?.deleteUploadedMaterialData?.deleteUploadedMaterialData?.data) {
      notify("File deleted", false, notifi);
      props.clearResponse("DELETE_UPLOADED_MATERIAL_RESET");
    }
    if (props.deleteUploadedMaterialData?.error) {
      notify(props.deleteUploadedMaterialData?.error?.message, true, notifi);
      props.clearResponse("DELETE_UPLOADED_MATERIAL_RESET");
    }
  }, [props?.deleteUploadedMaterialData]);

  useEffect(() => {
    if (props?.banTraineeRes?.banTraineeData?.data) {
      notify("Updated successfully", false, notifi);
    }
    if (props.banTraineeRes?.error)
      notify(props.banTraineeRes?.error?.message, true, notifi);
  }, [props?.banTraineeRes?.banTraineeData]);
  // useEffect(() => {
  //   if (props?.getTrainersTraineeRes?.trainersTraineeist?.data) {
  //     setTraineeList(
  //       props?.getTrainersTraineeRes?.trainersTraineeist?.data[0]
  //         ?.batchModuleRelation?.studentBatchRelations
  //     );
  //   }
  // }, [props?.getTrainersTraineeRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notifi} />
      </div>
      <div
        style={{
          height: "fit-content",
          width: "100%",
          background: "#27293D",
          padding: "16px",
          borderRadius: "4px",
          fontSize: font?.fontSize || "inherit",
          fontWeight: font?.fontWeight || "inherit",
          backgroundColor: colorTheme?.bodyColor || "all",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          {batchName && (
            <h1
              style={{
                fontSize: font?.fontSize + 4 || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                margin: "0 auto 0 0",
                textTransform: "capitalize",
              }}
            >
              {batchName}
            </h1>
          )}
          <Button
            title="Back"
            aria-label="Back"
            onClick={(e) => {
              history.push("/trainer/dashboard");
            }}
            color="info"
          >
            Back
          </Button>{" "}
        </div>

        <div role="table">
          <div role="rowgroup">
            <div style={tableDivStyle} role="row">
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Title
              </span>
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Sub
              </span>
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Progress
              </span>
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Download
              </span>
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Upload Grades
              </span>
              <span
                style={{
                  ...paraStyle,
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#FFFFFF",
                }}
                role="columnheader"
              >
                Upload pdf/audio
              </span>
            </div>
          </div>

          {moduleList ? (
            moduleList.map((mod) => {
              return (
                <TabularContainer
                  key={mapToCssModules.id}
                  mod={mod}
                  setModuleId={setModuleId}
                  moduleId={moduleId}
                  batchId={batchId}
                  batchName={batchName}
                  traineeList={traineeList}
                  setTraineeList={setTraineeList}
                  // callApi={() => props.getTrainersTraineeList(batchId, moduleId)}
                />
              );
            })
          ) : props.getTrainersModuleRes?.isLoading ? (
            <Loader />
          ) : (
            <h3>No training assigned</h3>
          )}
        </div>
      </div>
      {props?.getTrainersModuleRes?.isLoading ||
      props?.getTrainersTraineeRes?.isLoading ||
      props?.updateModprogressRes?.isLoading ||
      props?.updateTraineeProgressRes?.isLoading ||
      props?.updateTraineeGradeRes?.isLoading ? (
        <Loader />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  getTrainersModuleRes: state.getTrainersModule,
  getTrainersTraineeRes: state.getTrainersTrainee,
  updateModprogressRes: state.updateModprogress,
  updateTraineeProgressRes: state.updateTraineeprogress,
  updateTraineeGradeRes: state.updateTraineeGrade,
  banTraineeRes: state.banTrainee,
  uploadGradesRes: state.uploadGrades,
  uploadAudioRes: state.uploadAudio,
  deleteGrade: state.deleteGrades,
  fontData: state.fontValues,
  colorData: state.colorValues,
  deleteUploadedMaterialData: state.deleteUploadedMaterial,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainersModuleList: (url) => {
    dispatch(getTrainersModuleAction(url));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
  getTrainersTraineeList: (batchId, moduleId) => {
    dispatch(getTrainersTraineeAction(batchId, moduleId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainerBatchDetails);
