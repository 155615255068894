import * as actionTypes from '../actionType';

import {
  getRequest,
  putRequest,
} from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const getNotificationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_NOTIFICATION,
      payload: {},
      isLoading: true,
    });

    let url='api/activity/notification'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {

        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_NOTIFICATION_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
 
        dispatch({
          type: actionTypes.GET_NOTIFICATION_FAIL,
          payload: { error: err?.response?.data},
          isLoading: false,
        });
      });
  };
};

export const editNotificationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_NOTIFICATION,
      payload: {},
      isLoading: true,
    });

    putRequest('api/activity/notification', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_NOTIFICATION_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.EDIT_NOTIFICATION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

