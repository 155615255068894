import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getQuestionReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUESTION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questionList: action.payload,
      };
    case actionTypes.GET_QUESTION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_QUESTION_RESET':
      return {};
    default:
      return state;
  }
};
export const createQuestionReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_QUESTION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createQuestionData: action.payload,
      };
    case actionTypes.CREATE_QUESTION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'CREATE_QUESTION_RESET':
      return {};

    default:
      return state;
  }
};
export const editQuestionReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_QUESTION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        editQuestionData: action.payload,
      };
    case actionTypes.EDIT_QUESTION_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case 'EDIT_QUESTION_RESET':
      return {};
    default:
      return state;
  }
};

export const deleteQuestionReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_QUESTION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteQuestionData: action.payload,
      };
    case actionTypes.DELETE_QUESTION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_QUESTION_RESET':
      return {};
    default:
      return state;
  }
};
