import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { quizBulkDeleteAction } from "store/action/bulkDeleteAction/quizBulkDeleteAction";
import { getQuizAction } from "store/action/quizAction/quizAction";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function BulkDeleteQuiz(props) {
    const { onClick, quizBulkArr, setQuizBulkArr, notify } = props;

    const bulkDeleteQUiz = () => {
        const bulkDeleteUrl = `/api/quize/bulkDelete?id=''&${quizBulkArr.toString().replaceAll(',', '&')}`;
        props.quizBulkDelete(bulkDeleteUrl);
    };

    useEffect(() => {
        if (props?.quizBulkDeleteRes?.deleteQuizData) {
            notify("Quizzes deleted successfully", false);
            props.clearResponse("BULK_DELETE_QUIZ_RESET");
            props.getQuizList();
            setQuizBulkArr([])
            onClick();
        }
        if (props.quizBulkDeleteRes?.error) {
            notify(props.quizBulkDeleteRes?.error?.message, true);
            props.clearResponse("BULK_DELETE_QUIZ_RESET");
        }
    }, [props?.quizBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Quizzes?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteQUiz}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    quizBulkDeleteRes: state.quizBulkDelete,
    getQuizData: state.getQuiz,
});

const mapDispatchToProps = (dispatch) => ({
    quizBulkDelete: (payload) => {
        dispatch(quizBulkDeleteAction(payload));
    },
    getQuizList: () => {
        dispatch(getQuizAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteQuiz);
