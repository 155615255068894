/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import {
  createAdminAction,
  getAdminsAction,
} from "store/action/adminActions/adminActions";
import { getSecurityGroupAction } from "store/action/SecurityGroupAction/SecurityGroupAction";
import { clearResponse } from "store/action/clearResponseAction";
import "../../MY_LOGIN_AND_SIGNUP/db/config";
import * as firebase from "firebase";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
// import styles from './sign_up_form.module.css'

function CreateAdmin(props) {
  const { onClick, setUserData, notify } = props;
  const { register, handleSubmit, errors } = useForm();
  const [visible, setVisible] = useState(false);
  const [mobileError, setMobileError] = useState("");

  const [securityGroups, setSecurityGroups] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [error, setError] = useState("");

  //added this for the options
  const options = [
    // { label: 'Dashboard', value: 'Dashboard' },
    { label: "Admin Management", value: "User_Management" },
    { label: "Trainer Management", value: "Trainer_Management" },
    { label: "Trainee Management", value: "Trainee_Management" },
    { label: "Module Management", value: "Module_Management" },
    { label: "Quiz Management", value: "Quiz_Management" },
    { label: "Package Management", value: "Package_Management" },
    // { label: 'Transaction Management', value: 'Transaction_Management' },
    { label: "Batch Management", value: "Batch_Management" },
    { label: "Enquiry", value: "Enquiry" },
    { label: "Feedback", value: "Feedback" },
    { label: "Chat", value: "Chat" },
    { label: "Media Center", value: "Media_Center" },
    { label: "Discount Management", value: "Discount_Management" },
    // { label: 'Reports', value: 'Reports' },
  ];
  //added this for the state to pass to firebase
  const [selectedPermission, setselectedPermission] = useState([]);
  const [newUser, setNewUser] = useState({});
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  const handleChange = (e) => {
    e.persist();
    const name = e.target.name;
    const value = e.target.value;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSubmitCreate = () => {
    
    newUser.secGrpId = +newUser.secGrpId;
    newUser.firebaseId = "1";
    newUser.orgId = 1;
    props.createAdmin(newUser);
  };

  useEffect(() => {
    if (props.createAdminRes?.createAdminData) {
      props.getAdminList();
      notify("Admin created successfully", false);
      props.clearResponse("CREATE_ADMIN_RESET");
      onClick();
    }
    if (props?.createAdminRes?.error && !props.createAdminRes.isLoading) {
      notify(props.createAdminRes?.error.message, true);
      props.clearResponse("CREATE_ADMIN_RESET");
    }
  }, [props?.createAdminRes]);

  useEffect(() => {
    if (props?.getSecurityGroupRes?.securityGroupList) {
      const groupList = props?.getSecurityGroupRes?.securityGroupList?.data;
      let arr = [];
      groupList.map((security) => {
        arr.push({ label: security.name, value: security.id });
      });
      setSecurityGroups(arr);
    }
    if (props?.getSecurityGroupRes?.error) {
      console.log(props?.getSecurityGroupRes?.error);
    }
    if (props.getSecurityGroupRes?.error)
      notify(props.getSecurityGroupRes?.error?.message, true);
  }, [props?.getSecurityGroupRes]);

  useEffect(() => {
    if (!props?.getSecurityGroupRes?.securityGroupList) {
      props.getSecurityGroupList();
    }
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card
            style={{
              marginBottom: "0",
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader>
              <h2
                className="title"
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                User Registration
              </h2>
            </CardHeader>
            <CardBody>
              <Form
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   handleSubmitCreate();
                // }}
                onSubmit={handleSubmit(handleSubmitCreate)}
              >
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="FirstName">First Name</label>
                      <Input
                        id="FirstName"
                        innerRef={register({ required: true })}
                        name="firstName"
                        onChange={handleChange}
                        placeholder="First Name"
                        type="text"
                        // required
                      />
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter first name
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="LastName">Last Name</label>
                      <Input
                        id="LastName"
                        innerRef={register({ required: true })}
                        name="lastName"
                        onChange={handleChange}
                        placeholder="Last Name"
                        type="text"
                        // required
                      />
                      {errors.lastName &&
                        errors.lastName.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter last name
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="Email">Email</label>
                      <Input
                        id="Email"
                        innerRef={register({ required: true })}
                        name="email"
                        onChange={handleChange}
                        placeholder="user@user.com"
                        type="email"
                        // required
                      />
                      {errors.email && errors.email.type === "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please enter email
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="Password">Password</label>
                      <Input
                        id="Password"
                        innerRef={register({ required: true, minLength: 8 })}
                        // required
                        name="paswd"
                        onChange={handleChange}
                        type="password"
                        placeholder="Password"
                      />

                      {errors.paswd && errors.paswd.type === "minLength" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Password should be atleast 8 digits.
                        </span>
                      )}
                      {errors.paswd && errors.paswd.type === "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please enter password.
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="Mobile">Mobile</label>
                      <Input
                        id="Mobile"
                        innerRef={register({
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                          // min: 6000000000,
                        })}
                        name="mobile"
                        placeholder="10-digit Number"
                        type="number"
                        onChange={handleChange}
                        // required
                      />
                      {/* {errors.mobile && errors.mobile.type !== "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please Enter Valid Mobile No.
                        </span>
                      )} */}

                      {/* {errors !== '' ? <p style={{ color: 'red' }}>{mobileError}</p> : ''} */}

                      {error && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          {error}
                        </span>
                      )}
                      {errors.mobile && errors.mobile.type !== "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please Enter Valid Mobile No.
                        </span>
                      )}
                      {errors.mobile && errors.mobile.type === "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please Enter Mobile No.
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup className="permissions-multiselect">
                      <label htmlFor="Account">Account Permission</label>
                      <Input
                        id="Account"
                        name="secGrpId"
                        innerRef={register({ required: true })}
                        onChange={handleChange}
                        placeholder="Select security group"
                        type="select"
                        // required
                      >
                        <option value={""}>Select security group</option>
                        {securityGroups &&
                          securityGroups.map((secGrp) => {
                            return (
                              <option value={secGrp.value}>
                                {secGrp.label}
                              </option>
                            );
                          })}
                      </Input>
                      {errors.secGrpId &&
                        errors.secGrpId.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please select security group
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  {/* {visible === false ? <Button className="btn-fill" color="info" type="submit">
                    Save
                  </Button> : <Button onClick={onClick} className="btn-fill" color="info">Done</Button>}
                  {visible === false && <Button className="btn-fill" color="info" onClick={onClick}>Back</Button>} */}

                  <Button title="Submit" color="info" type="submit">
                    Submit
                  </Button>

                  <Button title="Back" onClick={onClick}>
                    Back
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  createAdminRes: state.createAdmin,
  getAdminData: state.getAdmin,
  getSecurityGroupRes: state.getSecurityGroup,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createAdmin: (payload) => {
    dispatch(createAdminAction(payload));
  },
  getAdminList: () => {
    dispatch(getAdminsAction());
  },
  getSecurityGroupList: () => {
    dispatch(getSecurityGroupAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);
