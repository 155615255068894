import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/messaging';
import "firebase/analytics";

// var firebaseConfig = {
//     apiKey: "AIzaSyC61Y16Wn0XbYlvbGJIGatZbEbj69rm5Ko",
//     authDomain: "ebook-ee740.firebaseapp.com",
//     databaseURL: "https://ebook-ee740.firebaseio.com",
//     projectId: "ebook-ee740",
//     storageBucket: "ebook-ee740.appspot.com",
//     messagingSenderId: "917961071487",
//     appId: "1:917961071487:web:a09914183512042f8ad84a"
//   };
//Billing Account
// var firebaseConfig = {
//   apiKey: "AIzaSyD-jnNFP4MtQjzhROzGyGb--_vM5WbfdUA",
//   authDomain: "tn-edu.firebaseapp.com",
//   projectId: "tn-edu",
//   storageBucket: "tn-edu.appspot.com",
//   messagingSenderId: "117151481797",
//   appId: "1:117151481797:web:f17ba8b4fab9be6ee415ac",
//   measurementId: "G-PDLLDCPBF9"
// };

const firebaseConfig = {
  apiKey: "AIzaSyApmwZ6U-xEsJhx-8nUYri1riUlyNJFAt0",
  authDomain: "atriev-lms.firebaseapp.com",
  projectId: "atriev-lms",
  storageBucket: "atriev-lms.appspot.com",
  messagingSenderId: "360469970197",
  appId: "1:360469970197:web:425c629b65bb54ff037d1c",
  measurementId: "G-55ZWE21LQW",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAnalytics = firebase.analytics();
var db = firebase.firestore();
const auth = firebaseApp.auth();
const messaging = firebaseApp.messaging();
export { auth, db, firebaseAnalytics, messaging };
