import {
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Table,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import { Edit, Delete } from "@material-ui/icons";

import CloseIcon from "@material-ui/icons/Close";
import NotificationAlert from "react-notification-alert";
import "./AddClassesTimeDialog.css";
import ModalBody from "reactstrap/lib/ModalBody";
import EditMeetingModal from "./EditMeetingModal";
import timeJson from './time.json'

function AddClassesTimeDialog({ classesOn, setModalOpen }) {
  const notification = React.useRef();
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    errors: errors1,
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
  } = useForm();
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    errors: errors3,
  } = useForm();
  const {
    register: register4,
    handleSubmit: handleSubmit4,
    errors: errors4,
  } = useForm();
  const {
    register: register5,
    handleSubmit: handleSubmit5,
    errors: errors5,
  } = useForm();
  const {
    register: register6,
    handleSubmit: handleSubmit6,
    errors: errors6,
  } = useForm();
  const {
    register: register0,
    handleSubmit: handleSubmit0,
    errors: errors0,
  } = useForm();

  const [timeError, setTimeError] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [editValue, setEditValue] = useState({
    Monday: {},
    Tuesday: {},
    Wednesday: {},
    Thursday: {},
    Friday: {},
    Saturday: {},
    Sunday: {},
  });
  const [selectedWeek, setSelectedWeek] = useState({});
  const [finalClassList, setFinalClassList] = useState([]);
  const [classesList, setClassesList] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const onSubmitMonday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Monday: [...prev.Monday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitTuesday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Tuesday: [...prev.Tuesday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitWednesday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Wednesday: [...prev.Wednesday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitThursday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Thursday: [...prev.Thursday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitFriday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Friday: [...prev.Friday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitSaturday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Saturday: [...prev.Saturday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  const onSubmitSunday = (data, e) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return;
    }
    setClassesList((prev) => ({
      ...prev,
      Sunday: [...prev.Sunday, data],
    }));
    setEditValue({
      Monday: {},
      Tuesday: {},
      Wednesday: {},
      Thursday: {},
      Friday: {},
      Saturday: {},
      Sunday: {},
    });
    e.target.reset();
    setTimeError(-1);
  };

  useEffect(() => {
    if (classesOn[0]) {
      classesOn[0].forEach((item) => {
        setClassesList((prev) => ({
          ...prev,
          [item.weekName]: [...prev[item.weekName], item],
        }));
        setSelectedWeek((prev) => ({
          ...prev,
          [item.weekName]: !prev[item.weekName],
        }));
      });
    }
  }, []);

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 6,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  useEffect(() => {
    let temp = [];
    Object.entries(classesList).forEach(([k, v], i) => {
      if (v && v?.length !== 0) {
        return classesList[k]?.forEach((item) => {
          temp.push({ ...item, weekName: k });
        });
      }
    });
    setFinalClassList(temp);
  }, [classesList]);

  return (
    <div
      style={{
        backgroundColor: "#1f1e2e",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notification} />
      </div>

      <div style={{ padding: "5px 20px" }}>
        <Dialog
          onClose={() => setDialogOpen((prev) => !prev)}
          aria-labelledby="simple-dialog-title"
          open={dialogOpen}
          fullWidth
        >
          <EditMeetingModal
            currentData={currentData}
            notify={notify}
            setDialogOpen={setDialogOpen}
            classesList={classesList}
            setClassesList={setClassesList}
          />
        </Dialog>
        {/* <Modal
          isOpen={true}
          style={{
            display: "flex",
            flex: 1,
            minWidth: "85%",
            zIndex: 9999999999999999,
          }}
        >
          <ModalBody
          // style={{
          //     width: '100%', display: 'inline-block', padding: '0px !important', position: 'absolute',
          //     border: '1px solid white !important',
          // }}
          >
            <EditMeetingModal
              currentData={currentData}
              notify={notify}
              setDialogOpen={setDialogOpen}
            />
          </ModalBody>
        </Modal> */}
        <Row style={{ justifyContent: "flex-end" }}>
          <IconButton
            edge="start"
            style={{ color: "white" }}
            onClick={() => setModalOpen((prev) => !prev)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Row>
        <Row>
          <Col md="12">
            <Card style={{ margin: 0, marginBottom: 60 }}>
              <CardHeader>
                <h5 className="title">Create Classes</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit1(onSubmitMonday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Monday"]
                                ? selectedWeek["Monday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Monday"]: !prev["Monday"],
                              }));
                            }}
                          />
                        }
                        label="Monday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={editValue && editValue.Monday.startTime}
                          innerRef={register1({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                        >
                          <option value={""}>Select</option>
                          {timeJson.map(item=>{
                            return <option value={item.value}>{item.label}</option>
                          })}
                        </Input>
                        {errors1.startTime &&
                          errors1.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Monday.endTime}
                          innerRef={register1({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors1.endTime &&
                          errors1.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 1 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={editValue && editValue.Monday.meetingID}
                          innerRef={register1({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors1.meetingID &&
                          errors1.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Monday.meetingPass
                          }
                          innerRef={register1({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors1.meetingPass &&
                          errors1.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Monday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
                {classesList &&
                  classesList.Monday !== undefined &&
                  classesList.Monday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Monday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Monday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                        // setEditValue((prev) => ({
                                        //   ...prev,
                                        //   Monday: item,
                                        // }));
                                        // setClassesList((prev) => ({
                                        //   ...prev,
                                        //   Monday: prev.Monday?.filter(
                                        //     (_, index) => index !== i
                                        //   ),
                                        // }));
                                      }}
                                    />
                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Monday: prev.Monday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}
                <Form onSubmit={handleSubmit2(onSubmitTuesday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Tuesday"]
                                ? selectedWeek["Tuesday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Tuesday"]: !prev["Tuesday"],
                              }));
                            }}
                          />
                        }
                        label="Tuesday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Tuesday.startTime
                          }
                          innerRef={register2({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors2.startTime &&
                          errors2.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Tuesday.endTime}
                          innerRef={register2({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors2.endTime &&
                          errors2.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 2 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Tuesday.meetingID
                          }
                          innerRef={register2({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors2.meetingID &&
                          errors2.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Tuesday.meetingPass
                          }
                          // defaultValue = {currentBatch && currentBatch.meetingPass}
                          innerRef={register2({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors2.meetingPass &&
                          errors2.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Tuesday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Tuesday !== undefined &&
                  classesList.Tuesday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Tuesday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Tuesday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Tuesday: prev.Tuesday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}

                <Form onSubmit={handleSubmit3(onSubmitWednesday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Wednesday"]
                                ? selectedWeek["Wednesday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Wednesday"]: !prev["Wednesday"],
                              }));
                            }}
                          />
                        }
                        label="Wednesday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Wednesday.startTime
                          }
                          innerRef={register3({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors3.startTime &&
                          errors3.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Wednesday.endTime
                          }
                          innerRef={register3({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors3.endTime &&
                          errors3.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 3 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Wednesday.meetingID
                          }
                          innerRef={register3({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors3.meetingID &&
                          errors3.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Wednesday.meetingPass
                          }
                          innerRef={register3({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors3.meetingPass &&
                          errors3.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Wednesday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Wednesday !== undefined &&
                  classesList.Wednesday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Wednesday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Wednesday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Wednesday: prev.Wednesday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}

                <Form onSubmit={handleSubmit4(onSubmitThursday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Thursday"]
                                ? selectedWeek["Thursday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Thursday"]: !prev["Thursday"],
                              }));
                            }}
                          />
                        }
                        label="Thursday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Thursday.startTime
                          }
                          innerRef={register4({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors4.startTime &&
                          errors4.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Thursday.endTime}
                          innerRef={register4({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors4.endTime &&
                          errors4.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 4 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Thursday.meetingID
                          }
                          innerRef={register4({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors4.meetingID &&
                          errors4.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Thursday.meetingPass
                          }
                          innerRef={register4({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors4.meetingPass &&
                          errors4.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Thursday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Thursday !== undefined &&
                  classesList.Thursday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Thursday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Thursday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Thursday: prev.Thursday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}

                <Form onSubmit={handleSubmit5(onSubmitFriday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Friday"]
                                ? selectedWeek["Friday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Friday"]: !prev["Friday"],
                              }));
                            }}
                          />
                        }
                        label="Friday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={editValue && editValue.Friday.startTime}
                          innerRef={register5({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors5.startTime &&
                          errors5.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Friday.endTime}
                          innerRef={register5({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors5.endTime &&
                          errors5.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 5 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={editValue && editValue.Friday.meetingID}
                          innerRef={register5({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors5.meetingID &&
                          errors5.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Friday.meetingPass
                          }
                          innerRef={register6({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors5.meetingPass &&
                          errors5.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Friday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Friday !== undefined &&
                  classesList.Friday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Friday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Friday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Friday: prev.Friday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}

                <Form onSubmit={handleSubmit6(onSubmitSaturday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Saturday"]
                                ? selectedWeek["Saturday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Saturday"]: !prev["Saturday"],
                              }));
                            }}
                          />
                        }
                        label="Saturday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Saturday.startTime
                          }
                          innerRef={register6({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors6.startTime &&
                          errors6.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Saturday.endTime}
                          innerRef={register6({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors6.endTime &&
                          errors6.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 6 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Saturday.meetingID
                          }
                          innerRef={register6({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors6.meetingID &&
                          errors6.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Saturday.meetingID
                          }
                          innerRef={register6({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors6.meetingPass &&
                          errors6.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Saturday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Saturday !== undefined &&
                  classesList.Saturday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Saturday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Saturday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Saturday: prev.Saturday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}

                <Form onSubmit={handleSubmit0(onSubmitSunday)}>
                  <Row>
                    <Col style={{ paddingTop: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedWeek["Sunday"]
                                ? selectedWeek["Sunday"]
                                : false
                            }
                            color="primary"
                            onChange={(e) => {
                              setSelectedWeek((prev) => ({
                                ...prev,
                                ["Sunday"]: !prev["Sunday"],
                              }));
                            }}
                          />
                        }
                        label="Sunday"
                      />
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Start Time</label>
                        <Input
                          defaultValue={editValue && editValue.Sunday.startTime}
                          innerRef={register0({ required: false })}
                          name="startTime"
                          placeholder="Start Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors0.startTime &&
                          errors0.startTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start time
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>End Time</label>
                        <Input
                          defaultValue={editValue && editValue.Sunday.endTime}
                          innerRef={register0({ required: false })}
                          name="endTime"
                          placeholder="End Time"
                          type="select"
                          >
                            <option value={""}>Select</option>
                            {timeJson.map(item=>{
                              return <option value={item.value}>{item.label}</option>
                            })}
                          </Input>
                        {errors0.endTime &&
                          errors0.endTime.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end time
                            </span>
                          )}
                        {timeError === 0 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            {timeError}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>ID</label>
                        <Input
                          defaultValue={editValue && editValue.Sunday.meetingID}
                          innerRef={register0({ required: false })}
                          name="meetingID"
                          placeholder="Meeting ID"
                          type="number"
                        />
                        {errors0.meetingID &&
                          errors0.meetingID.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting Id
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Password</label>
                        <Input
                          defaultValue={
                            editValue && editValue.Sunday.meetingPass
                          }
                          innerRef={register0({ required: false })}
                          name="meetingPass"
                          placeholder="Meeting Password"
                          type="text"
                        />

                        {errors0.meetingPass &&
                          errors0.meetingPass.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter the meeting password
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col style={{ display: "flex" }}>
                      {selectedWeek["Sunday"] && (
                        <Button
                          style={{ alignSelf: "center", marginBottom: 0 }}
                          color="info"
                          type="submit"
                        >
                          Add
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>

                {classesList &&
                  classesList.Sunday !== undefined &&
                  classesList.Sunday?.length !== 0 && (
                    <Card style={{ marginBottom: 10 }}>
                      <CardBody>
                        <Table>
                          <thead>
                            <tr>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Start From
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                End At
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Meeting Id
                              </th>
                              <th
                                className="timeTableHead"
                                style={{ textAlign: "center" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classesList.Sunday?.map((item, i) => {
                              return (
                                <tr
                                  key={`${item.startTime}_${item.endTime}_${i}`}
                                >
                                  <td className="timeTablecell" align="center">
                                    {item.startTime ? item.startTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.endTime ? item.endTime : 'N/A'}
                                  </td>
                                  <td className="timeTablecell" align="center">
                                    {item.meetingID ? item.meetingID : 'N/A'}
                                  </td>
                                  <td
                                    className="timeTablecell"
                                    align="center"
                                    style={{
                                      minWidth: 70,
                                    }}
                                  >
                                    <Edit
                                      style={{
                                        color: "#247cf7",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setCurrentData({
                                          week: "Sunday",
                                          index: i,
                                        });
                                        setDialogOpen((prev) => !prev);
                                      }}
                                    />

                                    <Delete
                                      onClick={() => {
                                        setClassesList((prev) => ({
                                          ...prev,
                                          Sunday: prev.Sunday?.filter(
                                            (_, index) => index !== i
                                          ),
                                        }));
                                      }}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {finalClassList.length !== 0 && (
          <Row
            style={{
              position: "fixed",
              bottom: "0",
              minHeight: "60px",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              left: "-10",
              backgroundColor: "#272a3d",
            }}
          >
            <Row style={{ width: "100%", alignItems: "center" }}>
              <Button
                color="info"
                onClick={() => {
                  classesOn[1](finalClassList);
                  setModalOpen((prev) => !prev);
                }}
              >
                Submit
              </Button>
            </Row>
          </Row>
        )}
      </div>
    </div>
  );
}

export default AddClassesTimeDialog;
