import * as actionTypes from "../actionType";
import { getRequest } from "Utilities/apiService/admin";
export const getTraineeReportAction = (batchId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRAINEE_REPORT,
      payload: {},
      isLoading: true,
    });

    let url = `api/batch/student/allOverProgress?batchId=${batchId}`;

  

    getRequest(url)
      .then((res) => {

        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_TRAINEE_REPORT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
    
        dispatch({
          type: actionTypes.GET_TRAINEE_REPORT_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
