import React, { useState, useEffect } from 'react';

import { Table, Button } from 'reactstrap';
import { Edit } from '@material-ui/icons';

import { actionBtnStyle } from 'Utilities/common/commonStyle';

import { connect } from 'react-redux';

import DropdownForDisplay from 'components/DropdownForDisplay/DropdownForDisplay';

const RelationTable = ({ relationList, handleEditButton, ...props }) => {
  const [font, setFont] = useState({});

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  return (
    <div>
      <div>
        <Table
          style={{
            fontSize: font?.fontSize || 'inherit',
            fontWeight: font?.fontWeight || 'inherit',
          }}
          className='tablesorter'
        >
          <thead className='text-primary'>
            <tr>
              <th style={{ textAlign: 'center' }}>Module</th>
              <th style={{ textAlign: 'center' }}>Trainer</th>
              <th style={{ textAlign: 'center' }}>Quiz</th>
              <th style={{ textAlign: 'center' }}>Assignment</th>
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {relationList &&
              relationList.map((relation) => {
                if (relation.module) {
                  return (
                    <tr>
                      {/* {!isEdit ? ( */}
                      <>
                        <td style={{ textAlign: 'center' }}>
                          {relation?.module ? relation?.module?.title : '-'}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {relation.teacherModuleRelations[0]
                            ? `${relation.teacherModuleRelations[0]?.teacher?.firstName} ${relation?.teacherModuleRelations[0]?.teacher?.lastName}`
                            : '-'}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {relation?.quizBatchRelations?.length !== 0 ? (
                            <DropdownForDisplay
                              aria-label='Assigned quizes'
                              title='Assigned quizes'
                              arr={relation?.quizBatchRelations}
                              data={`quiz`}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          {relation?.batchAssignmentRelations?.length !== 0 ? (
                            <DropdownForDisplay
                              aria-label='Assigned assignments'
                              title='Assigned assignments'
                              arr={relation?.batchAssignmentRelations}
                              data={`assignment`}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                      </>

                      <td style={{ textAlign: 'center' }}>
                        
                          <button
                            title='Edit'
                            style={actionBtnStyle}
                            onClick={() => handleEditButton(relation)}
                            aria-label='edit relation'
                          >
                            <Edit
                              style={{
                                color: '#247cf7',
                                cursor: 'pointer',
                              }}
                            />
                          </button>
                    
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RelationTable);
