import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { eachDayOfInterval } from 'date-fns';

import { positionCenter, btnStyle } from 'Utilities/common/commonStyle';

const CalculateDays = ({
  batchName,
  dateData,
  handleSubmit,
  handleBack,
  notify,
  totalDays,
  setTotalDays,
}) => {
  const days = [
    {
      day: 'monday',
      code: 'Mon',
    },
    {
      day: 'tuesday',
      code: 'Tue',
    },
    {
      day: 'wednesday',
      code: 'Wed',
    },
    {
      day: 'thursday',
      code: 'Thu',
    },
    {
      day: 'friday',
      code: 'Fri',
    },
    {
      day: 'saturday',
      code: 'Sat',
    },
    {
      day: 'sunday',
      code: 'Sun',
    },
  ];

  const inpStyle = {
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
    Height: '60px',
    padding: '0 4px',
    height: '35px',
    display: 'inline-block',
  };

  const [dates, setDates] = useState({ startDate: '', endDate: '' });
  const [selectedDays, setSelectedDays] = useState([]);

  const handleChangeStartDate = (e) => {
    const value = e?.target?.value;
    setDates({ ...dates, startDate: value });
  };

  const handleChangeEndDate = (e) => {
    const value = e?.target?.value;
    setDates({ ...dates, endDate: value });
  };

  const handleChangeCheckbox = (e) => {
    const checked = e?.target?.checked;
    const value = e?.target?.value;
    if (checked) {
      setSelectedDays([...selectedDays, value]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== value));
    }
  };

  const handleCalculate = () => {
    if (selectedDays?.length) {
      const result = eachDayOfInterval({
        start: new Date(dates?.startDate),
        end: new Date(dates?.endDate),
      });
      const data = result.filter((day) =>
        selectedDays.includes(day?.toString()?.slice(0, 3))
      );
      setTotalDays(data?.length);
      console.log(data, 'dates');
    } else {
      notify('Select days to calculate', true);
    }
  };

  useEffect(() => {
    setDates(dateData);
  }, []);

  useEffect(() => {
    console.log(selectedDays);
  }, [selectedDays]);

  return (
    <>
      <div>
        <h2
          style={{ fontSize: '20px' }}
        >{`Calculate number of days for ${batchName}`}</h2>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '24px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                color: 'white',
              }}
            >
              <label htmlFor='start-date'>Start date</label>

              <input
                style={inpStyle}
                value={dates?.startDate}
                type='date'
                name=''
                id='start-date'
                onChange={handleChangeStartDate}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '50%',
                color: 'white',
              }}
            >
              <label htmlFor='end-date'>End date</label>
              <input
                style={inpStyle}
                value={dates?.endDate}
                type='date'
                name=''
                id='end-date'
                onChange={handleChangeEndDate}
                min={dates?.startDate}
              />
            </div>
          </div>
          <div>
            <h3 style={{ fontSize: '16px', margin: '24px 0 16px' }}>
              Select days to count
            </h3>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '13px',
                color: 'white',
              }}
            >
              {days.map((item) => (
                <div
                  key={item?.day}
                  style={{
                    ...positionCenter,
                    borderRadius: '4px',
                    padding: '4px 6px',
                    background: '#005CC8',
                  }}
                >
                  <input
                    style={{ scale: '1.2' }}
                    type='checkbox'
                    id={item?.day}
                    value={item?.code}
                    onChange={handleChangeCheckbox}
                  />
                  <label
                    style={{
                      textTransform: 'capitalize',
                      margin: '0',
                      paddingLeft: '13px',
                    }}
                    htmlFor={item?.day}
                  >
                    {item?.day}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px',
            margin: '32px 0 16px',
          }}
        >
          <div style={{ display: 'flex', gap: '4px' }}>
            <input
              aria-label='calculated days'
              style={{
                ...inpStyle,
                width: '35px',
                textAlign: 'center',
                fontWeight: '600',
              }}
              type='text'
              value={totalDays}
              onChange={(e) => setTotalDays(e.target.value)}
            />
            <Button
              aria-label='Calculate'
              title='Calculate'
              color='info'
              style={{
                ...btnStyle,
                margin: '0',
                width: '100px',
                height: '35px',
              }}
              onClick={handleCalculate}
            >
              Calculate
            </Button>
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <Button
              color='info'
              aria-label='Submit'
              title='Submit'
              style={{
                ...btnStyle,
                margin: '0',
                width: '100px',
                height: '35px',
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              aria-label='Back'
              title='Back'
              style={{
                margin: '0',
                width: '100px',
                height: '35px',
                borderRadius: '4px',
              }}
              onClick={handleBack}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculateDays;
