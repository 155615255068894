import React from 'react';

import { Input, FormGroup } from 'reactstrap';

const InputComp = ({ label, useForm, name, errors, ...rest }) => {
  // const { register, errors } = useForm();

  return (
    <>
      <FormGroup>
        <label htmlFor={name}>{label}</label>
        <Input  id={name} name={name} {...rest} />
        {errors[name] && errors[name].type === 'required' && (
          <span
            style={{
              fontSize: 12,
              color: 'red',
              top: -20,
              position: 'relative',
            }}
          >
            {`${label} required`}
          </span>
        )}
      </FormGroup>
    </>
  );
};

export default InputComp;
