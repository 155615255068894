import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getFilesReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FILES:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_FILES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fileData: action.payload,
      };
    case actionTypes.GET_FILES_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_FILES_RESET':
      return {};
    default:
      return state;
  }
};
export const deleteFilesReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_FILE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deletedFileData: action.payload,
      };
    case actionTypes.DELETE_FILE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_FILE_RESET':
      return {};
    default:
      return state;
  }
};
