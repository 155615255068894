import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { trainingBulkDeleteAction } from "store/action/bulkDeleteAction/trainingBulkDeleteAction";
import { getBatchAction } from "store/action/batchActions/batchActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function BulkDeleteTraining(props) {
    const { onClick, trainingArr, setTrainingArr, notify } = props;

    const bulkDeleteTraining = () => {
        const bulkDeleteUrl = `/api/batch/bulkDelete?id=''&${trainingArr.toString().replaceAll(',', '&')}`;
        props.trainingBulkDelete(bulkDeleteUrl);
    };

    useEffect(() => {
        if (props?.trainingBulkDeleteRes?.deleteTrainingData) {
            notify("Trainings deleted successfully", false);
            props.clearResponse("BULK_DELETE_TRAINING_RESET");
            props.getBatchList();
            setTrainingArr([])
            onClick();
        }
        if (props.trainingBulkDeleteRes?.error) {
            notify(props.trainingBulkDeleteRes?.error?.message, true);
            props.clearResponse("BULK_DELETE_TRAINING_RESET");
        }
    }, [props?.trainingBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Trainings?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteTraining}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    trainingBulkDeleteRes: state.trainingBulkDelete,
    getBatchData: state.getBatch,
});

const mapDispatchToProps = (dispatch) => ({
    trainingBulkDelete: (payload) => {
        dispatch(trainingBulkDeleteAction(payload));
    },
    getBatchList: () => {
        dispatch(getBatchAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteTraining);
