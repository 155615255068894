import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

import { btnStyle, positionCenter } from 'Utilities/common/commonStyle';
const RemarkComp = ({ data, index }) => {
  const [inputValue, setInputValue] = useState('');
  const [isAdded, setIsAdded] = useState(false);
  const [error, setError] = useState(false);
  return (
    <div style={{ display: 'flex' }}>
      <input
        style={{
          border: error ? '1px solid red' : '1px solid lightgrey',
          width: '270px',
          height: '37px',
          padding: ' 0 4px',
          borderRadius: '4px',
          background: 'transparent',
          color: 'white',
          outline: 'none'
        }}
        key={index}
        title='Add remarks '
        type='search'
        name='Add remarks'
        placeholder='Add remarks'
        // value={inputData}
        onChange={(e) => {
          setInputValue(e.target.value);
          setError(false);
          if (!e.target.value) {
            setIsAdded(false);
          }
        }}
      />
      <button
        aria-disabled={isAdded}
        aria-label='Add Remarks'
        title='Add Remarks'
        style={{
          ...btnStyle,
          background: '#2C68F5',
          marginLeft: '4px',
          minWidth: 'fit-content',
          ...positionCenter,
          gap: '4px',
          minWidth: '92px',
          height: '37px',
        }}
        onClick={isAdded ? null : () => {
          // remarks[index] = inputData
          // setInputData("");
          if (inputValue) {
            data.remark = inputValue;
            setIsAdded(true);
            setInputValue('');
          } else {
            setError(true);
          }
        }}
      >
        {!isAdded ? (
          <>
            <span>Add </span>
            <AddIcon />
          </>
        ) : (
          <>
            <span>Added </span>
            <CheckIcon />
          </>
        )}
      </button>
    </div>
  );
};

export default RemarkComp;
