const intialState = {};
const resetState = {data: {bodyColor:"inherit", sidenavColor:"inherit", navbarColor:"inherit", borderColor:"inherit"}}

export const colorReducer = (state = intialState, action) => {
  switch (action.type) {
    case 'GET_COLOR':
      return { ...state, isLoading: false, colorData: action.payload };
    case 'COLOR_RESET':
      return { ...state, isLoading: false, colorData: resetState };
    default:
      return state;
  }
};
