import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ModalBody,
  Modal,
} from "reactstrap";
import firebase from "firebase";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { Dialog, Slide } from "@material-ui/core";
import AddClassesTime from "./AddClassesTime";
import AddClassesTimeDialog from "./AddClassesTimeDialog";
import { connect } from "react-redux";

import {
  createBatchAction,
  editBatchAction,
  getBatchAction,
} from "store/action/batchActions/batchActions";

import { clearResponse } from "store/action/clearResponseAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Add_Edit_Batch({
  handleClose,
  notify,
  setBatchData,
  currentBatch,
  ...props
}) {
  if (currentBatch.startDate)
    currentBatch.startDate = new Date(currentBatch?.startDate)
      .toISOString()
      .split("T")[0];
  if (currentBatch.endDate)
    currentBatch.endDate = new Date(currentBatch?.endDate)
      .toISOString()
      .split("T")[0];

  const weekOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const { register, handleSubmit, errors } = useForm();

  const [weekName, setWeekName] = React.useState(currentBatch.weekName || []);
  const [active, setActive] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [weekdayError, setWeekdayError] = useState("");
  const [batchErr, setBatchErr] = useState(false);
  const [dateError, setDateError] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const classesOn = useState([]);

  const handleChange = (event) => {
    setWeekdayError("");
    setWeekName(event.target.value);
  };

  const submitData = async (data) => {
    if (data.startDate >= data.endDate) {
      setDateError("End date cannot be less than start date");
      return;
    }

    if (!currentBatch) {
      props.createBatch(data);
    } else {
      data.id = currentBatch.id;
      props.editBatch(data);
    }

    setActive(true);
  };

  useEffect(() => {
    if (currentBatch)
      classesOn[1](
        currentBatch?.classesOn !== undefined ? currentBatch?.classesOn : []
      );
  }, []);

  useEffect(() => {
    if (props?.createBatchRes?.createBatchData?.data) {
      props.getBatchList();
      notify("Training created successfully", false);
      props.clearBatch("CREATE_BATCH_RESET");

      handleClose();
    }
  }, [props?.createBatchRes]);
  useEffect(() => {
    if (props?.editBatchRes?.editBatchData?.data) {
      props.getBatchList();
      notify(props?.editBatchRes?.editBatchData?.data?.message);
      props.clearBatch("EDIT_BATCH_RESET");
      handleClose();
    }
  }, [props?.editBatchRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <Dialog fullScreen open={modalOpen} TransitionComponent={Transition}>
        <AddClassesTimeDialog
          classesOn={classesOn}
          setModalOpen={setModalOpen}
        />
      </Dialog>

      <div className="content">
        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
                margin: "0",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform: "capitalize",
                  }}
                >
                  Create Training
                </h2>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit(submitData)}>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Training Name">Training Name</label>
                        <Input
                          id="Training Name"
                          defaultValue={currentBatch && currentBatch.title}
                          innerRef={register({ required: true })}
                          name="title"
                          placeholder="Training Name"
                          type="text"
                        />
                        {errors.title && errors.title.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter the training Name
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="Maximum Students">
                          Maximum Students
                        </label>
                        <Input
                          id="Maximum Students"
                          defaultValue={currentBatch && currentBatch.totalSpot}
                          innerRef={register({ required: true })}
                          name="totalSpot"
                          placeholder="Maximum Students"
                          type="number"
                        />
                        {errors.totalSpot &&
                          errors.totalSpot.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter Maximum number of students
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Training Start From">
                          Training Start From
                        </label>
                        <Input
                          id="Training Start From"
                          defaultValue={currentBatch && currentBatch.startDate}
                          innerRef={register({ required: true })}
                          name="startDate"
                          placeholder="Start From"
                          type="date"
                        />
                        {errors.startDate &&
                          errors.startDate.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter start date
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label htmlFor="Training End At">Training End At</label>
                        <Input
                          id="Training End At"
                          defaultValue={currentBatch && currentBatch.endDate}
                          innerRef={register({ required: true })}
                          name="endDate"
                          placeholder="End At"
                          type="date"
                        />
                        {errors.endDate &&
                          errors.endDate.type === "required" && (
                            <span
                              style={{
                                fontSize: 12,
                                color: "red",
                                top: -20,
                                position: "relative",
                              }}
                            >
                              Please enter end date
                            </span>
                          )}
                        {dateError && dateError !== "" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            {dateError}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <div>
                    {active ? (
                      <Button title="Submit" color="info" type="submit">
                        Submit
                      </Button>
                    ) : (
                      <Button title="Submit" color="info" disabled>
                        Submit
                      </Button>
                    )}

                    <Button title="Back" onClick={handleClose}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  createBatchRes: state.createBatch,
  editBatchRes: state.editBatch,
  getBatchRes: state.getBatch,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createBatch: (payload) => {
    dispatch(createBatchAction(payload));
  },
  editBatch: (payload) => {
    dispatch(editBatchAction(payload));
  },
  getBatchList: () => {
    dispatch(getBatchAction());
  },
  clearBatch: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Add_Edit_Batch);
