import * as actionTypes from '../actionType';

import {
  getRequest,
} from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const getActivityAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ACTIVITY,
      payload: {},
      isLoading: true,
    });

    let url='api/activity'
    if(payload)url=url+`?page=${payload?.page}&limit=${payload?.limit}`

    getRequest(url)
      .then((res) => {

        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_ACTIVITY_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
 
        dispatch({
          type: actionTypes.GET_ACTIVITY_FAIL,
          payload: { error: err?.response?.data},
          isLoading: false,
        });
      });
  };
};



