import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getSubmittedAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUBMITTED_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_SUBMITTED_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        submittedAssignmentList: action.payload,
      };
    case actionTypes.GET_SUBMITTED_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_SUBMITTED_ASSIGNMENT_REST':
      return {};

    default:
      return state;
  }
};
export const downloadSubmittedAssignmentReducer = (
  state = intialState,
  action
) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        submittedAssignmentData: action.payload,
      };
    case actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DOWNLOAD_SUBMITTED_ASSIGNMENT_REST':
      return {};

    default:
      return state;
  }
};
export const addEditQuizReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_EDIT_QUIZ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.ADD_EDIT_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addEditQuizData: action.payload,
      };
    case actionTypes.ADD_EDIT_QUIZ_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'ADD_EDIT_QUIZ_RESET':
      return {};

    default:
      return state;
  }
};
export const addEditAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_EDIT_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.ADD_EDIT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addEditAssignmentData: action.payload,
      };
    case actionTypes.ADD_EDIT_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'ADD_EDIT_ASSIGNMENT_RESET':
      return {};

    default:
      return state;
  }
};
