/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState } from 'react';
import { NavLink, Link, useHistory } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink } from 'reactstrap';

import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config';
import * as firebase from 'firebase';
import 'firebase/auth';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import { Equalizer } from '@material-ui/icons';

var ps;

//test data
const options = [
  { label: 'Dashboard', value: 'Dashboard' },
  { label: 'User Management', value: 'User_Management' },
  { label: 'Student Management', value: 'Student_Management' },
  { label: 'Book Management', value: 'Book_Management' },
  { label: 'Transaction Management', value: 'Transaction_Management' },
  { label: 'Reports', value: 'Reports' },
];

const sideOptions = [
  'Admin_Management',
  'Trainer_Management',
  'Trainee_Management',
  'Batch_Management',
  'Module_Management',
  'Quiz_Management',
  'Package_Management',
  'Chat',
  'Reports',
  'Notification_Management',
  'Media_Center',
  'Invoice_Management',
];

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.state = { currentPermission: [], font: {}, colorTheme: {} };
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  componentDidMount() {
    // this.getUsers();
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.sidebar, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    // }

    if (this?.props?.fontData) {
      this.setState({ font: this?.props?.fontData?.fontData?.data });
    } else if (localStorage.getItem('fnt')) {
      this.setState({ font: JSON.parse(localStorage.getItem('fnt')) });
    } else {
      return;
    }
    if (this?.props?.colorData) {
      this.setState({ colorTheme: this?.props?.colorData?.colorData?.data });
    }
    if (localStorage.getItem('clr')) {
      this.setState({ colorTheme: JSON.parse(localStorage.getItem('clr')) });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ colorTheme: nextProps?.colorData?.colorData?.data });
    console.log('componentWillReceiveProps', nextProps);
    this.setState({ font: nextProps?.fontData?.fontData?.data });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove('nav-open');
  };
  userAccessFn = () => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      let userOptions = user.personalData.functions.map((data) => {
        return data.zSystemFunction.name;
      });
      return userOptions;
    }
  };
  render() {
    const { bgColor, routes, rtlActive, logo } = this.props;
    let sidebar_component;
    if (localStorage.getItem('user')) {
      const user = jwtDecode(
        JSON.parse(localStorage.getItem('user')).token
      ).type;

      if (user === 'Admin') {
        sidebar_component = this.userAccessFn().map((data) => {
          return routes.filter((routesdata) => data == routesdata.id);
        });

        sidebar_component = sidebar_component.filter((item) => {
          return item.length !== 0;
        });
        sidebar_component = sidebar_component.sort(
          (a, b) => a[0].sNo - b[0].sNo
        );
      }
      if (user === 'Teacher') {
        let newRoutes = routes.filter((route) => route.status === 'vissible');
        sidebar_component = newRoutes.map((route) => {
          return [route];
        });
      }
    }

    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <div
            className='simple-text logo-mini'
            target='_blank'
            // onClick={this.props.toggleSidebar}
          >
            <div className='logo-img'>
              <img src={logo.imgSrc} alt='react-logo' />
            </div>
          </div>
        );
        logoText = (
          <div
            className='simple-text logo-normal'

            // onClick={() => {
            // }}
          >
            {logo.text}
          </div>
        );
      } else {
        logoImg = (
          <div
            to={logo.innerLink}
            className='simple-text logo-mini'
            onClick={this.props.toggleSidebar}
            style={{
              transition: 'all 300ms ease 0s',
              opacity: 1,
              float: 'left',
              width: 34,
              textAlign: 'center',
              marginLeft: '0',
              marginRight: '8px',
            }}
          >
            <div className='logo-img'>
              <img src={logo.imgSrc} alt='atriev-logo' />
            </div>
          </div>
        );
        logoText = (
          <div className='simple-text logo-normal'>
            <p style={{ fontSize: '13px', margin: '1px', lineHeight: '16px' }}>
              {logo.text}
              <br />
              <sub>Creating Vision Beyond Sight</sub>
            </p>
          </div>
        );
      }
    }
    return (
      <div className='sidebar' data={bgColor}>
        <div
          style={{
            backgroundColor: this.state.colorTheme?.sidenavColor || 'all',
          }}
          className='sidebar-wrapper'
          ref='sidebar'
        >
          {logoImg !== null || logoText !== null ? (
            <header className='logo'>
              {logoImg}
              {logoText}
            </header>
          ) : null}
          <nav style={{ marginTop: '-5px' }}>
            <Nav>
              {sidebar_component &&
                sidebar_component.map((prop, key) => {
                  // if (prop[0].redirect) return null;
                  return (
                    <li
                      className={
                        this.activeRoute(prop[0].path) +
                        (prop[0].pro ? ' active-pro' : '')
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop[0].layout + prop[0].path}
                        className='nav-link'
                        // activeClassName="active"

                        onClick={this.props.toggleSidebar}
                      >
                        <i className={prop[0].icon} />
                        <div
                          style={{
                            fontSize:
                              this.state.font?.fontSize - 4 || 'inherit',
                            fontWeight:
                              this.state.font?.fontWeight || 'inherit',
                            backgroundColor:
                              this.state.colorTheme?.sidenavColor || 'inherit',
                            wordBreak: 'break-word',
                          }}
                        >
                          {rtlActive ? prop[0].rtlName : prop[0].name}
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              <li className='active-pro'></li>
            </Nav>
          </nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};
const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

// export default Sidebar;
