import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { positionCenter, btnStyle } from "Utilities/common/commonStyle";
import { connect } from "react-redux";
import { fontAction } from "store/action/fontAction/fontAction";
import { useEffect } from "react";
const FontController = ({ handleBack, ...props }) => {
  const [fontSize, setFontSize] = useState(14);
  const [fontWeight, setFontWeight] = useState(400);

  const incFontSize = () => {
    if (fontSize < 24) {
      setFontSize((prev) => prev + 1);
    }
  };
  const decFontSize = () => {
    if (fontSize > 14) {
      setFontSize((prev) => prev - 1);
    }
  };
  const incFontWeight = () => {
    if (fontWeight < 700) {
      setFontWeight((prev) => prev + 100);
    }
  };
  const decFontWeight = () => {
    if (fontWeight > 400) {
      setFontWeight((prev) => prev - 100);
    }
  };

  const handleSave = () => {
    localStorage.setItem("fnt", JSON.stringify({ fontSize, fontWeight }));
    props.getFontData();
    handleBack();
  };

  const handleReset = () => {
    localStorage.setItem(
      "fnt",
      JSON.stringify({ fontSize: 14, fontWeight: 400 })
    );
    props.getFontData();
    handleBack();
  };

  useEffect(() => {
    const fontValue = JSON.parse(localStorage.getItem("fnt")) || "";

    if (fontValue) {
      setFontSize(fontValue.fontSize);
      setFontWeight(fontValue.fontWeight);
    }
  }, []);

  return (
    <div style={{ ...positionCenter, justifyContent: "space-around" }}>
      <div style={{ ...positionCenter, flexDirection: "column" }}>
        <button
          aria-label="Font weight increase"
          title="Font weight increase"
          style={{ ...btnStyle, ...positionCenter }}
          onClick={incFontWeight}
        >
          <KeyboardArrowUpIcon />
        </button>
        <div style={positionCenter}>
          <button
            aria-label="Font size decrease"
            title="Font size decrease"
            style={{ ...btnStyle, ...positionCenter }}
            onClick={decFontSize}
          >
            <RemoveIcon />
          </button>
          <div
            style={{
              height: "40px",
              width: "40px",
              ...positionCenter,
            }}
          >
            <p
              style={{
                fontSize: `${fontSize}px`,
                fontWeight: `${fontWeight}`,
                color: "white",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              A
            </p>
          </div>
          <button
            aria-label="Font size increase"
            title="Font size increase"
            style={{ ...btnStyle, ...positionCenter }}
            onClick={incFontSize}
          >
            <AddIcon />
          </button>
        </div>
        <button
          aria-label="Font weight decrease"
          title="Font weight decrease"
          style={{ ...btnStyle, ...positionCenter }}
          onClick={decFontWeight}
        >
          <KeyboardArrowDownIcon />
        </button>
      </div>
      <div style={{ display: "flex", gap: "8px" }}>
        <button
          style={{ ...btnStyle }}
          onClick={handleSave}
          color="info"
          title="Save"
          aria-label="Save"
        >
          Save
        </button>
        <button
          style={{ ...btnStyle, background: "red" }}
          onClick={handleReset}
          color="info"
          title="Reset"
          aria-label="Reset"
        >
          Reset
        </button>
        <button
          style={{ ...btnStyle, backgroundColor: "#2A3856" }}
          onClick={handleBack}
          color="light"
          title="Back"
          aria-label="Back"
        >
          Back
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
});
const mapDispatchToProps = (dispatch) => ({
  getFontData: (fontData) => {
    dispatch(fontAction(fontData));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FontController);
