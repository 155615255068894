import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { CSVDownload } from "react-csv";

import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import {
  actionBtnStyle,
  paraStyle,
  tableDivStyle,
  positionCenter,
  btnStyle,
} from "Utilities/common/commonStyle";

import TableComp from "components/TableComp/TableComp";

import {
  updateModprogressAction,
  getTrainersTraineeAction,
  uploadGradesAction,
  uploadAudioAction,
  getTrainersModuleAction,
  getUploadedStudyMaterial,
} from 'store/action/trainerActions/trainerProfileActions';

import { editRelationAction } from "store/action/batchActions/batchActions";

import { getQuizAction } from "store/action/quizAction/quizAction";

import { getAssignmentAction } from "store/action/assignmentAction/assignmentAction";

import {
  getSubmittedAssignmentAction,
  addEditQuizAction,
  addEditAssignmentAction,
} from "store/action/trainerActions/trainerTaskActions";

import { clearResponse } from "store/action/clearResponseAction";

import UpdateComp from "components/UpdateComp/UpdateComp";
import ModalComp from "components/ModalComp/ModalComp";
import AddQuiz from "Modules/TRAINER_MODULE/TaskManagement/AddQuiz";
import AddAssignmentRel from "Modules/TRAINER_MODULE/TaskManagement/AddAssignmentRel";
import SubmittedAssignmentTable from "Modules/TRAINER_MODULE/TaskManagement/SubmittedAssignmentTable";
import FileUploader from "components/FileUploader/FileUploader";

import Loader from 'components/Loader/Loader';
import { LinearProgress } from '@material-ui/core';
import UploadedMaterialList from 'components/UploadedMaterialLIst/UploadedMaterialList';
const TabularContainer = ({
  mod,
  setModuleId,
  moduleId,
  batchId,
  batchName,
  traineeList,
  setTraineeList,

  ...props
}) => {
  const [isUpdatingModProgress, setIsUpdatingModProgress] = useState(false);
  const [modProgressValue, setModProgressValue] = useState("");

  const [submittedAssignmentList, setSubmittedAssignmentList] = useState("");
  const [openUploadGrade, setOpenUploadGrade] = useState(false);
  const [openUploadAudio, setOpenUploadAudio] = useState(false);

  const [openAddQuiz, setOpenAddQuiz] = useState(false);
  const [quizList, setQuizList] = useState("");

  const [openAddAssignment, setOpenAddAssignment] = useState(false);
  const [assignmentList, setAssignmentList] = useState("");

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedAudio, setSelectedAudio] = useState("");

  const [exportingExcel, setExportingExcel] = useState(false);
  const [csvData, setCsvData] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleModDropDown = () => {
    if (!props.task) {
      setTraineeList("");
      if (traineeList && mod?.batchModuleRelation?.moduleId == +moduleId) {
        // setTraineeList('');
      } else if (
        traineeList &&
        mod?.batchModuleRelation?.moduleId !== +moduleId
      ) {
        // setTraineeList('');
        let modId = mod?.batchModuleRelation?.moduleId;
        setModuleId(modId);
        if (modId && batchId) {
          props.getTrainersTraineeList(batchId, modId);
        }
      } else if (!traineeList) {
        let modId = mod?.batchModuleRelation?.moduleId;
        setModuleId(modId);
        if (modId && batchId) {
          props.getTrainersTraineeList(batchId, modId);
        }
      }
    } else {
      setSubmittedAssignmentList("");

      if (
        submittedAssignmentList &&
        mod?.batchModuleRelation?.moduleId == +moduleId
      ) {
        // setSubmittedAssignmentList('');
      } else if (
        submittedAssignmentList &&
        mod?.batchModuleRelation?.moduleId !== +moduleId
      ) {
        // setSubmittedAssignmentList('');
        let modId = mod?.batchModuleRelation?.moduleId;
        setModuleId(modId);
        if (modId && batchId) {
          props.getSubmittedAssignmentList(batchId, modId);
        }
      } else if (!submittedAssignmentList) {
        let modId = mod?.batchModuleRelation?.moduleId;
        setModuleId(modId);
        if (modId && batchId) {
          props.getSubmittedAssignmentList(batchId, modId);
        }
      }
    }
  };

  const handleClickUpdateModProgress = () => {
    if (!isUpdatingModProgress) {
      setIsUpdatingModProgress(true);
    }
    //  else if (isUpdatingModProgress && modProgressValue) {
    //   handleUpdate();
    // }
    else {
      setIsUpdatingModProgress(false);
    }
  };

  const handleUpdate = (value) => {
    // if (modProgressValue) {
    let payload = {
      batchId,
      moduleId: mod?.batchModuleRelation?.moduleId,
      progress: +value,
      // progress: +modProgressValue,
    };
    props.updateModprogress(payload);
    // }
  };

  const handleUploadGrade = () => {
    const payloadData = new FormData();
    payloadData.append("batchId", batchId);
    payloadData.append("moduleId", mod?.batchModuleRelation?.moduleId);
    payloadData.append("student_grades", selectedFile);

    props.uploadGrade(payloadData);
  };
  const handleUploadAudio = () => {
    const payloadData = new FormData();
    payloadData.append("batchId", batchId);
    payloadData.append("moduleId", mod?.batchModuleRelation?.moduleId);
    payloadData.append("class_audio", selectedAudio);

    if (selectedAudio) props.uploadAudio(payloadData, setUploadProgress);
  };

  useEffect(() => {
    if (
      props?.getTrainersTraineeRes?.trainersTraineeist?.data &&
      props?.getTrainersTraineeRes?.trainersTraineeist?.data?.length !== 0 &&
      !props.task
    ) {
      if (!exportingExcel) {
        setTraineeList(
          props?.getTrainersTraineeRes?.trainersTraineeist?.data[0]
            ?.batchModuleRelation?.studentBatchRelations
        );
      }
      if (exportingExcel) {
        let data = [
          [
            "Student_Id",
            "Student_Name",
            "Batch_Name",
            "Module_Name",
            "Grades",
            "Max_Grades",
          ],
        ];
        props.getTrainersTraineeRes.trainersTraineeist.data[0].batchModuleRelation.studentBatchRelations.map(
          (item) => {
            let newData = [
              item.studentId,
              `${item?.student?.firstName} ${item?.student?.lastName}`,
              batchName,
              mod?.batchModuleRelation?.module?.title,
              item?.marksObtained,
              item?.maxMarks,
            ];
            data.push(newData);
          }
        );
        setCsvData(data);
        setTimeout(() => setCsvData(""), 1000);
      }
    }
  }, [props?.getTrainersTraineeRes]);

  // useEffect(() => {
  //   if (props?.updateModprogressRes?.modProgressData?.data) {
  //     props.clearResponse('GET_TRAINERS_MODULE_RESET');
  //     setIsUpdatingModProgress(false);
  //     props.getTrainersModuleList(`batchId=${batchId}`);
  //   }
  // }, [props?.updateModprogressRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  useEffect(() => {
    if (
      props?.getSubmittedAssignmentRes?.submittedAssignmentList?.data &&
      props?.getSubmittedAssignmentRes?.submittedAssignmentList?.data
        ?.length !== 0 &&
      props?.task
    )
      setSubmittedAssignmentList(
        props?.getSubmittedAssignmentRes?.submittedAssignmentList?.data
      );
  }, [props?.getSubmittedAssignmentRes]);

  useEffect(() => {
    if (traineeList) props.clearResponse("GET_TRAINERS_TRAINEE_RESET");
  }, [traineeList]);

  useEffect(() => {
    if (submittedAssignmentList)
      props.clearResponse("GET_SUBMITTED_ASSIGNMENT_REST");
  }, [submittedAssignmentList]);

  useEffect(() => {
    if (props?.getQuizRes?.quizList)
      setQuizList(props?.getQuizRes?.quizList?.data);
  }, [props?.getQuizRes?.quizList]);

  useEffect(() => {
    if (props?.getAssignmentRes?.assignmentList)
      setAssignmentList(props?.getAssignmentRes?.assignmentList?.data);
  }, [props?.getAssignmentRes?.assignmentList]);

  useEffect(() => {
    if (props?.banTraineeRes?.banTraineeData?.data) {
      props.clearResponse("BAN_TRAINEE_RESET");
      if (mod?.batchModuleRelation?.moduleId == +moduleId) {
        props.getTrainersTraineeList(
          batchId,
          mod?.batchModuleRelation?.moduleId
        );
      }
    }
  }, [props?.banTraineeRes?.banTraineeData]);

  useEffect(() => {
    if (props?.uploadGradesRes?.uploadGradeData?.data) {
      props.clearResponse("UPLOAD_GRADE_RESET");
      // props.getTrainersTraineeList(batchId, mod?.batchModuleRelation?.moduleId);

      setOpenUploadGrade(false);
    }
  }, [props?.uploadGradesRes?.uploadGradeData]);

  useEffect(() => {
    if (props?.uploadAudioRes?.uploadAudioData?.data) {
      props.clearResponse("UPLOAD_AUDIO_RESET");
      setUploadProgress(0);
      props.getUploadedMaterial(batchId, mod?.batchModuleRelation?.moduleId);

      // setOpenUploadAudio(false);
    }
  }, [props?.uploadAudioRes?.uploadAudioData]);

  useEffect(() => {
    if (props?.addEditQuizRes?.addEditQuizData?.data) {
      props.clearResponse("ADD_EDIT_QUIZ_RESET");
      setOpenAddQuiz(false);
    }
  }, [props?.addEditQuizRes?.addEditQuizData]);

  useEffect(() => {
    if (props?.addEditAssignmentRes?.addEditAssignmentData?.data) {
      props.clearResponse("ADD_EDIT_ASSIGNMENT_RESET");
      setOpenAddAssignment(false);
    }
  }, [props?.addEditAssignmentRes?.addEditAssignmentData]);

  useEffect(() => {
    if (!props?.getQuizRes?.quizList) {
      props.getQuizData();
    }
    if (!props?.getAssignmentRes?.assignmentList) {
      props.getAssignmentList();
    }
  }, []);

  return (
    <div
      style={{
        borderRadius: "4px",
        height: "fit-content",
        width: "fit-content",
        minWidth: "100%",
        marginBottom: "12px",
        boxShadow:
          (traineeList &&
            traineeList?.length !== 0 &&
            mod?.batchModuleRelation?.moduleId == +moduleId) ||
            (submittedAssignmentList &&
              submittedAssignmentList?.length !== 0 &&
              mod?.batchModuleRelation?.moduleId == +moduleId)
            ? " 0 0 4px white"
            : null,
        fontSize: font?.fontSize || "inherit",
        fontWeight: font?.fontWeight || "inherit",
        backgroundColor: colorTheme?.bodyColor || "all",
      }}
    >
      {csvData && <CSVDownload data={csvData} target="_blank" />}
      <div role="rowgroup">
        <div
          style={{
            ...tableDivStyle,
            borderBottom: "1px solid #525f7f",
            gridTemplateColumns: props.task
              ? "repeat(4, 1fr )"
              : "repeat(6, 1fr)",
            position: "relative",
          }}
          role="row"
        >
          <span style={{ ...paraStyle, color: "#FFFFFF" }} role="cell">
            {mod?.batchModuleRelation?.module?.title}
          </span>
          <span style={{ ...paraStyle, color: "#FFFFFF" }} role="cell">
            {mod?.batchModuleRelation?.module?.description}
          </span>
          {!props.task == true ? (
            <>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                role="cell"
              >
                <UpdateComp
                  handleChange={(e) => {
                    const value = e.target.value;
                    if (value) {
                      handleUpdate(value);
                    }
                    setModProgressValue(value);
                  }}
                  handleClick={handleClickUpdateModProgress}
                  controller={isUpdatingModProgress}
                  text={
                    mod?.batchModuleRelation?.progress === 0
                      ? "Not started"
                      : mod?.batchModuleRelation?.progress === 1
                        ? "In progress"
                        : mod?.batchModuleRelation?.progress === 2
                          ? "Completed"
                          : "-"
                  }
                  dropDown
                  title="module progress"
                />
              </span>

              <span role="cell">
                <button
                  title="Export to excel"
                  aria-label="Export to excel"
                  style={btnStyle}
                  onClick={() => {
                    setExportingExcel(true);
                    props.getTrainersTraineeList(
                      batchId,
                      mod?.batchModuleRelation?.moduleId
                    );
                  }}
                >
                  <GetAppIcon style={{ color: "white" }} />
                </button>
              </span>
              <span role="cell">
                {" "}
                <button
                  title="upload grades"
                  aria-label="upload grades"
                  style={btnStyle}
                  onClick={() => setOpenUploadGrade(true)}
                >
                  <PublishIcon style={{ color: "white" }} />
                </button>
              </span>
              <span role="cell">
                <button
                  title="upload pdf/audio"
                  aria-label="upload pdf/audio"
                  style={btnStyle}
                  onClick={() => setOpenUploadAudio(true)}
                >
                  <PublishIcon style={{ color: "white" }} />
                </button>
              </span>
            </>
          ) : (
            <>
              <span role="cell">
                <button
                  aria-label="Add/Edit Quiz"
                  title="Add/Edit Quiz"
                  onClick={() => setOpenAddQuiz(true)}
                  style={btnStyle}
                >
                  <LibraryBooksIcon style={{ color: "white" }} />
                </button>
              </span>{" "}
              <span role="cell">
                <button
                  aria-label="Add/Edit Assignment"
                  title="Add/Edit Assignment"
                  onClick={() => setOpenAddAssignment(true)}
                  style={btnStyle}
                >
                  <LibraryBooksIcon style={{ color: "white" }} />
                </button>
              </span>
              <> </>
            </>
          )}

          <button
            aria-label={(traineeList &&
              traineeList?.length !== 0 &&
              mod?.batchModuleRelation?.moduleId == +moduleId) ||
              (submittedAssignmentList &&
                submittedAssignmentList.length !== 0 &&
                mod?.batchModuleRelation?.moduleId == +moduleId)
              ? 'Hide students' : 'Show students'}
            title={(traineeList &&
              traineeList?.length !== 0 &&
              mod?.batchModuleRelation?.moduleId == +moduleId) ||
              (submittedAssignmentList &&
                submittedAssignmentList.length !== 0 &&
                mod?.batchModuleRelation?.moduleId == +moduleId)
              ? 'Hide students' : 'Show students'}
            onClick={() => {
              setExportingExcel(false);
              handleModDropDown();
            }}
            style={{
              background: "transparent",
              cursor: "pointer",
              position: "absolute",
              top: "50%",
              right: "20px",
              width: "fit-content",
              transform: "translateY(-50%)",
              aspectRatio: "1 / 1",
              border: 'none'
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                color: "white",
                width: "35px",
                height: "35px",
                transform:
                  (traineeList &&
                    traineeList?.length !== 0 &&
                    mod?.batchModuleRelation?.moduleId == +moduleId) ||
                    (submittedAssignmentList &&
                      submittedAssignmentList.length !== 0 &&
                      mod?.batchModuleRelation?.moduleId == +moduleId)
                    ? "rotate(180deg)"
                    : "",
              }}
            />
          </button>
        </div>
      </div>
      {traineeList?.length !== 0 &&
        mod?.batchModuleRelation?.moduleId == +moduleId &&
        !props.task ? (
        <div style={{ padding: "24px 32px" }}>
          <TableComp arr={traineeList} batchId={batchId} moduleId={moduleId} />
        </div>
      ) : null}

      {submittedAssignmentList &&
        mod?.batchModuleRelation?.moduleId == +moduleId &&
        props.task ? (
        <div style={{ padding: "24px 32px" }}>
          <SubmittedAssignmentTable
            arr={submittedAssignmentList}
            batchId={batchId}
            moduleId={moduleId}
          />
        </div>
      ) : null}

      <ModalComp controller={openAddQuiz} title="Add Quiz" bodyColor={colorTheme?.bodyColor}>
        <AddQuiz
          handleBack={() => setOpenAddQuiz(false)}
          callApi={(payload) => props.addEditQuiz(payload)}
          moduleId={mod?.batchModuleRelation?.moduleId}
          batchId={batchId}
          quizList={quizList}
          batModAssignId={mod?.batModAssignId}
          data={mod?.batchModuleRelation?.quizBatchRelations}
        />
      </ModalComp>
      <ModalComp controller={openAddAssignment} title="Add Quiz" bodyColor={colorTheme?.bodyColor}>
        <AddAssignmentRel
          handleBack={() => setOpenAddAssignment(false)}
          callApi={(payload) => props.addEditAssignment(payload)}
          moduleId={mod?.batchModuleRelation?.moduleId}
          batchId={batchId}
          assignmentList={assignmentList}
          batModAssignId={mod?.batModAssignId}
          assignmentData={mod?.batchModuleRelation?.batchAssignmentRelations}
        />
      </ModalComp>
      {/* ) : props.getTrainersTraineeRes?.isLoading ? 
      (<Loader />
    ) : '' 
      } */}

      <ModalComp controller={openUploadGrade} title="Upload Grades" bodyColor={colorTheme?.bodyColor}>
        <div style={{ backgroundColor: colorTheme?.bodyColor || "all" }}>
          <h2
            style={{
              fontSize: font?.fontSize + 2 || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              textTransform: "capitalize",
            }}
          >
            Upload grades
          </h2>
          <FileUploader
            label="Select file"
            onSubmit={handleUploadGrade}
            onChange={(e) => setSelectedFile(e.target.files[0])}
            onClick={() => setOpenUploadGrade(false)}
            accept=".xlsx,.xls,.csv"
            name="student_grades"
          />
        </div>
      </ModalComp>
      <ModalComp controller={openUploadAudio} title='Upload training material' bodyColor={colorTheme?.bodyColor}>
        <div
          style={{
            display: 'flex',
            width: '1000px',
            maxHeight: '450px',
            height: 'fit-content',
            overflow: 'auto',
          }}
        >
          <div
            style={{
              padding: '0 8px',
              width: '40%',
              backgroundColor: colorTheme?.bodyColor || 'all',
            }}
          >
            <h2
              style={{
                fontSize: font?.fontSize + 2 || 'inherit',
                fontWeight: font?.fontWeight || 'inherit',
                textTransform: 'capitalize',
              }}
            >
              {!props?.uploadAudioRes?.isLoading && !uploadProgress
                ? 'Upload Pdf/Audio'
                : 'Uploading...'}
            </h2>
            {!props?.uploadAudioRes?.isLoading && !uploadProgress ? (
              <FileUploader
                label='Select audio'
                onSubmit={handleUploadAudio}
                onChange={(e) => setSelectedAudio(e.target.files[0])}
                onClick={() => setOpenUploadAudio(false)}
                accept='.pdf,
                audio/mpeg, audio/mp4,audio/mp3'
                name='class_audio'
              />
            ) : (
              <LinearProgress
                aria-label='upload-audio-progress-bar'
                title='Upload-audio-progress-bar'
                style={{ width: '100%' }}
                variant='determinate'
                value={uploadProgress}
              />
            )}
          </div>
          <UploadedMaterialList
            batchId={batchId}
            moduleId={mod?.batchModuleRelation?.moduleId}
          />
        </div>
      </ModalComp>
      {props?.getQuizRes?.isLoading ||
        props?.getAssignmentRes?.isLoading ||
        // props?.uploadAudioRes?.isLoading ||
        props?.banTraineeRes?.isLoading ||
        props?.uploadGradesRes?.isLoading ||
        props?.getTrainersModuleRes?.isLoading ||
        props?.addEditQuizRes?.isLoading ||
        props?.addEditAssignmentRes?.isLoading ||
        props?.getSubmittedAssignmentRes?.isLoading ? (
        <Loader />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  getTrainersTraineeRes: state.getTrainersTrainee,
  updateModprogressRes: state.updateModprogress,
  getQuizRes: state.getQuiz,
  getAssignmentRes: state.getAssignment,
  getSubmittedAssignmentRes: state.getSubmittedAssignment,
  banTraineeRes: state.banTrainee,
  uploadGradesRes: state.uploadGrades,
  uploadAudioRes: state.uploadAudio,
  addEditQuizRes: state.addEditQuiz,
  addEditAssignmentRes: state.addEditAssignment,
  getTrainersModuleRes: state.getTrainersModule,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainersTraineeList: (batchId, moduleId) => {
    dispatch(getTrainersTraineeAction(batchId, moduleId));
  },
  updateModprogress: (payload) => {
    dispatch(updateModprogressAction(payload));
  },

  uploadGrade: (payload) => {
    dispatch(uploadGradesAction(payload));
  },
  uploadAudio: (payload, setProgress) => {
    dispatch(uploadAudioAction(payload, setProgress));
  },
  addEditQuiz: (payload) => {
    dispatch(addEditQuizAction(payload));
  },
  addEditAssignment: (payload) => {
    dispatch(addEditAssignmentAction(payload));
  },
  getQuizData: () => {
    dispatch(getQuizAction());
  },
  getAssignmentList: () => {
    dispatch(getAssignmentAction());
  },
  getSubmittedAssignmentList: (batchId, moduleId) => {
    dispatch(getSubmittedAssignmentAction(batchId, moduleId));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
  getTrainersModuleList: (url) => {
    dispatch(getTrainersModuleAction(url));
  },
  getUploadedMaterial: (batchId, moduleId) => {
    dispatch(getUploadedStudyMaterial(batchId, moduleId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TabularContainer);
