import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { actionBtnStyle } from 'Utilities/common/commonStyle';
import { Input } from 'reactstrap';
const UpdateComp = ({
  controller,
  handleChange,
  handleClick,
  text,
  dropDown,
  title,
  ...rest
}) => {
  return (
    <>
      {controller ? (
        !dropDown ? (
          <input
            style={{ outline: 'none', borderRadius: '4px', width: '70px' }}
            type='text'
            onChange={handleChange}
            {...rest}
          />
        ) : (
          <Input
            style={{ maxWidth: '200px', borderRadius: '4px' }}
            onChange={handleChange}
            type='select'
          >
            <option value=''>
              Select progress
            </option>
            <option value='0'>Not started</option>
            <option value='1'>In progress</option>
            <option value='2'>Completed</option>
          </Input>
        )
      ) : (
        <p style={{ minWidth: '30%' }}>{text}</p>
      )}{' '}
      <button
        title={`update ${title}`}
        aria-label={`update ${title}`}
        onClick={() => handleClick()}
        style={actionBtnStyle}
      >
        {controller ? (
          <CheckCircleOutlineIcon style={{ color: 'green' }} />
        ) : (
          <EditIcon style={{ color: 'deepskyblue' }} />
        )}
      </button>
    </>
  );
};

export default UpdateComp;
