import React from 'react';
import { Button } from 'reactstrap';

const DeleteModal = ({ confirmDelete, cancelDelete }) => {
  return (
    <div>
      <div>
        <p style={{ color: 'white' }}>Do you want to delete this file?</p>
        <div
          style={{ display: 'flex', flexDirection: 'row', paddingTop: '32px' }}
        >
          {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}

          <Button
            aria-label='Confirm delete'
            title='Confirm delete'
            color='info'
            onClick={() => {
              confirmDelete();
            }}
          >
            Yes
          </Button>

          {
            <Button
              aria-label='Cancel delete'
              title='Cancel delete'
              onClick={() => {
                cancelDelete();
              }}
            >
              No
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
