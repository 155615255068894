import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { traineeBulkDeleteAction } from "store/action/bulkDeleteAction/traineeBulkDeleteAction";
import { getTraineesAction } from "store/action/traineeActions/traineeAction";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function BulkDeleteTrainee(props) {
    const { onClick, userArr, setUserArr, notify } = props;

    const bulkDeleteTrainee = () => {
        const bulkDeleteUrl = `/api/student/bulkDelete?id=''&${userArr.toString().replaceAll(',', '&')}`;
        props.traineeBulkDelete(bulkDeleteUrl);
      };

    useEffect(() => {
      if (props?.traineeBulkDeleteRes?.deleteTraineeData) {
        notify("Trainees deleted successfully", false);
        props.clearResponse("BULK_DELETE_TRAINEE_RESET");
        props.getTraineeList();
        setUserArr([])
        onClick();
      }
      if (props.traineeBulkDeleteRes?.error) {
        notify(props.traineeBulkDeleteRes?.error?.message, true);
        props.clearResponse("BULK_DELETE_TRAINEE_RESET");
      }
    }, [props?.traineeBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Trainees?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteTrainee}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    traineeBulkDeleteRes: state.traineeBulkDelete,
    getTraineeData: state.getTrainee,
});

const mapDispatchToProps = (dispatch) => ({
    traineeBulkDelete: (payload) => {
        dispatch(traineeBulkDeleteAction(payload));
    },
    getTraineeList: () => {
        dispatch(getTraineesAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteTrainee);
