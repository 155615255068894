import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Table,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { db } from "MY_LOGIN_AND_SIGNUP/db/config";
import { Edit, Delete } from "@material-ui/icons";

import SearchIcon from "@material-ui/icons/Search";

import NotificationAlert from "react-notification-alert";
import styles from "../../../components/Quiz_Management//quiz_Management.module.css";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "Utilities/algoliaKeys";
import ModalComp from "components/ModalComp/ModalComp";
import RequestManagementModal from "./RequestManagementModal";
import { getRequestAction } from "store/action/requestAction/requestAction";

import { clearResponse } from "store/action/clearResponseAction";

import Loader from "components/Loader/Loader";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RequestManagement(props) {
  const [limit, setLimit] = useState(100);

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const indexForQues = searchClient.initIndex("Questions_Search");
  const indexForQuiz = searchClient.initIndex("Quiz_Search");

  const notification = useRef();
  const classes = useStyles();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [requestData, setRequestData] = useState("");
  const [extensionId, setExtensionId] = useState("");
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);

  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const nextSet = () => {};

  const prevSet = () => {};

  const nextQuizSet = () => {};

  const prevQuizSet = () => {};

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getUsers = (orderBy) => {};

  const emptyStudent = () => {
    getUsers();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getRequest();
    } else {
      setSearch(true);
      props.getRequest(studentSearchValue);
    }
  };

  useEffect(() => {
    document.title = "Request Management";
  }, []);

  useEffect(() => {
    props.getRequest();
  }, []);

  useEffect(() => {
    if (props?.getRequestData?.RequestList) {
      if (props?.getRequestData?.RequestList?.data?.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_REQUEST_RESET");
        setRequestData('');
      } else {
        setRequestData(props?.getRequestData?.RequestList?.data);
      }
    }
    if (props.getRequestData?.error) {
      notify(props.getRequestData?.error?.message, true);
      props.clearResponse("GET_REQUEST_RESET");
    }
  }, [props?.getRequestData]);
  useEffect(() => {
    if (props?.handleRequestData?.handleRequestData) {
      notify("Updated successfully", false);
      setRequestData(props?.handleRequestData?.handleRequestData?.data);
      setOpen(false);
      props.getRequest();
      props.clearResponse("HANDLE_REQUEST_RESET");
    }
    if (props.handleRequestData?.error) {
      notify(props.handleRequestData?.error?.message, true);
      props.clearResponse("HANDLE_REQUEST_RESET");
    }
  }, [props?.handleRequestData]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <main id="main" className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notification} />
      </div>
      <Row>
        <Col md="12">
          <Card
            style={{
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader className={styles.card_header}>
              <h1
                style={{
                  fontSize: font?.fontSize + 4 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  marginRight: "auto",
                  textTransform: "capitalize",
                }}
              >
                REQUEST MANAGEMENT
              </h1>

              {/* <abbr title="Add Questions">
                                <Button color='info' onClick={() => setIsModalVisible(true)}>
                                    Add Questions
                                </Button>
                            </abbr> */}
            </CardHeader>

            <CardBody>
              <Row>
                <Col sm={4}>
                  <Input
                    title="Student Name"
                    type="text"
                    name="search"
                    placeholder="Student Name"
                    // onInput={(e) => {
                    //     e.target.value.length === 0 && emptyCharCall();
                    //     setSearchQuiz(e.target.value);
                    //     emptyCall();
                    // }}
                    onChange={(e) => (
                      setStudentSearchValue(e.target.value),
                      e.target.value.length === 0 && emptyStudent(),
                      setSearch(false)
                    )}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    title="Search"
                    aria-label="Search"
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    // onClick={searchQuizFun}
                    onClick={studentSearch}
                  >
                    <SearchIcon />
                  </Button>
                </Col>
              </Row>
              <br></br>
              <Table className="tablesorter">
                <thead className="text-primary">
                  <tr>
                    <th>Student Name</th>
                    <th>Training Name</th>
                    <th>Module Name</th>
                    <th>Assignment Name</th>
                    <th>Progress</th>
                    <th>Request Created On</th>
                    <th>Requested Submission Date</th>
                    <th>Assignment Last Submission Date</th>

                    <th style={{ textAlign: "center" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData?.length !== 0 ? (
                    requestData.map((data, i) => {
                      return (
                        <tr
                          key={i}
                          style={{
                            borderBottom: `1px solid ${
                              colorTheme?.borderColor ||
                              "rgba(255, 255, 255, 0.1)"
                            }`,
                          }}
                        >
                          <th
                            style={{
                              fontSize: font?.fontSize || "inherit",
                              fontWeight: font?.fontWeight || "inherit",
                              color: "#FFFFFFB3",
                            }}
                          >
                            {data.studentName}
                          </th>
                          <td>{data.batchName}</td>

                          <td>{data.moduleName}</td>
                          <td>{data.assignmentName}</td>
                          <td>{data.progress}</td>
                          <td>
                            {data?.requestCreationDate
                              ?.replace("T", " ")
                              .replace(".000Z", "")}
                          </td>
                          <td>
                            {data?.askedFinalSubmissionDate?.replace(
                              "T00:00:00.000Z",
                              ""
                            )}
                          </td>
                          <td>
                            {data?.lastSubmissionDate?.replace(
                              "T00:00:00.000Z",
                              ""
                            ).replace("T", " ")
                            .replace(".000Z", "")}
                          </td>
                          <td
                            style={{ cursor: "pointer", textAlign: "center" }}
                          >
                            <div title="World Health Organization">
                              <Edit
                                style={{ color: "#247cf7" }}
                                onClick={() => {
                                  setExtensionId(data.extensionId);
                                  handleClickOpen();
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <h6
                          style={{ textAlign: "center", textTransform: "none" }}
                        >
                          Data not found
                        </h6>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ModalComp controller={open} bodyColor={colorTheme?.bodyColor}>
        <RequestManagementModal
          handleClose={handleClose}
          extensionId={extensionId}
        />
      </ModalComp>
      {props?.getRequestData?.isLoading ||
      props?.handleRequestData?.isLoading ? (
        <Loader />
      ) : null}
    </main>
  );
}
const mapStateToProps = (state, ownProps) => ({
  getRequestData: state.getRequest,
  handleRequestData: state.handleRequest,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getRequest: (payload) => {
    dispatch(getRequestAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestManagement);
