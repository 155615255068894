import jwtDecode from 'jwt-decode';
import React from 'react';
export const getUserType = () => {
  const user = JSON.parse(localStorage.getItem('user')) || '';
  if (user) {
    const userType = jwtDecode(user.token).type;
    const userId = jwtDecode(user.token).userId;
    return { userType, userId };
  }
  return;
};

export const notify = (msg, err, ref) => {
  // console.log('notify')
  let color = err == false ? 'success' : 'danger';
  let options = {
    type: color,
    place: 'tr',
    message: (
      <div>
        <div>
          <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
        </div>
      </div>
    ),
    icon: 'tim-icons icon-bell-55',
    autoDismiss: 15,
  };
  if (ref.current) {
    ref.current.notificationAlert(options);
  }
  // notification(options);
};
