import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getQuizReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUIZ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quizList: action.payload,
      };
    case actionTypes.GET_QUIZ_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_QUIZ_RESET":
      return {};
    default:
      return state;
  }
};
export const createQuizReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_QUIZ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createQuizData: action.payload,
      };
    case actionTypes.CREATE_QUIZ_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "CREATE_QUIZ_RESET":
      return {};
    default:
      return state;
  }
};
export const editQuizReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_QUIZ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        editQuizData: action.payload,
      };
    case actionTypes.EDIT_QUIZ_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case "EDIT_QUIZ_RESET":
      return {};
    default:
      return state;
  }
};

export const deleteQuizReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_QUIZ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteQuizData: action.payload,
      };
    case actionTypes.DELETE_QUIZ_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "DELETE_QUIZ_RESET":
      return {};
    default:
      return state;
  }
};
