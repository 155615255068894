import React from 'react';
import { batch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Modal, ModalBody, Button } from 'reactstrap';
import { btnStyle } from 'Utilities/common/commonStyle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
const CardComp = ({ font, path, obj, handleClick }) => {
  const history = useHistory();
  return (
    <tr>
      <th style={{ fontSize: font?.fontSize || "inherit", fontWeight: font?.fontWeight || "inherit", color: "#FFFFFFB3" }}>{obj.title}</th>
      <td style={{}}>{obj.startDate.slice(0, 10)}</td>
      <td style={{}}>{obj.endDate.slice(0, 10)}</td>
      <td>
        {obj.occupiedSpot} / {obj.totalSpot}
      </td>

      <td>
        <button
          aria-label='Training details'
          title='Training details'
          style={{ ...btnStyle, backgroundColor: '#2C68F5', margin: '0' }}
          onClick={() => {
            localStorage.setItem(
              'batch',
              JSON.stringify({
                bId: obj.id,
                bTitle: obj.title,
              })
            );

            if (path) {
              history.push(path);
            } else {
              handleClick();
            }
          }}
        >
          <MoreVertIcon style={{ color: 'white' }} />
          {/* Download */}
        </button>
      </td>
    </tr>
  );
};

export default CardComp;
