/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import * as firebase from "firebase";
import { Redirect } from "react-router-dom";

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { clearLogin } from "store/action/auth/loginAction";
import { connect } from "react-redux";
import { getUserType } from "Utilities/common/commonFn";
// import LogoutComp from 'components/LogoutComp/LogoutComp';
// import { get } from '../../Utilities/GetUser'
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
} from "reactstrap";

import ModalComp from "components/ModalComp/ModalComp";
import NotificationComp from "components/NotificationComp/NotificationComp";
import FontController from "components/FontController/FontController";
import ColorController from "components/ColorController/ColorController";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ColorLensIcon from "@material-ui/icons/ColorLens";

import { fontAction } from "store/action/fontAction/fontAction";
import { btnStyle, positionCenter } from "Utilities/common/commonStyle";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
      login: true,
      user: {},
      userData: "Profile",
      openFontModal: false,
      openColorModal: false,
      colorTheme: {},
      profileData: JSON.parse(localStorage.getItem("user")).personalData,
    };
    this.detectKeyDown = this.detectKeyDown.bind(this);
  }

  getUsers = () => {};

  detectKeyDown(e) {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyI") {
      this.logOut();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.detectKeyDown, true);
    window.removeEventListener("keydown", this.detectKeyDown);
    // this.getUsers();
    window.addEventListener("resize", this.updateColor);
    const fontValue = JSON.parse(localStorage.getItem("fnt")) || "";
    if (fontValue) {
      this.props.getFontData();
    }
    if (this?.props?.colorData) {
      this.setState({ colorTheme: this?.props?.colorData?.colorData?.data });
    }
    if (localStorage.getItem("clr")) {
      this.setState({ colorTheme: JSON.parse(localStorage.getItem("clr")) });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ colorTheme: nextProps?.colorData?.colorData?.data });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };

  logOut = () => {
    if (localStorage.getItem("user")) {
      this.props.clearLogin();
      // localStorage.removeItem('user');
      localStorage.clear();
      this.setState({ login: false });
      localStorage.setItem("logout", true);
    }
  };

  render() {
    if (this.state.login === true) {
      return (
        <>
          <nav>
            <Navbar
              className={classNames("navbar-absolute", this.state.color)}
              expand="lg"
            >
              <Container
                fluid
                style={{
                  backgroundColor:
                    this.state.colorTheme?.navbarColor || "inherit",
                }}
              >
                <div className="navbar-wrapper">
                  <div
                    className={classNames("navbar-toggle d-inline", {
                      toggled: this.props.sidebarOpened,
                    })}
                  >
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={this.props.toggleSidebar}
                    >
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </button>
                  </div>
                  {/* <NavbarBrand>


                  <p style={{ color: '#FFF' }}>{this.props.brandText}</p>
                </NavbarBrand> */}
                </div>
                <div>
                  <button
                    aria-label="Control font"
                    title="Control font"
                    style={{
                      position: "absolute",
                      right:
                        window.innerWidth > 900
                          ? "10%"
                          : window.innerWidth > 700
                          ? "12%"
                          : "15%",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "#2D66F5",
                      ...btnStyle,
                    }}
                    onClick={() =>
                      this.setState({
                        openFontModal: true,
                      })
                    }
                  >
                    <TextFieldsIcon
                      style={{
                        color: "white",
                      }}
                    />
                  </button>
                </div>
                <div>
                  <button
                    aria-label="Control color"
                    title="Control color"
                    style={{
                      position: "absolute",
                      right:
                        window.innerWidth > 1200
                          ? "14%"
                          : window.innerWidth > 900
                          ? "15%"
                          : window.innerWidth > 700
                          ? "18%"
                          : "24%",
                      cursor: "pointer",
                      top: "50%",
                      transform: "translateY(-50%)",
                      background: "#2D66F5",
                      ...btnStyle,
                    }}
                    onClick={() =>
                      this.setState({
                        openColorModal: true,
                      })
                    }
                  >
                    <ColorLensIcon
                      style={{
                        color: "white",
                      }}
                    />
                  </button>
                </div>

                <button
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#navigation"
                  data-toggle="collapse"
                  id="navigation"
                  type="button"
                  onClick={this.toggleCollapse}
                >
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </button>

                {this.state?.profileData?.type === "Teacher" ? null : (
                  <NotificationComp />
                )}

                <Collapse navbar isOpen={this.state.collapseOpen}>
                  <Nav aria-label="user options" className="ml-auto" navbar>
                    <UncontrolledDropdown nav>
                      <DropdownToggle
                        caret
                        color="default"
                        data-toggle="dropdown"
                        nav
                        onClick={(e) => e.preventDefault()}
                      >
                        <div className="photo">
                          <img
                            alt="..."
                            src={require("assets/img/anime3.png")}
                          />
                        </div>
                        <b className="caret d-none d-lg-block d-xl-block" />
                        <p className="d-lg-none" onClick={this.logOut}>
                          Log out
                        </p>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-navbar" right tag="ul">
                        {/* <NavLink tag='li'>
                        <DropdownItem
                          className='nav-item'
                          style={{ color: 'black', fontWeight: 600 }}
                        >
                          {this.state.userData}
                        </DropdownItem>
                      </NavLink> */}

                        <NavLink tag="li">
                          <DropdownItem
                            className="nav-item"
                            style={{ padding: "12px" }}
                          >
                            <p
                              style={{
                                color: "#000000",
                                fontWeight: "500",
                              }}
                            >{`Welcome ${getUserType().userType}`}</p>

                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                                padding: "6px",
                                marginBottom: "16px",
                                borderRadius: "4px",
                                border: "1px solid lightgray",
                              }}
                            >
                              {/* <div className="photo">
                                <img
                                  alt="..."
                                  src={require("assets/img/anime3.png")}
                                
                                />
                              </div> */}
                              <div style={{ marginTop: "5px" }}>
                                <p
                                  style={{ color: "black", fontWeight: "400" }}
                                >
                                  {this.state.profileData.firstName}{" "}
                                  {this.state.profileData.lastName}
                                </p>
                                <p
                                  style={{
                                    color: "black",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {this.state.profileData.email}
                                </p>
                              </div>
                            </div>
                            <div>
                              <button
                                type="button"
                                title="Logout"
                                aria-label="Logout"

                                style={{
                                  minWidth: "100%",
                                  background: "#1d8cf8",
                                  cursor: "pointer",
                                  color: "#ffffff",
                                  padding: "5px 0px",
                                  borderRadius: "4px",
                                  border: "none",
                                }}
                                onClick={this.logOut}
                              >
                                Logout
                              </button>
                            </div>
                          </DropdownItem>
                        </NavLink>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <li className="separator d-lg-none" />
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            <ModalComp
              controller={this.state.openFontModal}
              title="control font"
              bodyColor={this.state.colorTheme?.bodyColor}
            >
              <FontController
                handleBack={() =>
                  this.setState({
                    openFontModal: false,
                  })
                }
              />
            </ModalComp>
            <ModalComp
              controller={this.state.openColorModal}
              title="control color"
              bodyColor={this.state.colorTheme?.bodyColor}
            >
              <ColorController
                handleBack={() =>
                  this.setState({
                    openColorModal: false,
                  })
                }
              />
            </ModalComp>
            <Modal
              modalClassName="modal-search"
              isOpen={this.state.modalSearch}
              toggle={this.toggleModalSearch}
            >
              <div className="modal-header">
                <Input
                  id="inlineFormInputGroup"
                  placeholder="SEARCH"
                  type="text"
                />
                <button
                  
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.toggleModalSearch}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </div>
            </Modal>
          </nav>
        </>
      );
    } else {
      return <Redirect to="/admin/login" />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    // : state,
    colorData: state.colorValues,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearLogin: () => {
      dispatch(clearLogin());
    },
    getFontData: () => {
      dispatch(fontAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
// export default AdminNavbar;
