import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { trainerBulkDeleteAction } from "store/action/bulkDeleteAction/trainerBulkDeleteAction";
import { getTrainersAction } from "store/action/trainerActions/trainerActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function BulkDeleteTrainer(props) {
    const { onClick, userArr, setUserArr, notify } = props;

    const bulkDeleteTrainer = () => {
        const bulkDeleteUrl = `/api/teacher/bulkDelete?id=''&${userArr.toString().replaceAll(',', '&')}`;
        props.trainerBulkDelete(bulkDeleteUrl);
      };

    useEffect(() => {
      if (props?.trainerBulkDeleteRes?.deleteTrainerData) {
        notify("Trainers deleted successfully", false);
        props.clearResponse("BULK_DELETE_TRAINER_RESET");
        props.getTrainerList();
        setUserArr([])
        onClick();
      }
      if (props.adminBulkDeleteRes?.error) {
        notify(props.adminBulkDeleteRes?.error?.message, true);
        props.clearResponse("BULK_DELETE_TRAINER_RESET");
      }
    }, [props?.trainerBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Trainers?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteTrainer}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    trainerBulkDeleteRes: state.trainerBulkDelete,
    getTrainerData: state.getTrainer,
});

const mapDispatchToProps = (dispatch) => ({
    trainerBulkDelete: (payload) => {
        dispatch(trainerBulkDeleteAction(payload));
    },
    getTrainerList: () => {
        dispatch(getTrainersAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteTrainer);
