import React from 'react'
import {Button} from "reactstrap";

const DeleteNotification=({deleteNotification,setIsDeleteModalVisible})=> {
    
    return (
    <div>
        <p >Do you want to delete</p>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Button  title="Yes" aria-label="Yes"color='info' onClick={deleteNotification}>Yes</Button>
        <Button  title="No" aria-label="No" onClick={()=>setIsDeleteModalVisible(false)}>No</Button>
        </div>
    </div>
    )
}

export default DeleteNotification;