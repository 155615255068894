import React, { useState, useEffect } from 'react';

import { Table } from 'reactstrap';
import UpdateComp from 'components/UpdateComp/UpdateComp';
import TableLoopComp from './TableLoopComp';
import { connect } from 'react-redux';
const TableComp = ({ arr, moduleId, batchId, ...props }) => {
  const [font, setFont] = useState({});

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  return (
    <div
      style={{
        background: ' #ffffff0a',
        padding: '12px',
        borderRadius: '4px',
      }}
    >
      <Table
        style={{
          fontSize: font?.fontSize || 'inherit',
          fontWeight: font?.fontWeight || 'inherit',
        }}
        className='tablesorter'
      >
        <thead className='text-primary'>
          <tr>
            <th>Trainee Name</th>
            <th>Progress</th>
            <th>Grade</th>
            <th>Ban trainee</th>
          </tr>
        </thead>
        <tbody>
          {arr
            ? arr.map((item) => {
                return (
                  <TableLoopComp
                    item={item}
                    batchId={batchId}
                    moduleId={moduleId}
                  />
                );
              })
            : null}
        </tbody>
      </Table>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TableComp);
