import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Row,
  Table,
  Col,
  Progress,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import NotificationAlert from 'react-notification-alert';

import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config';

function AddMaterialStudent({ handleClose, notifi, currentStuId }) {
  const notification = useRef();

  const { handleSubmit, register, errors, reset } = useForm();
  const [bookData, setBookData] = useState([]);
  const [quizData, setQuizData] = useState([]);
  const [selectedBook, setSelectedBook] = useState({});
  const [selectedQuiz, setSelectedQuiz] = useState({});
  const [selectedQuizCount, setSelectedQuizCount] = useState([]);
  const [selectedBookCount, setSelectedBookCount] = useState([]);
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);

  const [addedBooks, setAddedBooks] = useState({});
  const [addedQuizes, setAddedQuizes] = useState({});

  const notify = (msg, err) => {
    let color = err === true ? 'danger' : 'success';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 6,
    };
    if (notifi.current) {
      notification.current.notificationAlert(options);
    }
  };
  let bookCount = Object.entries(selectedBook).map(([k, v], i) => {
    return { id: k, value: v };
  });
  let quizCount = Object.entries(selectedQuiz).map(([k, v], i) => {
    return { id: k, value: v };
  });

  let bookAddedCount = Object.entries(addedBooks)?.map(([k, v], i) => {
    return { id: k, value: v };
  });

  let quizAddedCount = Object.entries(addedQuizes)?.map(([k, v], i) => {
    return { id: k, value: v };
  });

  useEffect(() => {
    bookCount = bookCount.filter((item) => item.value === true);
    setSelectedBookCount(bookCount);
  }, [selectedBook]);

  useEffect(() => {
    quizCount = quizCount.filter((item) => item.value === true);
    setSelectedQuizCount(quizCount);
  }, [selectedQuiz]);

  const getBookData = (needfilter) => {
    db.collection('zSystemStore')
      .where('type', '==', 'book')
      .get()
      .then((querySnapshot) => {
        let book = querySnapshot.docs.map((doc) => doc.data());
        setBookData(book);
      });
  };

  const getQuizData = (needfilter) => {
    db.collection('zSystemStore')
      .where('type', '==', 'quiz')
      .get()
      .then((querySnapshot) => {
        let quiz = querySnapshot.docs.map((doc) => doc.data());
        setProgressVisible(false);
        setQuizData(quiz);
      });
  };

  const getBooks = (material) => {
    db.collection('zSystemStore')
      .where('type', '==', 'book')
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data());
        let bookArray = {};
        material.book.forEach((item, i) => {
          bookArray[item] = true;
        });
        setAddedBooks(bookArray);
        setSelectedBook(bookArray);
        setBookData(data);
      })
      .catch((err) => {
        notify(err.toString(), true);

      });
  };

  const getQuiz = (material) => {
    db.collection('zSystemStore')
      .where('type', '==', 'quiz')
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data());
        let quizArray = {};

        material.quiz.forEach((item, i) => {
          quizArray[item] = true;
        });
        setAddedQuizes(quizArray);
        setProgressVisible(false);
        setSelectedQuiz(quizArray);
        setQuizData(data);
      })
      .catch((err) => {
        notify(err.toString(), true);
      });
  };

  useEffect(() => {
    setProgressValue(99);
    db.collection('studyMaterial')
      .doc(currentStuId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          getBooks(doc.data());
          getQuiz(doc.data());
          return;
        }

        getBookData();
        getQuizData();
      });
  }, []);

  const onSubmit = (data) => {
    let deletedBooks = bookAddedCount
      ?.filter((item) => item.value === false)
      .map((item) => item.id);
    let deletedQuizes = quizAddedCount
      ?.filter((item) => item.value === false)
      .map((item) => item.id);
 
    // return
    let finalSelectedQuiz =
      selectedQuizCount.lenght !== 0
        ? selectedQuizCount.map((item) => item.id)
        : [];
    let finalSelectedBook =
      selectedBookCount.lenght !== 0
        ? selectedBookCount.map((item) => item.id)
        : [];
    db.collection('studyMaterial')
      .doc(currentStuId)
      .set(
        {
          book: finalSelectedBook,
          quiz: finalSelectedQuiz,
          deletedBooks: deletedBooks,
          deletedQuizes: deletedQuizes,
          type: 'student',
        },
        { merge: true }
      )
      .then((res) => {
        handleClose();
        notifi('Study material added Successfully', false);
      });

    return;
  };

  return (
    <div
      style={{
        backgroundColor: '#1f1e2e',
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      {progressVisible && (
        <Progress color='info' value={progressValue} style={{ height: 4 }} />
      )}

      <div className='react-notification-alert-container'>
        <NotificationAlert ref={notification} />
      </div>

      <div style={{ padding: '5px 20px' }}>
        <Row style={{ justifyContent: 'flex-end' }}>
          <IconButton
            edge='start'
            style={{ color: 'white' }}
            onClick={handleClose}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
        </Row>
        <Row>
          <Col md='12' style={{ marginBottom: '100px' }}>
            <Card style={{ margin: 0 }}>
              <CardHeader>
                <h5 className='title'>Add Study Material</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm={6} style={{ borderRight: '1px solid #3d4356' }}>
                    <h5 className='title' style={{ paddingLeft: '20px' }}>
                      Books
                    </h5>
                    <br></br>

                    <Table className='tablesorter'>
                      <thead className='text-primary'>
                        <tr>
                          <th> </th>
                          <th>Book Title</th>
                          <th>Author</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookData.map((item) => {
                          return (
                            <tr key={item.itemID}>
                              <td
                                style={{ display: 'flex', paddingLeft: '20px' }}
                              >
                                <Input
                                  type='checkbox'
                                  checked={selectedBook[item.itemID]}
                                  onChange={(e) => {
                                    if (addedBooks[item.itemID] !== undefined) {
                                      setAddedBooks((prev) => ({
                                        ...prev,
                                        [item.itemID]: !prev[item.itemID],
                                      }));
                                    }
                                    setSelectedBook((prev) => ({
                                      ...prev,
                                      [item.itemID]: !prev[item.itemID],
                                    }));
                                  }}
                                />
                              </td>
                              <td>{item.title}</td>
                              <td>{item.author}</td>
                              <td style={{ minWidth: '70px' }}>
                                ₹ {item.price}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={6} style={{ borderRight: '1px solid #3d4356' }}>
                    <h5 className='title' style={{ paddingLeft: '20px' }}>
                      Quiz
                    </h5>
                    <br></br>
                    <Table className='tablesorter'>
                      <thead className='text-primary'>
                        <tr>
                          <th> </th>
                          <th>Quiz Title</th>
                          <th>No of Questions</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quizData.map((item) => {
                          return (
                            <tr key={item.itemID}>
                              <td
                                style={{ display: 'flex', paddingLeft: '20px' }}
                              >
                                <Input
                                  type='checkbox'
                                  checked={selectedQuiz[item.itemID]}
                                  onChange={(e) => {
                                    if (
                                      addedQuizes[item.itemID] !== undefined
                                    ) {
                                      setAddedQuizes((prev) => ({
                                        ...prev,
                                        [item.itemID]: !prev[item.itemID],
                                      }));
                                    }
                                    setSelectedQuiz((prev) => ({
                                      ...prev,
                                      [item.itemID]: !prev[item.itemID],
                                    }));
                                  }}
                                />
                              </td>
                              <td>{item.title}</td>
                              <td>{item.numberOfQuestions}</td>
                              <td style={{ minWidth: '70px' }}>
                                ₹ {item.price}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <br></br>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* {selectedBookCount.length !== 0 ||
        selectedQuizCount.length !== 0 ? ( */}
        <Row
          style={{
            position: 'fixed',
            bottom: '0',
            minHeight: '60px',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            left: '-10',
            backgroundColor: '#272a3d',
          }}
        >
          <Form
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row style={{ width: '100%', alignItems: 'center' }}>
              <Button  title="Submit" aria-label="Submit" color='info' type='submit'>
                Submit
              </Button>
            </Row>
          </Form>
        </Row>
        {/* ) : (
          <div></div>
        )} */}
      </div>
    </div>
  );
}
export default AddMaterialStudent;
