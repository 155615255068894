import { Cancel, ImportExport, CloudUpload } from "@material-ui/icons";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import firebase from "firebase";
// import PDF from '../../components/PDF/PDF';

import NotificationAlert from "react-notification-alert";

import { connect } from "react-redux";
import {
  editAssignmentAction,
  getAssignmentAction,
} from "store/action/assignmentAction/assignmentAction";

import { clearResponse } from "store/action/clearResponseAction";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
// import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { func } from "prop-types";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditAssignment({ dataToEdit, notify, onClick, ...props }) {
  const history = useHistory();

  const [currentFile, setCurrentFile] = useState({});
  const [currentFileMob, setCurrentFileMob] = useState({});

  const [clickedFile, setClickedFile] = useState("");

  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const { register, handleSubmit, errors } = useForm();

  const location = useLocation();
  const notification = useRef();

  const notifi = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectImg = (e) => {
    console.log(e.target.file);
  };

  const handleSubmitAssignment = (data) => {
    // let img = data;
    const payloadData = new FormData();
    if (selectedFile) {
      payloadData.append("assignment_file", selectedFile);
    }
    payloadData.append("description", data.description);
    payloadData.append("title", data.title);
    payloadData.append("asssignmmentId", `${dataToEdit.id}`);
    payloadData.append("fileId", `${dataToEdit.fileId}`);

    props.editAssignment(payloadData);
    onClick();
  };

  // useEffect(() => {
  //   if (props?.editAssignmentRes?.editAssignmentData) {
  //     notify("Assignment updated successfully", false);

  //     props.clearResponse("EDIT_ASSIGNMENT_RESET");
  //     props.getAssignmentList();
  //     onClick();
  //   }
  //   if (props?.editAssignmentRes?.error) {
  //     notify(props?.editAssignmentRes?.error?.message, true);
  //     props.clearResponse("EDIT_ASSIGNMENT_RESET");
  //   }
  // }, [props?.editAssignmentRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{}}>
        <div className="react-notification-alert-container">
          {/* <NotificationAlert ref={notifi} /> */}
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          {/* <PDF
            handleClose={handleClose}
            clickedFile={clickedFile}
            currentFileMob={currentFileMob}
            currentFile={currentFile}
          /> */}
        </Dialog>
        <Row style={{}}>
          <Col md="11">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "center",
                  }}
                >
                  <h2
                    className="title"
                    style={{
                      fontSize: font?.fontSize + 2 || "inherit",
                      fontWeight: font?.fontWeight || "inherit",
                      textTransform: "capitalize",
                    }}
                  >
                    Edit assignment
                  </h2>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit((data, e) => {
                    handleSubmitAssignment(data);
                  })}
                >
                  <Row className="pr-md-1" md="1">
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Title">Title</label>
                        <Input
                          id="Title"
                          defaultValue={dataToEdit.title}
                          style={{ margin: "0" }}
                          innerRef={register({ required: true, minLength: 3 })}
                          name="title"
                          placeholder="Title"
                          type="text"
                          // required
                        />

                        {errors.title && errors.title.type === "minLength" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Title should be atleast 3 digits.
                          </span>
                        )}
                        {errors.title && errors.title.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Title is required.
                          </span>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="File">Upload File</label>
                        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <Input
                          id="File"
                          style={{ opacity: "1", position: "relative" }}
                          type="file"
                          accept=".txt, text/plain, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf"
                          // value={selectedFile}
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                        />
                         {
                          selectedFile ? null :
                          <p style={{color: 'white', position: 'absolute', left: '18%', background: '#27293d', zIndex: '1000', padding: '5px'}}>
                            {dataToEdit?.fileManager?.fileName}</p>
                         } 
                         </div>
                        {/* <CustomInput
                          innerRef={register({ required: true })}
                          type='file'
                          id='exampleCustomFileBrowser'
                          name='customFile'
                          label={'choose an image file'}
                          onChange={(e) => {
                            handleSelectImg(e);
                          }} 
                        // invalid={invalidFile}
                        />*/}
                        {errors.customFile && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            File required.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6" style={{}}>
                      <FormGroup>
                        <label htmlFor="Description">Description</label>
                        <Input
                          id="Description"
                          defaultValue={dataToEdit.description}
                          style={{
                            margin: "0",
                            minHeight: "118px",
                          }}
                          innerRef={register({ required: true, minLength: 3 })}
                          name="description"
                          placeholder="Description"
                          type="textarea"
                        />

                        {errors.description &&
                          errors.description.type === "minLength" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Description should be atleast 3 digits.
                            </span>
                          )}
                        {errors.description &&
                          errors.description.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Description is required.
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6"></Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      title="Submit"
                      color="info"
                      type="submit"
                      style={{ marginRight: 5 }}
                    >
                      Submit
                    </Button>

                    <Button title="Back" type="button" onClick={onClick}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  editAssignmentRes: state.editAssignment,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editAssignment: (payload) => {
    dispatch(editAssignmentAction(payload));
  },
  getAssignmentList: () => {
    dispatch(getAssignmentAction());
  },
  clearResponse: (payload) => {
    dispatch(clearResponse(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignment);
