import React, { useState } from "react";
import { Button } from "reactstrap";

import {
  deleteTeachingModuleAction,
  getTeachingModuleAction,
} from "store/action/teachingModuleAction/teachingModuleAction";
import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";
import { useEffect } from "react";

function DeleteModal(props) {
  const { moduleId, notify, setUserArr, onClick } = props;

  const deleteRecord = () => {
    const url = `api/module?id=${moduleId}`;
    props.deleteModule(url);
  };

  useEffect(() => {
    if (props?.deleteModuleRes?.deleteModuleData?.data) {
      notify(props?.deleteModuleRes?.deleteModuleData?.data?.message, false);
      props.clearResponse("DELETE_MODULE_RESET");
      // props.getModuleList();
      setUserArr([])
      onClick();
    }
    if (props.deleteModuleRes?.error) {
      notify(props.deleteModuleRes?.error?.message, true);
      props.clearResponse("DELETE_MODULE_RESET");
      onClick();
    }
  }, [props?.deleteModuleRes]);

  return (
    <div style={{ padding: 20 }}>
      <p>Do you want to delete this record</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}

        <Button
          title="Yes"
          color="info"
          onClick={() => {
            deleteRecord();
          }}
        >
          Yes
        </Button>

        {
          <Button title="No" onClick={onClick}>
            No
          </Button>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteModuleRes: state.deleteModule,
  getModuleRes: state.getModule,
});

const mapDispatchToProps = (dispatch) => ({
  deleteModule: (url) => {
    dispatch(deleteTeachingModuleAction(url));
  },
  getModuleList: () => {
    dispatch(getTeachingModuleAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
