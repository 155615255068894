/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";

import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { debounce } from "lodash";

import {
  editSecurityGroupAction,
  getSecurityGroupAction,
  getActionsAction,
  getFunctionsAction,
} from "store/action/SecurityGroupAction/SecurityGroupAction";

import { clearResponse } from "store/action/clearResponseAction";

// import styles from './sign_up_form.module.css'

function EditSecurityGroup(props) {
  const { handleClick, mySecGrp, notify } = props;
  const { register, handleSubmit, errors } = useForm();

  const [actionList, setActionList] = useState("");
  const [actionOptions, setActionOptions] = useState("");
  const [selectedActions, setSelectedActions] = useState("");

  const [functionList, setFunctionList] = useState("");
  const [functionOptions, setFunctionOptions] = useState("");
  const [selectedFunctions, setSelectedFunctions] = useState("");
  const [payloadFunctions, setPayloadFunctions] = useState("");

  const [isFirst, setIsFirst] = useState(true);
  const [secGrpName, setSecGrpName] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  //added this for the options

  //added this for the state to pass to firebase
  const [selectedPermission, setselectedPermission] = useState([]);
  const [newUser, setNewUser] = useState({});

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  // API data store start
  useEffect(() => {
    if (props?.getActionRes?.actionList)
      setActionList(props?.getActionRes?.actionList?.data);
  }, [props?.getActionRes]);

  useEffect(() => {
    if (props?.getFunctionRes?.functionList)
      setFunctionList(props?.getFunctionRes?.functionList?.data);

    if (props.getFunctionRes?.error)
      notify(props.getFunctionRes?.error?.message, true);
  }, [props?.getFunctionRes]);

  useEffect(() => {
    if (props?.editSecurityGroupRes?.editSecurityGroupData) {
      props.getSecurityGroupList();
      notify("Security group updated successfully", false);
      props.clearResponse("EDIT_SECURITY_GROUP_RESET");
      handleClick();
    }
    if (props.editSecurityGroupRes?.error) {
      notify(props.editSecurityGroupRes?.error?.message, true);
      props.clearResponse("EDIT_SECURITY_GROUP_RESET");
    }
  }, [props?.editSecurityGroupRes]);

  // API data store end

  // API call start
  useEffect(() => {
    if (!props?.getFunctionRes?.functionList) {
      props.getFunctionList();
    }
    setSecGrpName(mySecGrp.name);
    setSelectedActions(
      mySecGrp.securityGroupActions.map((action) => {
        return {
          label: action.zSystemAction.actionName,
          value: action.zSystemAction.id,
          fId: action.zSystemAction.systemFunctionId,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (functionOptions) {
      const mapped = mySecGrp.securityGroupFunctions.map((func) =>
        functionOptions.filter((opt) => opt.value === func.systemFunctionId)
      );

      setSelectedFunctions(
        mapped.map((item) => {
          return item[0];
        })
      );
    }
  }, [functionOptions]);

  // API call end

  useEffect(() => {
    if (functionList) {
      const mapped = functionList.map((functionObj) => {
        return { label: functionObj.name, value: functionObj.id };
      });
      setFunctionOptions(mapped);
    }
  }, [functionList]);

  useEffect(() => {
    if (selectedFunctions) {
      const functionArr = [];
      selectedFunctions.map((func) => {
        functionArr.push(func.value);
      });
      setPayloadFunctions(functionArr);

      props.getActionList({ functionsId: functionArr });
      if (selectedActions) {
        setSelectedActions(
          selectedActions.filter((item) => functionArr.includes(item.fId))
        );
      }
    }
  }, [selectedFunctions]);

  useEffect(() => {
    if (actionList) {
      const mapped = actionList.map((action) => {
        return { label: action.actionName, value: action.id };
      });
      setActionOptions(mapped);
    }
  }, [actionList]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const handleSelectFunction = (e) => {
    setSelectedFunctions(e);
  };

  const debouncedFn = useCallback(
    debounce((e) => {
      handleSelectFunction(e);
    }, 400)
  );

  const editSecurityGroup = () => {
    const actionsArr = [];
    if (selectedActions && selectedFunctions) {
      selectedActions.map((act) => {
        actionsArr.push(act.value);
      });

      const payload = {
        id: mySecGrp.id,
        name: secGrpName,
        functionsIds: payloadFunctions,
        actionIds: actionsArr,
      };
      if (!payloadFunctions.length) {
        notify("Access is required", true);
      } else if (!actionsArr.length) {
        notify("Action Access is required", true);
      } else {
        props.editSecurityGroup(payload);
      }
    } else {
      if (!payloadFunctions.length) {
        notify("Access is required", true);
      } else if (!actionsArr.length) {
        notify("Action Access is required", true);
      }
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card
            style={{
              margin: 0,
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader>
              <h2
                className="title"
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                Edit security group
              </h2>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(editSecurityGroup)}>
                <Row className="lg" md="1">
                  <Col className="lg" md="3">
                    <FormGroup>
                      <label htmlFor="Name">Name</label>
                      {secGrpName && (
                        <Input
                          id="Name"
                          innerRef={register({ required: true })}
                          defaultValue={secGrpName}
                          name="firstName"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length != 0) setSecGrpName(value);
                          }}
                          placeholder="Name"
                          type="text"
                          // required
                        />
                      )}
                      {errors.firstName &&
                        errors.firstName.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Name is required.
                          </span>
                        )}
                    </FormGroup>
                  </Col>

                  {/* <Col className='lg' md='6'></Col> */}
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <label htmlFor="permission">Access</label>
                    {functionOptions && (
                      <ReactMultiSelectCheckboxes
                        styles={customStyles}
                        onChange={(e) => {
                          debouncedFn(e);
                        }}
                        value={selectedFunctions}
                        placeholderButtonLabel="Select Access"
                        options={functionOptions}
                      />
                    )}
                  </Col>
                  <Col className="lg" md="6">
                    {actionOptions && <label>Action Access</label>}
                    {actionOptions && (
                      <ReactMultiSelectCheckboxes
                        styles={customStyles}
                        onChange={(e) => {
                          setSelectedActions(e);
                        }}
                        value={selectedActions}
                        placeholderButtonLabel="Select Action Access"
                        options={actionOptions}
                      />
                    )}
                  </Col>
                </Row>

                <div>
                  <Button title="Submit" color="info" type="submit">
                    Submit
                  </Button>

                  <Button
                    title="Back"
                    type="button"
                    onClick={() => {
                      handleClick();
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  editSecurityGroupRes: state.editSecurityGroup,
  getSecurityGroupRes: state.getSecurityGroup,
  getActionRes: state.getActions,
  getFunctionRes: state.getFunctions,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editSecurityGroup: (payload) => {
    dispatch(editSecurityGroupAction(payload));
  },
  getSecurityGroupList: () => {
    dispatch(getSecurityGroupAction());
  },
  getActionList: (payload) => {
    dispatch(getActionsAction(payload));
  },
  getFunctionList: () => {
    dispatch(getFunctionsAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSecurityGroup);
