import React, { useState, useEffect } from 'react';
import SubmittedAssignmentList from './SubmittedAssignmentList';

import { Table } from 'reactstrap';
import { connect } from 'react-redux';

const SubmittedAssignmentTable = ({ arr, ...props }) => {
  const [font, setFont] = useState({});

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);
  return (
    <div
      style={{
        background: ' #ffffff0a',
        padding: '12px',
        borderRadius: '4px',
      }}
    >
      <Table
        style={{
          fontSize: font?.fontSize || 'inherit',
          fontWeight: font?.fontWeight || 'inherit',
        }}
        className='tablesorter'
      >
        <thead className='text-primary'>
          <tr>
            <th>Trainee Name</th>
            <th>Submitted Assignments</th>
            <th>Quiz Report</th>
          </tr>
        </thead>
        <tbody>
          {arr.map((item, i) => {
            console.log(item);
            return (
              <>
                <SubmittedAssignmentList key={i} obj={item} />
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmittedAssignmentTable);
