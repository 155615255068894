import React, { useState } from "react";
import { Button, Col, FormGroup, Form } from "reactstrap";

import { useForm } from "react-hook-form";

import TitleIdDropdown from "components/TitleIdDropdown/TitleIdDropdown";
const AddRelation = ({
  trainerList,
  moduleList,
  batchId,
  callApi,
  controllModal,
}) => {
  const { register, handleSubmit, errors } = useForm();

  const [moduleId, setModuleId] = useState("");
  const [trainerId, settrainerId] = useState("");

  const createRelation = (data) => {
    let payLoad = {
      batchId,
      moduleId: +data.moduleId,
      teacherId: +data.trainerId,
    };

    callApi(payLoad);
  };

  return (
    <>
      <Form
        style={{
          flex: "2",
          paddingRight: 10,
          borderRight: "1px solid #29384a",
        }}
        onSubmit={handleSubmit(createRelation)}
      >
        <FormGroup col>
          <Col className="pr-md-1" md="12">
            {moduleList && (
              <TitleIdDropdown
                forName='module'
                label="Module"
                heading="Select Module"
                arr={moduleList}
                inputName="moduleId"
                // onChange={(e) => {
                //   setModuleId(e.target.value);
                // }}
                innerRef={register({ required: true })}
                errors={errors}
              />
            )}
          </Col>
          <Col className="pr-md-1" md="12">
            {trainerList && (
              <TitleIdDropdown
                forName='trainer'
                label="Trainer"
                heading="Select Trainer"
                arr={trainerList}
                inputName="trainerId"
                // onChange={(e) => {
                //   settrainerId(e.target.value);
                // }}
                name
                innerRef={register({ required: true })}
                errors={errors}
              />
            )}
          </Col>
        </FormGroup>

        <br></br>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button title="Submit" color="info" type="submit">
            Submit
          </Button>

          <Button
            title="Back"
            onClick={() => {
              controllModal(false);
            }}
          >
            Back
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddRelation;
