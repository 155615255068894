import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getTrainerReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAINER:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_TRAINER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trainerList: action.payload,
      };
    case actionTypes.GET_TRAINER_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_TRAINER_RESET":
      return {};
    default:
      return state;
  }
};
export const createTrainerReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TRAINER:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_TRAINER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createTrainerData: action.payload,
      };
    case actionTypes.CREATE_TRAINER_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "CREATE_TRAINER_RESET":
      return {};
    default:
      return state;
  }
};
export const editTrainerReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_TRAINER:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_TRAINER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editTrainerData: action.payload,
      };
    case actionTypes.EDIT_TRAINER_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "EDIT_TRAINER_RESET":
      return {};
    default:
      return state;
  }
};

export const deleteTrainerReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_TRAINER:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_TRAINER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteTrainerData: action.payload,
      };
    case actionTypes.DELETE_TRAINER_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "DELETE_TRAINER_RESET":
      return {};
    default:
      return state;
  }
};
