import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        traineeList: action.payload,
      };
    case actionTypes.GET_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
      case 'GET_TRAINEE_RESET':
        return {};
    default:
      return state;
  }
};
export const createTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createTraineeData: action.payload,
      };
    case actionTypes.CREATE_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'CREATE_TRAINEE_RESET':
      return {};

    default:
      return state;
  }
};
export const editTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        editTraineeData: action.payload,
      };
    case actionTypes.EDIT_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case 'EDIT_TRAINEE_RESET':
      return {};
    default:
      return state;
  }
};

export const deleteTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteTraineeData: action.payload,
      };
    case actionTypes.DELETE_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_TRAINEE_RESET':
      return {};
    default:
      return state;
  }
};

export const traineeBatchRelationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.TRAINEE_BATCH_RELATION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.TRAINEE_BATCH_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        traineeBatchRelationData: action.payload,
      };
    case actionTypes.TRAINEE_BATCH_RELATION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'TRAINEE_BATCH_RELATION_RESET':
      return {};
    default:
      return state;
  }
};
