import React, { useState, useEffect, useRef } from "react";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import DeleteIcon from "@material-ui/icons/Delete";
import { Table, Card, CardHeader, CardBody, Input } from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";

import { clearResponse } from "store/action/clearResponseAction";
import {
  getFilesAction,
  deleteFilesAction,
} from "store/action/fileManagerActions/fileManagerActions";
import { btnStyle } from "Utilities/common/commonStyle";
import ModalComp from "components/ModalComp/ModalComp";
import DeleteModal from "./DeleteModal";
import { indexOf } from "lodash";

import { notify } from "Utilities/common/commonFn";
import { getRequest } from "Utilities/apiService/admin";

import Loader from "components/Loader/Loader";

const FileManager = ({ ...props }) => {
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const [fileList, setFileList] = useState("");
  const [loopList, setLoopList] = useState("");
  const [category, setCategory] = useState("");

  const [fileType, setFileType] = useState("");
  const [fileId, setFileId] = useState();
  const [fileUrl, setFileUrl] = useState();

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const notifi = useRef();

  const handleChangeCategory = (e) => {
    setCategory(e?.target?.value);
  };

  const handleDelete = (type, id) => {
    setFileType(type);
    setFileId(id);
    setOpenDeleteModal(true);
  };

  const confirmDelete = () => {
    let url = `api/fileManager?fileId=${fileId}&type=${fileType}`;
    props.deleteFile(url);
  };

  const cancelDelete = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    document.title = "File Management";
    props.getFiles();
  }, []);

  useEffect(() => {
    if (props?.fileData?.fileData) {
      setFileList(props?.fileData?.fileData?.data);
      setCategory("getAssFile");
    }
  }, [props?.fileData]);

  useEffect(() => {
    if (props?.deletedFileData?.deletedFileData) {
      notify("Deleted successfully", false, notifi);
      props.clearResponse("DELETE_FILE_RESET");
      props.getFiles();
      setOpenDeleteModal(false);
    }
    if (props.deletedFileData?.error) {
      notify(props.deletedFileData?.error?.message, true, notifi);
      props.clearResponse("DELETE_FILE_RESET");
      setOpenDeleteModal(false);
    }
  }, [props?.deletedFileData]);

  useEffect(() => {
    if (category === "studyMaterial") {
      setLoopList([...fileList[0].studyVideoId, ...fileList[0]?.studyPdfId]);
    } else if (category === "getAssFile" || category === "subAssFileId") {
      setLoopList(fileList[0][category]);
    }
  }, [category, fileList]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <main id="main" className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notifi} />
      </div>
      <Card
        style={{
          fontSize: font?.fontSize || "inherit",
          fontWeight: font?.fontWeight || "inherit",
          backgroundColor: colorTheme?.bodyColor || "all",
        }}
      >
        <CardHeader
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <h1
            style={{
              marginRight: "auto",
              fontSize: font?.fontSize + 4 || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              textTransform: "capitalize",
            }}
          >
            FILE MANAGEMENT
          </h1>
        </CardHeader>

        <CardBody style={{ paddingTop: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              gap: "16px",
              padding: "16px 8px 16px 0",
            }}
          >
            <label
              style={{
                fontSize: "15px",
                fontWeight: "500",
                color: "white",
                margin: "0",
              }}
              htmlFor="data-type"
            >
              Category :
            </label>
            <Input
              style={{
                color: "white",
                border: "1px solid white",
                borderRadius: "4px",
                backgroundColor: "transparent",
                width: "250px",
                cursor: "pointer",
              }}
              type="select"
              name=""
              id="data-type"
              value={category}
              onChange={handleChangeCategory}
            >
              <option value="getAssFile">Assignments</option>
              <option value="subAssFileId">Submitted Assignments</option>
              <option value="studyMaterial">Study material</option>
            </Input>
          </div>

          <Table className="tablesorter">
            <thead>
              <tr style={{ borderBottom: "1px solid white", padding: "0 8px" }}>
                <th>Name</th>
                <th style={{ width: "64px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {loopList?.length ? (
                loopList?.map((item) => {
                  if (item?.fileManager?.fileName) {
                    return (
                      <tr
                        style={{
                          borderBottom: "1px solid grey",
                          padding: "0 8px",
                        }}
                      >
                        <th style={{ color: "white" }}>
                          {item?.fileManager?.fileName}
                        </th>
                        <td style={{ display: "flex", gap: "16px" }}>
                          <a
                            style={btnStyle}
                            aria-label="Open file"
                            title="Open file"
                            href={item?.fileManager?.fileUrl[0]}
                            target="_blank"
                          >
                            Open
                          </a>

                          <button
                            title="Delete"
                            aria-label="Delete"
                            onClick={() =>
                              handleDelete(
                                item?.type,
                                item?.fileId || item?.pdf || item?.video
                              )
                            }
                            style={actionBtnStyle}
                          >
                            <DeleteIcon
                              style={{ color: "red", fontSize: "30px" }}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <tr>
                  <td>No files found</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <ModalComp controller={openDeleteModal} bodyColor={colorTheme?.bodyColor}>
        <DeleteModal
          confirmDelete={confirmDelete}
          cancelDelete={cancelDelete}
        />
      </ModalComp>
      {props?.fileData?.isLoading || props?.deletedFileData?.isLoading ? (
        <Loader />
      ) : null}
    </main>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fileData: state.getFiles,
  deletedFileData: state.deleteFile,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getFiles: () => {
    dispatch(getFilesAction());
  },
  deleteFile: (url) => {
    dispatch(deleteFilesAction(url));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FileManager);
