import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Row, Col, FormGroup, Form } from "reactstrap";
import { Add, Delete } from "@material-ui/icons";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import { useForm } from "react-hook-form";
import InputComp from "components/InputComp/InputComp";
import TitleIdDropdown from "components/TitleIdDropdown/TitleIdDropdown";
import NotificationAlert from "react-notification-alert";
import { notify } from "../../Utilities/common/commonFn";
import { connect } from "react-redux";

const EditRelation = ({
  moduleList,
  trainerList,
  quizList,
  assignmentList,
  relationData,
  handleBack,
  callApi,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [newAssignmentData, setNewAssignmentData] = useState([]);
  const [newData, setNewData] = useState({});
  const [newQuizData, setNewQuizData] = useState([]);
  const [newQData, setNewQData] = useState({});
  const [error, setError] = useState(false);
  const [errorQ, setErrorQ] = useState(false);
  const [font, setFont] = useState({});

  const notification = useRef();

  let quizIdRef = useRef();
  let quizDateRef = useRef();
  let allocatedTimeRef = useRef();
  let marksPerQuestionRef = useRef();
  let negativeMarksRef = useRef();

  let optionRef = useRef();
  let dateRef = useRef();
  let remarkRef = useRef();

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    notification.current.notificationAlert(options);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace("T", " ");
    setNewData({ ...newData, [name]: value });
  };

  const handleChange1 = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace("T", " ");
    setNewQData({ ...newQData, [name]: value });
  };

  const checkValidation = () => {
    if (!newData.assignmentId) {
      setError(true);
    } else if (!newData.lastDate) {
      setError(true);
    } else if (!newData.remarks) {
      setError(true);
    } else if (
      newAssignmentData
        .map((item, i) => {
          return `${item.assignmentId}`;
        })
        .includes(newData.assignmentId)
    ) {
      setError(true);
    }
  };

  const checkQValidation = () => {
    if (!newQData.quizId) {
      setErrorQ(true);
    } else if (!newQData.quizDate) {
      setErrorQ(true);
    } else if (!newQData.allocatedTime) {
      setErrorQ(true);
    } else if (!newQData.marksPerQuestion) {
      setErrorQ(true);
    } else if (!newQData.negativeMarks) {
      setErrorQ(true);
    } else if (
      newQuizData
        .map((item, i) => {
          return `${item.quizId}`;
        })
        .includes(newQData.quizId)
    ) {
      setErrorQ(true);
    }
  };

  const handleAddAssignment = () => {
    if (
      newData.lastDate &&
      newData.assignmentId &&
      newData.remarks &&
      !newAssignmentData
        .map((item, i) => {
          return `${item.assignmentId}`;
        })
        .includes(newData.assignmentId)
    ) {
      notify("Assignment added", false);
      setError(false);
      setNewAssignmentData([...newAssignmentData, newData]);
      setNewData({});
      optionRef.current.value = "";
      dateRef.current.value = "";
      remarkRef.current.value = "";
    } else {
      checkValidation();
    }
  };

  const handleDeleteAssignment = (id) => {
    setNewAssignmentData([
      ...newAssignmentData.filter((item, i) => {
        return item.assignmentId != id;
      }),
    ]);
    notify("Assignment deleted", true);
  };

  const handleAddQuiz = () => {
    if (
      newQData.quizId &&
      newQData.quizDate &&
      newQData.allocatedTime &&
      newQData.marksPerQuestion &&
      newQData.negativeMarks &&
      !newQuizData
        .map((item, i) => {
          return `${item.quizId}`;
        })
        .includes(newQData.quizId)
    ) {
      notify("Quiz added", false);
      setErrorQ(false);
      setNewQuizData([...newQuizData, newQData]);
      setNewQData({});
      quizIdRef.current.value = "";
      quizDateRef.current.value = "";
      allocatedTimeRef.current.value = "";
      marksPerQuestionRef.current.value = "";
      negativeMarksRef.current.value = "";
    } else {
      checkQValidation();
    }
  };

  const handleDeleteQuiz = (id) => {
    setNewQuizData([
      ...newQuizData.filter((item, i) => {
        return item.quizId != id;
      }),
    ]);
    notify("Quiz deleted", true);
  };

  useEffect(() => {
    setNewAssignmentData(
      ...newAssignmentData,
      relationData?.batchAssignmentRelations?.map((item, i) => {
        return {
          assignmentId: item.assignmentId,
          lastDate: item.lastDate.replace(".000Z", "").replace("T", " "),
          remarks: item.remarks,
        };
      })
    );
  }, []);

  useEffect(() => {
    setNewQuizData(
      ...newQuizData,
      relationData?.quizBatchRelations?.map((item, i) => {
        return {
          quizId: item.quizId,
          quizDate: item.quizDate.replace(".000Z", "").replace("T", " "),
          allocatedTime: `${item.allocatedTime}`,
          marksPerQuestion: item.marksPerQuestion,
          negativeMarks: item.negativeMarks,
        };
      })
    );
  }, []);

  const handleSubmitEdit = (data) => {
    data.batModAssignId = relationData?.id;

    if (data?.remarks) {
      delete data.remarks;
    }
    if (data?.lastDate) {
      delete data.lastDate;
    }
    if (data?.assignmentId) {
      delete data.assignmentId;
    }
    if (relationData?.batchId) {
      data.batchId = relationData.batchId;
    }
    if (data?.moduleId) {
      data.moduleId = +data?.moduleId;
    }
    if (data?.teacherId) {
      data.teacherId = +data?.teacherId;
    }

    // }
    if (newAssignmentData) {
      data.assignments = newAssignmentData;
    }

    if (data?.quizId) {
      delete data.quizId;
    }
    if (data?.quizDate) {
      delete data.quizDate;
    }
    if (data?.allocatedTime) {
      delete data.allocatedTime;
    }
    if (data?.negativeMarks) {
      delete data.negativeMarks;
    }
    if (data?.marksPerQuestion) {
      delete data.marksPerQuestion;
    }
    if (newQuizData) {
      data.quiz = newQuizData;
    }
    // if (data.lastDate == '') {
    //   data.lastDate = null
    // }
    callApi(data);
  };
  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);
  return (
    <>
      <div>
        <NotificationAlert ref={notification} />
      </div>
      <Form
        style={{
          flex: "2",
        }}
        onSubmit={handleSubmit(handleSubmitEdit)}
      >
        <FormGroup
          col
          style={{
            borderBottom: "1px solid white",
            marginBottom: "12px",
          }}
        >
          <h3
            style={{
              fontSize: font?.fontSize + 1 || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              textTransform: "capitalize",
            }}
          >
            Module and Trainer relation
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            {/* <Col className='pr-md-1' md='12'> */}
            {moduleList && (
              <TitleIdDropdown
                forName="module"
                selectedValue={relationData?.module?.title}
                label="Module"
                heading="Select Module"
                arr={moduleList}
                inputName="moduleId"
                // onChange={handleChange}
                innerRef={register({ required: false })}
                errors={errors}
              />
            )}
            {/* </Col> */}
            {/* <Col className='pr-md-1' md='12'> */}
            {trainerList && (
              <TitleIdDropdown
                forName="trainer"
                selectedValue={`${relationData?.teacherModuleRelations[0]?.teacher?.firstName} ${relationData?.teacherModuleRelations[0]?.teacher?.lastName}`}
                label="Trainer"
                heading="Select Trainer"
                arr={trainerList}
                inputName="teacherId"
                name
                // onChange={handleChange}
                innerRef={register({ required: false })}
                errors={errors}
              />
            )}
            {/* </Col> */}
          </div>
        </FormGroup>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-between",
            gap: "20px",
            marginTop: "16px",
            borderBottom: "1px solid white",
            paddingBottom: "12px",
          }}
        >
          <FormGroup col style={{ flex: "2", paddingBottom: "10px" }}>
            <>
              <h3
                style={{
                  fontSize: font?.fontSize + 1 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                Quiz relation
              </h3>
              {errorQ &&
              newQuizData
                .map((item, i) => {
                  return `${item.quizId}`;
                })
                .includes(newQData.quizId) ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Same data are not allowed
                </span>
              ) : null}

              <Row>
                <Col>
                  {quizList && (
                    <TitleIdDropdown
                      forName="quiz"
                      label="Quiz"
                      heading="Select Quiz"
                      arr={quizList}
                      inputName="quizId"
                      onChange={handleChange1}
                      innerRef={quizIdRef}
                      // innerRef={register({ required: false })}
                      errors={errors}
                    />
                  )}
                  {errorQ && !newQData.quizId ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Quiz title is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    label="Quiz date"
                    name="quizDate"
                    type="date"
                    innerRef={quizDateRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder="Quiz date"
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.quizDate ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Quiz Date is required
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   relationData?.quizBatchRelations[0]?.allocatedTime
                    // }
                    label="Allocated time"
                    name="allocatedTime"
                    type="text"
                    innerRef={allocatedTimeRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder="Enter time in minute"
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.allocatedTime ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Allocated Time is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   relationData?.quizBatchRelations[0]?.marksPerQuestion
                    // }
                    label="Marks per question"
                    name="marksPerQuestion"
                    type="text"
                    innerRef={marksPerQuestionRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder="Enter marks per question"
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.marksPerQuestion ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Marks Per Question is required
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   relationData?.quizBatchRelations[0]?.negativeMarks
                    // }
                    label="Negative marking"
                    name="negativeMarks"
                    type="text"
                    innerRef={negativeMarksRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder="Enter negative marking/question"
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.negativeMarks ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Negative Marks is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    title="Add Quiz"
                    color="info"
                    type="button"
                    onClick={handleAddQuiz}
                  >
                    Add Quiz <Add />
                  </Button>
                </Col>
              </Row>
            </>
          </FormGroup>
          <div
            style={{
              flex: "3",
              borderLeft: "1px solid rgb(41, 56, 74)",
              paddingLeft: "10px",
            }}
          >
            <Table
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
              }}
              className="tablesorter"
            >
              <thead className="text-primary">
                <tr>
                  <th style={{ textAlign: "center" }}>Quiz</th>
                  <th style={{ textAlign: "center" }}>Quiz date</th>
                  <th style={{ textAlign: "center" }}>Allocated time</th>
                  <th style={{ textAlign: "center" }}>Marks per question</th>
                  <th style={{ textAlign: "center" }}>Negative marking</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {newQuizData?.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td style={{ textAlign: "center" }}>
                          {quizList?.map((itemm, i) => {
                            return itemm.id == item.quizId ? itemm.title : null;
                          })}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.quizDate}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.allocatedTime}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.marksPerQuestion}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.negativeMarks}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          <div title="Delete">
                            <button
                              title="Delete"
                              
                              type="button"
                              onClick={() => handleDeleteQuiz(item.quizId)}
                              style={actionBtnStyle}
                              aria-label="Delete relation"
                            >
                              <Delete
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-between",
            gap: "20px",
            marginTop: "12px",
          }}
        >
          <FormGroup col style={{ flex: "2" }}>
            <>
              <h3
                style={{
                  fontSize: font?.fontSize + 1 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                Assignment relation
              </h3>
              {error &&
              newAssignmentData
                .map((item, i) => {
                  return `${item.assignmentId}`;
                })
                .includes(newData.assignmentId) ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Same data are not allowed
                </span>
              ) : null}
              {assignmentList && (
                <TitleIdDropdown
                  // selectedValue={
                  //   relationData?.batchAssignmentRelations[0]?.assignment?.title
                  // }
                  forName="assignment"
                  label="Assignment"
                  heading="Select Assignment"
                  arr={assignmentList}
                  inputName="assignmentId"
                  onChange={handleChange}
                  innerRef={optionRef}
                  // innerRef={register({ required: true })}
                  errors={errors}
                />
              )}
              {error && !newData.assignmentId ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Assignment title is required
                </span>
              ) : null}
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={relationData?.batchAssignmentRelations[0]?.lastDate?.replace(
                    //   '.000Z',
                    //   ''
                    // )}
                    label="Last date"
                    name="lastDate"
                    type="datetime-local"
                    innerRef={dateRef}
                    // innerRef={register({ required: true })}
                    errors={errors}
                    placeholder="Last date"
                    onChange={handleChange}
                  />
                  {error && !newData.lastDate ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Last Date is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   relationData?.batchAssignmentRelations[0]?.remarks
                    // }
                    label="Remarks"
                    name="remarks"
                    type="text"
                    innerRef={remarkRef}
                    // innerRef={register({ required: true })}
                    errors={errors}
                    placeholder="Remarks"
                    onChange={handleChange}
                  />
                  {error && !newData.remarks ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Remark is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                  title="Add Assignment"
                    color="info"
                    type="button"
                    onClick={handleAddAssignment}
                  >
                    Add Assignment <Add />
                  </Button>
                </Col>
              </Row>
            </>
          </FormGroup>
          <div
            style={{
              flex: "3",
              borderLeft: "1px solid rgb(41, 56, 74)",
              paddingLeft: "10px",
            }}
          >
            <Table
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
              }}
              className="tablesorter"
            >
              <thead className="text-primary">
                <tr>
                  <th style={{ textAlign: "center" }}>Assignment</th>
                  <th style={{ textAlign: "center" }}>Last date</th>
                  <th style={{ textAlign: "center" }}>Remark</th>
                  <th style={{ textAlign: "center" }}>Action</th>
                </tr>
              </thead>
              <tbody>
                {newAssignmentData?.map((item, i) => {
                  return (
                    <>
                      <tr key={i}>
                        <td style={{ textAlign: "center" }}>
                          {assignmentList?.map((itemm, i) => {
                            return itemm.id == item.assignmentId
                              ? itemm.title
                              : null;
                          })}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.lastDate}</td>
                        <td style={{ textAlign: "center" }}>{item.remarks}</td>

                        <td style={{ textAlign: "center" }}>
                          <div title="Delete">
                            <button
                            title="Delete" 
                              type="button"
                              onClick={() =>
                                handleDeleteAssignment(item.assignmentId)
                              }
                              style={actionBtnStyle}
                              aria-label="Delete relation"
                            >
                              <Delete
                                style={{ color: "red", cursor: "pointer" }}
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
        <br></br>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button title="Submit" color="info" type="submit">
            Submit
          </Button>

          <Button title="Back" onClick={handleBack}>
            Back
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EditRelation);
