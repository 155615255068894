/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Assignment from 'Modules/AssignmentModule/Assignment';
// import Book_List from 'Book_Management_Module/Book_List';
import StudentsTable from 'Modules/STUDENT_MODULE/student_info_table';
import Dashboard from 'views/Dashboard.js';
import Icons from 'views/Icons.js';
import Map from 'views/Map.js';
import Notifications from 'views/Notifications.js';
import Rtl from 'views/Rtl.js';
import ActivityTable from 'Modules/ActivityModule/ActivityTable';
import AdminTable from 'Modules/AdminModule/AdminTable';
import Typography from 'views/Typography.js';
import UserProfile from 'views/UserProfile.js';
import EnquiryTable from 'components/Enquiry/EnquiryTable';
import FeedbackTable from 'components/Feedback/FeedbackTable';
import Quiz_Management from './components/Quiz_Management/Quiz_Management';
import TransactionTable from 'components/TransactionManagement/TransactionTable';
import ChatDialog from './components/Chats/ChatDialog';
import PackageManagement from './components/PackageManagement/PackageManagement';
import DiscountManagement from 'components/DiscountManagement/DiscountManagement';
import BatchList from 'Modules/Batch/BatchList';
import NotificationList from 'components/NotificationCenter/NotificationList';
import MediaCenter from 'components/MediaCenter/MediaCenter';
import InvoceList from 'components/InvoiceManagement/InvoceList';
import TrainerTable from 'Modules/TRAINER_MODULE/TrainerTable';
import SecurityGroupTable from 'Modules/SecurityGroup/SecurityGroupTable';
import TeachingModuleTable from 'Modules/TeachingModule/TeachingModuleTable';
import CertificateModuleTable from 'Modules/CertificateModule/CertificateModuleTable';

import TrainerDashboard from 'Modules/TRAINER_MODULE/TrainerProfilePages/TrainerDashboard';
import TrainerBatchDetails from 'Modules/TRAINER_MODULE/TrainerProfilePages/TrainerBatchDetails';
import TaskManagement from 'Modules/TRAINER_MODULE/TaskManagement/TaskManagement';
import TaskDetails from 'Modules/TRAINER_MODULE/TaskManagement/TrakDeatils';
import RequestManagement from 'Modules/TRAINER_MODULE/RequestManagement/RequestManagement';
import TrainersProfilePage from 'Modules/TRAINER_MODULE/TrainersProfilePage';

import TraineeReportBatches from 'Modules/TRAINER_MODULE/TraineeReport/TraineeReportBatches';
import Chat from 'components/Chats/Chat';
import FileManager from 'Modules/FileManager/FileManager';
//changed all the names to match with the firebase data

// >>>>>>>>>>ADMIN ROUTES

var routes = [
  {
    path: '/activity',
    name: 'Activities',
    id: 'Activity',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-bell-55',
    component: ActivityTable,
    layout: '/admin',
    sNo: 13,
  },
  {
    path: '/tables',
    name: 'Admin Management',
    id: 'Admin',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-single-02',
    component: AdminTable,
    layout: '/admin',
    sNo: 1,
  },
  {
    path: '/trainer',
    name: 'Trainer Management',
    id: 'Trainer',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-single-02',
    component: TrainerTable,
    layout: '/admin',
    sNo: 2,
  },
  {
    path: '/trainee',
    name: 'Trainee Management',
    id: 'Trainee',

    rtlName: 'قائمة الجدول',
    icon: 'fas fa-users',
    component: StudentsTable,
    layout: '/admin',
    sNo: 3,
  },
  {
    path: '/training',
    name: 'Training Management',
    id: 'Batch',
    icon: 'tim-icons icon-user-run',
    rtlName: 'قائمة الجدول',
    component: BatchList,
    layout: '/admin',
    sNo: 4,
  },
  {
    path: '/module',
    name: 'Module Management',
    id: 'Module',

    rtlName: 'طباعة',
    icon: 'tim-icons icon-book-bookmark',
    component: TeachingModuleTable,
    layout: '/admin',
    sNo: 5,
  },
  {
    path: '/assignment',
    name: 'Assignment Management',
    id: 'Assignment',
    rtlName: 'طباعة',
    icon: 'tim-icons icon-notes',
    component: Assignment,
    layout: '/admin',
    sNo: 6,
  },
  {
    path: '/security-group',
    name: 'Security Group',
    id: 'Security Group',

    rtlName: 'طباعة',
    icon: 'tim-icons icon-lock-circle',

    component: SecurityGroupTable,
    layout: '/admin',
    sNo: 11,
  },

  {
    path: '/quiz',
    name: 'Quiz Management',
    id: 'Quiz',
    icon: 'fas fa-gamepad',
    component: Quiz_Management,
    layout: '/admin',
    sNo: 7,
  },

  {
    path: '/chat',
    name: 'Chat',
    id: 'Chat',
    icon: 'fas fa-comments',
    component: ChatDialog,
    layout: '/admin',
    sNo: 10,
  },

  {
    path: '/notifications',
    name: 'Notification Management',
    id: 'Notification_Management',
    icon: 'far fa-bell',
    component: NotificationList,
    layout: '/admin',
    sNo: 12,
  },

  {
    path: '/certificate',
    name: 'Certificate Management',
    id: 'Certificate',
    // rtlName: 'قائمة الجدول',
    icon: 'fas fa-certificate',
    component: CertificateModuleTable,
    layout: '/admin',
    sNo: 9,
  },
  {
    path: '/files',
    name: 'File Management',
    id: 'Files',
    // rtlName: 'قائمة الجدول',
    icon: 'fas fa-file',
    component: FileManager,
    layout: '/admin',
    sNo: 8,
  },
];
export default routes;

// >>>>>>>>>>TRAINER ROUTES

export const trainerRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    id: 'Assignment',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-single-02',
    component: TrainerDashboard,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/profile',
    name: 'Trainer profile',
    id: 'Profile',
    rtlName: 'قائمة الجدول',
    icon: 'tim-icons icon-single-02',
    component: TrainersProfilePage,
    layout: '/trainer',
    status: 'hidden',
  },
  {
    path: '/assignment',
    name: 'Assignment Management',
    id: 'Assignment',
    rtlName: 'طباعة',
    icon: 'tim-icons icon-book-bookmark',
    component: Assignment,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/quiz',
    name: 'Quiz Management',
    id: 'Quiz',
    icon: 'fas fa-gamepad',
    component: Quiz_Management,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/training-details',
    name: 'Training Details',
    id: 'batchDetails',
    icon: 'fas fa-gamepad',
    component: TrainerBatchDetails,
    layout: '/trainer',
    status: 'hidden',
  },
  {
    path: '/task',
    name: 'Task Management',
    id: 'taskDetails',
    icon: 'fas fa-clipboard',
    component: TaskManagement,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/task-details',
    name: 'Task Management',
    id: 'taskDetails',
    icon: 'fas fa-gamepad',
    component: TaskDetails,
    layout: '/trainer',
    status: 'hidden',
  },
  {
    path: '/trainee-report',
    name: 'Trainee Report',
    id: 'TraineeReport',
    icon: 'fas fa-gamepad',
    component: TraineeReportBatches,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/requests',
    name: 'Request Management',
    id: 'requestDetails',
    icon: 'fas fa-hand-pointer',
    component: RequestManagement,
    layout: '/trainer',
    status: 'vissible',
  },
  {
    path: '/chatStudents',
    name: 'Chats',
    id: 'requestDetails',
    icon: 'fas fa-hand-pointer',
    component: Chat,
    layout: '/trainer',
    status: 'hidden',
  },
];
