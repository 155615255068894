import React, { useState, useEffect, useRef } from "react";
import {
  tableDivStyle,
  paraStyle,
  actionBtnStyle,
} from "Utilities/common/commonStyle";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { notify } from "Utilities/common/commonFn";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
  ModalBody,
  Modal,
} from "reactstrap";
import {
  getTrainersModuleAction,
  getTrainersTraineeAction,
} from "store/action/trainerActions/trainerProfileActions";
import { getSubmittedAssignmentAction } from "store/action/trainerActions/trainerTaskActions";
import { clearResponse } from "store/action/clearResponseAction";
import TabularContainer from "components/TabularContainer/TabularContainer";
import Loader from "components/Loader/Loader";

const TaskDetails = (props) => {
  const [moduleList, setModuleList] = useState("");
  const [batchName, setBatchName] = useState("");
  const [batchId, setBatchId] = useState("");
  const [moduleId, setModuleId] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const history = useHistory();

  const notifi = useRef();

  useEffect(() => {
    if (localStorage.getItem("batch")) {
      let batch = JSON.parse(localStorage.getItem("batch"));
      setBatchId(batch.bId);
      setBatchName(batch.bTitle);
    }
  }, []);

  useEffect(() => {
    if (props?.getTrainersModuleRes?.trainersModuleList?.data) {
      setModuleList(props?.getTrainersModuleRes?.trainersModuleList?.data);
    }
  }, [props?.getTrainersModuleRes]);

  useEffect(() => {
    if (batchId) {
      let url = `batchId=${batchId}`;
      props.getTrainersModuleList(url);
    }
  }, [batchId]);

  useEffect(() => {
    if (props?.addEditQuizRes?.addEditQuizData?.data) {
      notify("Data updated successfully", false, notifi);
      props.clearResponse("ADD_EDIT_QUIZ_RESET");
      if (batchId) {
        let url = `batchId=${batchId}`;
        props.getTrainersModuleList(url);
      }
      if (moduleId && batchId) {
        props.getSubmittedAssignmentList(batchId, moduleId);
      }
    }
    if (props.addEditQuizRes?.error) {
      notify(props.addEditQuizRes?.error?.message, true, notifi);
      props.clearResponse("ADD_EDIT_QUIZ_RESET");
    }
  }, [props?.addEditQuizRes?.addEditQuizData]);

  useEffect(() => {
    if (props?.addEditAssignmentRes?.addEditAssignmentData?.data) {
      notify("Data updated successfully", false, notifi);
      props.clearResponse("ADD_EDIT_ASSIGNMENT_RESET");
      if (batchId) {
        let url = `batchId=${batchId}`;
        props.getTrainersModuleList(url);
      }
      if (moduleId && batchId) {
        props.getSubmittedAssignmentList(batchId, moduleId);
      }
    }
    if (props.addEditAssignmentRes?.error) {
      notify(props.addEditAssignmentRes?.error?.message, true, notifi);
      props.clearResponse("ADD_EDIT_ASSIGNMENT_RESET");
    }
  }, [props?.addEditAssignmentRes?.addEditAssignmentData]);

  useEffect(() => {
    if (
      props?.getSubmittedAssignmentRes?.submittedAssignmentList?.data &&
      props?.getSubmittedAssignmentRes?.submittedAssignmentList?.data
        ?.length === 0 &&
      !props.task
    ) {
      notify("List is empty", true, notifi);
      props.clearResponse("GET_SUBMITTED_ASSIGNMENT_REST");
    }
    if (props.getSubmittedAssignmentRes?.error)
      notify("Something went wrong", true, notifi);
  }, [props?.getSubmittedAssignmentRes]);

  useEffect(() => {
    if (props?.downloadAssignment?.submittedAssignmentData?.data) {
      // saveAs(
      //   props?.downloadAssignment?.submittedAssignmentData?.data,
      //   'file.png'
      // );
      // var FileSaver = require('file-saver');
      // var blob = new Blob(
      //   [props?.downloadAssignment?.submittedAssignmentData?.data],
      //   {
      //     type: 'mimeType',
      //   }
      // );
      // // let url = window.URL.createObjectURL(blob);
      // FileSaver.saveAs(blob, 'hello world.txt');
      // props?.downloadAssignment?.submittedAssignmentData?.data;
      // FileSaver.saveAs('', 'image.jpg');
    }
  }, [props?.downloadAssignment]);

  // useEffect(() => {
  //   if (batchId) {
  //     let url = `batchId=${batchId}`;
  //     props.getTrainersModuleList(url);
  //   }
  // }, [batchId]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notifi} />
      </div>
      <div
        style={{
          padding: "16px",
          background: "#27293C",
          borderRadius: "4px",
          display: "flex",
          flexWrap: "wrap",
          fontSize: font?.fontSize || "inherit",
          fontWeight: font?.fontWeight || "inherit",
          backgroundColor: colorTheme?.bodyColor || "all",
        }}
      >
        {/* <div>{batchName && <h4>{batchName}</h4>} 
                <button onClick={(e)=>{history.push('/trainer/task')}}>
                    Back</button>
                    </div> */}

        {batchName ? (
          <>
            <Row
              style={{ objectFit: "fill", overflow: "hidden", width: "100%" }}
            >
              <Col md="12">
                <div>
                  <Card
                    style={{
                      fontSize: font?.fontSize || "inherit",
                      fontWeight: font?.fontWeight || "inherit",
                      backgroundColor: colorTheme?.bodyColor || "all",
                    }}
                  >
                    <>
                      <CardHeader
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <h1
                          
                          style={{
                            fontSize: font?.fontSize + 4 || "inherit",
                            fontWeight: font?.fontWeight || "inherit",
                            margin:"0 auto 0 0",
                            textTransform:"capitalize"
                          }}
                        >
                          {batchName}
                        </h1>
                        {/* <NavLink
                        to={{
                          pathname: '/admin/book-step-1',
                          setVisible: setVisible,
                        }}
                        exact
                        style={{ color: 'white' }}
                      >
                        {' '} */}
                        <Button
                        title="Back" aria-label="Back"
                          onClick={(e) => {
                            history.push("/trainer/task");
                          }}
                          color="info"
                        >
                          Back
                        </Button>{" "}
                        {/* </NavLink> */}
                      </CardHeader>
                    </>
                  </Card>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          "No Data"
        )}
       <div role="table" style={{width:"100%"}}>
       <div style={{width:"100%"}} role="rowgroup">
       <div
          style={{ ...tableDivStyle, gridTemplateColumns: "repeat(4, 1fr )" }}
          role="row"
        >
          <span style={{ ...paraStyle, fontWeight: "600", fontSize: "14px",color:"#FFFFFF" }} role="columnheader">
            Title
          </span>
          <span style={{ ...paraStyle, fontWeight: "600", fontSize: "14px",color:"#FFFFFF" }} role="columnheader">
            Description
          </span>
          {/* <span style={{ ...paraStyle, fontWeight: '600', fontSize: '14px' }}></span>
          <span style={{ ...paraStyle, fontWeight: '600', fontSize: '14px' }}></span> */}
          <span style={{ ...paraStyle, fontWeight: "600", fontSize: "14px",color:"#FFFFFF" }} role="columnheader">
            Add/Edit Quiz
          </span>
          <span style={{ ...paraStyle, fontWeight: "600", fontSize: "14px",color:"#FFFFFF" }} role="columnheader">
            Add/Edit Assignment
          </span>
        </div>
       </div>
       
        {moduleList ? (
          moduleList.map((mod) => {
            return (
              <TabularContainer
                mod={mod}
                setModuleId={setModuleId}
                moduleId={moduleId}
                batchId={batchId}
                task={true}
                // callApi={() => props.getTrainersTraineeList(batchId, moduleId)}
              />
            );
          })
        ) : (
          <h3>No module assigned</h3>
        )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  getTrainersModuleRes: state.getTrainersModule,
  getTrainersTraineeRes: state.getTrainersTrainee,
  addEditQuizRes: state.addEditQuiz,
  addEditAssignmentRes: state.addEditAssignment,
  getSubmittedAssignmentRes: state.getSubmittedAssignment,
  downloadAssignment: state.downloadSubmittedAssignment,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainersModuleList: (url) => {
    dispatch(getTrainersModuleAction(url));
  },
  getSubmittedAssignmentList: (batchId, moduleId) => {
    dispatch(getSubmittedAssignmentAction(batchId, moduleId));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
  // getTrainersTraineeList: (batchId, moduleId) => {
  //   dispatch(getTrainersTraineeAction(batchId, moduleId));
  // },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
