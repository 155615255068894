import * as actionTypes from '../../action/actionType';

const intialState = {};

export const traineeBulkDeleteReducer = (state = intialState, action) => {
    switch (action.type) {
      case actionTypes.BULK_DELETE_TRAINEE:
        return { ...state, isLoading: action.isLoading };
      case actionTypes.BULK_DELETE_TRAINEE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          deleteTraineeData: action.payload,
        };
      case actionTypes.BULK_DELETE_TRAINEE_FAIL:
        return { isLoading: action.isLoading, error: action.payload.error };
      case 'BULK_DELETE_TRAINEE_RESET':
        return {};
      default:
        return state;
    }
  };