import React, { useState, useEffect, useRef } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Table,
  Col,
  Progress,
  CustomInput,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import { useForm } from 'react-hook-form';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';

import NotificationAlert from 'react-notification-alert';

import firebase from 'firebase';

import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config';
import algoliasearch from 'algoliasearch/lite';
import { algoliaAppID, algoliaSearchID } from '../../Utilities/algoliaKeys';
import { getQuestionAction } from 'store/action/questionAction/questionAction';
import {
  createQuizAction,
  editQuizAction,
} from 'store/action/quizAction/quizAction';

function QuizDialog({
  currentQuiz,
  startIndex,
  setQuizesData,
  handleClose,
  notifi,
  ...props
}) {
  console.log('current Quiz', currentQuiz);

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const index = searchClient.initIndex('Questions_Search');

  const chunkSize = 10;
  const notification = useRef();

  const [searchValue, setSearchValue] = useState('');

  const [questionsData, setQuestionsData] = useState([]);
  const [selectedQues, setSelectedQues] = useState({});
  const [selectedSubjects, setselectedSubjects] = useState([]);
  const [selectedDifficulty, setselectedDifficulty] = useState('');
  const [selectedQuesCount, setSelectedQuesCount] = useState([]);

  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [reload, setreload] = useState(false);
  const [iswithoutTime, setIswithoutTime] = useState(false);
  const [quizCover, setQuizCover] = useState();
  const [imageUrl, setimageUrl] = useState('');
  const [quizImage, setQuizImage] = useState();

  const history = useHistory();
  const [alert, setalert] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const { handleSubmit, register, errors, reset } = useForm();

  const subjectOptions = [
    { label: 'Science', value: 'Science' },
    { label: 'Math', value: 'Math' },
    { label: 'English', value: 'English' },
    // { label: '', value: 'Book_Management' },
    // { label: 'Transaction Management', value: 'Transaction_Management' },
    // { label: 'Reports', value: 'Reports' },
    // { label: 'Quiz_Management', value: 'Quiz_Management' }
  ];

  const difficultyOptions = [
    { label: 'Easy', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'Difficult', value: 3 },
    // { label: 'Level 4', value: 4 },
    // { label: 'Level 5', value: 5 },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#272a3d' : 'black',
      padding: 10,
    }),
    control: () => ({
      display: 'none',
    }),
  };

  const notify = (msg, err) => {
    let color = err === true ? 'danger' : 'success';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 15,
    };
    if (notifi.current) {
      notification.current.notificationAlert(options);
    }
  };

  const getQuestions = () => {
    props.getQuestions();

    console.log('#$%', props);
  };

  const getEditQuestions = () => {
    console.log('edit');

    // let currentQuesArray = currentQuiz.questions

    console.log(')))))', currentQuiz);

    currentQuiz.quizQuestions.map((item) => {
      setSelectedQues((prev) => ({
        ...prev,
        [item.id]: !prev[item.id],
      }));
    });
  };

  const selectQuizCover = (event) => {
    console.log(event.target.files[0]);
    console.log('my image name', event.target.files[0].name);
    if (!event.target.files[0]) {
      return;
    }
    if (
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png'
    ) {
      try {
        let date = new Date().valueOf();

        var uploadTask = firebase
          .storage()
          .ref()
          .child(`quizCover/${date}${event.target.files[0].name}`)
          .put(event.target.files[0]);
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log('upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log('upload is running');
                break;
            }
          },
          function (error) {
            console.log('this is error', error);
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                notify('Quiz Cover Uploaded Successfully', false);

                console.log('File available at ', downloadURL);
                setimageUrl(downloadURL);
                setQuizImage(downloadURL);
              })
              .catch((err) => {
                notify(err.toString(), true);
              });
          }
        );
      } catch (error) {
        notify(error.toString(), true);

        console.log('my error', error);
      }
      setQuizCover(event.target.files[0].name);
    } else {
      notify('Please select only .jpeg/.png file', true);
    }
  };

  useEffect(() => {
    setProgressValue(99);

    if (props?.getQuestion?.questionList) {
      //     if(currentQuiz.questions.length > 10){
      //         let currentQuesArray= {};
      //         currentQuiz.questions.forEach((item, i) => {
      //             currentQuesArray[item] = true
      //         })
      // setSelectedQues(props?.getQuestion?.questionList?.data)

      setQuestionsData(props?.getQuestion?.questionList?.data);
      //     }
    }
    console.log('*****', props?.getQuestion?.questionList?.data);
  }, [props?.getQuestion]);

  useEffect(() => {
    if (currentQuiz) {
      getEditQuestions();
    } else {
      getQuestions();
    }
  }, []);

  useEffect(() => {
    console.log(selectedQues);
  }, [selectedQues]);

  const filterData = (item) => {
    console.log(item);
    // document.querySelector('#loading-indicator').style.display = 'flex';
  };

  const filterDifficulty = (item) => {
    console.log(item);
    document.querySelector('#loading-indicator').style.display = 'flex';

    db.collection('Questions')
      .where('difficulty', 'in', item)
      .limit(100)
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        if (selectedSubjects.length > 0) {
          let subject = selectedSubjects.map((item) => item.value);
          data = data.filter((item) =>
            subject.find((element) => element === item.subject)
          );
          console.log('static', data);
          setQuestionsData(data);
          document.querySelector('#loading-indicator').style.display = 'none';
        } else {
          document.querySelector('#loading-indicator').style.display = 'none';

          setQuestionsData(data);
        }
      })
      .catch((err) => {
        document.querySelector('#loading-indicator').style.display = 'none';
        notify(err.toString(), true);
        console.log(err.toString());
      });

    // db.collection('Questions').where('difficulty', '==', data).get()
    //     .then((querySnapshot) => {
    //         let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))

    //         if (index === 0) {
    //             setQuestionsData(data)

    //         } else {
    //             data.map(item => {

    //                 setQuestionsData(prev => [...prev, item])
    //             })

    //         }

    //         console.log(" => ", data);

    //     }
    //     )
    //     .catch(function (error) {
    //         console.log("Error getting documents: ", error);
    //     });
  };

  const onsubmit = () => {
    console.log('submit', selectedSubjects, selectedDifficulty);

    if (selectedSubjects.length !== 0) {
      let a = selectedSubjects.map((item) => item.value);
      console.log('selected.....', a);
      filterData(a);
    } else if (selectedDifficulty.length !== 0) {
      let b = selectedDifficulty.map((item) => item.value);
      console.log('selected.....', b);
      filterDifficulty(b);
    } else {
      if (currentQuiz) {
        document.querySelector('#loading-indicator').style.display = 'flex';

        getEditQuestions();
      } else {
        document.querySelector('#loading-indicator').style.display = 'flex';

        getQuestions();
      }
    }
    // selectedDifficulty.map((item, index) => {

    //     filterDifficulty(item.value, index)

    // })
  };
  // console.log('questuion data',questionsData)

  let count = Object.entries(selectedQues).map(([k, v], i) => {
    //    let a= {item[i],item[i+1}

    // console.log(k,v,i)
    return { id: k, value: v };
  });

  useEffect(() => {
    count = count.filter((item) => item.value === true);
    setSelectedQuesCount(count);

    console.log('selected COunt', count);
  }, [selectedQues]);

  const submitQuiz = async (data) => {
    console.log('onsubmit', data);

    if (selectedQuesCount.length !== 0) {
      if (currentQuiz) {
        let time = iswithoutTime ? 10 : parseInt(data.time);
        let finalSelectedQuestion = selectedQuesCount.map((item) => item.id);
        let finalData = {
          id: currentQuiz.id,
          title: data.quizTitle,
          questionId: finalSelectedQuestion,
          // numberOfQuestions: selectedQuesCount.length,
          timeAllocated: `00`,
          subject: 'Science',
          // quizCover: imageUrl,
          difficultyLevel: selectedDifficulty || currentQuiz.difficultyLevel,
          timeRequired: false,
          // lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        console.log('final data', finalData);

        props.updateQuize(finalData);
        handleClose();
      } else {
        let time = iswithoutTime ? 10 : parseInt(data.time);
        let finalSelectedQuestion = selectedQuesCount.map((item) => item.id);
        let finalData = {
          title: data.quizTitle,
          questionId: finalSelectedQuestion,
          // numberOfQuestions: selectedQuesCount.length,
          timeAllocated: `00`,
          subject: 'Science',
          // quizCover: imageUrl,
          difficultyLevel: selectedDifficulty,
          timeRequired: false,
          // lastUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };
        console.log('final data', finalData);

        props.createQuize(finalData);
        handleClose();
      }
    } else {
      notify('Select atleast 1 question ', true);
    }
  };

  const search = () => {
    console.log('search', searchValue);

    if (searchValue == '') {
      console.log('field Empty');
      notify('Search field can not be empty', true);
    } else if (searchValue.length < 3) {
      console.log('below 3');
      notify('Search atleast 4 words', true);
    } else {
      document.querySelector('#loading-indicator').style.display = 'flex';

      index
        .search(searchValue)
        .then((result) => {
          console.log('result', result.hits);

          if (result.hits.length === 0) {
            document.querySelector('#loading-indicator').style.display = 'none';

            notify('Search exact question title ', true);
          } else {
            document.querySelector('#loading-indicator').style.display = 'none';

            setQuestionsData(result.hits);
            setreload(true);
          }
        })
        .catch((err) => {
          document.querySelector('#loading-indicator').style.display = 'none';
          notify(err.toString(), true);
          console.log(err.toString());
        });

      // db.collection('Questions').orderBy('questionTitle').startAt(searchValue).endAt(searchValue+'\uf8ff').get()
      // .then(querySnapshot => {
      //     let data = querySnapshot.docs.map(doc => {

      //         return { ...doc.data(), id: doc.id }
      //     })
      // if(data.hits.length===0){
      //     document.querySelector("#loading-indicator").style.display = "none";

      //     notify('Search exact Question Title ',true)

      // }else{
      //     document.querySelector("#loading-indicator").style.display = "none";

      //     setQuestionsData(data.hits)
      //     setreload(true)
      // }
      //     // data=data.filter((item)=>item.type==='quiz')
      //     // setQuizesData(data)
      //     // setEndIndex(data.length)
      //     console.log('Search data is ', data)
      // }).catch(err=>{

      //     document.querySelector("#loading-indicator").style.display = "none";
      //     notify(err.toString(), true);
      //     console.log(err.toString())
      //   })
    }
  };

  const emptyCall = () => {
    if (reload) {
      if (searchValue.length >= 1 && searchValue.length < 4) {
        getQuestions();
        setreload(false);
      }
    }
  };

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div
      style={{
        backgroundColor: '#1f1e2e',
        height: '100%',
        overflowY: 'scroll',
        fontSize: font?.fontSize || 'inherit',
        fontWeight: font?.fontWeight || 'inherit',
        backgroundColor: colorTheme?.bodyColor || '#1e1e29',
      }}
    >
      {progressVisible && (
        <Progress color='info' value={progressValue} style={{ height: 4 }} />
      )}
      <div className='react-notification-alert-container'>
        <NotificationAlert ref={notification} />
      </div>

      <div style={{ padding: '5px 20px' }}>
        {/* <Row style={{ justifyContent: 'flex-end' }}>
          <abbr title="Close">
            <IconButton
              edge='start'
              style={{ color: 'white' }}
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </abbr>
        </Row> */}

        <Row>
          <Col
            md='12'
            style={
              {
                // marginBottom: '100px'
              }
            }
          >
            <Card
              style={{
                margin: 0,
                fontSize: font?.fontSize || 'inherit',
                fontWeight: font?.fontWeight || 'inherit',
                backgroundColor: colorTheme?.bodyColor || 'all',
              }}
            >
              <CardHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <h2
                    className='title'
                    style={{
                      fontSize: font?.fontSize + 2 || 'inherit',
                      fontWeight: font?.fontWeight || 'inherit',
                      textTransform: 'capitalize',
                    }}
                  >
                    Quiz Management
                  </h2>
                  <Button  title="Back" onClick={handleClose} color='info'>
                    Back
                  </Button>{' '}
                </div>
              </CardHeader>
              <CardBody>
                <Row style={{ alignItems: 'flex-end' }}>
                  <Col md='4'></Col>
                </Row>

                <br></br>
                <Table className='tablesorter'>
                  <thead className='text-primary'>
                    <tr>
                      <th></th>
                      <th>Question Title</th>
                      <th>Subject</th>
                      <th style={{ textAlign: 'center', minWidth: '130px' }}>
                        Difficulty Level
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {questionsData?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <th
                            style={{
                              display: 'flex',
                              paddingLeft: '20px',
                              color: 'white',
                            }}
                          >
                            <Input
                              type='checkbox'
                              checked={selectedQues[item?.id]}
                              onChange={(e) => {
                                setSelectedQues((prev) => ({
                                  ...prev,
                                  [item?.id]: !prev[item?.id],
                                }));
                              }}
                            />
                          </th>
                          <th
                            style={{
                              color: 'white',
                            }}
                          >
                            {item?.question}
                          </th>
                          <th
                            style={{
                              color: 'white',
                            }}
                          >
                            {item?.subject}
                          </th>
                          <th style={{ textAlign: 'center', color: 'white' }}>
                            {item?.difficulty == 1
                              ? 'Easy'
                              : item?.difficulty == 2
                              ? 'Medium'
                              : 'Difficult'}
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {selectedQuesCount.length !== 0 && (
        <Row
          style={{
            // position: 'fixed',
            bottom: '0',
            // marginLeft: '0px',
            // marginRight: '0px',
            minHeight: '120px',
            display: 'flex',
            maxWidth: '100%',
            justifyContent: 'flex-end',
            left: '-10',
            backgroundColor: colorTheme?.bodyColor || '#272a3d',
            margin: '0 20px',
          }}
        >
          <Form
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onSubmit={handleSubmit((data) => {
              submitQuiz(data);
            })}
          >
            <Row
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Col sm={8}>
                <Row style={{ alignItems: 'center' }}>
                  <Col sm={1} style={{ minWidth: '115px' }}>
                    <label style={{ color: 'white', maxWidth: '125px' }}>
                      {selectedQuesCount.length} Selected
                    </label>
                  </Col>
                  <Col sm={2} style={{ textAlign: 'end', maxWidth: '100px' }}>
                    <label style={{ color: 'white' }} htmlFor='quizTitle'>
                      Quiz Title
                    </label>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Input
                        id='quizTitle'
                        defaultValue={currentQuiz && currentQuiz.title}
                        innerRef={register({ required: true })}
                        name='quizTitle'
                        placeholder='Enter Quiz Title'
                        type='text'
                        // required
                      />
                      {errors.quizTitle &&
                        errors.quizTitle.type === 'required' &&
                        notify("Quiz title field can't be empty", true)}
                    </FormGroup>
                  </Col>
                  <Col sm={2} style={{ textAlign: 'end' }}>
                    <label
                      style={{ color: 'white' }}
                      htmlFor='Difficulty-Level'
                    >
                      Difficulty Level
                    </label>
                  </Col>{' '}
                  <Col sm={2}>
                    <FormGroup>
                      {/* <ReactMultiSelectCheckboxes
                        id="Difficulty-Level"
                        // className={styles.signUp_multiSelect}
                        styles={customStyles}
                        onChange={(option) => {
                          setselectedDifficulty(option);
                          console.log(`Option selected:`, option);
                        }}
                        options={difficultyOptions}
                      /> */}
                      <Input
                        id='Difficulty-Level'
                        // innerRef={register({ required: true })}
                        type='select'
                        name='difficulty'
                        required={currentQuiz ? false : true}
                        styles={customStyles}
                        onChange={(e) => setselectedDifficulty(e.target.value)}
                        defaultValue={
                          currentQuiz && currentQuiz.difficultyLevel
                        }
                      >
                        <option value=''>Select</option>
                        <option value={1}>Easy</option>
                        <option value={2}>Medium</option>
                        <option value={3}>Difficult</option>
                      </Input>

                      {/* <ReactMultiSelectCheckboxes
                        // className={styles.signUp_multiSelect}
                        // styles={customStyles}
                        onChange={(option) => {
                          setselectedDifficulty(option);
                          console.log(`Option selected:`, option);
                        }}
                        options={difficultyOptions}
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col>
                    {quizImage && (
                      <img
                        src={quizImage}
                        alt=''
                        style={{
                          width: 40,
                          height: 55,
                          marginTop: 5,
                          marginLeft: 20,
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={2} style={{ marginLeft: '20px' }}>
                <Button title='Submit' color='info' type='submit'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getQuestion: state.getQuestion,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getQuestions: () => {
    dispatch(getQuestionAction());
  },
  createQuize: (payload) => {
    dispatch(createQuizAction(payload));
  },
  updateQuize: (payload) => {
    dispatch(editQuizAction(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizDialog);
