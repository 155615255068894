import React from 'react';

import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalComp = ({ children, controller, bodyColor,  title }) => {
  return (
    <Modal isOpen={controller} style={{ display: 'flex', flex: 1 }} size='lg'>
      {/* <ModalHeader>{title}</ModalHeader> */}
      <ModalBody
        style={{
          background: bodyColor || '#27293D',
          width: '500px',
          height: 'fit-content',
          minWidth: 'fit-content',
          display: 'inline-block',
          padding: '0px !important',
          position: 'absolute',
          border: '1px solid white',
          borderRadius: '4px',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        size='sm'
      >
        {children}
      </ModalBody>
    </Modal>
  );
};

export default ModalComp;
