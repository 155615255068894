import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { positionCenter, btnStyle, paraStyle } from 'Utilities/common/commonStyle';
import { connect } from 'react-redux';
import { colorAction } from 'store/action/colorAction/colorAction';
import { useEffect } from 'react';
import { clearResponse } from 'store/action/clearResponseAction';

const ColorController = ({ handleBack, ...props }) => {

    const [bodyColor, setBodyColor] = useState();
    const [sidenavColor, setSidenavColor] = useState();
    const [navbarColor, setNavbarColor] = useState();
    const [borderColor, setBorderColor] = useState();

    const bodyColorChange = (e) => {
        setBodyColor(e.target.value)
    }
    const sidenavColorChange = (e) => {
        setSidenavColor(e.target.value)
    }
    const navbarColorChange = (e) => {
        setNavbarColor(e.target.value)
    }
    const borderColorChange = (e) => {
        setBorderColor(e.target.value)
    }

    const handleSave = () => {
        localStorage.setItem('clr', JSON.stringify({ bodyColor, sidenavColor, navbarColor, borderColor }));
        props.getColorData();
        handleBack();
    };

    const handleReset = () => {
        let bodyColor = '#27293d'
        let sidenavColor = '#1e89f7';
        let navbarColor = '#1e1e2e';
        let borderColor = 'lightgray';

        localStorage.setItem('clr', JSON.stringify({ bodyColor, sidenavColor, navbarColor, borderColor }));
        props.getColorData();
        // props.clearResponse('COLOR_RESET')
        handleBack();
    };

    useEffect(() => {
        const colorValue = JSON.parse(localStorage.getItem('clr')) || '';

        if (colorValue) {
            setBodyColor(colorValue.bodyColor);
            setSidenavColor(colorValue.sidenavColor);
            setNavbarColor(colorValue.navbarColor);
            setBorderColor(colorValue.borderColor);
        }
    }, []);

    return (
        <div style={{ ...positionCenter, justifyContent: 'space-between', width: "40vw", }}>
            <div style={{ height: "200px", width: "60%", marginTop: "-10%" }}>
                <h5 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "5px", margin: "0", padding: "0", paddingBottom: "10px" }}>
                    preview
                </h5>
                <div style={{ border: `1px solid ${borderColor ? borderColor : "white"}`, height: "100%", width: "100%", borderRadius: "4px" }}>
                    <div style={{ ...positionCenter, borderBottom: "1px solid white", height: "20%", width: "100%", backgroundColor: `${navbarColor}` }}>
                        Nav Bar
                    </div>
                    <div style={{ display: "flex", width: "100%", height: "100%" }}>
                        <div style={{ ...positionCenter, borderRight: "1px solid white", height: "80%", width: "20%", backgroundColor: `${sidenavColor}` }}>
                            Side Nav
                        </div>
                        <div style={{ ...positionCenter, height: "80%", width: "80%", backgroundColor: `${bodyColor}` }}>
                            Main Container
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: "100%", width: "40%" }}>
                <h5 style={{ textAlign: "center", textDecoration: "underline", textUnderlineOffset: "5px", margin: "0", padding: "0", paddingBottom: "10px" }}>
                    Customize color
                </h5>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto", justifyContent: "center" }}>
                    <div style={{ ...positionCenter, flexDirection: "column", marginBottom: "0.5em", paddingBottom: "0.5em", borderBottom: "1px solid white" }}>
                        <p style={{ color: "#525f7f", ...paraStyle, fontSize: "12px" }}>pick a color</p>
                        <label htmlFor='main' style={{ fontSize: "12px", color: 'white' }}>Main Container</label>
                        <input id='main' type="color" onChange={bodyColorChange} />
                    </div>
                    <div style={{ ...positionCenter, flexDirection: "column", marginBottom: "0.5em", paddingBottom: "0.5em", borderBottom: "1px solid white" }}>
                        <p style={{ color: "#525f7f", ...paraStyle, fontSize: "12px" }}>pick a color</p>
                        <label htmlFor='sidenav' style={{ fontSize: "12px", color: 'white' }}>Side Nav</label>
                        <input id='sidenav' type="color" onChange={sidenavColorChange} />
                    </div>
                    <div style={{ ...positionCenter, flexDirection: "column", marginBottom: "0.5em" }}>
                        <p style={{ color: "#525f7f", ...paraStyle, fontSize: "12px" }}>pick a color</p>
                        <label htmlFor='navbar' style={{ fontSize: "12px", color: 'white' }}>Nav Bar</label>
                        <input id='navbar' type="color" onChange={navbarColorChange} />
                    </div>
                    <div style={{ ...positionCenter, flexDirection: "column", marginBottom: "0.5em" }}>
                        <p style={{ color: "#525f7f", ...paraStyle, fontSize: "12px" }}>pick a color</p>
                        <label htmlFor='border' style={{ fontSize: "12px", color: 'white' }}> Border</label>
                        <input id='border' type="color" onChange={borderColorChange} />
                    </div>
                </div>
                <div style={{ ...positionCenter, marginTop: '44px' }}>
                    <button
                        style={{ ...btnStyle, margin: "0 5px" }}
                        onClick={handleSave}
                        color='info'
                        title="Save" aria-label="Save"
                    >
                        Save
                    </button>
                    <button
                        style={{ ...btnStyle, margin: "0 5px", backgroundColor: 'red' }}
                        onClick={handleReset}
                        color='info'
                        title="Reset" aria-label="Reset"
                    >
                        Reset
                    </button>
                    <button
                        style={{ ...btnStyle, backgroundColor: '#2A3856', margin: "0 5px" }}
                        onClick={handleBack}
                        color='light'
                        title="Back" aria-label="Back"
                    >
                        Back
                    </button>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state, ownProps) => ({
    colorData: state.colorValues,
});
const mapDispatchToProps = (dispatch) => ({
    getColorData: (colorData) => {
        dispatch(colorAction(colorData));
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ColorController);
