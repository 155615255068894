import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import  algoliasearch  from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";
import format from "date-fns/format";

const useStyles = makeStyles({
  listbox: {
    background: "#27293d"
  },
  input: {
    borderColor:"#2b3553",
    fontSize: "0.85rem",
    color: "whitesmoke",
    borderRadius: "0.4285rem",
    padding: "10px 18px 10px 18px"
  },
  inputRoot: {
    padding: "0px 0px 5px 0px !important"
  }
})

function SpecialDiscountModal({ notify, setModal,id, getStudent, setSpecialData, currentSpecialData, studentData, edit, studentSearch }) {


  const [error, setError] = useState("");
  const { register, handleSubmit, errors, control, getValues } = useForm();
  const [stuID, setStuID] = useState("");
  const [stuName, setStuName] = useState("");
  const [fromDate, setFromDate] = useState("");

  const classes = useStyles();

  const onSubmit = (data) => {
    let res;

    if(currentSpecialData){
      res = db.collection('DiscountCoupons').doc(currentSpecialData.itemID)
    }else{
      res = db.collection('DiscountCoupons').doc()
    }

    let finalData = {}
    
    if(currentSpecialData){
      finalData = {
        itemID: res.id,
        couponCode: data.couponCode,
        discountType: data.DiscountType,
        discountAmount: parseFloat(data.discountAmount) ,
        discountIn: data.discountIn,
        validFrom: data.validFrom,
        validTill: data.validTill,
        // remarks: data.remarks,
        couponFor: {
          stuID: stuID,
          stuName: stuName
        },
        used: false,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'Special'
      }
    }else{
      finalData = {
        itemID: res.id,
        couponCode: data.couponCode,
        discountType: data.DiscountType,
        discountAmount: parseFloat(data.discountAmount),
        discountIn: data.discountIn,
        validFrom: data.validFrom,
        validTill: data.validTill,
        // remarks: data.remarks,
        couponFor: {
          stuID: stuID,
          stuName: stuName
        },
        used: false,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'Special'
      }
    }

    res.set(finalData).then((res) => {
      if(currentSpecialData){
        notify("Coupon Updated Successfuly", false);
      }else{
        notify("Coupon Created Successfuly", false);
      }

      if(currentSpecialData){
        setSpecialData(prev => prev.map((item) => item.itemID == currentSpecialData.itemID ? ({ ...finalData }) : item))
      }else{
        setSpecialData(prev => [finalData, ...prev]);
      }
      setModal()
    }).catch(err => {
      notify(err.toString(), true)
    })

  };


  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row>
          <Col md="8">
            <Card style={{ border: "2px solid white" }}>
              <CardHeader>
                <h5 className="title">Add Special Code</h5>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(onSubmit)}
                  autoComplete="off"
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Coupon Code</label>
                        <Input
                          innerRef={register({ required: true, maxLength: 10, minLength: 10, pattern: {
                            value: /^(?=.*[A-Za-z])[A-Za-z\d@$!%*#?&]{8,}$/
                          }})}
                          defaultValue={currentSpecialData ? `${currentSpecialData.couponCode}` : `SPE${id.toUpperCase()}`}
                          name="couponCode"
                          placeholder="Coupon Code"
                          type="text"
                          style={{marginBottom:0}}
                        />
                        {errors.couponCode && errors.couponCode.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                          {errors.couponCode && errors.couponCode.type === "minLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Minimum length for couponcode is 10
                          </span>
                        )}
                         {errors.couponCode && errors.couponCode.type === "maxLength" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Maximum length for couponcode is 10
                          </span>
                        )}
                        {errors.couponCode && errors.couponCode.type === "pattern" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            Please enter valid code
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Type</label>
                       
                        <Input
                          defaultValue = {currentSpecialData && currentSpecialData.discountType}
                          innerRef={register({ required: true })}
                          type="select"
                          name="DiscountType"
                          style={{marginBottom:0}}
                        >
                          <option value="">Select type of discount</option>
                          <option value="disability">Disability</option>
                          <option value="sibling">Sibling</option>
                          <option value="oldStudent">Old Student</option>
                          <option value="Other">Other</option>
                        </Input>
                        {errors.DiscountType && errors.DiscountType.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid From</label>
                        <Input
                          defaultValue={currentSpecialData && currentSpecialData.validFrom}
                          innerRef={register({ required: true })}
                          name="validFrom"
                          placeholder="Valid From"
                          type="datetime-local"
                          style={{marginBottom:0}}
                          onChange={(e) => setFromDate(e.target.value)}
                          min={edit ? format(new Date(),"yyyy-MM-dd'T'00:00")  : ""}
                        />
                        {errors.validFrom && errors.validFrom.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      
                    <FormGroup>
                        <label>Students</label>
                        <Autocomplete 
                          options={studentData}
                          getOptionLabel={(option) => 
                            `${option.firstname} ${option.lastname} ${option.enrollmentNO ? `-> ${option.enrollmentNO}`  : ""}` 
                          }
                          style={{width: 340}}
                          classes={{
                            listbox: classes.listbox,
                            input: classes.input,
                            inputRoot: classes.inputRoot
                          }}
                          // limitTags="10"
                          
                          getOptionSelected={(option, value) => {
                            setStuID(value._uniqueID)
                            setStuName(`${value.firstname} ${value.lastname}`)
                          }}
                          renderInput={(params) => {
                          return(
                            <TextField {...params} placeholder="Student Name"  name="studentDetails"  variant="outlined"
                              onChange={(e) => { 
                                if(e.target.value.length > 2) {
                                  studentSearch(e.target.value)
                                  console.log(e.target.value)
                                } else if(e.target.value.length === 0){
                                  getStudent()
                                }
                            }}
                            />
                          )
                        }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Discount Of</label>
                        <Row>
                          <Col md="4">
                            <Input
                              defaultValue ={currentSpecialData && currentSpecialData.discountIn}
                              innerRef={register({ required: true })}
                              type="select"
                              name="discountIn"
                              
                              style={{paddingLeft : 9, marginBottom:0}}
                            >
                              <option value={"Rupees"}>Rupees</option>
                              <option value={"Percentage"}>Percent</option>
                            </Input>
                        {errors.discountIn && errors.discountIn.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                          </Col>
                          <Col md="8">
                            <Controller render={(field) => {
                              return <Input
                                defaultValue={currentSpecialData && currentSpecialData.discountAmount}
                                {...field}
                                innerRef={register({ required: true })}
                                name="discountAmount"
                                placeholder="Discount OF"
                                type="number"
                                onChange={(e) => {
                                  if(getValues().discountIn == 'Percentage' && parseFloat(e.target.value || 0) <= 100){
                                    field.onChange(e.target.value);
                                  }
                                }}
                                style={{marginBottom:0}}
                              />
                            }}
                            name="discountAmount"
                            control={control}
                             />
                        {errors.discountAmount && errors.discountAmount.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {/* <Col>
                      <FormGroup>
                        <label>Remarks</label>
                        <Input
                          defaultValue={currentSpecialData && currentSpecialData.remarks}
                          innerRef={register({ required: false })}
                          name="remarks"
                          placeholder="Remarks"
                          type="textarea"
                          style={{marginBottom:0}}
                        />
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row style={{display:'none'}}>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label>Valid Till</label>
                        <Input
                          defaultValue = {format(new Date('1 1 2080'),"yyyy-MM-dd'T'HH:mm")}
                          innerRef={register({ required: true,  })}
                          name="validTill"
                          placeholder="Valid Till"
                          type="datetime-local"
                          style={{marginBottom:0}}
                          
                          // min={!fromDate == "" ? format(new Date(fromDate),"yyyy-MM-dd'T'00:00") :format(new Date(),"yyyy-MM-dd'T'00:00") }
                        />
                        {errors.validTill && errors.validTill.type === "required" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            This field is required
                          </span>
                        )}
                        {errors.validTill && errors.validTill.type === "min" && (
                          <span style={{fontSize: 12, color: "red"}}>
                            End datetime should be greater than from datetime
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button color="info" type="submit">
                      Submit
                    </Button>
                    <Button onClick={() => setModal(false)}>Back</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SpecialDiscountModal;
