import React, { useEffect, useState, useRef } from "react";

import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Button from "@material-ui/core/Button";

import AtrievLogo from "../assets/img/atriev.svg"
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import "./forgotPassword.css";
import Loader from "components/Loader/Loader";
const ForgotPassword = ({ isForgotPassword, setIsForgotPassword, notify,setIsLoading }) => {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [newData, setNewData] = useState("");
  const [userType, setUserType] = useState("admin");

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setNewData({ ...newData, [name]: value });
    // console.log(newData);
  };

  const emailApiHandler = async (e) => {
    e.preventDefault();
    if (!newData.email) {
      notify("Please enter email", true);
    } else {
      setIsLoading(true)
      try {
        const data = await axios.post(
          "https://atriev-lms.dt.r.appspot.com/api/forget/password",
          { ...newData, userType: userType, forgetRoute: `newPassword` }
        );
        if (data) {
          setIsLoading(false)
          notify("Please check email", false);
        }
      } catch (err) {
        setIsLoading(false)
        notify(err?.response?.data?.message, true);
      }
    }
  };
  // useEffect(()=>{
  //   // newData.userType=userType
  //   setNewData({...newData,userType:userType})
  // },[userType])
  return (
    <>
      <div className="forgot-password-container">
        <form>
          <div className="emailComp-container">

            <div style={{
              width:"70px",
              margin:"0 auto"
            }}>
              <img src={AtrievLogo} alt="Atriev Logo"/>
            </div>

            <h1 className="forgot-password-heading">Forgot Password</h1>
            {/* <p className="forgot-password-para">
              Provide your account's email for which <br />
              you want to reset your password!
            </p> */}
            <main className="forgot-password-input-container">
              <FormGroup>
                <label htmlFor="Email">
                  Email
                </label>
                <Input
                  id="Email"
                  innerRef={register({ required: true })}
                  name="email"
                  onChange={handleChange}
                  placeholder="Please enter email"
                  type="email"
                  style={{color:"black"}}
                  // required
                />
              </FormGroup>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin:"10px 0px"
                }}
              >
                <p style={{ margin: "0 8px",color:"#808080" }}>User Type</p>
                <div
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="Admin"
                    onChange={(e) => {
                      setUserType(e.target.value);
                      history.push("/admin/login");
                    }}
                    style={{ scale: "1.2" }}
                    name="login-option"
                    type="radio"
                    value="admin"
                    defaultChecked={window.location.pathname === "/admin/login"}
                  />

                  <label
                    style={{ margin: "0px"}}
                    htmlFor="Admin"
                  >
                    Admin
                  </label>
                </div>
                <p style={{  margin: "0 8px",color:"#808080" }}>or</p>

                <div
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    id="Trainer"
                    onChange={(e) => {
                      setUserType(e.target.value);

                      history.push("/trainer/login");
                    }}
                    style={{ scale: "1.2" }}
                    name="login-option"
                    type="radio"
                    value="teacher"
                    defaultChecked={
                      window.location.pathname === "/trainer/login"
                    }
                  />

                  <label
                    style={{ margin: "0px" }}
                    htmlFor="Trainer"
                  >
                    Trainer
                  </label>
                </div>
              </div>
              <Button
                type="submit"
                title="Submit"
                aria-label="Submit"
                className="email-sub-btn"
                color="primary"
                variant="contained"
                style={{
                  marginTop:"5px"
                }}
                onClick={emailApiHandler}
              >
                Submit
              </Button>
              <Button
                type="button"
                title="Back to Sign In"
                aria-label="Back to Sign In"
                className="sign-in-btn"
                color="primary"
                variant="contained"
                style={{
                  marginTop:"10px"
                }}

                onClick={() => setIsForgotPassword(false)}
              >
                Back to Sign In
              </Button>
            </main>
          </div>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
