import * as actionTypes from '../actionType';

import {
  deleteRequest,
} from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const adminBulkDeleteAction = (url) => {
    return (dispatch) => {
      dispatch({
        type: actionTypes.BULK_DELETE_ADMIN,
        payload: {},
        isLoading: true,
      });
      
      deleteRequest(url)
        .then((res) => {
          if (res.status == 200) {
            dispatch({
              type: actionTypes.BULK_DELETE_ADMIN_SUCCESS,
              payload: { data: res?.data?.data },
              isLoading: false,
            });
          } else {
            throw res;
          }
        })
        .catch((err) => {
       
          dispatch({
            type: actionTypes.BULK_DELETE_ADMIN_FAIL,
            payload: { error: err.response.data },
            isLoading: false,
          });
        });
    };
  };