import * as actionTypes from '../actionType';

import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const getAdminsAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ADMIN,
      payload: {},
      isLoading: true,
    });

    let url='api/admin'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {

        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_ADMIN_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
 
        dispatch({
          type: actionTypes.GET_ADMIN_FAIL,
          payload: { error: err?.response?.data},
          isLoading: false,
        });
      });
  };
};

export const createAdminAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_ADMIN,
      payload: {},
      isLoading: true,
    });

    postRequest('api/admin', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_ADMIN_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.CREATE_ADMIN_FAIL,
          payload: { error:  err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editAdminAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_ADMIN,
      payload: {},
      isLoading: true,
    });

    putRequest('api/admin', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_ADMIN_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.EDIT_ADMIN_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteAdminAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ADMIN,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_ADMIN_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.DELETE_ADMIN_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
