import { combineReducers } from "redux";
import { loginReducer } from "./authReducer/loginReducer";
import {
  createAdminReducer,
  getAdminReducer,
  editAdminReducer,
  deleteAdminReducer,
} from "./adminReducer/adminReducer";

import { getActivityReducer } from "./activityReducer/activityReducer";

import { adminBulkDeleteReducer } from './bulkDeleteReducer/adminBulkDeleteReducer';

import {
  createTrainerReducer,
  getTrainerReducer,
  editTrainerReducer,
  deleteTrainerReducer,
} from "./trainerReducer/trainerReducer";

import {
  getTrainersBatchReducer,
  getTrainersModuleReducer,
  getTrainersTraineeReducer,
  updateModprogressReducer,
  updateTraineeprogressReducer,
  updateTraineeGradeReducer,
  banTraineeReducer,
  uploadGradesReducer,
  deleteGrades,
  uploadAudioReducer,
  uploadedMaterialReducer,
  deleteUploadedMaterial,
} from "./trainerReducer/trainerProfileReducer";

import {
  getSubmittedAssignmentReducer,
  downloadSubmittedAssignmentReducer,
  addEditQuizReducer,
  addEditAssignmentReducer,
} from "./trainerReducer/trainerTaskReducer";

import {
  createTraineeReducer,
  getTraineeReducer,
  editTraineeReducer,
  deleteTraineeReducer,
  traineeBatchRelationReducer,
} from "./traineeReducer/traineeReducer";

import {
  createBatchReducer,
  getBatchReducer,
  editBatchReducer,
  deleteBatchReducer,
  batchRelationReducer,
  batchQuizRelationReducer,
  batchAssignmentRelationReducer,
  editRelationReducer,
} from "./batchReducer/batchReducer";

import {
  createTeachingModuleReducer,
  getTeachingModuleReducer,
  editTeachingModuleReducer,
  deleteTeachingModuleReducer,
} from "./teachingModuleReducer/teachingModuleReducer";

import {
  createSecurityGroupReducer,
  getSecurityGroupReducer,
  editSecurityGroupReducer,
  deleteSecurityGroupReducer,
  getActionsReducer,
  getFunctionsReducer,
} from "./SecurityGroupReducer/SecurityGroupReducer";

import { securityGroupBulkDeleteReducer } from "./bulkDeleteReducer/securityGroupBulkDeleteReducer";

import {
  createQuestionReducer,
  getQuestionReducer,
  editQuestionReducer,
  deleteQuestionReducer,
} from "./questionReducer/questionReducer";

import {
  createAssignmentReducer,
  getAssignmentReducer,
  editAssignmentReducer,
  deleteAssignmentReducer,
} from "./assignmentReducer/assignmentReducer";

import {
  createQuizReducer,
  getQuizReducer,
  editQuizReducer,
  deleteQuizReducer,
} from "./quizReducer/quizReducer";

import { quizBulkDeleteReducer } from "./bulkDeleteReducer/quizBulkDeleteReducer";

import {
  getRequestReducer,
  handleRequestReducer,
} from "./requestReducer/requestReducer";

import {
  getNotificationReducer,
  editNotificationReducer,
} from "./notificationReducer/notificationReducer";

import { getCertificateRequestReducer } from './adminCertificateReducer/adminCertificateReducer';
import { handleCertificateRequestReducer } from './adminCertificateReducer/adminCertificateReducer';
import { fontReducer } from './fontReducer/fontReducer';
import { colorReducer } from './colorReducer/colorReducer';
import { getTraineeReportReducer } from './trainerReducer/traineeReportReducer';
import { trainerBulkDeleteReducer } from './bulkDeleteReducer/trainerBulkDeleteReducer';
import { trainingBulkDeleteReducer } from './bulkDeleteReducer/trainingBulkDeleteReducer';
import { traineeBulkDeleteReducer } from './bulkDeleteReducer/traineeBulkDeleteReducer';
import { assignmentBulkDeleteReducer } from './bulkDeleteReducer/assignmentBulkDeleteReducer';
import { moduleBulkDeleteReducer } from './bulkDeleteReducer/moduleBulkDeleteReducer';
import { questionBulkDeleteReducer } from './bulkDeleteReducer/questionBulkDeleteReducer';
import {
  getFilesReducer,
  deleteFilesReducer,
} from './fileManagerReducer/fileManagerReducer';

const rootReducer = combineReducers({
  login: loginReducer,

  getActivity: getActivityReducer,

  createAdmin: createAdminReducer,
  getAdmin: getAdminReducer,
  editAdmin: editAdminReducer,
  deleteAdmin: deleteAdminReducer,
  adminBulkDelete: adminBulkDeleteReducer,

  createTrainer: createTrainerReducer,
  getTrainer: getTrainerReducer,
  editTrainer: editTrainerReducer,
  deleteTrainer: deleteTrainerReducer,
  trainerBulkDelete: trainerBulkDeleteReducer,

  getTrainersBatch: getTrainersBatchReducer,
  getTrainersModule: getTrainersModuleReducer,
  getTrainersTrainee: getTrainersTraineeReducer,
  updateModprogress: updateModprogressReducer,
  updateTraineeprogress: updateTraineeprogressReducer,
  updateTraineeGrade: updateTraineeGradeReducer,
  banTrainee: banTraineeReducer,
  uploadGrades: uploadGradesReducer,
  uploadAudio: uploadAudioReducer,
  uploadedMaterial: uploadedMaterialReducer,
  deleteUploadedMaterial: deleteUploadedMaterial,

  getSubmittedAssignment: getSubmittedAssignmentReducer,
  downloadSubmittedAssignment: downloadSubmittedAssignmentReducer,
  addEditQuiz: addEditQuizReducer,
  addEditAssignment: addEditAssignmentReducer,
  deleteGrades: deleteGrades,

  createTrainee: createTraineeReducer,
  getTrainee: getTraineeReducer,
  editTrainee: editTraineeReducer,
  deleteTrainee: deleteTraineeReducer,
  traineeBatchRelation: traineeBatchRelationReducer,
  traineeBulkDelete: traineeBulkDeleteReducer,

  createBatch: createBatchReducer,
  getBatch: getBatchReducer,
  editBatch: editBatchReducer,
  deleteBatch: deleteBatchReducer,
  batchRelation: batchRelationReducer,
  batchQuizRelation: batchQuizRelationReducer,
  batchAssignmentRelation: batchAssignmentRelationReducer,
  editRelation: editRelationReducer,
  trainingBulkDelete: trainingBulkDeleteReducer,

  createModule: createTeachingModuleReducer,
  getModule: getTeachingModuleReducer,
  editModule: editTeachingModuleReducer,
  deleteModule: deleteTeachingModuleReducer,
  moduleBulkDelete: moduleBulkDeleteReducer,

  createSecurityGroup: createSecurityGroupReducer,
  getSecurityGroup: getSecurityGroupReducer,
  editSecurityGroup: editSecurityGroupReducer,
  deleteSecurityGroup: deleteSecurityGroupReducer,
  securityGroupBulkDelete: securityGroupBulkDeleteReducer,
  getActions: getActionsReducer,
  getFunctions: getFunctionsReducer,

  createQuestion: createQuestionReducer,
  getQuestion: getQuestionReducer,
  editQuestion: editQuestionReducer,
  deleteQuestion: deleteQuestionReducer,
  questionBulkDelete: questionBulkDeleteReducer,

  createAssignment: createAssignmentReducer,
  getAssignment: getAssignmentReducer,
  editAssignment: editAssignmentReducer,
  deleteAssignment: deleteAssignmentReducer,
  assignmentBulkDelete: assignmentBulkDeleteReducer,

  createQuiz: createQuizReducer,
  getQuiz: getQuizReducer,
  editQuiz: editQuizReducer,
  deleteQuiz: deleteQuizReducer,
  quizBulkDelete: quizBulkDeleteReducer,

  getRequest: getRequestReducer,
  handleRequest: handleRequestReducer,

  getCertificateRequest: getCertificateRequestReducer,
  handleCertificateRequest: handleCertificateRequestReducer,
  fontValues: fontReducer,
  colorValues: colorReducer,

  getTraineeReport: getTraineeReportReducer,

  getNotification: getNotificationReducer,
  editNotification: editNotificationReducer,
  getFiles: getFilesReducer,
  deleteFile: deleteFilesReducer,
});

export default rootReducer;
