import React from 'react';

import { FormGroup, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';

const TitleIdDropdown = ({
  arr,
  label,
  heading,
  inputName,
  name,
  forName,
  selectedValue,
  errors,
  ...rest
}) => {
  return (
    <>
      <FormGroup style={{ width: '100%' }}>
        <label htmlFor={forName}>{label}</label>
        <Input id={forName} name={inputName} type='select' {...rest}>
          <option
            style={{
              color: 'black',
              fontWeight: '600',
              textTransform: 'uppercase',
            }}
            value={''}
          >
            {heading}
          </option>
          {arr &&
            arr.map((item) => {
              return (
                <option
                  key={item.id}
                  value={item.id}
                  selected={
                    (selectedValue && selectedValue == item.title) ||
                    selectedValue == `${item.firstName} ${item.lastName}`
                      ? true
                      : false
                  }
                >
                  {name ? `${item.firstName} ${item.lastName}` : item.title}
                </option>
              );
            })}
        </Input>
        {errors[inputName] && errors[inputName].type === 'required' && (
          <span
            style={{
              fontSize: 12,
              color: 'red',
              top: -20,
              position: 'relative',
            }}
          >
            {`${label} required`}
          </span>
        )}
      </FormGroup>
    </>
  );
};

export default TitleIdDropdown;
