export const colorAction = () => {
    return (dispatch) => {
      const colorValue = JSON.parse(localStorage.getItem('clr')) || '';
  
      if (colorValue)
        dispatch({
          type: 'GET_COLOR',
          payload: { data: colorValue },
        });
    };
  };
  