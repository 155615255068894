import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';
import { setQuarter } from 'date-fns/esm';

export const getSubmittedAssignmentAction = (batchId, moduleId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SUBMITTED_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });

    let url = `api/teacher/assignment/?batchId=${batchId}&moduleId=${moduleId}`;

    getRequest(url)
      .then((res) => {
      
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_SUBMITTED_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.GET_SUBMITTED_ASSIGNMENT_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
export const downloadSubmittedAssignmentAction = (fileId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });

    let url = `api/assignment/download?fileId=${fileId}`;

    getRequest(url)
      .then((res) => {
    
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.DOWNLOAD_SUBMITTED_ASSIGNMENT_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const addEditQuizAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_EDIT_QUIZ,
      payload: {},
      isLoading: true,
    });

    postRequest('api/batch/relation/quize', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.ADD_EDIT_QUIZ_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_EDIT_QUIZ_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const addEditAssignmentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_EDIT_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });

    postRequest('api/batch/relation/assignment', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.ADD_EDIT_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.ADD_EDIT_ASSIGNMENT_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
