import React, { useState,useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  handleClose,
} from "reactstrap";

const HandleReqComp = ({ handleClose, handleDecline, handleApproove,...props }) => {
  const [font, setFont] = useState({});

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  return (
    <Row>
      <Col md="12">
        <Card style={{ margin: 0, background: 'inherit' }}>
          <CardHeader>
            <h2
              className="title"
              style={{
                fontSize: font?.fontSize + 2 || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                textTransform:"capitalize"
              }}
            >
              HANDLE CERTIFICATE REQUEST
            </h2>
          </CardHeader>
          <CardBody>
            <Button
              title="Approve"
              aria-label="Approve"
              onClick={handleApproove}
              color="info"
              type="button"
            >
              Approve
            </Button>
            <Button
              type="button"
              color="danger"
              title="Decline"
              aria-label="Decline"
              onClick={handleDecline}
            >
              Decline
            </Button>
            <Button
              type="button"
              title="Back"
              aria-label="back"
              onClick={handleClose}
            >
              Back
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default HandleReqComp;
