import React, { useState, useEffect, useRef } from 'react';
import ChatMsgRendererAdmin from './ChatMsgRendererAdmin.js';
import chatIcon from '../../assets/img/chatbot.png';
import ChatBot from './ChatBot.js';
import './chat.css';

import AnnouncementIcon from '@material-ui/icons/NotificationImportant';
import { useReactMediaRecorder } from 'react-media-recorder';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { db } from 'MY_LOGIN_AND_SIGNUP/db/config.js';
import { Button } from 'reactstrap';
const MergedChat = ({ chatData, isPanel }) => {
  const [isChatWidget, setIsChatWidget] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);

  const [numberOfNewMsg, setNumberOfNewMsg] = useState(0);
  console.log('chatData', chatData);

  useEffect(() => {
    // Subscribe to query with onSnapshot
    const query = db.collection('TeachersChat');
    const unsubscribe = query.onSnapshot((querySnapshot) => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const userChatData = data.filter((val) => {
        return val?.members?.includes(
          JSON.parse(localStorage.getItem('user'))?.personalData?.firebaseId
        );
      });
      if (userChatData.length > 0) {
        const isUnreadNewMessage = userChatData.filter((val) => {
          return (
            val?.unreadMessage !==
              JSON.parse(localStorage.getItem('user'))?.personalData
                ?.firebaseId && val?.unreadMessage !== '0'
          );
        });
        if (isUnreadNewMessage.length > 0) {
          setNumberOfNewMsg(isUnreadNewMessage?.length);
          setIsNewMessage(true);
        } else {
          setIsNewMessage(false);
        }
        console.log('querySnapshot.docsisUnreadNewMessage', isUnreadNewMessage);
      }
    });
    return unsubscribe;
  }, [isChatWidget]);
  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({ audio: true });
  return (
    <div
      className='chatBotWidget_Container'
      style={{
        maxWidth: isChatWidget ? 370 : 60,
      }}
    >
      {isChatWidget ? (
        <ChatBot
          setIsChatWidget={setIsChatWidget}
          chatUsers={isPanel ? chatData : chatData?.trainerList}
          isPanel={isPanel}
          status={status}
          startRecording={startRecording}
          stopRecording={stopRecording}
          mediaBlobUrl={mediaBlobUrl}
        />
      ) : (
        ''
      )}
      <div
        className='chatBotWidget'
        onClick={() => setIsChatWidget(!isChatWidget)}
      >
        {isChatWidget ? (
          <button
            className='chatBotWidget_icon'
            style={{ height: '60px', width: '65px', borderRadius: '50%' }}
          >
            Exit Chat
          </button>
        ) : (
          // <HighlightOffIcon className="chatBotWidget_icon" />
          <>
            {isNewMessage && (
              <div
                style={{
                  maxWidth: '30PX',
                  aspectRatio: '1 / 1',
                  width: '25px',
                  position: 'absolute',
                  top: 4,
                  right: -3,
                  color: 'red',
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  aria-label='new chat'
                  title='New chat'
                  style={{
                    position: 'absolute',
                    fontWeight: 500,
                    fontSize: '16px',
                    margin: '0',
                  }}
                >
                  {numberOfNewMsg}
                </p>
                {/* <AnnouncementIcon style={{ fontSize: '35px' }} /> */}
              </div>
            )}

            {/* <img className="chatBotWidget_icon" src={chatIcon} /> */}
            <button
              className='chatBotWidget_icon'
              style={{ height: '60px', width: '65px', borderRadius: '50%' }}
            >
              Chat
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default MergedChat;
