import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getTeachingModuleAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_MODULE,
      payload: {},
      isLoading: true,
    });
    let url='api/module'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_MODULE_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_MODULE_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const createTeachingModuleAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_MODULE,
      payload: {},
      isLoading: true,
    });

    postRequest('api/module', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_MODULE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
    
        dispatch({
          type: actionTypes.CREATE_MODULE_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editTeachingModuleAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_MODULE,
      payload: {},
      isLoading: true,
    });

    putRequest('api/module', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_MODULE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          })
          dispatch(getTeachingModuleAction());
        } else {
          throw res;
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.EDIT_MODULE_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteTeachingModuleAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_MODULE,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_MODULE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          })
          dispatch(getTeachingModuleAction());
          
        } else {
          throw res;
        }
      })
      .catch((err) => {
    
        dispatch({
          type: actionTypes.DELETE_MODULE_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const clearModule = (type) => {
  return (dispatch) => {
    dispatch({
      type: type,
      payload: {},
      isLoading: false,
    });
  };
};
