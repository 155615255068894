import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        assignmentList: action.payload,
      };
    case actionTypes.GET_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_ASSIGNMENT_RESET":
      return {};
    default:
      return state;
  }
};
export const createAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createAssignmentData: action.payload,
      };
    case actionTypes.CREATE_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "CREATE_ASSIGNMENT_RESET":
      return {};
    default:
      return state;
  }
};
export const editAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        editAssignmentData: action.payload,
      };
    case actionTypes.EDIT_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case "EDIT_ASSIGNMENT_RESET":
      return {};

    default:
      return state;
  }
};

export const deleteAssignmentReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ASSIGNMENT:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteAssignmentData: action.payload,
      };
    case actionTypes.DELETE_ASSIGNMENT_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "DELETE_ASSIGNMENT_RESET":
      return {};
    default:
      return state;
  }
};
