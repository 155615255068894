import React, { useState, useRef } from "react";

// icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextIcon from "@material-ui/icons/SkipNext";

import { Button, Input } from "reactstrap";

import { positionCenter, btnStyle } from "Utilities/common/commonStyle";

export default function Pagination({ page, setPage, lastPageNumber, notify }) {
  const [inputNumber, setInputNumber] = useState("");

  let inputRef = useRef();

  const previousPage = () => {
    if (page <= 1) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
    inputRef.current.value = page - 1;
  };

  const nextPage = () => {
    setPage(page + 1);
    inputRef.current.value = page + 1;
  };

  const goToFirstPage = () => {
    setPage(1);
    inputRef.current.value = 1;
  };

  const goToLastPage = () => {
    setPage(lastPageNumber);
    inputRef.current.value = lastPageNumber;
  };

  const handleChange = (e) => {
    setInputNumber(parseInt(e.target.value));
  };

  const getInputValue = () => {
    if (inputNumber > lastPageNumber) {
      notify("Enter a page number less than total pages", true);
    } else if (inputNumber >= 1) {
      setPage(inputNumber);
    } else {
      setInputNumber(null);
    }
  };

  return (
    <>
      <div>
        <div
          style={{
            ...positionCenter,
            justifyContent: "flex-end",
            gap: "2rem",
          }}
        >
          {page > 1 ? (
            <>
              <Button
                style={{ ...btnStyle, borderRadius: "4px", margin: "0" }}
                title="Go to first page"
                color="info"
                onClick={() => {
                  goToFirstPage();
                }}
              >
                <SkipPreviousIcon />
              </Button>

              <Button
                style={{ ...btnStyle, borderRadius: "4px", margin: "0" }}
                title="Previous page"
                color="info"
                onClick={() => {
                  previousPage();
                }}
              >
                <ChevronLeftIcon />
              </Button>
            </>
          ) : null}
          <div style={{ ...positionCenter, gap: "5px" }}>
            <input
              aria-label="Enter page number"
              title="Enter page number"
              style={{
                width: "60px",
                height: "40px",
                border: "1px solid white",
                borderRadius: "4px",
                textAlign: "center",
                backgroundColor: "transparent",
                color: "white",
              }}
              type="number"
              ref={inputRef}
              defaultValue={1}
              onChange={handleChange}
            />
            <Button
              aria-label="Go to page"
              title="Go to page"
              onClick={getInputValue}
              color="info"
              style={{
                ...btnStyle,
                borderRadius: "4px",
                margin: "0",
                height: "40px",
                width: "40px",
              }}
            >
              Go
            </Button>
          </div>
          {page >= lastPageNumber ? null : (
            <>
              <Button
                style={{ ...btnStyle, borderRadius: "4px", margin: "0" }}
                title="Next page"
                color="info"
                onClick={() => {
                  nextPage();
                }}
              >
                <ChevronRightIcon />
              </Button>

              <Button
                style={{ ...btnStyle, borderRadius: "4px", margin: "0" }}
                title="Go to last page"
                color="info"
                onClick={() => {
                  goToLastPage();
                }}
              >
                <SkipNextIcon />
              </Button>
            </>
          )}
        </div>
        <p
          style={{
            fontSize: "13px",
            textAlign: "end",
            color: "lightgray",
            marginTop: "4px",
          }}
        >
          Page {page} of {lastPageNumber}
        </p>
      </div>
    </>
  );
}
