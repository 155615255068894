import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
// import * as firebase from 'firebase';
import { connect } from "react-redux";

import {
  deleteAdminAction,
  getAdminsAction,
} from "store/action/adminActions/adminActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function DeleteAdmin(props) {
  const { uniqueId, onClick, myUser,setUserArr, setUserData, notify } = props;

  const [visible, setVisible] = useState(false);

  let mystatus = myUser.disabled === false ? "disable" : "enable";

  const deleteUser = () => {
    const deleteUrl = `api/admin?userId=${myUser.id}`;

    props.deleteAdmin(deleteUrl);
  };

  useEffect(() => {
    if (props.deleteAdminRes.deleteAdminData) {
      notify("User deleted successfully", false);
      props.clearResponse("DELETE_ADMIN_RESET");
      props.getAdminList();
      setUserArr([])
      onClick();
    }
    if (props.deleteAdminRes?.error) {
      notify(props.deleteAdminRes?.error?.message, true);
      props.clearResponse("DELETE_ADMIN_RESET");
    }
  }, [props?.deleteAdminRes?.deleteAdminData]);

  return (
    <div>
      <p>Do you want to delete this user</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button title="Yes" color="info" onClick={deleteUser}>
          Yes
        </Button>

        <Button title="No" onClick={onClick}>
          No
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteAdminRes: state.deleteAdmin,
  getAdminData: state.getAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  deleteAdmin: (payload) => {
    dispatch(deleteAdminAction(payload));
  },
  getAdminList: () => {
    dispatch(getAdminsAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAdmin);
