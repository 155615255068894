import * as actionTypes from '../../action/actionType';

const intialState = {};
export const getTraineeReportReducer= (state = intialState, action) => {
    switch (action.type) {
      case actionTypes.GET_TRAINEE_REPORT:
        return { ...state, isLoading: action.isLoading };
      case actionTypes.GET_TRAINEE_REPORT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          traineeReportData: action.payload,
        };
      case actionTypes.GET_TRAINEE_FAIL:
        return { isLoading: action.isLoading, error: action.payload.error };
        case 'GET_TRAINEE_REPORT_RESET':
          return {};
      default:
        return state;
    }
  };