import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getBatchAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BATCH,
      payload: {},
      isLoading: true,
    });
    let url='api/batch'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_BATCH_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_BATCH_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const createBatchAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_BATCH,
      payload: {},
      isLoading: true,
    });

    postRequest('api/batch/', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_BATCH_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_BATCH_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editBatchAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_BATCH,
      payload: {},
      isLoading: true,
    });

    putRequest('api/batch/', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_BATCH_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.EDIT_BATCH_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteBatchAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_BATCH,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_BATCH_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_BATCH_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const batchRelationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BATCH_RELATION,
      payload: {},
      isLoading: true,
    });

    postRequest('api/batch/relation/', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.BATCH_RELATION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.BATCH_RELATION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
export const batchQuizRelationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BATCH_QUIZ_RELATION,
      payload: {},
      isLoading: true,
    });

    postRequest('api/quize/assign/batch', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.BATCH_QUIZ_RELATION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.BATCH_QUIZ_RELATION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
export const batchAssignmentRelationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BATCH_ASSIGNMENT_RELATION,
      payload: {},
      isLoading: true,
    });

    postRequest('api/assignment/relation/batch', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.BATCH_ASSIGNMENT_RELATION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.BATCH_ASSIGNMENT_RELATION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editRelationAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_RELATION,
      payload: {},
      isLoading: true,
    });

    postRequest('api/batch/relation', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_RELATION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.EDIT_RELATION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
