/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

import { clearResponse } from "store/action/clearResponseAction";
import { getActivityAction } from "store/action/activityAction/activityAction";

import Loader from "components/Loader/Loader";

import Pagination from "components/Pagination/Pagination";

function ActivityTable(props) {
  const [activityData, setActivityData] = useState([]);
  const [page, setPage] = useState(1);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  const payloadDetails = {
    page: page ? page : 1,
    limit: 10,
  };

  useEffect(() => {
    props.getActivityList(payloadDetails);
  }, [page]);

  useEffect(() => {
    if (props.getActivityData.activityList) {
      if (props.getActivityData.activityList.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_ACTIVITY_RESET");
      } else {
        setActivityData(props.getActivityData.activityList.data);
      }
    }
    if (props.getActivityData?.error) {
      notify(props.getActivityData?.error?.message, true);
      props.clearResponse("GET_ACTIVITY_RESET");
    }
  }, [props.getActivityData]);

  useEffect(() => {
    document.title = "Activity Management";
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  if (!localStorage.getItem("user")) history.push("/login");

  return (
    <>
      <main id="main" className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <h1
                  style={{
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    margin: "0 auto 0 0",
                    textTransform: "capitalize",
                  }}
                >
                  Activity Management
                </h1>
              </CardHeader>
              <Row style={{ marginLeft: "7px" }}>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  sm={6}
                ></Col>
              </Row>
              <CardBody>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th style={{ textAlign: "left" }}>Action</th>
                      <th>User Name</th>
                      <th style={{ textAlign: "center" }}>User Type</th>
                      <th style={{ textAlign: "center" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityData[1]?.map((activity, i) => {
                      return (
                        <tr
                          key={activity.id}
                          style={{
                            borderBottom: `1px solid ${
                              colorTheme?.borderColor ||
                              "rgba(255, 255, 255, 0.1)"
                            }`,
                          }}
                        >
                          <th style={{ textAlign: "left", color: 'white' }}>
                            {activity.actionType}
                          </th>
                          <td>{activity.userName}</td>

                          <td style={{ textAlign: "center" }}>
                            {activity.userType}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {activity.createdAt
                              ?.replace("T", " ")
                              .replace(".000Z", "")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Pagination
          page={page}
          lastPageNumber={activityData[0]?.pages}
          setPage={setPage}
          notify={notify}
        />
      </main>
      {props?.getActivityData?.isLoading ? <Loader /> : null}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getActivityData: state.getActivity,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getActivityList: (payload) => {
    dispatch(getActivityAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);
