import React, { useState, useEffect } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import axios from "axios";
import {
  getUploadedStudyMaterial,
  deleteUploadedMaterial,
} from "store/action/trainerActions/trainerProfileActions";
import ModalComp from "components/ModalComp/ModalComp";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import Loader from "components/Loader/Loader";

const UploadedMaterialList = ({ batchId, moduleId, ...props }) => {
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [loopData, setLoopData] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState("");

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  useEffect(() => {
    const newData = [];
    if (props?.uploadedMaterialData?.uploadedMaterialData?.data) {
      props.uploadedMaterialData.uploadedMaterialData.data.map((data) => {
        if (data?.classPdf?.fileName) {
          data.classPdf.fileTypeName = "pdf";
          data.classPdf.batchModFileId = data?.batchModFileId;

          newData.push(data?.classPdf);
        } else if(data?.classRec?.fileName) {
          data.classRec.batchModFileId = data?.batchModFileId;

          data.classRec.fileTypeName = data?.classRec?.fileName
            ?.substring(
              data?.classRec?.fileName?.lastIndexOf("."),
              data?.classRec?.fileName?.length
            )
            .replace(".", "");
          newData.push(data?.classRec);
        }
      });

      // newData.map((item) => {
      //   doc.push(item?.url[0]);
      // });
      // setDoc(doc);

      console.log("recordingData", newData);
      setLoopData(newData);
      console.log(props.uploadedMaterialData.uploadedMaterialData);
    }
  }, [props?.uploadedMaterialData]);

  useEffect(() => {
    if (props?.deleteUploadedMaterialData?.deleteUploadedMaterialData) {
      props.getUploadedMaterial(batchId, moduleId);
      setOpenDeleteModal(false);
    }
  }, [props?.deleteUploadedMaterialData]);

  useEffect(() => {
    props.getUploadedMaterial(batchId, moduleId);
  }, []);

  const handleOpenDelete = (id) => {
    setIdForDelete(id);
    setOpenDeleteModal(true);
  };

  const handleDelete = () => {
    let url = `api/teacher/recordings?batchModFileId=${idForDelete}`;
    props.deleteUploadedMaterial(url);
  };

  return (
    <div
      style={{
        padding: "0 8px",
        borderLeft: "1px solid white",
        width: "60%",
        height: "fit-content",
      }}
    >
      <h2
        style={{
          fontSize: font?.fontSize + 2 || "inherit",
          fontWeight: font?.fontWeight || "inherit",
          textTransform: "capitalize",
        }}
      >
        Uploaded training materials
      </h2>
      <table style={{ width: "100%" }}>
        <thead>
          <tr style={{ height: "60px", borderBottom: "1px solid white" }}>
            <th
              style={{
                padding: "0 4px",
                textAlign: "center",
                color: "#FFFFFFCC",
              }}
            >
              Name
            </th>
            <th
              style={{
                padding: "0 4px",
                textAlign: "center",
                color: "#FFFFFFCC",
              }}
            >
              Type
            </th>
            {/* <th style={{ padding: '0 4px', textAlign: 'center' }}>Files</th> */}

            <th
              style={{
                padding: "0 4px",
                textAlign: "center",
                color: "#FFFFFFCC",
              }}
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {loopData.map((file) => {
            return (
              <tr style={{ height: "50px", borderBottom: "1px solid white" }}>
                <td
                  style={{
                    padding: "0 4px",
                    textAlign: "center",
                    color: "#FFFFFFCC",
                  }}
                >
                  {file?.fileName?.slice(0, file?.fileName?.lastIndexOf(".")) ||
                    ""}
                </td>
                <td style={{ color: "#FFFFFFCC" }}>
                  {file?.fileTypeName || ""}
                </td>
                {/* <td>
                  {file?.fileType?.includes('audio') ? (
                    <audio
                      src={file?.url[0]}
                      controls
                      controlsList='nodownload'
                      style={{ display: 'flex' }}
                    />
                  ) : file?.fileType?.includes('video') ? (
                    <video
                      width='100%'
                      height='80px'
                      src={file?.url[0]}
                      controls
                      controlsList='nodownload'
                      style={{ display: 'flex' }}
                    ></video>
                  ) : file?.fileType?.includes('image') ? (
                    <a target='_blank' href={file?.url[0]}>
                      {' '}
                      <img
                        style={{ width: '100%', height: '80px' }}
                        src={file?.url[0]}
                        alt=''
                      />
                    </a>
                  ) : file?.fileType?.includes('pdf') ? (
                    <iframe
                      src={file?.url[0]}
                      width='100%'
                      height='100%'
                      frameborder='0'
                    >
                      This is an embedded{' '}
                      <a target='_blank' href='http://office.com'>
                        Microsoft Office
                      </a>{' '}
                      document, powered by{' '}
                      <a target='_blank' href='http://office.com/webapps'>
                        Office Online
                      </a>
                      .
                    </iframe>
                  ) : (
                    // <a target='_blank' href={file?.url[0]}>
                    //   file
                    // </a>
                    <button
                      onClick={() => {
                        // window.open(file?.url[0], 'window2', '');
                        // axios({
                        //   method: 'get',
                        //   url: file?.url[0],
                        //   headers: {},
                        // });
                      }}
                    >
                      File
                    </button>
                  )}
                </td> */}
                <td style={{ padding: "0 4px", textAlign: "center" }}>
                  <button
                    title="Delete"
                    aria-label="Delete"
                    onClick={() => handleOpenDelete(file?.batchModFileId)}
                    style={actionBtnStyle}
                  >
                    <DeleteIcon style={{ color: "red" }} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <ModalComp controller={openDeleteModal} bodyColor={colorTheme?.bodyColor}>
        <div>
          <p
            style={{
              color: "white",
            }}
          >
            Do you want to delete this File?
          </p>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              title="Yes"
              color="info"
              onClick={() => handleDelete(idForDelete)}
            >
              Yes
            </Button>

            <Button title="No" onClick={() => setOpenDeleteModal(false)}>
              No
            </Button>
          </div>
        </div>
      </ModalComp>
      {props.deleteUploadedMaterialData.isLoading ? <Loader /> : null}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
  colorData: state.colorValues,
  uploadedMaterialData: state.uploadedMaterial,
  deleteUploadedMaterialData: state.deleteUploadedMaterial,
});

const mapDispatchToProps = (dispatch) => ({
  getUploadedMaterial: (batchId, moduleId) => {
    dispatch(getUploadedStudyMaterial(batchId, moduleId));
  },
  deleteUploadedMaterial: (url) => {
    dispatch(deleteUploadedMaterial(url));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadedMaterialList);
