import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    Card,
    Input,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalBody,
    Progress,
    Table,
    ButtonGroup,
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import PackageDialog from './PackageDialog'
import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { Edit, Delete } from '@material-ui/icons'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import PackageDelete from './PackageDelete'


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PackageManagement() {

    const notification = useRef();
    const [progressVisible, setProgressVisible] = useState(true)
    const [progressValue, setProgressValue] = useState(8)
    const [open, setOpen] = useState(false);
    const [packageData, setPackageData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentPackage, setcurrentPackage] = useState('');




    const notify = (msg, err) => {
        let color = err === true ? 'danger' : 'success'
        let options = {
            type: color,
            place: "tr",
            message: (
                <div>
                    <div>
                        <b>{err === true ? 'Error' : 'Success'}</b> - {msg}.
              </div>
                </div>
            ),
            icon: "tim-icons icon-bell-55",
            autoDismiss: 6
        };
        if(notification.current){
            notification.current.notificationAlert(options)
        }
    }


    const handleClickOpen = () => {

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setProgressValue(99)

        db.collection('zSystemStore').where('type', '==', 'package').get().then((querySnapshot) => {
            let data = querySnapshot.docs.map((doc) => doc.data())
            setPackageData(data)
            setProgressVisible(false)

        })
    }, [])

    const deletePackage = (data) => {
        const afterDelete = packageData.filter(item => item.itemID !== data);
        setPackageData(afterDelete)

        db.collection("zSystemStore").doc(data).delete().then(() => {
            console.log('record deleted ')
            notify("Package deleted successfully",false);
        }).catch((error) => {
            notify(error.toString(),true);

            console.log('this is error ', error)
        })
    }

    return (
        <>

            {progressVisible ? <Progress color='info' value={progressValue} style={{ height: 4 }} />
                :
                <div className="content">
                    <div className="react-notification-alert-container">
                        <NotificationAlert ref={notification} />
                    </div>
                    <Modal isOpen={deleteModal} color='primary' style={{ display: 'flex', flex: 1, top: -40, width: 250, backgroundColor: '#132639', objectFit: 'fill' }} >
                        <ModalBody style={{ width: '100%' }} size='sm'>
                            <PackageDelete
                                deletePackage={() => deletePackage(currentPackage)}
                                setDeleteModal={setDeleteModal}
                            ></PackageDelete>
                        </ModalBody>
                    </Modal>
                    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                        <PackageDialog currentPackage={currentPackage} setPackageData={setPackageData} notifi={notify} handleClose={handleClose} />
                    </Dialog>
                    <Row>
                        <Col md="12">
                            <Card >
                                <CardHeader >
                                    <Row>
                                        <Col sm={9} style={{ textAlign: 'center' }}>
                                            <CardTitle tag="h4" style={{ fontWeight: 300 }}>
                                                PACKAGE MANAGEMENT
                                    </CardTitle>
                                        </Col>
                                        <Col sm={3}>

                                            <Button color='info' style={{float:'right'}} onClick={() => {
                                                // setCurrentQuiz('')
                                                setcurrentPackage('')
                                                handleClickOpen()
                                            }}>Add Package</Button>

                                        </Col>

                                    </Row>

                                </CardHeader>


                                <CardBody >
                                    <Table className="tablesorter" >
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Package Title</th>
                                                <th style={{textAlign: "center"}}>Book Count</th>
                                                <th style={{textAlign: "center"}}>Quiz Count</th>
                                                <th style={{textAlign: "center"}}>Question Count</th>
                                                <th style={{textAlign: "center"}}>With Classes</th>
                                                <th style={{textAlign: "center"}}>Price</th>
                                                <th style={{textAlign: "center"}}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {packageData.map(item => {
                                                // let status = user.status && [];
                                                console.log('packageData:', item);
                                                return (
                                                    <tr key={item.itemID}>
                                                        <td>{item.title}</td>
                                                        <td style={{textAlign: "center"}}>{item.numberOfBook}</td>
                                                        <td style={{textAlign: "center"}}>{item.numberOfQuiz}</td>
                                                        <td style={{textAlign: "center"}}>{item.numberOfQuestions}</td>
                                                        <td style={{textAlign: "center"}}>{item.withClasses ? 
                                                            <CheckCircleIcon style={{ fontSize: '35px', color: '#17de3e' }}/>    
                                                            :
                                                            <CancelIcon style={{ fontSize: '35px', color: '#f51d1d' }}/>
                                                        }
                                                        </td>
                                                        <td style={{fontFamily: 'Roboto, sans-serif', textAlign: "center"}}>₹ {item.price}</td>


                                                        <td style={{textAlign: "center"}}>
                                                            <Edit style={{color:'#247cf7', cursor: 'pointer'}}
                                                            onClick={() => {
                                                                setcurrentPackage(item)
                                                                handleClickOpen()

                                                            }}
                                                            />
                                                            <Delete
                                                                onClick={() => {
                                                                    setcurrentPackage(item.itemID)
                                                                    setDeleteModal(true)
                                                                }}
                                                                style={{ color: 'red', cursor: 'pointer' }}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>

                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/* <Modal isOpen={editModal} style={{ display: 'flex', flex: 1, top: -200, minWidth:'85%' }} >
                <ModalBody className={styles.create__quiz_modal_body} >
                    <QuizEditModal notify={notify}
                        setquizData={setquizData} currentQuestion={currentQuestion} onClick={() => {
                            setEditModal(false)
                        }}>

                    </QuizEditModal>
                </ModalBody>
            </Modal> */}

                </div>
            }
        </>
    )
}

export default PackageManagement
