import * as actionTypes from '../../action/actionType';

const intialState = {};

export const securityGroupBulkDeleteReducer = (state = intialState, action) => {
    switch (action.type) {
      case actionTypes.BULK_DELETE_SECURITY_GROUP:
        return { ...state, isLoading: action.isLoading };
      case actionTypes.BULK_DELETE_SECURITY_GROUP_SUCCESS:
        return {
          ...state,
          isLoading: false,
          deleteSecurityGroupData: action.payload,
        };
      case actionTypes.BULK_DELETE_SECURITY_GROUP_FAIL:
        return { isLoading: action.isLoading, error: action.payload.error };
      case 'BULK_DELETE_SECURITY_GROUP_RESET':
        return {};
      default:
        return state;
    }
  };