import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  Table,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { Edit, Delete } from "@material-ui/icons";

import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import SearchIcon from "@material-ui/icons/Search";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";

import NotificationAlert from "react-notification-alert";

import QuizEditModal from "./QuizEditModal";
import Quiz_Modal from "./Quiz_Modal";
import Delete_Quiz_Question_Modal from "./Delete_Quiz_Question_Modal";
import styles from "./quiz_Management.module.css";

import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

import QuizDialog from "./QuizDialog";
import DeleteQuizModal from "./DeleteQuizModal";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "Utilities/algoliaKeys";

import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";

import {
  getQuestionAction,
  deleteQuestionAction,
} from "store/action/questionAction/questionAction";

import {
  getQuizAction,
  deleteQuizAction,
} from "store/action/quizAction/quizAction";

import { clearResponse } from "store/action/clearResponseAction";

import Loader from "components/Loader/Loader";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import BulkDeleteQuiz from "./BulkDeleteQuiz";
import { questionBulkDeleteAction } from "store/action/bulkDeleteAction/questionBulkDeleteAction";
import BulkDeleteQuestion from "./BulkDeleteQuestion";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Quiz_Management(props) {
  const [limit, setLimit] = useState(100);

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const indexForQues = searchClient.initIndex("Questions_Search");
  const indexForQuiz = searchClient.initIndex("Quiz_Search");

  const notification = useRef();
  const classes = useStyles();

  const [reload, setreload] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteQuizModal, setDeleteQuizModal] = useState(false);
  const [quizData, setquizData] = useState([]);
  const [quizesData, setQuizesData] = useState([]);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [searchQuiz, setSearchQuiz] = useState("");

  const [currentQuestion, setcurrentQuestion] = useState("");
  const [currentQuiz, setCurrentQuiz] = useState("");
  const [clicked, setClicked] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);

  const [active, setactive] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [quizBulkArr, setQuizBulkArr] = useState([]);
  const [userArr, setUserArr] = useState([]);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);
  const [bulkDeleteQuizVisible, setBulkDeleteQUizVisible] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setOpen(!open);
    }
  };

  const setQuizesDataWrapper = (data) => {
    // if(typeof data == "function")
    // data = data(quizesData);
    setQuizesData(data.slice(0, limit));
    console.log(quizesData);
  };

  const setQuesDataWrapper = (data) => {
    console.log(data);
    if (data != undefined && data.length > 0) setquizData(data);
    // data = data(quizData);
    // console.log('datatdatatadt ques',quizData,data)
  };

  const deleteQuestion = (data) => {
    console.log("data data,,, ", data);
    const deleteUrl = `api/question?questionId=${data}`;

    props.deleteQuestion(deleteUrl);
  };

  const nextSet = () => {};

  const prevSet = () => {};

  const nextQuizSet = () => {};

  const prevQuizSet = () => {};

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const handleQuizCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setQuizBulkArr([...quizBulkArr, `id=${value}`]);
    } else {
      setQuizBulkArr(quizBulkArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  const filterSearch = (data) => {};

  const getQuiz = () => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getSubjects = () => {
    db.collection("subjects")
      .orderBy("name")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setSubjects(data);
      });
  };

  useEffect(() => {
    document.title = "Quiz Management";
  }, []);

  useEffect(() => {
    // props?.getQuestion
    if (props?.getQuestionData?.questionList?.data) {
      if (props?.getQuestionData?.questionList?.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_QUESTION_RESET");
        setquizData("");
      } else {
        setQuesDataWrapper(props?.getQuestionData?.questionList?.data);
      }
    }
    if (props?.getQuestionData?.error) {
      notify(props?.getQuestionData?.error?.message, true);
      props.clearResponse("GET_QUESTION_RESET");
    }
  }, [props.getQuestionData]);

  // useEffect(() => {
  //   console.log(quesDataWrapper);
  // }, [quesDataWrapper]);

  useEffect(() => {
    if (props?.getDeleteQuestionData?.deleteQuestionData?.data) {
      props.getQuestion();
      notify("Question deleted successfully", false);
      props.clearResponse("DELETE_QUESTION_RESET");
      setUserArr([]);
      setDeleteModal(false);
    }
    if (props?.getDeleteQuestionData?.error) {
      notify(props?.getDeleteQuestionData?.error?.message, true);
      props.clearResponse("DELETE_QUESTION_RESET");
      setDeleteModal(false);
    }
  }, [props.getDeleteQuestionData]);

  useEffect(() => {
    if (props?.getDeleteQuizRes?.error) {
      notify(props?.getDeleteQuizRes?.error?.message, true);
      props.clearResponse("DELETE_QUIZ_RESET");
      setDeleteModal(false);
    }
    if (props?.getDeleteQuizRes?.deleteQuizData?.data) {
      notify("Quiz deleted successfully", false);
      props.clearResponse("DELETE_QUIZ_RESET");
      props.getQuizData();
      setQuizBulkArr([]);
      setDeleteModal(false);
    }
  }, [props.getDeleteQuizRes]);

  useEffect(() => {
    if (props.editQuizRes?.editQuizData?.data) {
      notify("Data update successfully", false);
      props.clearResponse("EDIT_QUIZ_RESET");

      props.getQuizData();
    }

    if (props.editQuizRes?.error) {
      notify(props.editQuizRes?.error?.message, true);
      props.clearResponse("EDIT_QUIZ_RESET");
    }
  }, [props.editQuizRes]);

  useEffect(() => {
    if (props?.createQuize?.error) {
      notify(props?.createQuize?.error?.message, true);
      props.clearResponse("CREATE_QUIZ_RESET");
    }
    if (props?.createQuize?.createQuizData?.data) {
      props.getQuizData();
      notify("Quiz created successfully", false);
      props.clearResponse("CREATE_QUIZ_RESET");
    }
  }, [props.createQuize]);

  useEffect(() => {
    !active ? props.getQuestion() : props.getQuizData();
  }, [active]);

  useEffect(() => {
    // props?.getQuestion
    if (props?.getQuizRes?.quizList?.data) {
      if (props?.getQuizRes?.quizList?.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_QUIZ_RESET");
        setQuesDataWrapper("");
        setQuizesData("")
      } else {
        setQuizesDataWrapper(props?.getQuizRes?.quizList?.data);
        setQuesDataWrapper(props?.getQuestionData?.questionList?.data);
      }
    }
    //  else {
    //   setQuesDataWrapper([]);
    // }
    if (props?.getQuizRes?.error) {
      notify(props?.getQuizRes?.error?.message, true);
      props.clearResponse("GET_QUIZ_RESET");
    }
  }, [props.getQuizRes]);

  useEffect(() => {
    console.log("create quize", props.createQuize);
  }, []);

  const deleteQuiz = (data) => {
    console.log("beforedelete", data);
    const deleteURL = `api/quize?id=${data}`;
    console.log(deleteURL);
    props.deleteQuiz(deleteURL);
    props.getQuizData();
  };

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const search = () => {
    if (searchQuestion == "") {
      props.getQuestion();
      // notify('Search field Can not be empty', true);
    } else {
      props.getQuestion(searchQuestion);
      // notify("Data Fetched successfully", false);
    }
  };

  const searchQuizFun = () => {
    if (searchQuiz == "") {
      props.getQuizData();
      // notify('Search field Can not be empty', true);
    } else {
      props.getQuizData(searchQuiz);
      // notify("Data Fetched successfully", false);
    }
  };

  const emptyCall = () => {};

  const emptyCharCall = () => {};

  return (
    <main id="main" className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notification} />
      </div>

      <Row>
        <Col md="12">
          <Card
            style={{
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader className={styles.card_header}>
              <Col style={{ padding: 0 }}>
                <ButtonGroup>
                  <Button
                    title="QUIZ"
                    className={active ? "data" : "btn-simple data"}
                    color="info"
                    size="sm"
                    onClick={() => {
                      setactive((prev) => !prev);
                      // console.log(active);
                      setQuizBulkArr([]);
                      getQuiz();
                    }}
                    style={{ borderRadius: "4px" }}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      QUIZ
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-single-02" />
                    </span>
                  </Button>

                  <Button
                    title="Questions"
                    className={active ? "btn-simple data" : "data"}
                    color="info"
                    size="sm"
                    onClick={() => {
                      setactive((prev) => !prev);
                      setUserArr([]);
                      // console.log(active);
                      //   getQuestions();
                    }}
                    style={{ borderRadius: "4px", marginLeft: "5px" }}
                  >
                    <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                      Questions
                    </span>
                    <span className="d-block d-sm-none">
                      <i className="tim-icons icon-gift-2" />
                    </span>
                  </Button>
                </ButtonGroup>
              </Col>
              {active ? (
                <Button
                  title="Add Quiz"
                  color="info"
                  onClick={() => {
                    setCurrentQuiz("");
                    handleClickOpen();
                  }}
                >
                  Add Quiz
                </Button>
              ) : (
                <Button
                  title="Add Questions"
                  color="info"
                  onClick={() => setIsModalVisible(true)}
                >
                  Add Questions
                </Button>
              )}
            </CardHeader>

            {active ? (
              <CardBody>
                <Modal
                  isOpen={isDeleteQuizModal}
                  color="primary"
                  style={{
                    display: "flex",
                    flex: 1,
                    top: 0,
                    width: 250,
                    backgroundColor: "#132639",
                    objectFit: "fill",
                  }}
                >
                  <ModalBody style={{ width: "100%" }} size="sm">
                    <DeleteQuizModal
                      deleteQuiz={() => deleteQuiz(currentQuiz)}
                      setDeleteQuizModal={setDeleteQuizModal}
                    ></DeleteQuizModal>
                  </ModalBody>
                </Modal>

                <Modal
                  isOpen={bulkDeleteQuizVisible}
                  color="primary"
                  style={{
                    display: "flex",
                    flex: 1,
                    top: 0,
                    width: 250,
                    backgroundColor: "#132639",
                    objectFit: "fill",
                  }}
                >
                  <ModalBody style={{ width: "100%" }} size="sm">
                    <BulkDeleteQuiz
                      quizBulkArr={quizBulkArr !== undefined && quizBulkArr}
                      setQuizBulkArr={setQuizBulkArr}
                      notify={notify}
                      onClick={() => {
                        setBulkDeleteQUizVisible(false);
                      }}
                    ></BulkDeleteQuiz>
                  </ModalBody>
                </Modal>

                <Dialog
                  fullScreen
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                >
                  <QuizDialog
                    startIndex={startIndex}
                    currentQuiz={currentQuiz}
                    setQuizesData={setQuizesDataWrapper}
                    notifi={notify}
                    handleClose={handleClose}
                  />
                </Dialog>

                <Row>
                  <Col sm={4}>
                    <Input
                      title="Search Quiz"
                      type="text"
                      name="search"
                      placeholder="Search Quiz"
                      onInput={(e) => {
                        e.target.value.length === 0 && emptyCharCall();
                        setSearchQuiz(e.target.value);
                        emptyCall();
                      }}
                    />
                  </Col>
                  <Col style={{ paddingLeft: "0px" }} sm={2}>
                    <Button
                      title="Search"
                      style={{ margin: "0px", padding: "6px 10px" }}
                      color="info"
                      onClick={searchQuizFun}
                    >
                      <SearchIcon />
                    </Button>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    sm={6}
                  >
                    {quizBulkArr.length > 0 ? (
                      <Button
                        title="Delete Marked"
                        aria-label="Delete Marked"
                        style={{ margin: "0px 0 0 0", padding: "6px 10px" }}
                        color="danger"
                        onClick={() => {
                          setBulkDeleteQUizVisible(true);
                        }}
                      >
                        Delete Marked
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <br></br>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Quiz Title</th>
                      <th style={{ textAlign: "center" }}>No of Questions</th>
                      {/* <th>Subject</th> */}
                      {/* <th style={{ textAlign: 'center' }}>Price</th> */}

                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizesData?.length !== 0 ? (
                      quizesData.map((data) => {
                        return (
                          <tr
                            key={data.id}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                                color: "white",
                              }}
                            >
                              <Input
                                id={data?.id}
                                defaultChecked={false}
                                type="checkbox"
                                key={data?.id}
                                value={data?.id}
                                onChange={handleQuizCheckbox}
                              />
                            </td>
                            <th style={{ width: 450 }}>
                              <label 
                               style={{
                                fontSize: font?.fontSize || "inherit",
                                fontWeight: font?.fontWeight || "inherit",
                                color: "#FFFFFFB3",
                              }}
                              htmlFor={data?.id}>{data.title}</label>
                            </th>
                            <td
                              style={{
                                paddingLeft: "15px",
                                textAlign: "center",
                              }}
                            >
                              {data.quizQuestions && data.quizQuestions.length}
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                            >
                              <button
                                title="Edit"
                                aria-label="Edit"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setCurrentQuiz(data);
                                  handleClickOpen();
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>

                              <button
                                title="Delete"
                                aria-label="Delete"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setCurrentQuiz(data.id);
                                  setDeleteQuizModal((prev) => !prev);
                                }}
                              >
                                <Delete style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                        <td>
                          <h3 style={{ textAlign: "center" }}>
                            Data not found
                          </h3>
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            ) : (
              <CardBody>
                <Row>
                  <Col sm={4}>
                    <Input
                      title="Search Question"
                      type="text"
                      name="searchQuestion"
                      placeholder="Search Question"
                      onInput={(e) => {
                        e.target.value.length === 0 && emptyCharCall();
                        setSearchQuestion(e.target.value);
                        emptyCall();
                      }}
                    />
                  </Col>
                  <Col style={{ paddingLeft: "0px" }} sm={2}>
                    <Button
                      title="Search"
                      style={{ margin: "0px", padding: "6px 10px" }}
                      color="info"
                      onClick={search}
                    >
                      <SearchIcon />
                    </Button>
                  </Col>
                  <Col
                    sm={6}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {userArr.length > 0 ? (
                      <Button
                      title="Delete Marked"
                      aria-label="Delete Marked"
                        style={{ margin: "0px 0px 0 0", padding: "6px 10px" }}
                        color="danger"
                        onClick={() => {
                          setBulkDeleteVisible(true);
                        }}
                      >
                        Delete Marked
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <br></br>
                <Modal
                  isOpen={isModalVisible}
                  style={{
                    display: "flex",
                    flex: 1,
                    top: -200,
                    minWidth: "85%",
                  }}
                >
                  <ModalBody className={styles.create__quiz_modal_body}>
                    <Quiz_Modal
                      notify={notify}
                      startIndex={startIndex}
                      setquizData={setQuesDataWrapper}
                      onClick={() => {
                        setIsModalVisible(false);
                      }}
                    ></Quiz_Modal>
                  </ModalBody>
                </Modal>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Questions Title</th>
                      <th style={{ textAlign: "center" }}>Subject</th>
                      <th style={{ textAlign: "center" }}>Difficulty</th>

                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizData?.length !== 0 ? (
                      quizData.map((data) => {
                        return (
                          <tr
                            key={data.id}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                                color: "white",
                                marginTop: "1px",
                              }}
                            >
                              <Input
                                id={data?.id}
                                defaultChecked={false}
                                type="checkbox"
                                key={data?.id}
                                value={data?.id}
                                onChange={handleCheckbox}
                              />
                            </td>
                            <th style={{ width: 500 }}>
                              <label 
                               style={{
                                fontSize: font?.fontSize || "inherit",
                                fontWeight: font?.fontWeight || "inherit",
                                color: "#FFFFFFB3",
                              }}
                              htmlFor={data?.id}>{data.question}</label>
                            </th>
                            <td style={{ textAlign: "center" }}>
                              {data.subject}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {data.difficulty == 1
                                ? "Easy"
                                : data.difficulty == 2
                                ? "Medium"
                                : "Difficult"}
                            </td>
                            <td
                              style={{ cursor: "pointer", textAlign: "center" }}
                            >
                              <button
                                title="Edit"
                                aria-label="Edit"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setcurrentQuestion(data);
                                  setEditModal(true);
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>

                              <button
                                title="Delete"
                                aria-label="Delete"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setcurrentQuestion(data.id);
                                  setDeleteModal(true);
                                }}
                              >
                                <Delete style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                        <td>
                          <h3 style={{ textAlign: "center" }}>
                            Data not found
                          </h3>
                        </td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={bulkDeleteVisible}
        color="primary"
        style={{
          display: "flex",
          flex: 1,
          top: 0,
          width: 250,
          backgroundColor: "#132639",
          objectFit: "fill",
        }}
      >
        <ModalBody style={{ width: "100%" }} size="sm">
          <BulkDeleteQuestion
            userArr={userArr !== undefined && userArr}
            setUserArr={setUserArr}
            notify={notify}
            onClick={() => {
              setBulkDeleteVisible(false);
            }}
          ></BulkDeleteQuestion>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={deleteModal}
        color="primary"
        style={{
          display: "flex",
          flex: 1,
          top: 0,
          width: 250,
          backgroundColor: "#132639",
          objectFit: "fill",
        }}
      >
        <ModalBody style={{ width: "100%" }} size="sm">
          <Delete_Quiz_Question_Modal
            deleteQuestion={() => deleteQuestion(currentQuestion)}
            setDeleteModal={setDeleteModal}
          ></Delete_Quiz_Question_Modal>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={editModal}
        style={{ display: "flex", flex: 1, top: -200, minWidth: "85%" }}
      >
        <ModalBody className={styles.create__quiz_modal_body}>
          <QuizEditModal
            notify={notify}
            setquizData={setquizData}
            currentQuestion={currentQuestion}
            onClick={() => {
              setEditModal(false);
            }}
          ></QuizEditModal>
        </ModalBody>
      </Modal>
      {props?.getQuestionData?.isLoading ||
      props?.getDeleteQuestionData?.isLoading ||
      props?.getModuleRes?.isLoading ||
      props?.getQuizRes?.isLoading ||
      props?.getDeleteQuizRes?.isLoading ||
      props?.editQuizRes?.isLoading ||
      props?.editQuestionRes?.isLoading ||
      props?.createQuize?.isLoading ||
      props?.createQuestionRes?.isLoading ||
      props?.questionBulkDeleteRes?.isLoading ||
      props?.quizBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </main>
  );
}
const mapStateToProps = (state, ownProps) => ({
  getQuestionData: state.getQuestion,
  getDeleteQuestionData: state.deleteQuestion,
  questionBulkDeleteRes: state.questionBulkDelete,
  getModuleRes: state.getModule,
  getQuizRes: state.getQuiz,
  createQuize: state.createQuiz,
  getDeleteQuizRes: state.deleteQuiz,
  quizBulkDeleteRes: state.quizBulkDelete,
  editQuizRes: state.editQuiz,
  createQuestionRes: state.createQuestion,
  editQuestionRes: state.editQuestion,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getQuestion: (props) => {
    dispatch(getQuestionAction(props));
  },
  deleteQuestion: (payload) => {
    dispatch(deleteQuestionAction(payload));
  },

  getQuizData: (payload) => {
    dispatch(getQuizAction(payload));
  },
  questionBulkDelete: (payload) => {
    dispatch(questionBulkDeleteAction(payload));
  },

  deleteQuiz: (payload) => {
    dispatch(deleteQuizAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz_Management);
