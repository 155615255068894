import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getBatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BATCH:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batchList: action.payload,
      };
    case actionTypes.GET_BATCH_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_BATCH_RESET":
      return {};
    default:
      return state;
  }
};
export const createBatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_BATCH:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createBatchData: action.payload,
      };
    case actionTypes.CREATE_BATCH_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "CREATE_BATCH_RESET":
      return {};
    default:
      return state;
  }
};
export const editBatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_BATCH:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editBatchData: action.payload,
      };
    case actionTypes.EDIT_BATCH_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "EDIT_BATCH_RESET":
      return {};
    default:
      return state;
  }
};

export const deleteBatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_BATCH:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteBatchData: action.payload,
      };
    case actionTypes.DELETE_BATCH_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "DElETE_BATCH_RESET":
      return {};
    default:
      return state;
  }
};

export const batchRelationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.BATCH_RELATION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.BATCH_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batchRelationData: action.payload,
      };
    case actionTypes.BATCH_RELATION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "BATCH_RELATION_RESET":
      return {};

    default:
      return state;
  }
};
export const batchQuizRelationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.BATCH_QUIZ_RELATION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.BATCH_QUIZ_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batchQuizRelationData: action.payload,
      };
    case actionTypes.BATCH_QUIZ_RELATION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "BATCH_QUIZ_RELATION_RESET":
      return {};

    default:
      return state;
  }
};
export const batchAssignmentRelationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.BATCH_ASSIGNMENT_RELATION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.BATCH_ASSIGNMENT_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        batchAssignmentRelationData: action.payload,
      };
    case actionTypes.BATCH_ASSIGNMENT_RELATION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "BATCH_ASSIGNMENT_RELATION_RESET":
      return {};

    default:
      return state;
  }
};

export const editRelationReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_RELATION:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_RELATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editRelationData: action.payload,
      };
    case actionTypes.EDIT_RELATION_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "EDIT_RELATION_RESET":
      return {};

    default:
      return state;
  }
};
