import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadSubmittedAssignmentAction } from 'store/action/trainerActions/trainerTaskActions';
import { btnStyle } from 'Utilities/common/commonStyle';
import DropdownForDisplay from 'components/DropdownForDisplay/DropdownForDisplay';
const SubmittedAssignmentList = ({ obj, ...props }) => {
  const [downloadLink, setDownloadLink] = useState('');

  return (
    <tr>
      <td
        style={{ textTransform: 'capitalize' }}
      >{`${obj?.student?.data?.firstName} ${obj?.student?.data?.lastName}`}</td>
      <td>
        {/* {obj?.student?.assignment?.length !== 0 ? ( */}
        <DropdownForDisplay
          customStyle={{ justifyContent: 'flex-start' }}
          title='Submitted assignments'
        >
          {obj?.student?.assignment?.length !== 0 ? (
            obj?.student?.assignment?.map((assignment) =>
              assignment?.submittedAssignment?.length !== 0 ? (
                assignment?.submittedAssignment?.map((subAsgn) => {
                  return (
                    <section
                      id='task-section'
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        backgroundColor: '#f0f0f0',
                        padding: '4px 8px',
                        margin: '1px 0',
                      }}
                    >
                      <p style={{ color: 'black' }}>
                        {subAsgn?.title || 'Anonymous assignment'}
                      </p>
                      <a
                        style={{
                          maxWidth: 'fit-content',
                          display: 'block',
                          pointerEvents: assignment?.submittedAssignment[0]
                            ?.file
                            ? 'auto'
                            : 'none',
                        }}
                        href={`https://atriev-lms.dt.r.appspot.com/api/assignment/download?fileId=${
                          subAsgn?.file
                        }&authorization=${
                          JSON.parse(localStorage.getItem('user')).token
                        }`}
                      >
                        <button
                          style={{
                            ...btnStyle,
                            opacity: subAsgn?.file ? '1' : '0.5',
                          }}
                        >
                          <GetAppIcon style={{ color: 'white' }} />
                        </button>
                      </a>
                    </section>
                  );
                })
              ) : (
                <p style={{ color: 'black' }}>Assignment not submitted</p>
              )
            )
          ) : (
            <p>No data found</p>
          )}
        </DropdownForDisplay>
        {/* ) : (
          'No assignment found'
        )} */}
      </td>
      <td style={{ display: 'flex' }}>
        <DropdownForDisplay
          customStyle={{ justifyContent: 'flex-start' }}
          title='Quiz report'
        >
          <div>
          {obj?.student?.quiz?.length !== 0 ? (
            obj?.student?.quiz?.map((quiz) =>
              quiz?.quizSubmissions?.length !== 0 ? (
                quiz?.quizSubmissions?.map((subQuiz) => {
                  return (
                    <p style={{ color: 'black' }}>
                      <span style={{ marginRight: '4px' }}>
                        {quiz?.quiz?.title || 'Anonymous quiz'}
                      </span>
                      <span>
                        {`${subQuiz?.score} / ${subQuiz?.maxScore}` ||
                          'No data'}
                      </span>
                    </p>
                  );
                })
              ) : (
                <p>Quiz not submitted</p>
              )
            )
          ) : (
            <p style={{ color: 'black' }}>No data found</p>
          )}
          </div>
        </DropdownForDisplay>
      </td>
    </tr>
  );
};

const mapStateToProps = (state, ownProps) => ({
  downloadAssignment: state.downloadSubmittedAssignment,
});

const mapDispatchToProps = (dispatch) => ({
  downloadSubmittedAssignment: (fileId) => {
    dispatch(downloadSubmittedAssignmentAction(fileId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmittedAssignmentList);
