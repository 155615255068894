import React, { useEffect, useState, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  Table,
  Progress,
  Input,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { Add, Edit, Delete } from "@material-ui/icons";
import DeleteBatch from "./DeleteBatch";
import Add_Edit_Batch from "./Add_Edit_Batch";
import format from "date-fns/format";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AddMaterial from "./AddMaterial";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import SearchIcon from "@material-ui/icons/Search";

import { actionBtnStyle } from "Utilities/common/commonStyle";

import {
  getBatchAction,
  deleteBatchAction,
} from "store/action/batchActions/batchActions";
import { clearResponse } from "store/action/clearResponseAction";
import Loader from "components/Loader/Loader";
import AssignModuleTrainer from "./AssignModuleTrainer";
import BulkDeleteTraining from "./BulkDeleteTraining";

// import { actionBtnStyle } from 'Utilities/common/commonStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BatchList(props) {
  const [limit, setLimit] = useState(10);
  const notification = useRef();
  const [progressVisible, setProgressVisible] = useState(false);
  const [progressValue, setProgressValue] = useState(8);
  const [open, setOpen] = useState(false);
  const [batchData, setBatchData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentBatch, setcurrentBatch] = useState("");
  const [currentBatchId, setcurrentBatchId] = useState("");
  const [fullDialogOpen, setFullDialogOpen] = useState(false);

  const [assignModuleModal, setAssignModuleModal] = useState(false);

  const handlefullToggle = () => {
    setFullDialogOpen((prev) => !prev);
  };
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [relationList, setRelationList] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [trainingArr, setTrainingArr] = useState([]);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target
    if (checked) {
      setTrainingArr([...trainingArr, `id=${value}`])
    }
    else {
      setTrainingArr(trainingArr.filter((id, i) => id !== `id=${value}`))
    }
  }

  useEffect(() => {
    document.title = "Training Management"
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setOpen(!open);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteBatch = (data) => {
    const afterDelete = batchData.filter((item) => item.itemID !== data);
    setBatchData(afterDelete);
    const deleteUrl = `api/batch?id=${data.id}`;
    props.deleteBatch(deleteUrl);
  };

  const nextSet = () => { };

  const prevSet = () => { };

  useEffect(() => {
    props.getBatchList();
  }, []);

  useEffect(() => {
    if (props?.getBatch?.batchList?.data) {
      if (props?.getBatch?.batchList?.data.length == 0) {
        notify("No data found.", true);
        props.clearBatch("GET_BATCH_RESET");
        setBatchData('');
      } else {
        setBatchData(props?.getBatch?.batchList?.data);
      }
    }
    if (props?.getBatch?.error) {
      notify(props?.getBatch?.error?.message, true);
      props.clearBatch("GET_BATCH_RESET");
    }
  }, [props?.getBatch]);

  const emptyStudent = () => {
    getUsers();
  };

  const getUsers = (orderBy) => { };
  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getBatchList();
    } else {
      setSearch(true);
      props.getBatchList(studentSearchValue);
    }
  };



  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      {progressVisible ? (
        <>
          <Progress color="info" value={progressValue} style={{ height: 4 }} />
        </>
      ) : (
        <>
          <main id="main"  className="content">
            <div className="react-notification-alert-container">
              <NotificationAlert ref={notification} />
            </div>
            <Modal
              isOpen={deleteModal}
              color="primary"
              style={{
                display: "flex",
                flex: 1,
                top: -40,
                width: 250,
                backgroundColor: "#132639",
                objectFit: "fill",
              }}
            >
              <ModalBody size="sm">
                <DeleteBatch
                  setTrainingArr={setTrainingArr}
                  notify={notify}
                  id={currentBatchId}
                  setDeleteModal={setDeleteModal}
                ></DeleteBatch>
              </ModalBody>
            </Modal>
            <Modal
              isOpen={open}
              style={{ display: "flex", flex: 1, minWidth: "85%" }}
            >
              <ModalBody
                style={{
                  width: "100%",
                  display: "inline-block",
                  padding: "0px !important",
                  position: "absolute",
                  border: "1px solid white !important",
                }}
                size="sm"
              >
                <Add_Edit_Batch
                  currentBatch={currentBatch}
                  setBatchData={setBatchData}
                  notify={notify}
                  handleClose={handleClose}
                />
              </ModalBody>
            </Modal>
            <Modal
              isOpen={assignModuleModal}
              style={{
                display: "flex",
                flex: 1,
                minWidth: "85%",
                position: "absolute",
                left: "50%",
                transform: " translateX(-47%)",
              }}
            >
              <ModalBody
                style={{
                  width: "100%",
                  display: "inline-block",
                  padding: "0px !important",
                  position: "absolute",
                  border: "1px solid white !important",
                }}
                size="sm"
              >
                <AssignModuleTrainer
                  id={currentBatchId}
                  notify={notify}
                  controllModal={setAssignModuleModal}
                  relationList={relationList}
                />
              </ModalBody>
            </Modal>

            <Modal
              isOpen={bulkDeleteVisible}
              color="primary"
              style={{
                display: "flex",
                flex: 1,
                top: 0,
                width: 250,
                backgroundColor: "#132639",
                objectFit: "fill",
              }}
            >
              <ModalBody style={{ width: "100%" }} size="sm">
                <BulkDeleteTraining
                  trainingArr={trainingArr !== undefined && trainingArr}
                  setTrainingArr={setTrainingArr}
                  notify={notify}
                  onClick={() => {
                    setBulkDeleteVisible(false);
                  }}
                ></BulkDeleteTraining>
              </ModalBody>
            </Modal>

            <Dialog
              fullScreen
              open={fullDialogOpen}
              onClose={handlefullToggle}
              TransitionComponent={Transition}
            >
              <AddMaterial
                notifi={notify}
                currentBatchId={currentBatchId}
                handleClose={handlefullToggle}
              />
            </Dialog>
            <Row>
              <Col md="12">
                <Card
                  style={{
                    fontSize: font?.fontSize || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    backgroundColor: colorTheme?.bodyColor || "all",
                  }}
                >
                  <CardHeader
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >

                    <h1
                      style={{
                        fontSize: font?.fontSize + 4 || "inherit",
                        fontWeight: font?.fontWeight || "inherit",
                        margin: "0 auto 0 0",
                        textTransform: "capitalize",
                      }}

                    >
                      TRAINING MANAGEMENT
                    </h1>


                    <Button
                      title="Add Training"
                      color="info"
                      // style={{ float: 'right' }}
                      onClick={() => {
                        setcurrentBatch("");
                        handleClickOpen();
                      }}
                    >
                      Add Training <Add />
                    </Button>
                  </CardHeader>
                  <Row style={{ justifyContent: "flex-end" }}>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      sm={6}
                    >
                      <div
                        style={{
                          position: "relative",
                          top: "-5px",
                          marginRight: "5px",
                        }}
                      ></div>
                    </Col>
                  </Row>
                  <Row style={{ marginLeft: "7px" }}>
                    <Col sm={4}>
                      <Input
                        title="Search Training"
                        type="search"
                        name="search"
                        placeholder="Search Training"
                        onChange={(e) => (
                          setStudentSearchValue(e.target.value),
                          e.target.value.length === 0 && emptyStudent(),
                          setSearch(false)
                        )}
                      />
                    </Col>
                    <Col style={{ paddingLeft: "0px" }} sm={2}>
                      <Button
                        title="Search"
                        style={{ margin: "0px", padding: "6px 10px" }}
                        color="info"
                        onClick={studentSearch}
                      >
                        <SearchIcon />
                      </Button>
                    </Col>
                    <Col
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      sm={6}
                    >
                      {
                        trainingArr.length > 0 ?
                          <Button
                       title="Delete Marked"
                        aria-label="Delete Marked"
                            style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                            color="danger"
                            onClick={() => {
                              setBulkDeleteVisible(true);
                            }}
                          >
                            Delete Marked
                          </Button>
                          :
                          null
                      }
                    </Col>
                  </Row>

                  <CardBody>
                    <Table className="tablesorter">
                      <thead className="text-primary">
                        <tr>
                          <th></th>
                          <th>Training Name</th>
                          <th style={{ textAlign: "center" }}>Period</th>
                          <th style={{ textAlign: "center" }}>
                            Vacancy status
                          </th>
                          <th style={{ textAlign: "center" }}>Module</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {batchData && batchData.length > 0 ? (
                          batchData.map((item) => {
                            return (
                              <tr
                                key={item.itemID}
                                style={{
                                  borderBottom: `1px solid ${colorTheme?.borderColor ||
                                    "rgba(255, 255, 255, 0.1)"
                                    }`,
                                }}
                              >
                                <td style={{
                                  display: 'flex',
                                  paddingLeft: '20px',
                                  color: 'white',
                                  marginTop: "1px"

                                }}>
                                  <Input
                                    id={item?.id}
                                    defaultChecked={false}
                                    type='checkbox'
                                    key={item?.id}
                                    value={item?.id}
                                    onChange={handleCheckbox}

                                  />
                                </td>
                                <th><label
                                 style={{
                                  fontSize: font?.fontSize || "inherit",
                                  fontWeight: font?.fontWeight || "inherit",
                                  color:"#FFFFFFB3"
                                }}
                                htmlFor={item?.id}>{item.title}</label></th>
                                <td style={{ textAlign: "center" }}>
                                  {item.startDate
                                    ? format(
                                      new Date(item.startDate),
                                      "dd/MM/yyyy"
                                    )
                                    : "N/A"}
                                  {" to "}
                                  {item.endDate
                                    ? format(
                                      new Date(item.endDate),
                                      "dd/MM/yyyy"
                                    )
                                    : "N/A"}
                                </td>
                                <td>
                                  <p
                                    style={{ textAlign: "center" }}
                                  >{`${item.occupiedSpot} / ${item.totalSpot}`}</p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    style={actionBtnStyle}
                                    aria-label="manage relation"
                                    title="manage relation"
                                    onClick={() => {
                                      setAssignModuleModal(true);
                                      setcurrentBatchId(item.id);
                                      setRelationList(
                                        item.batchModuleRelations
                                      );
                                    }}
                                  >
                                    <LibraryBooksIcon
                                      style={{ color: "white" }}
                                    />
                                  </button>
                                </td>

                                <td
                                  style={{ textAlign: "center", minWidth: 70 }}
                                >
                                  <button
                                    aria-label="Edit"
                                    title="Edit"
                                    style={actionBtnStyle}
                                    onClick={() => {
                                      setcurrentBatch(item);
                                      handleClickOpen();
                                    }}
                                  >
                                    <Edit style={{ color: "#247cf7" }} />
                                  </button>

                                  <button
                                    aria-label="Delete"
                                    title="Delete"
                                    style={actionBtnStyle}
                                    onClick={() => {
                                      setcurrentBatchId(item.id);
                                      setDeleteModal(true);
                                    }}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>No data to display</tr>
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </main>
        </>
      )}
      {props?.getBatch?.isLoading ||
        props?.createBatchRes?.isLoading ||
        props?.editBatchRes?.isLoading ||
        props?.getModuleRes?.isLoading ||
        props?.getTrainerRes?.isLoading ||
        props?.batchRelationRes?.isLoading ||
        props?.editRelationRes?.isLoading ||
        props?.deleteBatchRes?.isLoading ||
        props?.trainingBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  getBatch: state.getBatch,
  createBatchRes: state.createBatch,
  editBatchRes: state.editBatch,
  deleteBatchRes: state.deleteBatch,
  trainingBulkDeleteRes: state.trainingBulkDelete,
  getModuleRes: state.getModule,
  fontData: state.fontValues,
  colorData: state.colorValues,
  editRelationRes: state.editRelation,
  getTrainerRes: state.getTrainer,
  batchRelationRes: state.batchRelation,
});

const mapDispatchToProps = (dispatch) => ({
  getBatchList: (payload) => {
    dispatch(getBatchAction(payload));
  },
  deleteBatch: (payload) => {
    dispatch(deleteBatchAction(payload));
  },
  clearBatch: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchList);
