import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import {
  deleteBatchAction,
  getBatchAction,
} from "store/action/batchActions/batchActions";

import { clearResponse } from "store/action/clearResponseAction";

const DeleteBatch = ({ id, notify, setDeleteModal,setTrainingArr, ...props }) => {
  const deleteRecord = () => {
    const deleteUrl = `api/batch?id=${id}`;
    props.deleteBatch(deleteUrl);
  };

  useEffect(() => {
    if (props?.deleteBatchRes?.deleteBatchData) {
      props.getBatchList();
      notify(props?.deleteBatchRes?.deleteBatchData?.data?.message);
      props.clearResponse("DElETE_BATCH_RESET");
      setTrainingArr([])
      setDeleteModal(false);
    }
  }, [props?.deleteBatchRes]);

  const onclick = () => {
    setDeleteModal(false);
  };
  return (
    <div>
      <p>Do you want to delete this Training</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button title="Yes" color="info" onClick={deleteRecord}>
          Yes
        </Button>

        <Button title="No" onClick={onclick}>
          No
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  deleteBatchRes: state.deleteBatch,
  getBatch: state.getBatch,
});

const mapDispatchToProps = (dispatch) => ({
  deleteBatch: (payload) => {
    dispatch(deleteBatchAction(payload));
  },
  getBatchList: () => {
    dispatch(getBatchAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteBatch);
