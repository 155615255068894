import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { handleRequestAction } from "store/action/requestAction/requestAction";

function RequestManagementModal({ handleClose, ...props }) {
  const { register, handleSubmit, errors } = useForm();
  const [date, setDate] = useState({});
  const [font, setFont] = useState({});

  // const handleChange = (e) => {
  //     e.persist();
  //     const name = e.target.name;
  //     const value = e.target.value;
  //     setDate({ ...date, [name]: value });

  // };

  // const submitData = (data) => {
  //     data.progress = data.lastSubmissionDate ? 'accepted' : 'rejected'
  //     data.lastSubmissionDate = data.lastSubmissionDate ? data.lastSubmissionDate : 'null'
  //     data.studentAssignmentExtensionsId = props.id
  //     props.handleRequest(data);
  // }

  const handleApprove = (data) => {
    data.progress = "Accepted";
    data.studentAssignmentExtensionsId = props.extensionId || null;
    props.handleRequest(data);
  };

  const handleDecline = () => {
    let data = {};
    data.progress = "Rejected";
    data.studentAssignmentExtensionsId = props.extensionId || null;
    props.handleRequest(data);
    handleClose();
  };
  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  return (
    <Row>
      <Col md="12">
        <Card style={{ margin: 0, background: 'inherit' }}>
          <CardHeader>
            <h2
              className="title"
              style={{
                fontSize: font?.fontSize + 2 || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                textTransform: "capitalize",
              }}
            >
              REQUEST MANAGEMENT
            </h2>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(handleApprove)}>
              <Row>
                <Col className="pr-md-1" md="6">
                  <FormGroup>
                    <label htmlFor="Last Submission Date">
                      Last Submission Date
                    </label>
                    <Input
                      // onChange={handleChange}
                      id="Last Submission Date"
                      innerRef={register({ required: true })}
                      name="lastSubmissionDate"
                      placeholder="Start From"
                      type="date"
                    />
                      {errors.lastSubmissionDate &&
                        errors.lastSubmissionDate.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter last Submission Date
                          </span>
                        )}
                  </FormGroup>
                </Col>
              </Row>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  
                    <Button  title="Approve" aria-label="Approve"color="info" type="submit" onClick={handleApprove}>
                      Approve
                    </Button>
                  
                  <Button
                    title="Decline"
                    aria-label="Decline"
                    color="danger"
                    type="button"
                    onClick={handleDecline}
                  >
                    Decline
                  </Button>
                </div>
          
                  <Button title="Back" aria-label='Back' type="button" onClick={handleClose}>
                    Back
                  </Button>
     
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state, ownProps) => ({
  handleRequestData: state.handleRequest,
});

const mapDispatchToProps = (dispatch) => ({
  handleRequest: (payload) => {
    dispatch(handleRequestAction(payload));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestManagementModal);
