import React, { useState, useRef } from "react";
import { Table, Button, Row, Col, FormGroup, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import InputComp from "components/InputComp/InputComp";
import TitleIdDropdown from "components/TitleIdDropdown/TitleIdDropdown";
import { useEffect } from "react";
import { getUserType } from "Utilities/common/commonFn";
import { Add, Delete } from "@material-ui/icons";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import { notify } from "Utilities/common/commonFn";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";

const AddAssignmentRel = ({
  assignmentList,
  handleBack,
  callApi,
  batchId,
  moduleId,
  batModAssignId,
  assignmentData,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [newData, setNewData] = useState("");
  const [error, setError] = useState(false);
  const [newAssignmentData, setNewAssignmentData] = useState([]);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const notification = useRef();

  let optionRef = useRef();
  let dateRef = useRef();
  let remarkRef = useRef();
  const notifi = useRef();

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    notification.current.notificationAlert(options);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewData({ ...newData, [name]: value });
  };

  const handleAddAssignment = () => {
    if (
      newData.lastDate &&
      newData.assignmentId &&
      newData.remarks &&
      !newAssignmentData
        .map((item, i) => {
          return `${item.assignmentId}`;
        })
        .includes(newData.assignmentId)
    ) {
      notify("Assignment added", false);
      const filterData = assignmentList.filter(
        (item, i) => item.id == newData.assignmentId
      );
      newData.title = filterData[0].title;
      newData.lastDate = newData.lastDate.replace(".000Z", "").replace("T", " ")
      setError(false);
      setNewAssignmentData([...newAssignmentData, newData]);
      setNewData({});
      optionRef.current.value = "";
      dateRef.current.value = "";
      remarkRef.current.value = "";
    } else {
      checkValidation();
    }
  };

  const checkValidation = () => {
    if (!newData.assignmentId) {
      setError(true);
    } else if (!newData.lastDate) {
      setError(true);
    } else if (!newData.remarks) {
      setError(true);
    } else if (
      newAssignmentData
        .map((item, i) => {
          return `${item.assignmentId}`;
        })
        .includes(newData.assignmentId)
    ) {
      setError(true);
    }
  };

  const handleDeleteAssignment = (id) => {
    setNewAssignmentData([
      ...newAssignmentData.filter((item, i) => {
        return item.assignmentId != id;
      }),
    ]);
    notify("Assignment deleted", true);
  };

  // const submitHandler = (payload) => {
  //   payload.batModAssignId = batModAssignId;
  //   // payload.assignmentId = +newData.assignmentId || data?.assignment?.id;

  //   callApi(payload);
  // };

  const handleSubmitEdit = (data) => {
    data.batModAssignId = batModAssignId;
    // data.batchId = batchId;
    // data.moduleId = moduleId;
    // if (data?.remarks) {
    //   data.remarks = +data?.remarks;
    // }
    if (data?.remarks) {
      delete data.remarks;
    }
    if (data?.lastDate) {
      delete data.lastDate;
    }
    if (data?.assignmentId) {
      delete data.assignmentId;
    }
    if (data?.teacherId) {
      data.teacherId = +data?.teacherId;
    }
    // if (data?.quizId) {
    //   data.quizId = +data?.quizId;
    // }
    if (newAssignmentData) {
      data.assignment = newAssignmentData;
    }
    // if (data?.assignmentId) {
    //   data.assignmentId = +data?.assignmentId;
    // }
    callApi(data);
  };

  useEffect(() => {
    setNewAssignmentData(
      ...newAssignmentData,
      assignmentData?.map((item, i) => {
        return {
          title: item?.assignment?.title,
          assignmentId: item.assignment.id,
          lastDate: item.lastDate.replace(".000Z", "").replace("T", " "),
          remarks: item.remarks,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div>
        <NotificationAlert ref={notification} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-between",
          gap: "20px",
          marginTop: "12px",
          width: "80vw",
          fontSize: font?.fontSize || "inherit",
          fontWeight: font?.fontWeight || "inherit",
          backgroundColor: colorTheme?.bodyColor || "all",
        }}
      >
        <Form
          onSubmit={handleSubmit(handleSubmitEdit)}
          style={{ width: "30%" }}
        >
          <FormGroup col style={{ flex: "2", paddingBottom: "10px" }}>
            <>
              <h2
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                Assignment relation
              </h2>
              {error &&
              newAssignmentData
                .map((item, i) => {
                  return `${item.assignmentId}`;
                })
                .includes(newData.assignmentId) ? (
                <span style={{ color: "red", fontSize: "13px" }}>
                  Same data are not allowed
                </span>
              ) : null}
              <Row>
                <Col>
                  {assignmentList && (
                    <TitleIdDropdown
                      // selectedValue={
                      //   assignmentData?.batchAssignmentRelations[0]?.assignment?.title
                      // }
                      forName="assignment"
                      label="Assignment"
                      heading="Select Assignment"
                      arr={assignmentList}
                      inputName="assignmentId"
                      onChange={handleChange}
                      innerRef={optionRef}
                      // innerRef={register({ required: true })}
                      errors={errors}
                    />
                  )}
                  {error && !newData.assignmentId ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Assignment title is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={assignmentData?.batchAssignmentRelations[0]?.lastDate?.replace(
                    //   '.000Z',
                    //   ''
                    // )}
                    label="Last date"
                    name="lastDate"
                    type="datetime-local"
                    innerRef={dateRef}
                    // innerRef={register({ required: true })}
                    errors={errors}
                    placeholder="Last date"
                    onChange={handleChange}
                  />
                  {error && !newData.lastDate ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Last Date is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   assignmentData?.batchAssignmentRelations[0]?.remarks
                    // }
                    label="Remarks"
                    name="remarks"
                    type="text"
                    innerRef={remarkRef}
                    // innerRef={register({ required: true })}
                    errors={errors}
                    placeholder="Remarks"
                    onChange={handleChange}
                  />
                  {error && !newData.remarks ? (
                    <span style={{ color: "red", fontSize: "13px" }}>
                      Remark is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    title="Add Assignment"
                    aria-label="Add Assignment"
                    color="info"
                    type="button"
                    onClick={handleAddAssignment}
                  >
                    Add Assignment <Add />
                  </Button>
                </Col>
              </Row>
            </>
          </FormGroup>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              title="Submit"
              aria-label="Submit"
              color="info"
              type="submit"
            >
              Submit
            </Button>
            <Button title="Return" aria-label="Return" onClick={handleBack}>
              Return
            </Button>
          </div>
        </Form>

        <div
          style={{
            flex: "3",
            borderLeft: "1px solid rgb(41, 56, 74)",
            paddingLeft: "10px",
          }}
        >
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th style={{ textAlign: "center" }}>Assignment</th>
                <th style={{ textAlign: "center" }}>Last date</th>
                <th style={{ textAlign: "center" }}>Remark</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {newAssignmentData?.map((item, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td style={{ textAlign: "center" }}>{item?.title}</td>
                      <td style={{ textAlign: "center" }}>{item.lastDate}</td>
                      <td style={{ textAlign: "center" }}>{item.remarks}</td>

                      <td style={{ textAlign: "center" }}>
                        <div title="Delete">
                          <button
                            title="Delete"
                            type="button"
                            onClick={() =>
                              handleDeleteAssignment(item.assignmentId)
                            }
                            style={actionBtnStyle}
                            aria-label="Delete relation"
                          >
                            <Delete
                              style={{ color: "red", cursor: "pointer" }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
  colorData: state.colorValues,
});

export default connect(mapStateToProps)(AddAssignmentRel);
