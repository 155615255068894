import { Add } from '@material-ui/icons';
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Route, Redirect, withRouter, NavLink } from 'react-router-dom';

import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
  ModalBody,
  Modal,
} from "reactstrap";
import Book_Edit_Step1 from "./Book_Add_Step/Book_Edit_Step1";
import AssignmentList from "./AssignmentList";
import AddAssignment from "./AddAssignment";
//import {db} from '../MY_LOGIN_AND_SIGNUP/db/config'
import NotificationAlert from 'react-notification-alert';
import { connect } from "react-redux";

function Assignment(props) {
  const [headFootVisible, setHeadFootVisible] = useState(true);
  const [visible, setVisible] = useState(false);
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(5);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const [addVissible, setAddVissible] = useState(false);

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getAdminList();
    } else {
      setSearch(true);
      props.getAdminList(studentSearchValue);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', detectKeyDown, true)
    return () => window.removeEventListener("keydown", detectKeyDown);
  })

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key)
    if((e.metaKey || e.ctrlKey) && e.code === 'KeyC'){
      setAddVissible(!addVissible)
    }
  }

  const notifi = useRef();

  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Sucess" : "Error"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };
  const emptyStudent = () => {
    getUsers();
  };

  const getUsers = (orderBy) => {};

  const history = useHistory();
  if (!localStorage.getItem('user')) history.push('/login');

  useEffect(()=>{
    document.title="Assignment Management"
  },[])

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);
  
  useEffect(() => {
    if (props?.colorData){
      setColorTheme(props?.colorData?.colorData?.data);
    } 
  }, [props?.colorData]);

  return (
    <>
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notifi} />
      </div>

      <main id="main" 
        className="content"
        style={{ objectFit: "fill", overflow: "hidden" }}
      >
        {/* <Row style={{}}>
            <Col md="8">
              <Card style={{}}>
                <CardHeader>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
                  <h4 className="title" style={{marginRight:300,fontWeight:450}}>BOOK MANAGEMENT</h4>
                  <Button color='info' onClick={()=>{

                  }}><NavLink to='/admin/book-step-1' style={{color:'white'}}> Add Book </NavLink></Button>
                 </div>
                </CardHeader>
                <CardBody style={{}}>
                </CardBody>
               </Card>
            </Col>
           </Row>  */}
        <Row style={{ objectFit: 'fill', overflow: 'hidden' }}>
          <Col md='12'>
            <div
            style={{display:'flex', justifyContent:'space-between'}}>
              <Card style={{ fontSize: font?.fontSize || 'inherit',
                  fontWeight: font?.fontWeight || 'inherit',
                  backgroundColor: colorTheme?.bodyColor || 'all'}}>
                <>
                  <CardHeader
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      
                      style={{
                        fontSize: font?.fontSize + 4 || "inherit",
                        fontWeight: font?.fontWeight || "inherit",
                        margin:"0 auto 0 0",
                        textTransform:"capitalize"
                      }}
                    >
                      ASSIGNMENT MANAGEMENT
                    </h1>
                    {/* <NavLink
                        to={{
                          pathname: '/admin/book-step-1',
                          setVisible: setVisible,
                        }}
                        exact
                        style={{ color: 'white' }}
                      >
                        {' '} */}
                    <Button
                      title="Add Assignment"
                      onClick={() => setAddVissible(true)}
                      color="info"
                    >
                      Add Assignment <Add />
                    </Button>{" "}
                    {/* </NavLink> */}
                  </CardHeader>
                </>

                {visible === false && <CardHeader></CardHeader>}
                <CardBody style={{ paddingTop: "0px" }}>
                  <Col>
                    <Row>
                      <AssignmentList
                        setProgressVisible={setProgressVisible}
                        setVisible={setVisible}
                        setProgressValue={setProgressValue}
                      ></AssignmentList>
                    </Row>
                  </Col>
                </CardBody>
                {visible === false && <CardFooter></CardFooter>}
              </Card>
            </div>
          </Col>
        </Row>
        <Modal
          isOpen={addVissible}
          style={{
            display: "flex",
            flex: 1,
            minWidth: "85%",
            position: "absolute",
            left: "50%",
            transform: " translateX(-47%)",
          }}
        >
          <ModalBody
            style={{
              width: "100%",
              display: "inline-block",
              padding: "0px !important",
              position: "absolute",
              border: "1px solid white !important",
            }}
          >
            <AddAssignment
              // deletedBook={editBook !== undefined && editBook}
              // // setVisible={setVisible}
              // setBoookData={setBoookData}
              notify={notify}
              onClick={() => {
                setAddVissible(false);
              }}
            />
          </ModalBody>
        </Modal>
      </main>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
  colorData: state.colorValues,
});
export default connect(mapStateToProps)(Assignment);
