import * as actionTypes from '../../action/actionType';

const intialState = {};

export const loginReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQ:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userData: action.payload,
      };
    case actionTypes.LOGIN_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case actionTypes.LOGIN_RESET:
      return {};
    default:
      return state;
  }
};
