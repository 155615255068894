import axiosInstances from 'axiosInstance/axiosInstance';
import fileInstance from 'axiosInstance/axiosInstance';

export const postRequest = async (url, payload) => {
  const data = await axiosInstances.post(url, { ...payload });
  return data;
};

export const postFormRequest = async (url, payload) => {
  const data = await fileInstance.post(url, payload);
  return data;
};
export const uploadFileWithProgress = async (url, payload, setProgress) => {
  const data = await fileInstance.post(url, payload, {
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 50;
      setProgress(progress);
      console.log(progress, 'progress');
      debugger;
    },
  });
  debugger;
  return data;
};

export const getRequest = async (url) => {
  const data = await axiosInstances.get(url);
  return data;
};

export const putRequest = async (url, payload) => {
  const data = await axiosInstances.patch(url, { ...payload });
  return data;
};

export const deleteRequest = async (url) => {
  const data = await axiosInstances.delete(url);
  return data;
};
