/*!

========================================================= 
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Input as MInput,
  withStyles,
  Menu,
} from "@material-ui/core";

//import { Modal } from '@material-ui/core';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Input,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Edit, Delete, Add, Tune } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import GroupIcon from "@material-ui/icons/Group";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";

import Add_Student from "./TrainerAdd";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import TrainerEditForm from "./TrainerEditForm";
import DeleteModal from "./Delete_Modal";
import NotificationAlert from "react-notification-alert";
import firebase from "firebase";

import { CSVLink } from "react-csv";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AddMaterialStudent from "./AddMaterialStudent";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";

import { connect } from "react-redux";
import { getTrainersAction } from "store/action/trainerActions/trainerActions";
import { clearResponse } from "store/action/clearResponseAction";
import BulkDeleteTrainer from "./BulkDeleteTrainer";

import Loader from "components/Loader/Loader";

import { useHistory } from "react-router-dom";

import { actionBtnStyle } from "Utilities/common/commonStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function TrainerTable(props) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };
  const [limit, setLimit] = useState(10);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };

  const handleClose = () => {
    setFilter(0);
    setAnchorEl(null);
  };

  const handleCloseSort = () => {
    setFilter(0);
    setAnchorElSort(null);
  };

  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [userData, setUserData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [enroll, setEnroll] = useState(null);
  const [editUser, setEditUser] = useState();
  const [deleteUserVisible, setDeleteUserVisible] = useState(false);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);
  const [deletedUser, setDeletedUser] = useState();

  const [batchList, setBatchList] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [filter, setFilter] = useState(0);
  const [fullDialogOpen, setFullDialogOpen] = useState(false);
  const [currentStuId, setcurrentStuId] = useState("");
  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const studentSearchIndex = searchClient.initIndex("Student_Search");
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [userArr, setUserArr] = useState([]);

  const history = useHistory();

  const [search, setSearch] = useState(false);
  const [sortByErollment, setSortByErollment] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const handlefullToggle = () => {
    setFullDialogOpen((prev) => !prev);
  };
  const headers = [
    { label: "Enrollment No.", key: "enrollment" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Email", key: "email" },
    { label: "Mobile No.", key: "mobile" },
    { label: "Status", key: "status" },
    { label: "Address", key: "address" },
    { label: "Batch", key: "batchName" },
  ];

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  useEffect(() => {
    document.title = "Trainer Management";
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
  };

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getTrainerList();
    } else {
      setSearch(true);
      props.getTrainerList(studentSearchValue);
    }
  };

  const getBatch = () => {
    db.collection("Batch")
      .where("type", "==", "batch")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs.map((doc) => doc.data());
        setBatchList(data);
      });
  };

  useEffect(() => {
    if (props?.getTrainerData?.trainerList?.data) {
      props.getTrainerList();
    }
  }, []);

  useEffect(() => {
    if (props?.getTrainerData?.trainerList?.data) {
      if (props?.getTrainerData?.trainerList?.data.length == 0) {
        notify("No data found.", true);
        setUserData("");
        props.clearResponse("GET_TRAINER_RESET");
      } else {
        setUserData(props?.getTrainerData?.trainerList?.data);
      }
    }
    if (props.getTrainerData?.error) {
      notify(props.getTrainerData?.error?.message, true);
      props.clearResponse("GET_TRAINER_RESET");
    }
  }, [props?.getTrainerData]);

  const filterSearch = (data) => {};

  const filterBatchSearch = (data) => {};

  const nextSet = () => {};

  const prevSet = async () => {};

  const batchDataUpdate = (newBatch, action, id, batchName) => {};

  useEffect(() => {
    if (props?.editTrainerRes?.editTrainerData?.data?.message) {
      props.getTrainerList();
      notify(props?.editTrainerRes?.editTrainerData?.data?.message, false);
      props.clearResponse("EDIT_TRAINER_RESET");
      setEditVisible(false);
    }
    if (props.editTrainerRes?.error) {
      notify(props.editTrainerRes?.error?.message, true);
      props.clearResponse("EDIT_TRAINER_RESET");
    }
  }, [props?.editTrainerRes?.editTrainerData?.data]);

  useEffect(() => {
    if (props?.deleteTrainerRes?.deleteTrainerData?.data) {
      props.getTrainerList();
      notify(props?.deleteTrainerRes?.deleteTrainerData?.data?.message, false);
      props.clearResponse("DELETE_TRAINER_RESET");
      setDeleteUserVisible(false);
    }
    if (props.deleteTrainerRes?.error) {
      notify(props.deleteTrainerRes?.error?.message, true);
      props.clearResponse("DELETE_TRAINER_RESET");
    }
  }, [props?.deleteTrainerRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  // const emptyStudent = () => {
  //   getUsers();
  // };
  if (!localStorage.getItem("user")) history.push("/login");
  return (
    <>
      {/* {progressVisible ? (
        <Progress color='info' value={progressValue} style={{ height: 4 }} />
      ) :  */}
      (
      <main id="main" className="content" style={{ marginTop: "-21px" }}>
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <h1
                  style={{
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    margin: "0 auto 0 0",
                    textTransform: "capitalize",
                  }}
                >
                  TRAINER MANAGEMENT
                </h1>

                <Button
                  title="Create Trainer"
                  color="info"
                  // style={{ marginRight: 25 }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Create Trainer <Add />
                </Button>
              </CardHeader>
              {/* <CardBody style={{ paddingTop: '10px' }}> */}
              <Row style={{ marginLeft: "5px" }}>
                <Col sm={4}>
                  <Input
                    title="Search Trainer"
                    type="search"
                    name="search"
                    placeholder="Search Trainer"
                    onChange={(e) => (
                      setStudentSearchValue(e.target.value),
                      e.target.value.length === 0, //&& emptyStudent(),
                      setSearch(false)
                    )}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    title="Search"
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    onClick={studentSearch}
                  >
                    <SearchIcon />
                  </Button>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  sm={6}
                >
                  {userArr.length > 0 ? (
                    <Button
                      title="Delete Marked"
                      aria-label="Delete Marked"
                      style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                      color="danger"
                      onClick={() => {
                        setBulkDeleteVisible(true);
                      }}
                    >
                      Delete Marked
                    </Button>
                  ) : null}
                </Col>
              </Row>
              <Modal
                isOpen={isModalVisible}
                style={{ display: "flex", flex: 1 }}
                size="lg"
              >
                <ModalBody
                  style={{
                    width: "100%",
                    display: "inline-block",
                    padding: "0px !important",
                    position: "absolute",
                    border: "1px solid white !important",
                  }}
                  size="sm"
                >
                  <Add_Student
                    notify={notify}
                    setModal={(val) => setIsModalVisible(val)}
                  ></Add_Student>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={bulkDeleteVisible}
                color="primary"
                style={{
                  display: "flex",
                  flex: 1,
                  top: 0,
                  width: 250,
                  backgroundColor: "#132639",
                  objectFit: "fill",
                }}
              >
                <ModalBody style={{ width: "100%" }} size="sm">
                  <BulkDeleteTrainer
                    userArr={userArr !== undefined && userArr}
                    setUserArr={setUserArr}
                    notify={notify}
                    onClick={() => {
                      setBulkDeleteVisible(false);
                    }}
                  ></BulkDeleteTrainer>
                </ModalBody>
              </Modal>

              <CardBody>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Trainer Name</th>
                      {/* <th style={{ textAlign: 'center' }}>ENROLL.No</th> */}
                      <th style={{ textAlign: "center" }}>Email</th>
                      <th style={{ textAlign: "center" }}>Mobile</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                      {/* <th style={{ textAlign: 'center' }}>Study Material</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.length ? (
                      userData.map((user, i) => {
                        if (
                          user.firstName === undefined &&
                          user.lastName === undefined
                        ) {
                          return null;
                        }
                        return (
                          <tr
                            key={user.id}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                                color: "white",
                                marginTop: "1px",
                              }}
                            >
                              <Input
                                id={user?.id}
                                defaultChecked={false}
                                type="checkbox"
                                key={user?.id}
                                value={user?.id}
                                onChange={handleCheckbox}
                              />
                            </td>
                            <th>
                              <label
                                htmlFor={user?.id}
                                style={{
                                  fontSize: font?.fontSize || "inherit",
                                  fontWeight: font?.fontWeight || "inherit",
                                  color: "#FFFFFFB3",
                                }}
                              >
                                {user.firstName + " " + user.lastName}
                              </label>
                            </th>

                            <td style={{ textAlign: "center" }}>
                              {user.email}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {user.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {user.status == 1 ? "Active" : "Inactive"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <button
                                title="Edit"
                                aria-label="Edit"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setEditVisible(true);
                                  setEditUser(user);
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>

                              <button
                                title="Delete"
                                aria-label="Delete"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setDeleteUserVisible(true);
                                  setDeletedUser(user);
                                }}
                              >
                                <Delete style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>No data to display</tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={editVisible}
          style={{ display: "flex", flex: 1, top: 0 }}
          size="lg"
        >
          <ModalBody
            style={{
              width: "100%",
              display: "inline-block",
              padding: "0px !important",
              position: "absolute",
              border: "1px solid white !important",
            }}
            size="sm"
          >
            <TrainerEditForm
              notify={notify}
              key={editUser?.id}
              userData={editUser}
              setUserData={setUserData}
              onClick={() => {
                setEditVisible(false);
              }}
            ></TrainerEditForm>
          </ModalBody>
        </Modal>
        <Modal isOpen={deleteUserVisible} style={{ width: 250 }}>
          <ModalBody style={{ width: "100%", padding: 0 }} size="sm">
            <DeleteModal
              setUserArr={setUserArr}
              notify={notify}
              setUserData={setUserData}
              id={deletedUser !== undefined && deletedUser.id}
              onClick={() => {
                setDeleteUserVisible(false);
              }}
            ></DeleteModal>
          </ModalBody>
        </Modal>
      </main>
      {props?.getTrainerData?.isLoading ||
      props?.createTrainerRes?.isLoading ||
      props?.editTrainerRes?.isLoading ||
      props?.deleteTrainerRes?.isLoading ||
      props?.trainerBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
      ){/* } */}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getTrainerData: state.getTrainer,
  createTrainerRes: state.createTrainer,
  editTrainerRes: state.editTrainer,
  deleteTrainerRes: state.deleteTrainer,
  trainerBulkDeleteRes: state.trainerBulkDelete,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainerList: (payload) => {
    dispatch(getTrainersAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerTable);
