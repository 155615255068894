import * as actionTypes from '../actionType';

import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const getRequestAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_REQUEST,
      payload: {},
      isLoading: true,
    });

    let url = 'api/assignment/extension'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {
       
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_REQUEST_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_REQUEST_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const handleRequestAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HANDLE_REQUEST,
      payload: {},
      isLoading: true,
    });

    putRequest('api/assignment/extension/lastSubmissionDate', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.HANDLE_REQUEST_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.HANDLE_REQUEST_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
