import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Table, Button, Row, Col, FormGroup, Form } from 'reactstrap';
import { Add, Delete } from '@material-ui/icons';
import { actionBtnStyle } from 'Utilities/common/commonStyle';
import InputComp from 'components/InputComp/InputComp';
import TitleIdDropdown from 'components/TitleIdDropdown/TitleIdDropdown';
import { useEffect } from 'react';
import { getUserType } from 'Utilities/common/commonFn';
import { connect } from 'react-redux';
import NotificationAlert from "react-notification-alert";

const AddQuiz = ({
  quizList,
  handleBack,
  callApi,
  batchId,
  moduleId,
  batModAssignId,
  data,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [newQuizData, setNewQuizData] = useState([]);
  const [newQData, setNewQData] = useState({});
  const [errorQ, setErrorQ] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const notification = useRef();

  let quizIdRef = useRef();
  let quizDateRef = useRef();
  let allocatedTimeRef = useRef();
  let marksPerQuestionRef = useRef();
  let negativeMarksRef = useRef();

  const notify = (msg, err) => {
    let color = err === true ? 'danger' : 'success';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 15,
    };
    notification.current.notificationAlert(options);
  };

  const handleChange1 = (e) => {
    const name = e.target.name;
    const value = e.target.value.replace('T', ' ');
    setNewQData({ ...newQData, [name]: value });
  };

  const checkQValidation = () => {
    if (!newQData.quizId) {
      setErrorQ(true);
    } else if (!newQData.quizDate) {
      setErrorQ(true);
    } else if (!newQData.allocatedTime) {
      setErrorQ(true);
    } else if (!newQData.marksPerQuestion) {
      setErrorQ(true);
    } else if (!newQData.negativeMarks) {
      setErrorQ(true);
    } else if (
      newQuizData
        .map((item, i) => {
          return `${item.quizId}`;
        })
        .includes(newQData.quizId)
    ) {
      setErrorQ(true);
    }
  };

  const handleAddQuiz = () => {
    if (
      newQData.quizId &&
      newQData.quizDate &&
      newQData.allocatedTime &&
      newQData.marksPerQuestion &&
      newQData.negativeMarks &&
      !newQuizData
        .map((item, i) => {
          return `${item.quizId}`;
        })
        .includes(newQData.quizId)
    ) {
      notify('Quiz added', false)
      const filterData = quizList.filter((item, i) => item.id == newQData.quizId)
      newQData.title = filterData[0].title
      newQData.quizDate = newQData.quizDate.replace(".000Z", "").replace("T", " ")
      newQData.marksPerQuestion = parseInt(newQData.marksPerQuestion)
      newQData.negativeMarks = parseInt(newQData.negativeMarks)
      newQData.quizId = parseInt(newQData.quizId)
      setErrorQ(false);
      setNewQuizData([...newQuizData, newQData]);
      setNewQData({});
      quizIdRef.current.value = '';
      quizDateRef.current.value = '';
      allocatedTimeRef.current.value = '';
      marksPerQuestionRef.current.value = '';
      negativeMarksRef.current.value = '';
    } else {
      checkQValidation();
    }
  };

  const handleDeleteQuiz = (id) => {
    setNewQuizData([
      ...newQuizData.filter((item, i) => {
        return item.quizId != id;
      }),
    ]);
    notify('Quiz deleted', true)
  };

  // const submitHandler = (payload) => {
  //   e.preventDefault();
  //   payload.quizId = +newData.quizId || data?.quizId;
  //   payload.batModAssignId = batModAssignId;
  //   callApi(payload);
  // };

  useEffect(() => {
    setNewQuizData(
      ...newQuizData,
      data?.map((item, i) => {
        return {
          title: item?.quiz?.title,
          quizId: item.quizId,
          quizDate: item.quizDate.replace('.000Z', '').replace('T', ' '),
          allocatedTime: `${item.allocatedTime}`,
          marksPerQuestion: item.marksPerQuestion,
          negativeMarks: item.negativeMarks,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const handleSubmitEdit = (datas) => {
    datas.batModAssignId = batModAssignId;
    // if (datas?.marksPerQuestion) {
    //   datas.marksPerQuestion = +datas?.marksPerQuestion;
    // }
    if (datas?.quizId) {
      delete datas.quizId;
    }
    if (datas?.quizDate) {
      delete datas.quizDate;
    }
    if (datas?.allocatedTime) {
      delete datas.allocatedTime;
    }
    if (datas?.negativeMarks) {
      delete datas.negativeMarks;
    }
    if (datas?.marksPerQuestion) {
      delete datas.marksPerQuestion;
    }
    if (newQuizData) {
      datas.quiz = newQuizData;
    }

    callApi(datas);
  };

  // useEffect(() => {
  //   if (relationList)
  //     relationList.map((relation) => {
  //       if (relation.module && relation.teacherModuleRelations[0].teacher) {
  //         setModuleId(relation.module.id);
  //         setTeacherId(relation.teacherModuleRelations[0].teacher.id);
  //       }
  //     });
  // }, []);

  return (
    <>
      <div>
        <NotificationAlert ref={notification} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-between',
          gap: '20px',
          marginTop: '12px',
          width: '80vw',
          fontSize: font?.fontSize || 'inherit',
          fontWeight: font?.fontWeight || 'inherit',
          backgroundColor: colorTheme?.bodyColor || 'all',
        }}
      >
        <Form onSubmit={handleSubmit(handleSubmitEdit)}>
          <FormGroup col style={{ flex: '2', paddingBottom: '10px' }}>
            <>
              <h2
              style={{
                fontSize: font?.fontSize + 2 || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                textTransform:"capitalize"
              }}
              >Quiz relation</h2>
              {errorQ &&
                newQuizData
                  .map((item, i) => {
                    return `${item.quizId}`;
                  })
                  .includes(newQData.quizId) ? (
                <span style={{ color: 'red', fontSize: '13px' }}>
                  Same data are not allowed
                </span>
              ) : null}

              <Row>
                <Col>
                  {quizList && (
                    <TitleIdDropdown
                      // selectedValue={
                      //   data?.quizBatchRelations[0]?.quiz?.title
                      // }
                      forName='quiz'
                      label='Quiz'
                      heading='Select Quiz'
                      arr={quizList}
                      inputName='quizId'
                      onChange={handleChange1}
                      innerRef={quizIdRef}
                      // innerRef={register({ required: false })}
                      errors={errors}
                    />
                  )}
                  {errorQ && !newQData.quizId ? (
                    <span style={{ color: 'red', fontSize: '13px' }}>
                      Quiz title is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={data?.quizBatchRelations[0]?.quizDate?.slice(
                    //   0,
                    //   10
                    // )}
                    label='Quiz date'
                    name='quizDate'
                    type='date'
                    innerRef={quizDateRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder='Quiz date'
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.quizDate ? (
                    <span style={{ color: 'red', fontSize: '13px' }}>
                      Quiz Date is required
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   data?.quizBatchRelations[0]?.allocatedTime
                    // }
                    label='Allocated time'
                    name='allocatedTime'
                    type='text'
                    innerRef={allocatedTimeRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder='Enter time in minute'
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.allocatedTime ? (
                    <span style={{ color: 'red', fontSize: '13px' }}>
                      Allocated Time is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   data?.quizBatchRelations[0]?.marksPerQuestion
                    // }
                    label='Marks per question'
                    name='marksPerQuestion'
                    type='text'
                    innerRef={marksPerQuestionRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder='Enter marks per question'
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.marksPerQuestion ? (
                    <span style={{ color: 'red', fontSize: '13px' }}>
                      Marks Per Question is required
                    </span>
                  ) : null}
                </Col>
                <Col>
                  <InputComp
                    // defaultValue={
                    //   data?.quizBatchRelations[0]?.negativeMarks
                    // }
                    label='Negative marking'
                    name='negativeMarks'
                    type='text'
                    innerRef={negativeMarksRef}
                    // innerRef={register({ required: false })}
                    errors={errors}
                    placeholder='Enter negative marking/question'
                    onChange={handleChange1}
                  />
                  {errorQ && !newQData.negativeMarks ? (
                    <span style={{ color: 'red', fontSize: '13px' }}>
                      Negative Marks is required
                    </span>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button title="Add Quiz" aria-label="Add Quiz"color='info' type='button' onClick={handleAddQuiz}>
                    Add Quiz <Add />
                  </Button>
                </Col>
              </Row>
            </>
          </FormGroup>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button title="Submit" aria-label="Submit" color='info' type='submit'>
              Submit
            </Button>
            <Button  title="Return" aria-label="Return"onClick={handleBack}>Return</Button>
          </div>
        </Form>
        <div
          style={{
            flex: '3',
            borderLeft: '1px solid rgb(41, 56, 74)',
            paddingLeft: '10px',
          }}
        >
          <Table className='tablesorter'>
            <thead className='text-primary'>
              <tr>
                <th style={{ textAlign: 'center' }}>Quiz</th>
                <th style={{ textAlign: 'center' }}>Quiz date</th>
                <th style={{ textAlign: 'center' }}>Allocated time</th>
                <th style={{ textAlign: 'center' }}>Marks per question</th>
                <th style={{ textAlign: 'center' }}>Negative marking</th>
                <th style={{ textAlign: 'center' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {newQuizData?.map((item, i) => {
                return (
                  <>
                    <tr key={i}>
                      <td style={{ textAlign: 'center' }}>
                        {/* {quizList?.map((itemm, i) => {
                          return (
                            itemm.id == item.quizId ? (itemm.title) : null
                          )
                        })} */}
                        {item?.title}
                      </td>
                      <td style={{ textAlign: 'center' }}>{item.quizDate}</td>
                      <td style={{ textAlign: 'center' }}>
                        {item.allocatedTime}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.marksPerQuestion}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.negativeMarks}
                      </td>

                      <td style={{ textAlign: 'center' }}>
                        <div title='Delete'>
                          <button
                            title="Delete"
                            type='button'
                            onClick={() => handleDeleteQuiz(item.quizId)}
                            style={actionBtnStyle}
                            aria-label='Delete relation'
                          >
                            <Delete style={{ color: 'red', cursor: 'pointer' }} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  fontData: state.fontValues,
  colorData: state.colorValues,
});

export default connect(mapStateToProps)(AddQuiz);
