import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";

import Select from "react-select";

import { connect } from "react-redux";

import { clearResponse } from "store/action/clearResponseAction";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config';
//installed this library
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import {
  editAdminAction,
  getAdminsAction,
} from "store/action/adminActions/adminActions";

import { getSecurityGroupAction } from "store/action/SecurityGroupAction/SecurityGroupAction";

function EditAdmin(props) {
  const { myUser, onClick, setUserData, notify } = props;
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();

  //added this for the options
  const options = [
    // { label: 'Dashboard', value: 'Dashboard' },
    { label: "User Management", value: "User_Management" },
    { label: "Student Management", value: "Student_Management" },
    { label: "Book Management", value: "Book_Management" },
    { label: "Quiz Management", value: "Quiz_Management" },
    { label: "Package Management", value: "Package_Management" },
    // { label: 'Transaction Management', value: 'Transaction_Management' },
    { label: "Batch Management", value: "Batch_Management" },
    { label: "Enquiry", value: "Enquiry" },
    { label: "Feedback", value: "Feedback" },
    { label: "Chat", value: "Chat" },
    { label: "Media Center", value: "Media_Center" },
    { label: "Discount Management", value: "Discount_Management" },
    { label: "Invoice_Management", value: "Invoice_Management" },
    { label: "Notification_Management", value: "Notification_Management" },
    // { label: 'Reports', value: 'Reports' },
  ];
  //added this for the state to pass to firebase
  const [selectedPermission, setselectedPermission] = useState(
    myUser.permissions
  );

  const [dataToEdit, setDataToEdit] = useState();
  const [securityGroups, setSecurityGroups] = useState("");
  const [selectedSecGrp, setSelectedSecGrp] = useState("No data");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  const handleChange = (e) => {
    // e.persist();
    const name = e.target.name;
    const value = e.target.value;

    setDataToEdit({ ...dataToEdit, [name]: value });
  };

  const handleEdit = () => {
    props.editAdmin(dataToEdit);
  };

  useEffect(() => {
    if (myUser) {
      setDataToEdit({
        firstName: myUser.firstName,
        lastName: myUser.lastName,
        secGrpId: myUser.secGrpId,
        email: myUser.email,
        mobile: myUser.mobile,
        userId: myUser.userId,
        status: myUser.status,
        userId: myUser.id,
      });
    }
    if (!props?.getSecurityGroupRes?.securityGroupList) {
      props.getSecurityGroupList();
    }
  }, []);

  useEffect(() => {
    if (props.editAdminRes.editAdminData) {
      notify("User updated successfully", false);
      props.clearResponse("EDIT_ADMIN_RESET");
      props.getAdminList();
      onClick();
    }
    if (props.editAdminRes?.error) {
      notify(props.editAdminRes?.error?.error?.message, true);
      props.clearResponse("EDIT_ADMIN_RESET");
    }
  }, [props?.editAdminRes]);

  useEffect(() => {
    if (props?.getSecurityGroupRes?.securityGroupList) {
      const groupList = props?.getSecurityGroupRes?.securityGroupList?.data;
      let arr = [];
      groupList.map((security) => {
        if (security.id === myUser.secGrpId) {
          setSelectedSecGrp(security.id);
        }
        arr.push({ label: security.name, value: security.id });
      });
      setSecurityGroups(arr);
    }
    if (props.getSecurityGroupRes?.error)
      notify(props.getSecurityGroupRes?.error?.message, true);
  }, [props?.getSecurityGroupRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div className="content">
      <Row>
        <Col>
          <Card
            style={{
              margin: 0,
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader>
              <h2
                className="title"
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform:"capitalize"
                }}
              >
                Edit User
              </h2>
            </CardHeader>
            <CardBody>
              <Form
                style={{}}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleEdit();
                }}
              >
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="FirstName">First Name</label>
                      <Input
                        id="FirstName"
                        defaultValue={myUser.firstName}
                        // value={dataToEdit.firstName}
                        // innerRef={register({ required: true })}
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="LastName">Last Name</label>
                      <Input
                        id="LastName"
                        defaultValue={myUser.lastName}
                        onChange={handleChange}
                        innerRef={register}
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col  md="6">
                    <FormGroup>
                      <label htmlFor="Email">Email</label>
                      <Input
                        id="Email"
                        defaultValue={myUser.email}
                        innerRef={register({ required: true })}
                        name="email"
                        onChange={handleChange}
                        placeholder="user@user.com"
                        type="email"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col  md="6">
                    <FormGroup>
                      <label htmlFor="Mobile">Mobile</label>
                      <Input
                        id="Mobile"
                        defaultValue={myUser.mobile}
                        innerRef={register({
                          minLength: 10,
                          maxLength: 10,
                          required: true,
                        })}
                        name="mobile"
                        onChange={handleChange}
                        placeholder="10-digit Number"
                        type="mobile"
                        required
                      />

                      {errors.mobile && errors.mobile.type !== "required" && (
                        <span
                          style={{
                            fontSize: "10px",
                            position: "absolute",
                            bottom: "-20px",
                            color: "red",
                          }}
                        >
                          Please Enter Valid Mobile No.
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  {/* <Col className='pl-md-1' md='6'>
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        defaultValue={myUser.password}
                        innerRef={register({ required: true, minLength: 6 })}
                        required
                        name='password'
                        onChange={handleChange}
                        type='password'
                        placeholder='Password'
                      />
                      {errors.password && errors.password.type === 'minLength' && (
                        <span
                          style={{
                            fontSize: '10px',
                            position: 'absolute',
                            bottom: '-20px',
                            color: 'red',
                          }}
                        >
                          Password should be atleast 6 digits.
                        </span>
                      )}
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col className="lg" md="6">
                    <FormGroup>
                      <label htmlFor="Account">Account Permission</label>
                      {/* Added this library */}
                      <Input
                        id="Account"
                        name="secGrpId"
                        onChange={handleChange}
                        placeholder="Select security group"
                        type="select"
                      >
                        {securityGroups &&
                          securityGroups.map((secGrp) => {
                            return (
                              <option
                                selected={
                                  secGrp.value === dataToEdit.secGrpId
                                    ? true
                                    : false
                                }
                                value={secGrp.value}
                              >
                                {secGrp.label}
                              </option>
                            );
                          })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1" md="6">
                    {/* <FormGroup>
                          <label style={{marginLeft:10}}>User Role</label>
                          
                         <Input defaultValue={myUser.role} type='select' name='role' innerRef={register({required:true})}>
                           <option>admin</option>
                           <option>superadmin</option>
                         </Input>
                        </FormGroup> */}
                  </Col>
                </Row>
                <div>
                  <Button
                    title="Save"
                    className="btn-fill"
                    color="info"
                    type="submit"
                  >
                    Save
                  </Button>

                  <Button title="Back" className="btn-fill" onClick={onClick}>
                    Back
                  </Button>

                  {/* {visible === false ? <Button className="btn-fill" color="info" type="submit">
                    Save
                  </Button> : <Button onClick={onClick} className="btn-fill" color="info">Done</Button>}
                  {visible === false && <Button className="btn-fill" onClick={onClick}>Back</Button>} */}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  editAdminRes: state.editAdmin,
  getAdminData: state.getAdmin,
  getSecurityGroupRes: state.getSecurityGroup,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editAdmin: (payload) => {
    dispatch(editAdminAction(payload));
  },
  getAdminList: () => {
    dispatch(getAdminsAction());
  },
  getSecurityGroupList: () => {
    dispatch(getSecurityGroupAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin);
