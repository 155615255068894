import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import rootReducer from './reducer/rootReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'login',

    'getActivity',

    'createAdmin',
    'getAdmin',
    'editAdmin',
    'deleteAdmin',
    'adminBulkDelete',

    'getTrainer',
    'createTrainer',
    'editTrainer',
    'deleteTrainer',
    'trainerBulkDelete',

    'getTrainersBatch',
    'getTrainersModule',
    'getTrainersTrainee',
    'updateModprogress',
    'updateTraineeprogress',
    'updateTraineeGrade',
    'uploadGrades',
    'uploadAudio',
    'uploadedMaterial',
    'deleteUploadedMaterial',

    'getSubmittedAssignment',
    'downloadSubmittedAssignment',
    'addEditQuiz',
    'deleteGrades',

    'getTrainee',
    'createTrainee',
    'editTrainee',
    'deleteTrainee',
    'traineeBatchRelation',
    'traineeBulkDelete',

    'getBatch',
    'createBatch',
    'editBatch',
    'deleteBatch',
    'batchRelation',
    'batchQuizRelation',
    'batchAssignmentRelation',
    'editRelation',
    'trainingBulkDelete',

    'getModule',
    'createModule',
    'editModule',
    'deleteModule',
    'moduleBulkDelete',

    'getSecurityGroup',
    'createSecurityGroup',
    'editSecurityGroup',
    'deleteSecurityGroup',
    'securityGroupBulkDelete',
    'getFunctions',
    'getActions',

    'getQuestion',
    'createQuestion',
    'editQuestion',
    'deleteQuestion',
    'questionBulkDelete',

    'getAssignment',
    'createAssignment',
    'editAssignment',
    'deleteAssignment',
    'assignmentBulkDelete',

    'createQuiz',
    'getQuiz',
    'editQuiz',
    'deleteQuiz',
    'quizBulkDelete',

    'getRequest',
    'handleRequest',

    'getCertificateRequest',
    'handleCertificateRequest',

    'getNotification',
    'editNotification',

    'fontValues',
    'colorValues',
    'getTraineeReport',

    'getFiles',
    'deleteFile',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
