import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CSVDownload } from 'react-csv';
import GetAppIcon from '@material-ui/icons/GetApp';
import { btnStyle } from 'Utilities/common/commonStyle';
import {
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Table,
  ButtonGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import { db } from 'MY_LOGIN_AND_SIGNUP/db/config';
import { Edit, Delete } from '@material-ui/icons';

import SearchIcon from '@material-ui/icons/Search';

import NotificationAlert from 'react-notification-alert';
import styles from '../../../components/Quiz_Management//quiz_Management.module.css';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import algoliasearch from 'algoliasearch/lite';
import { algoliaAppID, algoliaSearchID } from 'Utilities/algoliaKeys';
import ModalComp from 'components/ModalComp/ModalComp';
import RequestManagementModal from '../RequestManagement/RequestManagementModal';

import { clearResponse } from 'store/action/clearResponseAction';
import { getTraineeReportAction } from 'store/action/trainerActions/traineeReportAction';
import RemarkComp from './RemarkComp';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const TraineeReport = (props) => {
  const [limit, setLimit] = useState(100);

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const indexForQues = searchClient.initIndex('Questions_Search');
  const indexForQuiz = searchClient.initIndex('Quiz_Search');

  const notification = useRef();
  const classes = useStyles();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [reportData, setReportData] = useState('');
  const [extensionId, setExtensionId] = useState('');
  const [remarks, setRemarks] = useState([]);
  const [exportingExcel, setExportingExcel] = useState(false);
  const [csvData, setCsvData] = useState(false);
  const [inputData, setInputData] = useState('');

  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const nextSet = () => {};

  const prevSet = () => {};

  const nextQuizSet = () => {};

  const prevQuizSet = () => {};

  const notify = (msg, err) => {
    let color = err === true ? 'danger' : 'success';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err === true ? 'Error' : 'Success'}</b> -{msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // let batchId;

    // if (localStorage.getItem("batch")) {
    //   let batch = JSON.parse(localStorage.getItem("batch"));

    //   batchId = batch.id;
    // }

    props.getReport(props.batchId);
  }, []);

  useEffect(() => {
    if (exportingExcel) {
      let data = [
        ['STUDENT NAME', 'TRAINING', 'OBTAINED MARKS', 'MAX MARKS', 'REMARKS'],
      ];
      props.getTraineeReportData.traineeReportData.data.data.map((item, i) => {
        let newData = [
          item.name,
          item.title,
          item.totalMarksObtained,
          item.max_marks,
          item.remark,
        ];
        data.push(newData);
      });
      setCsvData(data);
      setRemarks([]);
      setTimeout(() => setCsvData(''), 1000);
      setTimeout(() => traineeHandler(), 2000);
    }
    setExportingExcel(false);
  }, [exportingExcel]);

  useEffect(() => {
    if (props?.getTraineeReportData?.traineeReportData) {
      setReportData(props?.getTraineeReportData?.traineeReportData?.data?.data);

      // props.clearResponse("GET_REQUEST_RESET");
    }
  }, [props?.getTraineeReportData]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const traineeHandler = () => {
    props.setOpenTraineeReport(false);
  };
  return (
    <div className='content'>
      {csvData && <CSVDownload data={csvData} target='_blank' />}
      <div style={{ width: '1200px', maxWidth: '100%' }}>
        <div className='react-notification-alert-container'>
          <NotificationAlert ref={notification} />
        </div>
        <Row>
          <Col md='12'>
            <Card
              style={{
                fontSize: font?.fontSize || 'inherit',
                fontWeight: font?.fontWeight || 'inherit',
                backgroundColor: colorTheme?.bodyColor || 'all',
              }}
            >
              <CardHeader className={styles.card_header}>
                <h1
                  
                  style={{
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    margin:"0 auto 0 0",
                    textTransform:"capitalize"
                  }}
                >
                  TRAINEE REPORT
                </h1>
                <Button
                  title='Export to excel'
                  aria-label='Export to excel'
                  color='info'
                  style={{ padding: '8.9px 40px' }}
                  onClick={() => setExportingExcel(true)}
                >
                  <GetAppIcon style={{ color: 'white' }} />
                </Button>
                <Button
                  aria-label='Close'
                  title='Close'
                  color='info'
                  type='button'
                  onClick={() => {
                    traineeHandler();
                  }}
                >
                  Close
                </Button>
              </CardHeader>

              <CardBody>
                <br></br>
                <Table className='tablesorter'>
                  <thead className='text-primary'>
                    <tr>
                      <th>Student name</th>
                      <th>Training name</th>
                      <th>
                        <Col sm={8}>Remarks</Col>
                      </th>
                      <th>Final Grade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData?.length !== 0 ? (
                      reportData.map((data, index) => {
                        return (
                          <tr key={data.studentId}>
                            <td>{data.name}</td>
                            <td>{data.title}</td>
                            <td>
                              {
                                <Col sm={8}>
                                  <RemarkComp data={data} index={index} />
                                </Col>
                              }
                            </td>
                            <td>
                              {`${data?.totalMarksObtained || '-'}/${
                                +data.max_marks || '-'
                              }`}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td></td>
                        <td style={{ textAlign: 'end' }}>No Data Found !</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => ({
  getTraineeReportData: state.getTraineeReport,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getReport: (batchId) => {
    dispatch(getTraineeReportAction(batchId));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TraineeReport);
