import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { adminBulkDeleteAction } from "store/action/bulkDeleteAction/adminBulkDeleteAction";
import { getAdminsAction } from "store/action/adminActions/adminActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";
import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";
import { moduleBulkDeleteAction } from "store/action/bulkDeleteAction/moduleBulkDeleteAction";

function BulkDeleteTeaching(props) {
    const { onClick, userArr, setUserArr, notify } = props;

    const bulkDeleteTeaching = () => {
        const bulkDeleteUrl = `/api/module/bulkDelete?id=''&${userArr.toString().replaceAll(',', '&')}`;
        props.moduleBulkDelete(bulkDeleteUrl);
      };

    useEffect(() => {
      if (props?.moduleBulkDeleteRes?.deleteModuleData) {
        notify("Modules deleted successfully", false);
        props.clearResponse("BULK_DELETE_MODULE_RESET");
        props.getModuleList();
        setUserArr([])
        onClick();
      }
      if (props.moduleBulkDeleteRes?.error) {
        notify(props.moduleBulkDeleteRes?.error?.message, true);
        props.clearResponse("BULK_DELETE_MODULE_RESET");
      }
    }, [props?.moduleBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Modules?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteTeaching}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    moduleBulkDeleteRes: state.moduleBulkDelete,
    getModuleData: state.getModule,
});

const mapDispatchToProps = (dispatch) => ({
    moduleBulkDelete: (payload) => {
        dispatch(moduleBulkDeleteAction(payload));
    },
    getModuleList: () => {
        dispatch(getTeachingModuleAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteTeaching);
