import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getQuizAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_QUIZ,
      payload: {},
      isLoading: true,
    });

    let url='api/quize'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_QUIZ_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_QUIZ_FAIL,
          payload: { error: err.message },
          isLoading: false,
        });
      });
  };
};

export const createQuizAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_QUIZ,
      payload: {},
      isLoading: true,
    });

    postRequest('api/quize', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_QUIZ_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.CREATE_QUIZ_FAIL,
          payload: { error: err.message },
          isLoading: false,
        });
      });
  };
};

export const editQuizAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_QUIZ,
      payload: {},
      isLoading: true,
    });

    putRequest('api/quize', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_QUIZ_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.EDIT_QUIZ_FAIL,
          payload: { error: err.message },
          isLoading: false,
        });
      });
  };
};

export const deleteQuizAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_QUIZ,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_QUIZ_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.DELETE_QUIZ_FAIL,
          payload: { error: err.message },
          isLoading: false,
        });
      });
  };
};
