import * as actionTypes from '../actionType';

import { postRequest, getRequest } from 'Utilities/apiService/admin';
import axiosInstance from 'axiosInstance/axiosInstance';

export const getCertificateRequestAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CERTIFICATE_REQUEST,
      payload: {},
      isLoading: true,
    });

    let url = 'api/admin/certificate/requests';
    if(payload)url=url+`?q=${payload}`
    getRequest(url)
      .then((res) => {
   
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_CERTIFICATE_REQUEST_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_CERTIFICATE_REQUEST_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
export const handleCertificateRequestAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.HANDLE_CERTIFICATE_REQUEST,
      payload: {},
      isLoading: true,
    });

    let url = 'api/admin/certificate/approve';

    postRequest(url, payload)
      .then((res) => {
   
        if (res.status == 200) {
          dispatch({
            type: actionTypes.HANDLE_CERTIFICATE_REQUEST_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
    
        dispatch({
          type: actionTypes.HANDLE_CERTIFICATE_REQUEST_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
