import axios from 'axios';
import * as actionTypes from '../actionType';
import {
  getRequest,
  putRequest,
  postRequest,
  deleteRequest,
  postFormRequest,
  uploadFileWithProgress,
} from 'Utilities/apiService/admin';
export const getTrainersBatchAction = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRAINERS_BATCH,
      payload: {},
      isLoading: true,
    });

    getRequest('api/batch/teacher')
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_TRAINERS_BATCH_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_TRAINERS_BATCH_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const getTrainersModuleAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRAINERS_MODULE,
      payload: {},
      isLoading: true,
    });

    getRequest(`api/module/teacher?${url}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_TRAINERS_MODULE_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_TRAINERS_MODULE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const getTrainersTraineeAction = (batchId, moduleId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRAINERS_TRAINEE,
      payload: {},
      isLoading: true,
    });

    getRequest(`api/teacher/students?batchId=${batchId}&moduleId=${moduleId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_TRAINERS_TRAINEE_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_TRAINERS_TRAINEE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const updateModprogressAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_MODULE_PROGRESS,
      payload: {},
      isLoading: true,
    });

    putRequest('api/teacher/batch/progress', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.UPDATE_MODULE_PROGRESS_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_MODULE_PROGRESS_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const updateTraineeProgressAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRAINEE_PROGRESS,
      payload: {},
      isLoading: true,
    });

    putRequest('api/student/module/progress', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.UPDATE_TRAINEE_PROGRESS_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_TRAINEE_PROGRESS_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const updateTraineeGradeAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_TRAINEE_GRADE,
      payload: {},
      isLoading: true,
    });

    postRequest('api/module/student/addGrades', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.UPDATE_TRAINEE_GRADE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPDATE_TRAINEE_GRADE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const banTraineeAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BAN_TRAINEE,
      payload: {},
      isLoading: true,
    });

    postRequest('api/teacher/banStudent', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.BAN_TRAINEE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.BAN_TRAINEE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const deleteGrades = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_GRADE,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_GRADE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_GRADE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const uploadGradesAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_GRADE,
      payload: {},
      isLoading: true,
    });

    // const user = localStorage.getItem('user')
    //   ? JSON.parse(localStorage.getItem('user'))
    //   : '';

    // if (user)
    //   axios
    //     .post(
    //       'https://atriev-lms.dt.r.appspot.com/api/module/student/grades',
    //       payload,
    //       {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           Authorization: `Bearer ${user.token}`,
    //         },
    //       }
    //     )

    postFormRequest('api/module/student/grades', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.UPLOAD_GRADE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPLOAD_GRADE_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
export const uploadAudioAction = (payload, setProgress) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPLOAD_AUDIO,
      payload: {},
      isLoading: true,
    });

    // const user = localStorage.getItem('user')
    //   ? JSON.parse(localStorage.getItem('user'))
    //   : '';

    // if (user)
    //   axios
    //     .post(
    //       'https://atriev-lms.dt.r.appspot.com/api/teacher/recordings',
    //       payload,
    //       {
    //         headers: {
    //           'Content-Type': 'multipart/form-data',
    //           Authorization: `Bearer ${user.token}`,
    //         },
    //       }
    //     )

    // postFormRequest

    // uploadFileWithProgress('api/teacher/recordings', payload, setProgress)

    const user = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user'))
      : '';
    axios
      .post(
        'https://atriev-lms.dt.r.appspot.com/api/teacher/recordings',
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        }
      )
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.UPLOAD_AUDIO_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.UPLOAD_AUDIO_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const getUploadedStudyMaterial = (batchId, moduleId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_UPLOADED_MATERIAL,
      payload: {},
      isLoading: true,
    });
    getRequest(`api/teacher/recordings?batchId=${batchId}&moduleId=${moduleId}`)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_UPLOADED_MATERIAL_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_UPLOADED_MATERIAL_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const deleteUploadedMaterial = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_UPLOADED_MATERIAL,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_UPLOADED_MATERIAL_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_UPLOADED_MATERIAL_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
