import React, { useState, useEffect } from "react";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { GiftedChat,Send,View,Image,Bubble } from "react-web-gifted-chat";
import SendIcon from '@material-ui/icons/Send';

function ChatMsg({ currentChat,studentId,messagesFirestoreData,  }) {
  const [email, setEmail] = useState("");
  // const [messages, setMessages] = useState(currentChat);
  const userId ='admin'

  // useEffect(() => {
  //   if (currentChat !== undefined && currentChat !== "") {
  //     setMessages([]);
  //     console.log('current chat inside chat ', currentChat)
  //     setEmail("");
  //     appendMessages(currentChat);
  //   }
  // }, [currentChat]);

  // const setAllValues = (id) => {
  //       setEmail(email);
        // const unsubscribe = db
        //   .collection("Chats")
        //   .doc(id)
        //   .collection("messages").orderBy("createdAt", "desc").limit(10)
        //   .onSnapshot((querySnapshot) => {
        //     const messagesFirestore = querySnapshot
        //       .docChanges()
        //       .filter(({type}) => type == "added")
        //       .map(({ doc }) => {
        //         let message = doc.data();
        //         console.log('message data',message)
        //         message.user.id = message.user._id;
        //         return { ...message, createdAt: message.createdAt.toDate() };
        //       })
        //     appendMessages(messagesFirestore);
        // });
        // return unsubscribe;
        
          // appendMessages(id);
      

  // };

  // const appendMessages = (message) => {
  //   setMessages((previousMessages) => GiftedChat.append(previousMessages, message));
  // };

  async function handleSend(messages) {
    console.log('message',messages,userId)
    if(messages[0].text){
      document.getElementById("messageOut").play();

      messages[0].email = email;
      messages[0].user._id = userId;
      messages[0].userID=studentId
      // db.collection("Chats").doc(currentChat).collection("messages").add(messages[0]);
      db.collection("Chats2").add(messages[0]);

      
    }
  }

  const renderBubble = (props) => {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            maxWidth: "30vw",
          },
          right: {
            marginLeft: 0,
            maxWidth: "30vw",
          },
        }}
      ></Bubble>
    );
  };


    const renderSend=(props) =>{
      return (
          <Send
            {...props}
            containerStyle={{

              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              marginRight: 15,
            }
            }
          >
              
            <SendIcon style={{color:'#0084ff'}} />
              
          </Send>
      );
    }

  const renderChat = () => {
    return <GiftedChat className="giftedChat"  renderSend={renderSend} messages={currentChat} user={{ id: userId, name: email }} onSend={handleSend} renderBubble={renderBubble} />;
  };

  return <div style={styles.chat}>{renderChat()}</div>;
}

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "80vh",
  },
  chat: {
    display: "flex",
    flex: 3,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",

    border: "#2873f5",
    borderStyle: "solid",
    borderWidth: "1px",
  },
};

export default ChatMsg;
