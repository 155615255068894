import axios from 'axios';
import regeneratorRuntime from 'regenerator-runtime';
import jwtDecode from 'jwt-decode';
const  baseUrl ="https://atriev-lms.dt.r.appspot.com/api/";


const getStudents = async () => {
  const data = JSON.parse(localStorage.getItem('user'));
  const userData = jwtDecode(data.token);
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}student/allStudents`, {
        headers: {
          Authorization: `Bearer ${data.token}`, //the token is a variable which holds the token
        },
      })
        .then((response) => {
          const { data } = response?.data;
          console.log(data );
          resolve(data);
        })
        .catch((error) => reject(error));
    });
  };

export const getAllStudents = { getStudents };
