import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";

import NotificationAlert from "react-notification-alert";
// reactstrap components
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavLink,
  Nav,
} from "reactstrap";
import {
  actionBtnStyle,
  btnStyle,
  positionCenter,
} from "Utilities/common/commonStyle";

import NotificationsIcon from "@material-ui/icons/Notifications";

import {
  getNotificationAction,
  editNotificationAction,
} from "store/action/notificationAction/notificationAction";
import { clearResponse } from "store/action/clearResponseAction";

function NotificationComp(props) {
  const [notificationBtnCLick, setNotificationBtnCLick] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const payloadData = {
    status: 0,
  };

  const deleteNotifications = () => {
    props.editNotification(payloadData);
  };

  useEffect(() => {
    props.getNotificationList();
  }, []);

  useEffect(() => {
    props.getNotificationList();
  }, [notificationBtnCLick]);

  useEffect(() => {
    if (props.getNotificationData.notificationList) {
      if (props.getNotificationData.notificationList?.data?.length == 0) {
        // notify("No data found.", true);
        props.clearResponse("GET_NOTIFICATION_RESET");
        setNotificationData(props.getNotificationData.notificationList.data);
      } else {
        setNotificationData(props.getNotificationData.notificationList.data);
      }
    }
    if (props.getNotificationData?.error) {
      notify(props.getNotificationData?.error?.message, true);
      props.clearResponse("GET_NOTIFICATION_RESET");
    }
  }, [props.getNotificationData]);

  useEffect(() => {
    if (props.editNotificationRes.editNotificationData) {
      notify("Notifications cleared successfully", false);
      props.clearResponse("EDIT_NOTIFICATION_RESET");
      props.getNotificationList();
    }
    if (props.editNotificationRes?.error) {
      notify(props.editNotificationRes?.error?.error?.message, true);
      props.clearResponse("EDIT_NOTIFICATION_RESET");
    }
  }, [props?.editNotificationRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  if (!localStorage.getItem("user")) history.push("/login");

  return (
    <>
      <div onClick={() => setNotificationBtnCLick(!notificationBtnCLick)}>
        <Nav
          style={{
            position: "absolute",
            right:
              window.innerWidth > 1200
                ? "18%"
                : window.innerWidth > 900
                ? "19%"
                : window.innerWidth > 700
                ? "22%"
                : "28%",
            cursor: "pointer",
            top: "50%",
            transform: "translateY(-50%)",
            background: "#2D66F5",
            ...btnStyle,
            padding: "0",
          }}
          aria-label="Notifications"
          title="Notifications"
          navbar
        >
          <UncontrolledDropdown style={{ padding: "0" }} nav>
            <DropdownToggle
              caret
              color="default"
              data-toggle="dropdown"
              nav
              onClick={(e) => e.preventDefault()}
              style={{ padding: "0" }}
            >
              <div
                style={{ ...positionCenter, position: "relative" }}
                className="photo"
              >
                <NotificationsIcon />
                {notificationData.length < 1 ? null : (
                  <span
                    style={{
                      width: "fit-content",
                      minWidth: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      display: "inline-block",
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                      fontSize: "8px",
                      lineHeight: "16px",
                      position: "absolute",
                      top: "6px",
                      right: "4px",
                    }}
                  >
                    {notificationData.length}
                  </span>
                )}
              </div>
            </DropdownToggle>
            <DropdownMenu className="dropdown-navbar" right tag="ul">
              <NavLink tag="li">
                <DropdownItem
                  className="nav-item"
                  style={{
                    padding: "12px",
                    maxHeight: "60vh",
                    minWidth: "260px",
                    overflowY: "auto",
                  }}
                >
                  <div className="react-notification-alert-container">
                    <NotificationAlert ref={notifi} />
                  </div>
                  {notificationData.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: "5px",
                          fontSize: "13px",
                          borderBottom: "0.5px solid #D3D3D3",
                        }}
                      >
                        <span>
                          Notifications &nbsp;
                          <span
                            style={{
                              width: "fit-content",
                              minWidth: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "inline-block",
                              backgroundColor: "#1d8cf8",
                              color: "white",
                              textAlign: "center",
                              fontSize: "13px",
                              lineHeight: "20px",
                            }}
                          >
                            {notificationData.length}
                          </span>
                        </span>
                        <button
                          title="Clear all notifications"
                          aria-label="Clear all notifications"
                          style={{ ...actionBtnStyle, color: "#1d8cf8" }}
                          onClick={deleteNotifications}
                        >
                          Clear all
                        </button>
                      </div>
                      {notificationData?.map((notification, i) => {
                        return (
                          <p
                            style={{
                              color: "black",
                              borderBottom: `${
                                notificationData.length - 1 == i
                                  ? "0px solid #D3D3D3"
                                  : "0.5px solid #D3D3D3"
                              }`,
                              padding: "2px 0",
                            }}
                          >
                            {notification.actionType}
                          </p>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      <span>No notifications yet</span>
                    </div>
                  )}
                </DropdownItem>
              </NavLink>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getNotificationData: state.getNotification,
  editNotificationRes: state.editNotification,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getNotificationList: (payload) => {
    dispatch(getNotificationAction(payload));
  },
  editNotification: (payload) => {
    dispatch(editNotificationAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationComp);
