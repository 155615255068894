import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';
import { getUserType } from 'Utilities/common/commonFn';

export const getTrainersAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_TRAINER,
      payload: {},
      isLoading: true,
    });

    let url = 'api/teacher';
    if (payload) url = url + `?q=${payload}`;

    getRequest(url)
      .then((res) => {
        // notify("Data fatched Successfully", false);

        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_TRAINER_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_TRAINER_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const createTrainerAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_TRAINER,
      payload: {},
      isLoading: true,
    });

    postRequest('api/teacher', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_TRAINER_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_TRAINER_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const editTrainerAction = (payload) => {
  const userType = getUserType().userType;
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_TRAINER,
      payload: {},
      isLoading: true,
    });

    putRequest('api/teacher', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_TRAINER_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.EDIT_TRAINER_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};

export const deleteTrainerAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_TRAINER,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_TRAINER_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_TRAINER_FAIL,
          payload: { error: err?.response?.data },
          isLoading: false,
        });
      });
  };
};
