import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { adminBulkDeleteAction } from "store/action/bulkDeleteAction/adminBulkDeleteAction";
import { getAdminsAction } from "store/action/adminActions/adminActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";
import { assignmentBulkDeleteAction } from "store/action/bulkDeleteAction/assignmentBulkDeleteAction";
import { getAssignmentAction } from "store/action/assignmentAction/assignmentAction";

function BulkDeleteAssignment(props) {
    const { onClick, userArr, setUserArr, notify } = props;

    const bulkDeleteAssignment= () => {
        const bulkDeleteUrl = `/api/assignment/bulkDelete?id=''&${userArr.toString().replaceAll(',', '&')}`;
        props.assignmentBulkDelete(bulkDeleteUrl);
      };

    useEffect(() => {
      if (props?.assignmentBulkDeleteRes?.deleteAssignmentData) {
        notify("Assignments deleted successfully", false);
        props.clearResponse("BULK_DELETE_ASSIGNMENT_RESET");
        props.getAssignmentList();
        setUserArr([])
        onClick();
      }
      if (props.assignmentBulkDeleteRes?.error) {
        notify(props.assignmentBulkDeleteRes?.error?.message, true);
        props.clearResponse("BULK_DELETE_ASSIGNMENT_RESET");
      }
    }, [props?.assignmentBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Assignments?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteAssignment}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    assignmentBulkDeleteRes: state.assignmentBulkDelete,
    getAssignmentData: state.getAssignment,
});

const mapDispatchToProps = (dispatch) => ({
    assignmentBulkDelete: (payload) => {
        dispatch(assignmentBulkDeleteAction(payload));
    },
    getAssignmentList: () => {
        dispatch(getAssignmentAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteAssignment);
