import React, { useState, useRef, useEffect } from "react";
import { ModalBody, Modal, Row, Col, Input, Button, Table } from "reactstrap";
import { Edit, Delete, Money, BookmarkBorderSharp } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import NotificationAlert from "react-notification-alert";

import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";

import Book_Edit_Step1 from "./Book_Add_Step/Book_Edit_Step1";
import Book_Delete_Step1 from "./Book_Add_Step/Book_Delete_Step1";
import AddAssignment from "./AddAssignment";

import {
  createAssignmentAction,
  getAssignmentAction,
} from "store/action/assignmentAction/assignmentAction";

import { connect } from "react-redux";

import EditAssignment from "./EditAssignment";
import DeleteAssignment from "./DeleteAssignment";

import Loader from "components/Loader/Loader";
import { tr } from "date-fns/locale";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import { clearResponse } from "store/action/clearResponseAction";
import { assignmentBulkDeleteAction } from "store/action/bulkDeleteAction/assignmentBulkDeleteAction";
import BulkDeleteAssignment from "./BulkDeleteAssignment";

function AssignmentList({
  setVisible,
  setProgressVisible,
  setProgressValue,
  ...props
}) {
  const [editVisible, setEditVisible] = useState(false);
  const [bookData, setBoookData] = useState([]);
  const [editBook, setEditBook] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [limit, setLimit] = useState(10);

  const [assignmentList, setAssignmentList] = useState("");

  const [currentData, setCurrentData] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [userArr, setUserArr] = useState([]);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);

  const notifi = useRef();

  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  const nextSet = () => {};

  const prevSet = () => {};
  const emptyStudent = () => {
    getUsers();
  };

  const getUsers = (orderBy) => {};
  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getAssignmentList();
    } else {
      setSearch(true);
      props.getAssignmentList(studentSearchValue);
    }
  };

  useEffect(() => {
    if (props?.getAssignmentRes?.assignmentList?.data) {
      if (props?.getAssignmentRes?.assignmentList?.data.length == 0) {
        setAssignmentList("");
        notify("No data found.", true);
        props.clearResponse("GET_ASSIGNMENT_RESET");
      }
      // console.log('data', props?.getAssignmentRes?.assignmentList);
      // notify('Data fetched successfully.', false);
      else {
        setAssignmentList(props?.getAssignmentRes?.assignmentList?.data);
      }
    }
    if (props?.getAssignmentRes?.error) {
      notify(props?.getAssignmentRes?.error?.message, true);
      props.clearResponse("GET_ASSIGNMENT_RESET");
    }
  }, [props?.getAssignmentRes]);

  useEffect(() => {
    props.getAssignmentList();
  }, []);

  useEffect(() => {
    if (props?.editAssignmentRes?.editAssignmentData?.data) {
      // notify(
      //   props?.editAssignmentRes?.editAssignmentData?.data?.message,
      //   false
      // );
      notify("Assignment updated successfully", false);

      props.clearResponse("EDIT_ASSIGNMENT_RESET");
      setEditVisible(false);
      props.getAssignmentList();
    }
    if (props.editAssignmentRes?.error) {
      notify(props.editAssignmentRes?.error?.message, true);
      props.clearResponse("EDIT_ASSIGNMENT_RESET");
    }
  }, [props?.editAssignmentRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <Col>
      <Row style={{ marginBottom: "15px" }}>
        <Col sm={4} style={{ padding: 0 }}>
          <Input
            title="Search Assignment"
            type="search"
            name="search"
            placeholder="Search Assignment"
            onChange={(e) => (
              setStudentSearchValue(e.target.value),
              e.target.value.length === 0 && emptyStudent(),
              setSearch(false)
            )}
          />
        </Col>
        <Col style={{ paddingLeft: "10px" }} sm={2}>
          <Button
            title="Search"
            style={{ margin: "0px", padding: "6px 10px" }}
            color="info"
            onClick={studentSearch}
          >
            <SearchIcon />
          </Button>
        </Col>
        <Col
          style={{ display: "flex", justifyContent: "flex-end", padding: 0 }}
          sm={6}
        >
          {userArr.length > 0 ? (
            <Button
              title="Delete Marked"
              aria-label="Delete Marked"
              style={{ margin: "0px 0px 0 0", padding: "6px 10px" }}
              color="danger"
              onClick={() => {
                setBulkDeleteVisible(true);
              }}
            >
              Delete Marked
            </Button>
          ) : null}
        </Col>
      </Row>

      <Row>
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>
        {editVisible === true && (
          <Modal
            isOpen={editVisible}
            style={{
              display: "flex",
              flex: 1,
              minWidth: "85%",
              position: "absolute",
              left: "50%",
              transform: " translateX(-47%)",
            }}
          >
            <ModalBody
              style={{
                width: "100%",
                display: "inline-block",
                padding: "0px !important",
                position: "absolute",
                border: "1px solid white !important",
              }}
            >
              <EditAssignment
                // setBoookData={setBoookData}
                dataToEdit={currentData}
                notify={notify}
                onClick={() => {
                  setEditVisible(false);
                }}
              />
            </ModalBody>
          </Modal>
        )}

        <Modal isOpen={deleteModalVisible} style={{ width: 230 }}>
          <ModalBody>
            <DeleteAssignment
              setUserArr={setUserArr}
              dataToDelete={currentData}
              notify={notify}
              onClick={() => {
                setDeleteModalVisible(false);
              }}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={bulkDeleteVisible}
          color="primary"
          style={{
            display: "flex",
            flex: 1,
            top: 0,
            width: 250,
            backgroundColor: "#132639",
            objectFit: "fill",
          }}
        >
          <ModalBody style={{ width: "100%" }} size="sm">
            <BulkDeleteAssignment
              userArr={userArr !== undefined && userArr}
              setUserArr={setUserArr}
              notify={notify}
              onClick={() => {
                setBulkDeleteVisible(false);
              }}
            ></BulkDeleteAssignment>
          </ModalBody>
        </Modal>

        <Row style={{ margin: 0, width: "100%" }}>
          <Table
            style={{
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
            className="tablesorter"
          >
            <thead className="text-primary">
              <tr>
                <th></th>
                <th>Title</th>
                <th style={{ width: "450px", textAlign: "center" }}>Details</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {assignmentList ? (
                assignmentList.map((assignment, i) => {
                  return (
                    <tr
                      key={assignment.id}
                      style={{
                        borderBottom: `1px solid ${
                          colorTheme?.borderColor || "rgba(255, 255, 255, 0.1)"
                        }`,
                      }}
                    >
                      <td
                        style={{
                          display: "flex",
                          paddingLeft: "20px",
                          color: "white",
                          marginTop: "1px",
                        }}
                      >
                        <Input
                          id={assignment?.id}
                          defaultChecked={false}
                          type="checkbox"
                          key={assignment?.id}
                          value={assignment?.id}
                          onChange={handleCheckbox}
                        />
                      </td>
                      <th>
                        <label
                          style={{
                            fontSize: font?.fontSize || "inherit",
                            fontWeight: font?.fontWeight || "inherit",
                            color: "#FFFFFFB3",
                          }}
                          htmlFor={assignment?.id}
                        >
                          {assignment.title}
                        </label>
                      </th>
                      <td style={{ textAlign: "center" }}>
                        {assignment.description}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <div>
                          <button
                            title="Edit"
                            aria-label="Edit"
                            style={actionBtnStyle}
                            onClick={() => {
                              setEditVisible(true);
                              //  setDeleteVisible(false)
                              setDeleteModalVisible(false);
                              setCurrentData(assignment);
                            }}
                          >
                            <Edit style={{ color: "#247cf7" }} />
                          </button>

                          <button
                            title="Delete"
                            style={actionBtnStyle}
                            aria-label="Delete"
                            onClick={() => {
                              setEditVisible(false);
                              // setDeleteVisible(true)
                              setDeleteModalVisible(true);
                              setCurrentData(assignment);
                            }}
                          >
                            <Delete
                              style={{
                                color: "red",
                              }}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td></td>
                  <td>
                    <h3 style={{ textAlign: "center" }}> Data not found</h3>
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* </div> */}
        </Row>
        {/* </div> */}
      </Row>
      {props?.getAssignmentRes?.isLoading ||
      props?.deleteAssignmentRes?.isLoading ||
      props?.createAssignmentRes?.isLoading ||
      props?.editAssignmentRes?.isLoading ||
      props?.assignmentBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </Col>
  );
}
const mapStateToProps = (state, ownProps) => ({
  getAssignmentRes: state.getAssignment,
  createAssignmentRes: state.createAssignment,
  editAssignmentRes: state.editAssignment,
  deleteAssignmentRes: state.deleteAssignment,
  assignmentBulkDeleteRes: state.assignmentBulkDelete,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getAssignmentList: (payload) => {
    dispatch(getAssignmentAction(payload));
  },
  createAssignment: (payload) => {
    dispatch(createAssignmentAction(payload));
  },
  assignmentBulkDelete: (payload) => {
    dispatch(assignmentBulkDeleteAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentList);
