import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Label,
  Col,
  Alert,
  Modal,
  ModalBody,
} from "reactstrap";
import styles from "./quizEditModal.module.css";
import { useForm } from "react-hook-form";

import {
  editQuestionAction,
  getQuestionAction,
} from "store/action/questionAction/questionAction";

import { clearResponse } from "store/action/clearResponseAction";

import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";
import { connect } from "react-redux";

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

function QuizEditModal({
  onClick,
  currentQuestion,
  setquizData,
  notify,
  ...props
}) {
  const { handleSubmit, register, errors, reset } = useForm();
  const [correctAns, setCorrectAns] = useState("");
  console.log("edit quiz", currentQuestion);

  const [moduleList, setModuleList] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  useEffect(() => {
    if (currentQuestion.quizQuestionOptions) {
      let ans;
      currentQuestion.quizQuestionOptions.map((item, index) => {
        if (item.isCorrect == 1) ans = index;
      });
      ans == 0
        ? setCorrectAns("a")
        : ans == 1
        ? setCorrectAns("b")
        : ans == 2
        ? setCorrectAns("c")
        : ans == 3
        ? setCorrectAns("d")
        : ans == 4
        ? setCorrectAns("e")
        : setCorrectAns("f");

      console.log(ans);
    }
  }, []);

  useEffect(() => {
    if (props?.editQuestionRes?.editQuestionData) {
      console.log(props?.editQuestionRes?.editQuestionData?.data);
      notify("Question updated successfully", false);
      props.clearResponse("EDIT_QUESTION_RESET");
      props.getQuestion();
      onClick();
    }
    if (props?.editQuestionRes?.error) {
      notify(props?.editQuestionRes?.error?.message, true);
      props.clearResponse("EDIT_QUESTION_RESET");
    }
  }, [props?.editQuestionRes]);

  useEffect(() => {
    if (props.getModuleRes.moduleList) {
      setModuleList(props?.getModuleRes?.moduleList?.data);
    }
  }, [props.getModuleRes]);

  useEffect(() => {
    props.getModuleList();
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const onSubmit = (data) => {
    let options = [];
    let payloadOptions = [];
    Object.keys(data).map((item, index) => {
      if (item.toLowerCase().startsWith("option") && data[item]) {
        options.push(data[item]);
      }
    });

    options.map((item, index) =>
      payloadOptions.push({
        value: item,
        isCorrect: index == data.correctOption ? true : false,
      })
    );

    const payload = {
      questionId: currentQuestion.id,
      questionType: currentQuestion.questionType,
      subject: data.subject,
      description: data.description,
      question: data.questionTitle,
      qOptions: payloadOptions,
      difficulty: +data.difficulty,
    };
    let correctAnswer = false;
    payloadOptions.map((item) => {
      if (item.isCorrect) {
        correctAnswer = true;
      }
    });
    correctAnswer
      ? props.editQuestion(payload)
      : notify("correct answer is not in the list", true);
    // const data = {
    //     questionTitle: values.questionTitle,
    //     options: options,
    //     correctOption: parseInt(values.correctOption),
    //     difficulty: parseInt(values.difficulty),
    //     subject: values.subject,
    // }

    // db.collection('Questions').add(data).then(()=>{db.collection('Questions')
    //     .get()
    //     .then(querySnapshot => {
    //         let data = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
    //         setquizData(data)
    //         // console.log('my user data is ', data)
    //         notify("Question Added Successfully")
    //         // setquizData((previousData) => ([...previousData, data]))
    //         // console.log('documnet successfully inserted',data);
    //         setalert(true);
    //         e.target.reset();
    //     })} )

    // if (data.optionE !== '')
    //   options.push({ value: data.optionE, isCorrect: false });
    // if (data.optionF !== '')
    //   options.push({ value: data.optionF, isCorrect: false });

    // const options = [
    //   { value: data.optionA, isCorrect: false },
    //   { value: data.optionB, isCorrect: false },
    //   { value: data.optionC, isCorrect: false },
    //   { value: data.optionD, isCorrect: false },
    // ];

    // console.log('options........', options);
    // const updatedData = {
    //   questionTitle: data.questionTitle,
    //   qOptions: options,
    //   correctOption: parseInt(data.correctOption),
    //   difficulty: parseInt(data.difficulty),
    //   subject: data.subject,
    // };
    // console.log('updatedData........', updatedData);

    // db.collection('Questions')
    //   .doc(currentQuestion.id)
    //   .update(updatedData)
    //   .then(function () {
    //     console.log('documnet Edited', data);
    //     setquizData((prev) =>
    //       prev.map((item) =>
    //         item.id == currentQuestion.id
    //           ? { ...updatedData, id: currentQuestion.id }
    //           : item
    //       )
    //     );
    //     onClick();
    //     notify('Question updated successfully', false);
    //   })
    //   .catch(function (error) {
    //     notify(error.toString(), true);

    //     console.log('this is error', error);
    //   });
  };
  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card
            style={{
              margin: 0,
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader>
              <h2
                className="title"
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform:"capitalize"
                }}
              >
                Edit Question
              </h2>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup row>
                  <Col sm={5} style={{ borderRight: "1px solid #29384a" }}>
                    <FormGroup row>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="questionTitle"
                        style={{ display: "flex" }}
                      >
                        Question
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="questionTitle"
                          style={{ minHeight: "80px" }}
                          defaultValue={currentQuestion.question}
                          innerRef={register({ required: true })}
                          type="textarea"
                          name="questionTitle"
                          placeholder="Please Enter Question"
                          required
                        />
                      </Col>
                    </FormGroup>
                    <br></br>
                    {currentQuestion.quizQuestionOptions &&
                    currentQuestion.quizQuestionOptions.length > 0 ? (
                      <Col sm={12}>
                        {console.log(
                          "sachin",
                          currentQuestion.quizQuestionOptions
                        )}
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionA"
                          >
                            A.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionA"
                              defaultValue={
                                currentQuestion.quizQuestionOptions[0].qOptions
                              }
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionA"
                              placeholder="Option A"
                              style={{ padding: "4px 18px 4px 18px" }}
                              required
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionB"
                          >
                            B.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionB"
                              defaultValue={
                                currentQuestion.quizQuestionOptions[1].qOptions
                              }
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionB"
                              placeholder="Option B"
                              required
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionC"
                          >
                            C.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionC"
                              defaultValue={
                                currentQuestion.quizQuestionOptions[2].qOptions
                              }
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionC"
                              placeholder="Option C"
                              required
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionD"
                          >
                            D.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionD"
                              defaultValue={
                                currentQuestion.quizQuestionOptions[3].qOptions
                              }
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionD"
                              placeholder="Option D"
                              required
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionE"
                          >
                            E.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionE"
                              defaultValue={
                                currentQuestion.quizQuestionOptions.length > 4
                                  ? currentQuestion.quizQuestionOptions[4]
                                      .qOptions
                                  : ""
                              }
                              innerRef={register({ required: false })}
                              type="textarea"
                              name="optionE"
                              placeholder="Option E"
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionF"
                          >
                            F.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionF"
                              defaultValue={
                                currentQuestion.quizQuestionOptions.length > 5
                                  ? currentQuestion.quizQuestionOptions[5]
                                      .qOptions
                                  : ""
                              }
                              innerRef={register({ required: false })}
                              type="textarea"
                              name="optionF"
                              placeholder="Option F"
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Col>
                  <Col sm={7}>
                    <FormGroup row>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="description"
                        style={{ display: "flex" }}
                      >
                        Description
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="description"
                          defaultValue={currentQuestion.description}
                          style={{ minHeight: "130px" }}
                          innerRef={register({ required: true })}
                          type="textarea"
                          name="description"
                          placeholder="Please Enter Description"
                          required
                        />
                      </Col>
                    </FormGroup>
                    {currentQuestion.quizQuestionOptions ? (
                      <FormGroup row>
                        <Label
                          style={{ padding: "9px" }}
                          className={styles.headlabel}
                          sm={2}
                          htmlFor="correctOption"
                        >
                          Correct Answer
                        </Label>
                        <Col sm={10}>
                          <Input
                            id="correctOption"
                            // defaultValue={correctAns ? correctAns : ''}
                            innerRef={register({ required: true })}
                            type="select"
                            name="correctOption"
                            required
                          >
                            <option
                              selected={correctAns == "a" ? true : false}
                              value={0}
                            >
                              A
                            </option>
                            <option
                              selected={correctAns == "b" ? true : false}
                              value={1}
                            >
                              B
                            </option>
                            <option
                              selected={correctAns == "c" ? true : false}
                              value={2}
                            >
                              C
                            </option>
                            <option
                              selected={correctAns == "d" ? true : false}
                              value={3}
                            >
                              D
                            </option>
                            <option
                              selected={correctAns == "e" ? true : false}
                              value={4}
                            >
                              E
                            </option>
                            <option
                              selected={correctAns == "f" ? true : false}
                              value={5}
                            >
                              F
                            </option>
                          </Input>
                        </Col>
                      </FormGroup>
                    ) : null}
                    <FormGroup row>
                      <Label
                        style={{ padding: "9px", display: "flex" }}
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="difficulty"
                      >
                        Difficulty Level
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="difficulty"
                          // defaultValue={currentQuestion.difficulty}
                          innerRef={register({ required: true })}
                          type="select"
                          name="difficulty"
                          required
                        >
                          <option
                            selected={
                              currentQuestion.difficulty == 1 ? true : false
                            }
                            value={1}
                          >
                            Easy
                          </option>
                          <option
                            selected={
                              currentQuestion.difficulty == 2 ? true : false
                            }
                            value={2}
                          >
                            Medium
                          </option>
                          <option
                            selected={
                              currentQuestion.difficulty == 3 ? true : false
                            }
                            value={3}
                          >
                            Difficult
                          </option>
                          {/* <option value={4}>Level 4</option>
                                                <option value={5}>Level 5</option> */}
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="subject"
                        style={{ display: "flex" }}
                      >
                        Subject
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="subject"
                          // defaultValue={currentQuestion.subject}
                          innerRef={register({ required: true })}
                          type="select"
                          name="subject"
                          required
                        >
                          {moduleList ? (
                            moduleList.map((module) => {
                              return (
                                <option
                                  selected={
                                    module.title == currentQuestion.subject
                                  }
                                  value={module.title}
                                >
                                  {module.title}
                                </option>
                              );
                            })
                          ) : (
                            <option>No data found !</option>
                          )}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>

                  {/* <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Correct Answer</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.correctOption} innerRef={register({ required: true })} type="select" name="correctOption" required>
                                                <option value={0}>A</option>
                                                <option value={1}>B</option>
                                                <option value={2}>C</option>
                                                <option value={3}>D</option>
                                                <option value={4}>E</option>
                                                <option value={5}>F</option>

                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.difficulty} innerRef={register({ required: true })} type="select" name="difficulty" required>
                                                <option value={1}>Level 1</option>
                                                <option value={2}>Level 2</option>
                                                <option value={3}>Level 3</option>
                                                <option value={4}>Level 4</option>
                                                <option value={5}>Level 5</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label className={styles.headlabel} sm={2}>Subject</Label>
                                        <Col sm={9}>
                                            <Input defaultValue={currentQuestion.subject} innerRef={register({ required: true })} type="select" name="subject" required>
                                                <option>Science</option>
                                                <option>Math</option>
                                                <option>English</option>
                                                <option>Social Science</option>
                                            </Input>
                                        </Col>
                                    </FormGroup> */}
                </FormGroup>
                <br></br>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <Button title="Update" color="info" type="submit">
                    Update
                  </Button>

                  <Button title="Back" onClick={onClick}>
                    {" "}
                    Back
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getQuestion: state.getQuestion,
  editQuestionRes: state.editQuestion,
  getModuleRes: state.getModule,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editQuestion: (payload) => {
    dispatch(editQuestionAction(payload));
  },
  getQuestion: () => {
    dispatch(getQuestionAction());
  },
  getModuleList: () => {
    dispatch(getTeachingModuleAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizEditModal);
