import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getActivityReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITY:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activityList: action.payload,
      };
    case actionTypes.GET_ACTIVITY_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_ACTIVITY_RESET':
      return {};
    default:
      return state;
  }
};



