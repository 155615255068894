import React from 'react';

import { Form, Button, Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';

import InputComp from 'components/InputComp/InputComp';

const FileUploader = ({ onSubmit, onChange, onClick, accept, name, label }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        color: 'white',
        gap: '20px',
      }}
      // onSubmit={(e) => {
      //   e.preventDefault();
      //   onSubmit();
      // }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row>
        <Col>
          {/* style=
          {{
            width: '100px',
            background: 'white',
            color: 'black',
            display: ' flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: ' 35px',
            borderRadius: ' 4px',
            paddingTop: ' 5%',
          }} */}

          <InputComp
            onChange={onChange}
            type='file'
            name={name}
            accept={accept}
            label={label}
            innerRef={register({ required: true })}
            errors={errors}
            style={{
              position: 'inherit !important',
              opacity: '1',
            }}
            // placeholder='Last date'
          />
        </Col>
      </Row>
      {/* <input
        onChange={onChange}
        type='file'
        name='student_grades'
        id=''
        accept={accept}
      /> */}
      <div>
        <Button color='info' type='submit' title="Upload" aria-label="Upload">
          Upload
        </Button>
        <Button color='light' onClick={onClick} type='button' title="Back" aria-label="Back">
          Back
        </Button>
      </div>
    </Form>
  );
};

export default FileUploader;
