import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getAdminReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADMIN:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        adminList: action.payload,
      };
    case actionTypes.GET_ADMIN_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_ADMIN_RESET':
      return {};
    default:
      return state;
  }
};
export const createAdminReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ADMIN:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createAdminData: action.payload,
      };
    case actionTypes.CREATE_ADMIN_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'CREATE_ADMIN_RESET':
      return {};
    default:
      return state;
  }
};
export const editAdminReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_ADMIN:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editAdminData: action.payload,
      };
    case actionTypes.EDIT_ADMIN_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case 'EDIT_ADMIN_RESET':
      return {};
    default:
      return state;
  }
};

export const deleteAdminReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ADMIN:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteAdminData: action.payload,
      };
    case actionTypes.DELETE_ADMIN_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_ADMIN_RESET':
      return {};
    default:
      return state;
  }
};
