export const actionBtnStyle = {
  border: 'none',
  backgroundColor: 'transparent',
  borderRadius: '50%',
  height: 'fit-content',
  width: 'fit-content',
  cursor: 'pointer',
  padding: 'none',
};

export const btnStyle = {
  border: 'none',
  height: 'fit-content',
  width: 'fit-content',
  cursor: ' pointer',
  padding: '8px',
  borderRadius: '4px',
  backgroundColor: '#1d8cf8',
  margin: '0 auto',
  display: 'block',
  color: 'white',
};

export const tableDivStyle = {
  padding: '8px 16px',
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr )',
  minHeight: '60px',
  height: 'fit-content',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'center',
};

export const paraStyle = {
  paddingRight: '6px',
  wordBreak: 'break-all',
  margin: 0,
  textTransform: 'capitalize',
  textAlign: 'center',
};

export const positionCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
