import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Label,
  Col,
  Alert,
  Modal,
  ModalBody,
} from "reactstrap";
import styles from "./quiz_modal.module.css";
import { useForm } from "react-hook-form";
import firebase from "firebase";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import { connect } from "react-redux";
import {
  createQuestionAction,
  getQuestionAction,
} from "store/action/questionAction/questionAction";

import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";

import { clearResponse } from "store/action/clearResponseAction";

function Quiz_Modal({ onClick, notify, startIndex, setquizData, ...props }) {
  const [alert, setalert] = useState(false);
  const { handleSubmit, register, errors, reset } = useForm();

  const [isMcq, setIsMcq] = useState(true);

  const [moduleList, setModuleList] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const onSubmit = (values, e) => {
    // console.log("quiz modal......", values.correctOption);

    const options = [
      {
        value: values.optionA,
        isCorrect: values.correctOption == 0 ? true : false,
      },
      {
        value: values.optionB,
        isCorrect: values.correctOption == 1 ? true : false,
      },
      {
        value: values.optionC,
        isCorrect: values.correctOption == 2 ? true : false,
      },
      {
        value: values.optionD,
        isCorrect: values.correctOption == 3 ? true : false,
      },
    ];
    if (values.optionE !== "")
      options.push({
        value: values.optionE,
        isCorrect: values.correctOption == 4 ? true : false,
      });
    if (values.optionF !== "")
      options.push({
        value: values.optionF,
        isCorrect: values.correctOption == 5 ? true : false,
      });

    let correctAnswer = false;
    options.map((item) => {
      if (item.isCorrect) {
        correctAnswer = true;
      }
    });

    // console.log("quiz modal......after options   ", correctAnswer);
    let data;
    if (isMcq) {
      data = {
        question: values.questionTitle,
        description: values.description,
        qOptions: options,
        difficulty: parseInt(values.difficulty),
        questionType: "Objective",
        subject: values.subject,
        // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
    } else {
      data = {
        question: values.questionTitle,
        description: values.description,
        subject: values.subject,
        difficulty: parseInt(values.difficulty),
        questionType: "Subjective",
      };
    }

    correctAnswer
      ? props.createQuestion(data)
      : notify("correct answer is not in the list", true);
  };

  useEffect(() => {
    if (props.createQuestionRes.createQuestionData) {
      notify("Question created successfully", false);
      props.clearResponse("CREATE_QUESTION_RESET");
      props.getQuestion();
      onClick();

      console.log(props.createQuestionRes.createQuestionData);
    }
    if (props?.createQuestionRes?.error) {
      notify(props?.createQuestionRes?.error?.message, true);
      props.clearResponse("CREATE_QUESTION_RESET");
    }
  }, [props.createQuestionRes]);

  useEffect(() => {
    if (props.getModuleRes.moduleList) {
      setModuleList(props?.getModuleRes?.moduleList?.data);
    }
  }, [props.getModuleRes]);

  useEffect(() => {
    props.getModuleList();
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card
            style={{
              margin: 0,
              fontSize: font?.fontSize || "inherit",
              fontWeight: font?.fontWeight || "inherit",
              backgroundColor: colorTheme?.bodyColor || "all",
            }}
          >
            <CardHeader>
              <h2
                className="title"
                style={{
                  fontSize: font?.fontSize + 2 || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  textTransform: "capitalize",
                }}
              >
                Quiz Management
              </h2>

              {/* <div>
                <Button
                  style={{ float: 'right' }}
                  onClick={() => setIsMcq(!isMcq)}
                >
                  {isMcq ? 'Objective' : 'Subjective'}
                </Button>
              </div> */}
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup row>
                  <Col sm={5} style={{ borderRight: "1px solid #29384a" }}>
                    <FormGroup row>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="questionTitle"
                        style={{ display: "flex" }}
                      >
                        Question
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="questionTitle"
                          style={{ minHeight: "80px" }}
                          innerRef={register({ required: true })}
                          type="textarea"
                          name="questionTitle"
                          placeholder="Please Enter Question"
                          // required
                        />
                        {errors.questionTitle &&
                          errors.questionTitle.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter question
                            </span>
                          )}
                      </Col>
                    </FormGroup>
                    <br></br>

                    {isMcq ? (
                      <Col sm={12}>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionA"
                          >
                            A.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionA"
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionA"
                              placeholder="Option A"
                              // required
                              style={{ padding: "4px 18px 4px 18px" }}
                            />
                            {errors.optionA &&
                              errors.optionA.type === "required" && (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    position: "absolute",
                                    bottom: "-20px",
                                    color: "red",
                                  }}
                                >
                                  Please enter option A
                                </span>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionB"
                          >
                            B.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionB"
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionB"
                              placeholder="Option B"
                              // required
                              style={{
                                padding: "4px 18px 4px 18px",
                                marginTop: "10px",
                              }}
                            />
                            {errors.optionB &&
                              errors.optionB.type === "required" && (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    position: "absolute",
                                    bottom: "-20px",
                                    color: "red",
                                  }}
                                >
                                  Please enter option B
                                </span>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionC"
                          >
                            C.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionC"
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionC"
                              placeholder="Option C"
                              // required
                              style={{
                                padding: "4px 18px 4px 18px",
                                marginTop: "10px",
                              }}
                            />
                            {errors.optionC &&
                              errors.optionC.type === "required" && (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    position: "absolute",
                                    bottom: "-20px",
                                    color: "red",
                                  }}
                                >
                                  Please enter option C
                                </span>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionD"
                          >
                            D.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionD"
                              innerRef={register({ required: true })}
                              type="textarea"
                              name="optionD"
                              placeholder="Option D"
                              // required
                              style={{
                                padding: "4px 18px 4px 18px",
                                marginTop: "10px",
                              }}
                            />
                            {errors.optionD &&
                              errors.optionD.type === "required" && (
                                <span
                                  style={{
                                    fontSize: "10px",
                                    position: "absolute",
                                    bottom: "-20px",
                                    color: "red",
                                  }}
                                >
                                  Please enter option D
                                </span>
                              )}
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionE"
                          >
                            E.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionE"
                              innerRef={register({ required: false })}
                              type="textarea"
                              name="optionE"
                              placeholder="Option E"
                              style={{
                                padding: "4px 18px 4px 18px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            className={styles.textalign}
                            sm={2}
                            htmlFor="optionF"
                          >
                            F.
                          </Label>
                          <Col sm={10}>
                            <Input
                              id="optionF"
                              innerRef={register({ required: false })}
                              type="textarea"
                              name="optionF"
                              placeholder="Option F"
                              style={{
                                padding: "4px 18px 4px 18px",
                                marginTop: "10px",
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Col>
                  <Col sm={7}>
                    <FormGroup row>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="description"
                        style={{ display: "flex" }}
                      >
                        Description
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="description"
                          style={{ minHeight: "130px" }}
                          innerRef={register({ required: true })}
                          type="textarea"
                          name="description"
                          placeholder="Please Enter Description"
                          // required
                        />
                        {errors.description &&
                          errors.description.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter description
                            </span>
                          )}
                      </Col>
                    </FormGroup>
                    {isMcq ? (
                      <FormGroup row style={{ marginTop: "20px" }}>
                        <Label
                          style={{ padding: "7px" }}
                          className={styles.headlabel}
                          sm={2}
                          htmlFor="correctOption"
                        >
                          Correct Answer
                        </Label>
                        <Col sm={10}>
                          <Input
                            id="correctOption"
                            innerRef={register({ required: true })}
                            type="select"
                            name="correctOption"
                            // required
                          >
                            <option value="">Please select the answer</option>
                            <option value={0}>A</option>
                            <option value={1}>B</option>
                            <option value={2}>C</option>
                            <option value={3}>D</option>
                            <option value={4}>E</option>
                            <option value={5}>F</option>
                          </Input>
                          {errors.correctOption &&
                            errors.correctOption.type === "required" && (
                              <span
                                style={{
                                  fontSize: "10px",
                                  position: "absolute",
                                  bottom: "-20px",
                                  color: "red",
                                }}
                              >
                                Please select correct answer
                              </span>
                            )}
                        </Col>
                      </FormGroup>
                    ) : null}
                    <FormGroup row style={{ marginTop: "20px" }}>
                      <Label
                        style={{ padding: "9px", display: "flex" }}
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="difficulty"
                      >
                        Difficulty Level
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="difficulty"
                          innerRef={register({ required: true })}
                          type="select"
                          name="difficulty"
                          // required
                        >
                          <option value="">Please select level</option>
                          <option value={1}>Easy</option>
                          <option value={2}>Medium</option>
                          <option value={3}>Difficult</option>
                          {/* <option value={4}>Level 4</option>
                                                    <option value={5}>Level 5</option> */}
                        </Input>
                        {errors.difficulty &&
                          errors.difficulty.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please select difficulty level
                            </span>
                          )}
                      </Col>
                    </FormGroup>
                    <FormGroup row style={{ marginTop: "20px" }}>
                      <Label
                        className={styles.headlabel}
                        sm={2}
                        htmlFor="subject"
                        style={{ display: "flex" }}
                      >
                        Module
                      </Label>
                      <Col sm={10}>
                        <Input
                          id="subject"
                          innerRef={register({ required: true })}
                          type="select"
                          name="subject"
                          // required
                        >
                          <option value="">Please Select Module</option>
                          {moduleList ? (
                            moduleList.map((module) => {
                              return (
                                <option value={module.title}>
                                  {module.title}
                                </option>
                              );
                            })
                          ) : (
                            <option>No data found !</option>
                          )}
                          {/* <option>Please select subject</option>
                          <option>Science</option>
                          <option>Math</option>
                          <option>English</option>
                          <option>Social Science</option> */}
                        </Input>
                        {errors.subject &&
                          errors.subject.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please select module
                            </span>
                          )}
                      </Col>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <br></br>
                {/* <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Correct Answer</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="correctOption" required>
                                        <option value={0}>A</option>
                                        <option value={1}>B</option>
                                        <option value={2}>C</option>
                                        <option value={3}>D</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Difficulty Level</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="difficulty" required>
                                        <option value={1}>Level 1</option>
                                        <option value={2}>Level 2</option>
                                        <option value={3}>Level 3</option>
                                        <option value={4}>Level 4</option>
                                        <option value={5}>Level 5</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label className={styles.headlabel} sm={2}>Subject</Label>
                                <Col sm={9}>
                                    <Input innerRef={register({ required: true })} type="select" name="subject" required>
                                        <option>Science</option>
                                        <option>Math</option>
                                        <option>English</option>
                                        <option>Social Science</option>
                                    </Input>
                                </Col>
                            </FormGroup> */}
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <Button title="Submit" color="info" type="submit">
                    Submit
                  </Button>

                  <Button title="Back" onClick={onClick}>
                    {" "}
                    Back
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getQuestion: state.getQuestion,
  createQuestionRes: state.createQuestion,
  getModuleRes: state.getModule,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createQuestion: (payload) => {
    dispatch(createQuestionAction(payload));
  },
  getQuestion: () => {
    dispatch(getQuestionAction());
  },
  getModuleList: () => {
    dispatch(getTeachingModuleAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz_Modal);
