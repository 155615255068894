import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';

import {
  deleteTrainerAction,
  getTrainersAction,
} from 'store/action/trainerActions/trainerActions';
import { clearResponse } from 'store/action/clearResponseAction';
import * as firebase from 'firebase';

function DeleteModal({ id, notify, setUserData, setUserArr, onClick, ...props }) {
  const [visible, setVisible] = useState(false);

  const deleteRecord = () => {
    // document.querySelector('#loading-indicator').style.display = 'flex';
    setVisible(true);

    const deleteUrl = `api/teacher?userId=${id}`;

    props.deleteTrainer(deleteUrl);
    // notify(result.data.message.errorInfo.message.toString(), true);
  };

  useEffect(() => {
    if (props?.deleteTrainerRes?.deleteTrainerData?.data) {
      // props.getTrainerList();
      // notify(props?.deleteTrainerRes?.deleteTrainerData?.data?.message, false);
      props.clearResponse("DELETE_TRAINER_RESET");
      setUserArr([])
      onClick();
    }
    if (props.deleteTrainerRes?.error) {
      // notify(props.deleteTrainerRes?.error?.message, true);
      props.clearResponse("DELETE_TRAINER_RESET");
    }
  }, [props?.deleteTrainerRes]);

  return (
    <div style={{ padding: 20 }}>
      <p>Do you want to delete this record</p>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}

        <Button
          title='Yes'
          color='info'
          onClick={() => {
            deleteRecord();
          }}
        >
          Yes
        </Button>

        {visible === false && (
          <Button title='No' onClick={onClick}>
            No
          </Button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteTrainerRes: state.deleteTrainer,
  getTrainerData: state.getTrainer,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTrainer: (payload) => {
    dispatch(deleteTrainerAction(payload));
  },
  getTrainerList: () => {
    dispatch(getTrainersAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
