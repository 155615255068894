import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NotificationAlert from 'react-notification-alert';

import TrainerEditForm from './TrainerEditForm';
import { clearResponse } from 'store/action/clearResponseAction';
const TrainersProfilePage = (props) => {
  const notifi = useRef();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user')).personalData;

  const notify = (msg, err) => {
    let color = err == false ? 'success' : 'danger';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  useEffect(() => {
    if (props?.editTrainerRes?.editTrainerData?.data?.message) {
      notify('Profile updated', false);
      history.push('/trainer/dashboard');
      props.clearResponse('EDIT_TRAINER_RESET');
    }
    if (props.editTrainerRes?.error) {
      notify(props.editTrainerRes?.error?.message, true);
      props.clearResponse('EDIT_TRAINER_RESET');
    }
  }, [props?.editTrainerRes?.editTrainerData?.data]);

  return (
    <div className='content'>
      <div className='react-notification-alert-container'>
        <NotificationAlert ref={notifi} />
      </div>
      <div>
        <TrainerEditForm
          notify={notify}
          userData={user}
          onClick={() => {
            history.push('/trainer/dashboard');
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  editTrainerRes: state.editTrainer,
});
const mapDispatchToProps = (dispatch) => ({
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrainersProfilePage);
