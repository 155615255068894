import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getSecurityGroupReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SECURITY_GROUP:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        securityGroupList: action.payload,
      };
    case actionTypes.GET_SECURITY_GROUP_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };

    default:
      return state;
  }
};
export const createSecurityGroupReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SECURITY_GROUP:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createSecurityGroupData: action.payload,
      };
    case actionTypes.CREATE_SECURITY_GROUP_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'CREATE_SECURITY_GROUP_RESET':
      return {};

    default:
      return state;
  }
};
export const editSecurityGroupReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_SECURITY_GROUP:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editSecurityGroupData: action.payload,
      };
    case actionTypes.EDIT_SECURITY_GROUP_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'EDIT_SECURITY_GROUP_RESET':
      return {};

    default:
      return state;
  }
};

export const deleteSecurityGroupReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SECURITY_GROUP:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_SECURITY_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteSecurityGroupData: action.payload,
      };
    case actionTypes.DELETE_SECURITY_GROUP_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_SECURITY_GROUP_RESET':
      return {};

    default:
      return state;
  }
};

// Actions & Functions

export const getActionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIONS:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        actionList: action.payload,
      };
    case actionTypes.GET_ACTIONS_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };

    default:
      return state;
  }
};
export const getFunctionsReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FUNCTIONS:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_FUNCTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        functionList: action.payload,
      };
    case actionTypes.GET_FUNCTIONS_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };

    default:
      return state;
  }
};
