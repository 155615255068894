import React from 'react';
import SecurityIcon from '@material-ui/icons/Security';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
const DropdownForDisplay = ({
  arr,
  data,
  children,
  customStyle,
  title,
  ...rest
}) => {
  let customStyles = customStyle || {};
  return (
    <UncontrolledDropdown
      {...rest}
      style={{
        display: 'flex',
        justifyContent: 'center',
        ...customStyles,
      }}
    >
      <DropdownToggle
        style={{
          margin: '0px',
          padding: '6px 10px',
        }}
        className='nav-link'
        color='info'
        caret
      >
        <SecurityIcon />
      </DropdownToggle>
      <DropdownMenu
        style={{
          left: customStyle?.justifyContent === 'flex-start' ? '2%' : '42%',
        }}
      >
        <DropdownItem style={{ color: 'black' }} header>
          {title ? title : null}
        </DropdownItem>
        <DropdownItem>
          <div
            style={{
              display: 'flex',
              width: '250px',
              flexWrap: 'wrap',
              gap: '4px',
            }}
          >
            {!children
              ? arr.map((item) => (
                  <p
                    style={{
                      color: 'black',
                      background: '#f0f0f0',
                      padding: '4px',
                    }}
                  >
                    {/* {console.log(item[data])} */}
                    {data ? item[data]?.title : null}
                  </p>
                ))
              : children}
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default DropdownForDisplay;
