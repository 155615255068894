import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import Button from "@material-ui/core/Button";

import NotificationAlert from "react-notification-alert";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import AtrievLogo from "../.././assets/img/atriev.svg";

import axios from "axios";
import "./resetPassword.css";
import Loader from "components/Loader/Loader";

const ResetPassword = () => {
  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const notification = useRef();

  const [newData, setNewData] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfrimShowPassword] = useState(false);
  const [getToken, setGetToken] = useState("");
  const [isToken, setIsToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordUpdate, setIsPasswordUpdate] = useState(false);

  const newPasswordApiHandler = async (e) => {
    e.preventDefault();

    if (!newData.password) {
      notify("Please enter password", true);
    } else if (newData.password.length < 8) {
      notify("Password must be 8 character long", true);
    } else if (newData.password !== newData.cnf_password) {
      notify("Password not match", true);
    } else {
      setIsLoading(true);
      try {
        const data = await axios.post(
          "https://atriev-lms.dt.r.appspot.com/api/forget/password/verify",
          newData,
          getToken
        );
        if (data) {
          setIsLoading(false);

          notify("Password updated", false);
          setIsPasswordUpdate(true);
        }
      } catch (err) {
        setIsLoading(false);

        notify(err?.response?.data?.message, true);
      }
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;

    const value = e.target.value;

    setNewData({ ...newData, [name]: value });
    // console.log(newData);
  };

  useEffect(() => {
    let token = window.location.search.split("=")[1];

    setIsToken(token);
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setGetToken(config);
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notification} />
        </div>
        {isToken ? (
          <div className="reset-password-container">
            <form>
              <div className="reset-form-container">
                <div
                  style={{
                    width: "70px",
                    margin: "0 auto",
                  }}
                >
                  <img src={AtrievLogo} alt="Atriev Logo" />
                </div>

                <h1 className="reset-password-heading">
                  {isPasswordUpdate ? "Atriev" : "New Password"}
                </h1>

                <main className="reset-password-input-container">
                  {isPasswordUpdate ? (
                    <Button
                      color="primary"
                      variant="contained"
                      title="Go to Sign In"
                      aria-label="Go to Sign In"
                      className="reset-sub-btn"
                      onClick={() => history.push("/")}
                    >
                      Go to Sign In
                    </Button>
                  ) : (
                    <>
                      {" "}
                      <FormGroup>
                        <label
                          htmlFor="Password"
                          style={{
                            color: "#000000",
                          }}
                        >
                          Password
                        </label>
                        <Input
                          id="Password"
                          innerRef={register({ required: true })}
                          name="password"
                          onChange={handleChange}
                          placeholder="Create new password"
                          type={showPassword ? "text" : "password"}
                          style={{ color: "#000000", position: "relative" }}

                          // required
                        />

                        <button
                          style={{
                            background: "transparent",
                            height: "fit-content",
                            width: "fit-content",
                            border: "none",
                            position: "absolute",
                            top: "50%",
                            right: "0",
                          }}
                          type="button"
                          onClick={() => setShowPassword((prev) => !prev)}
                          aria-label={
                            showPassword ? "Show password" : "Hide password"
                          }
                        >
                          {showPassword ? (
                            <VisibilityIcon
                              style={{
                                cursor: "pointer",
                                margin: "auto 0",
                              }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </button>
                      </FormGroup>
                      <FormGroup>
                        <label
                          htmlFor="Confirm-Password"
                          style={{
                            color: "#000000",
                          }}
                        >
                          Confirm Password
                        </label>
                        <Input
                          id="Confirm-Password"
                          innerRef={register({ required: true })}
                          name="cnf_password"
                          onChange={handleChange}
                          placeholder="Confirm new password"
                          style={{ color: "#000000" }}
                          type={confirmShowPassword ? "text" : "password"}

                          // required
                        />
                        <button
                          style={{
                            background: "transparent",
                            height: "fit-content",
                            width: "fit-content",
                            border: "none",
                            position: "absolute",
                            top: "50%",
                            right: "0",
                          }}
                          type="button"
                          onClick={() =>
                            setConfrimShowPassword((prev) => !prev)
                          }
                          aria-label={
                            confirmShowPassword
                              ? "Show password"
                              : "Hide password"
                          }
                        >
                          {confirmShowPassword ? (
                            <VisibilityIcon
                              style={{
                                cursor: "pointer",
                                margin: "auto 0",
                              }}
                            />
                          ) : (
                            <VisibilityOffIcon
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </button>
                      </FormGroup>
                      <Button
                        type="submit"
                        title="Submit"
                        aria-label="Submit"
                        className="reset-sub-btn"
                        color="primary"
                        variant="contained"
                        style={{
                          marginTop: "5px",
                        }}
                        onClick={newPasswordApiHandler}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </main>
              </div>
            </form>
          </div>
        ) : (
          <div className="page-not-found">
            <h1 style={{ color: "#000000" }}>Page not found...</h1>
          </div>
        )}
        {isLoading ? <Loader /> : null}
      </div>
    </>
  );
};

export default ResetPassword;
