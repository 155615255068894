import React, { useState, useEffect } from "react";
import { Nav, NavLink, Row, Col } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";

import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import "./chat.css";
import ChatMsg from "./ChatMsg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    minWidth: 250,
    color: "black",
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  blue: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

let currentStudents = "";
const Chat = () => {
  let history = useHistory();
  const classes = useStyles();

  const [students, setStudents] = useState([]);
  const [chatStudents, setChatStudents] = useState([]);
  const [currentChat, setCurrentChat] = useState([]);
  const [messagesFirestoreData, setMessagesFirestoreData] = useState({});
  const [firstTimeSeq, setFirstTimeSeq] = useState(true);
  const [currentChatID, setCurrentChatID] = useState("");
  // console.log('currentID' ,currentChat , typeof currentChat)

  // console.log("currentChat", currentChat, currentStudents);
  // console.log("students students", students);

  useEffect(() => {
    getStudents();
    getMessages();
  }, []);

  useEffect(() => {
    if (
      students.length > 0 &&
      Object.keys(chatStudents).length > 0 &&
      firstTimeSeq
    ) {
      setFirstTimeSeq(false);
      // console.log('currentID' ,currentChatID,)

      setStudents((prev) =>
        prev
          .map((item) => {
            // console.log('currentID',  item._uniqueID)
            return chatStudents[item._uniqueID]
              ? currentChatID == item._uniqueID
                ? {
                    ...item,
                    createdAtChat: chatStudents[item._uniqueID],
                    selectedChat: true,
                  }
                : { ...item, createdAtChat: chatStudents[item._uniqueID] }
              : item;
          })
          .sort((a, b) => {
            if (a.createdAtChat < b.createdAtChat) {
              return 1;
            }
            if (a.createdAtChat > b.createdAtChat) {
              return -1;
            }
            return 0;
          })
      );
    }
  }, [chatStudents, students]);

  const getMessages = () => {
    let chatId = {};

    db.collection("Chats2")
      .orderBy("createdAt", "desc")
      .onSnapshot((querySnapshot) => {
        querySnapshot
          .docChanges()
          .filter(({ type }) => type == "added")
          .forEach(({ doc }) => {
            let message = doc.data();
            message.user.id = message.user._id;
            if (!chatId[message.userID]) {
              chatId[message.userID] = [];
            }

            if (querySnapshot.docChanges().length !== 1) {
              chatId[message.userID].push({
                ...message,
                createdAt: message.createdAt.toDate(),
              });
            } else {
              document.getElementById("messageIn").play();

              chatId[message.userID].splice(0, 0, {
                ...message,
                createdAt: message.createdAt.toDate(),
              });
              setStudents((prev) =>
                prev
                  .map((item) => {
                    return item._uniqueID == message.userID
                      ? {
                          ...item,
                          createdAtChat: message.createdAt.toDate(),
                          showBadge:
                            message.user.id === "admin" ||
                            currentStudents == message.user.id
                              ? false
                              : true,
                        }
                      : item;
                  })
                  .sort((a, b) => {
                    if (a.createdAtChat < b.createdAtChat) {
                      return 1;
                    }
                    if (a.createdAtChat > b.createdAtChat) {
                      return -1;
                    }
                    return 0;
                  })
              );
            }
          });
        console.log("chatId 2", chatId);

        let temp = {};
        Object.keys(chatId).map((item) => {
          temp[item] = chatId[item][0].createdAt;
          return { createdAt: chatId[item][0].createdAt, id: item };
        });
        setChatStudents(temp);

        setMessagesFirestoreData(chatId);

        if (currentChat.length === 0 || !currentChat) {
          // console.log('Object.keys(chatId)[0]',Object.keys(chatId)[0])
          // console.log('chatId[Object.keys(chatId)[0]]',chatId[Object.keys(chatId)[0]])

          setCurrentChatID(Object.keys(chatId)[0]);
          setCurrentChat(chatId[Object.keys(chatId)[0]]);
        }
        if (currentStudents) {
          setCurrentChat(chatId[currentStudents]);
        }
      });
  };

  const getStudents = () => {
    db.collection("zSystemUsers")
      .get()
      .then((querySnapshot) => {
        let data = querySnapshot.docs
          .map((doc) => {
            const { firstName, lastName, email } = doc.data();
            if (firstName !== undefined && lastName !== undefined) {
              return {
                firstName,
                lastName,
                email,
                _uniqueID: doc.id,
                createdAtChat: new Date(1970),
                showBadge: false,
                selectedChat: false,
              };
            } else {
              return null;
            }
          })
          .filter((item) => item);
        console.log("student id here", data);

        setStudents(data);
      });
  };

  const changeStudent = (id) => {
    currentStudents = id;
    setCurrentChat(messagesFirestoreData[id]);
    setStudents((prev) =>
      prev.map((item) => {
        return item._uniqueID == id
          ? { ...item, showBadge: false, selectedChat: true }
          : { ...item, selectedChat: false };
      })
    );
  };

  return (
    <>
      <Row
        style={{
          justifyContent: "flex-end",
          backgroundColor: "#1e1e2a",
          width: "100vw",
          margin: 0,
        }}
      >
        <Col sm={1} style={{ textAlign: "center" }}>
          <IconButton
            style={{ color: "white" }}
            edge="start"
            aria-label="close"
          >
            <CloseIcon
              onClick={() => {
                history.goBack();
              }}
              //  onClick={handleClose}
            />
          </IconButton>
        </Col>
      </Row>

      <div
        className="scrollNone"
        style={{
          display: "flex",
          backgroundColor: "#1e1e2a",
          height: "100%",
          overflow: "auto",
        }}
      >
        <div
          className="scrollNone"
          style={{
            backgroundColor: "#272a3d",
            padding: "0px",
            paddingBottom: "20px",
            marginLeft: "20px",
            marginBottom: "12px",
            overflow: "auto",
            minWidth: "300px",
            borderRadius: "8px",
          }}
        >
          <div style={{ padding: "20px" }}>
            <h6>Users</h6>
          </div>
          <List className={classes.root}>
            {students.map((item, i) => {
              return (
                <>
                  <ListItem
                    alignItems="flex-start"
                    key={item._uniqueID}
                    style={
                      item.selectedChat
                        ? { backgroundColor: "cadetblue" }
                        : { cursor: "pointer", borderRadius: "8px" }
                    }
                    onClick={() => changeStudent(item._uniqueID)}
                  >
                    <ListItemAvatar style={{ marginRight: "5px" }}>
                      <Avatar
                        alt={item.firstName}
                        src="/static/images/avatar/1.jpg"
                        className={i % 2 == 0 ? classes.orange : classes.blue}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      primary={`${item.firstName} ${item.lastName}`}
                      secondary={item.email}
                    />

                    {item.showBadge && (
                      <Badge
                        color="error"
                        variant="dot"
                        style={{ top: "30px", right: "10px" }}
                      ></Badge>
                    )}
                  </ListItem>
                </>
              );
            })}
          </List>
        </div>
        <div style={{ width: "100%", position: "relative" }}>
          {!currentChat && (
            <div
              style={{
                position: "absolute",
                fontSize: 20,
                color: "#fff",
                left: "calc( 50% - 70px)",
                top: "44%",
              }}
            >
              No chat history!
            </div>
          )}
          <div style={{ padding: "20px", paddingBottom: "0px" }}>
            <h6>Chat</h6>
          </div>
          <div style={styles.container}>
            {ChatMsg({
              studentId: currentStudents,
              currentChat,
              messagesFirestoreData,
              userId: localStorage.getItem("uid"),
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "80vh",
    margin: "20px",
  },
  chat: {
    display: "flex",
    flex: 3,
    border: "#2873f5",
    borderStyle: "solid",
    borderWidth: "1px",
  },
};
export default Chat;
