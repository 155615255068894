import * as actionTypes from '../actionType';
import axios from 'axios';
export const adminLoginAction = (url, payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_REQ,
      payload: {},
      isLoading: true,
    });

    axios({
      method: 'post',
      url: `https://atriev-lms.dt.r.appspot.com/${url}`,
      data: payload,
      headers: { 'Content-Type': 'application/json' ,},
     
    })
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.LOGIN_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const clearLogin = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_RESET,
      payload: {},
      isLoading: false,
    });
  };
};
