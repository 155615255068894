import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import { actionBtnStyle } from 'Utilities/common/commonStyle';
import NotificationAlert from 'react-notification-alert';

import UpdateComp from 'components/UpdateComp/UpdateComp';
import {
  updateTraineeProgressAction,
  updateTraineeGradeAction,
  banTraineeAction,
  deleteGrades,
} from 'store/action/trainerActions/trainerProfileActions';
import { notify } from 'Utilities/common/commonFn';
const TableLoopComp = ({ item, batchId, moduleId, ...props }) => {
  const [isUpdatingTraineeProgress, setIsUpdatingTraineeProgress] =
    useState(false);
  const [traineeProgressValue, setTraineeProgressValue] = useState('');

  const [isUpdatingTraineeGrade, setIsUpdatingTraineeGrade] = useState(false);
  const [traineeGradeValue, setTraineeGradeValue] = useState('');
  const [totalGradeValue, setTotalGradeValue] = useState('');

  const notifi = useRef();

  const handleUpdate = (studentId, value) => {
    // if (traineeProgressValue) {
    let payload = {
      batchId,
      moduleId,
      // progress: +traineeProgressValue,
      progress: +value,
      studentId,
    };
    console.log(payload);
    props.updateTraineeProgress(payload);
    // }
  };

  const handleClickUpdateTraineeProgress = (studentId) => {
    if (!isUpdatingTraineeProgress) {
      setIsUpdatingTraineeProgress(true);
    }
    // else if (isUpdatingTraineeProgress && traineeProgressValue) {
    //   handleUpdate(studentId);
    // }
    else {
      setIsUpdatingTraineeProgress(false);
    }
  };
  console.log(typeof item?.maxMarks, 'type');
  const handleUpdateGrade = (studentId) => {
    if (traineeGradeValue && totalGradeValue) {
      if (+traineeGradeValue <= +totalGradeValue) {
        let payload = {
          batchId,
          moduleId,
          grade: +traineeGradeValue,
          maxGrade: +totalGradeValue,
          studentId,
        };
        console.log(payload);
        props.updateTraineeGrade(payload);
      } else {
        notify('Total grade can not be lesser then grade', true, notifi);
      }
    }
  };

  const handleClickUpdateTraineeGrade = (studentId) => {
    if (!isUpdatingTraineeGrade) {
      setIsUpdatingTraineeGrade(true);
    } else if (isUpdatingTraineeGrade && traineeGradeValue) {
      handleUpdateGrade(studentId);
    } else {
      setIsUpdatingTraineeGrade(false);
    }
  };

  const handleDelteGrade = (studentId) => {
    if (batchId && moduleId && studentId) {
      let url = `api/module/student/grades?batchId=${batchId}&moduleId=${moduleId}&studentId=${studentId}`;
      props.deleteGrade(url);
    }
  };

  const handleBanTrainee = (studentId, traineeStatus) => {
    if (studentId) {
      let payload = {
        moduleId,
        batchId,
        studentId,
        status: traineeStatus === 1 ? 0 : 1,
      };
      console.log(payload);
      props.banTrainee(payload);
    }
  };

  return (
    <>
      <div className='react-notification-alert-container'>
        <NotificationAlert ref={notifi} />
      </div>
      <tr>
        <td>{`${item?.student?.firstName} ${item?.student?.lastName}`}</td>
        <td style={{ display: 'flex' }}>
          <UpdateComp
            handleChange={(e) => {
              setTraineeProgressValue(e.target.value);
              const value = e.target.value;
              handleUpdate(item?.studentId, value);
            }}
            handleClick={() => {
              handleClickUpdateTraineeProgress(item.studentId);
            }}
            controller={isUpdatingTraineeProgress}
            text={
              item?.progress === 0
                ? 'Not started'
                : item?.progress === 1
                ? 'In progress'
                : item?.progress === 2
                ? 'Completed'
                : '-'
            }
            title='individual progress'
            dropDown
          />
        </td>
        <td>
          <div style={{ display: 'flex' }}>
            {isUpdatingTraineeGrade && (
              <input
                onChange={(e) => setTraineeGradeValue(e.target.value)}
                type='text'
                name=''
                id=''
                style={{
                  outline: 'none',
                  borderRadius: '4px',
                  width: '70px',
                }}
                placeholder='Grade'
              />
            )}

            <UpdateComp
              handleChange={(e) => {
                setTotalGradeValue(e.target.value);
              }}
              handleClick={() => handleClickUpdateTraineeGrade(item.studentId)}
              controller={isUpdatingTraineeGrade}
              text={`${
                typeof item?.marksObtained == 'number'
                  ? item?.marksObtained
                  : '-'
              }/${item?.maxMarks || '-'} `}
              title='individual grade'
              placeholder='Max grade'
            />
            <button
              aria-label='delete grade'
              title='delete-grade'
              style={actionBtnStyle}
              onClick={() => handleDelteGrade(item?.studentId)}
            >
              <DeleteIcon style={{ color: 'red' }} />
            </button>
          </div>
        </td>
        <td>
          <button
            title='Ban student'
            aria-label='Ban student'
            onClick={() => handleBanTrainee(item?.studentId, item?.status)}
            style={actionBtnStyle}
          >
            {item?.status == 1 ? (
              <PersonIcon style={{ color: 'green' }} />
            ) : (
              <PersonAddDisabledIcon style={{ color: 'red' }} />
            )}
          </button>
        </td>
      </tr>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  // getTrainersTraineeRes: state.getTrainersTrainee,
  updateTraineeprogressRes: state.updateTraineeprogress,
});
const mapDispatchToProps = (dispatch) => ({
  updateTraineeProgress: (payload) => {
    dispatch(updateTraineeProgressAction(payload));
  },
  updateTraineeGrade: (payload) => {
    dispatch(updateTraineeGradeAction(payload));
  },
  banTrainee: (payload) => {
    dispatch(banTraineeAction(payload));
  },
  deleteGrade: (url) => {
    dispatch(deleteGrades(url));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TableLoopComp);
