import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  editTraineeAction,
  getTraineesAction,
} from "store/action/traineeActions/traineeAction";
import { getBatchAction } from "store/action/batchActions/batchActions";
import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

function EditStudent({ notify, userData, setUserData, onClick, ...props }) {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [batchList, setBatchList] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [selectedBatchList, setSelectedBatchList] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };
  const onSubmit = (data) => {
    // data.orgId = 1;
    data.userId = userData.id;
    data.batchId = selectedBatch;
    props.editTrainee(data);
  };

  useEffect(() => {
    setSelectedBatchList(
      userData?.studentBatchRelations?.map((item) => {
        return { label: item?.batch.title, value: item?.batchId };
      })
    );

    if (!props?.getBatchRes?.batchList?.data) {
      // props.getBatchList();
    }
  }, []);

  useEffect(() => {
    if (props?.getBatchRes?.batchList?.data) {
      const data = props?.getBatchRes?.batchList?.data;
      setBatchList(
        data.map((item) => {
          return { label: item?.title, value: item?.id };
        })
      );
    }
  }, [props?.getBatchRes]);

  useEffect(() => {
    if (selectedBatchList) {
      setSelectedBatch(
        selectedBatchList.map((item) => {
          return item?.value;
        })
      );
    }
  }, [selectedBatchList]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{}}>
          <Col md="8">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform:'capitalize'
                  }}
                >
                  Edit Student
                </h2>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(async (data) => {
                    setVisible(true);
                    // data.selectedBatch = selectedBatch;
                    onSubmit(data);
                  })}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="FirstName">First Name</label>
                        <Input
                          id="FirstName"
                          defaultValue={userData.firstName}
                          innerRef={register({ required: true })}
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="LastName">Last Name</label>
                        <Input
                          id="LastName"
                          defaultValue={userData.lastName}
                          innerRef={register}
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Mobile">Mobile</label>
                        <Input
                          id="Mobile"
                          defaultValue={userData.mobile}
                          innerRef={register({
                            minLength: 10,
                            maxLength: 10,
                            required: true,
                          })}
                          name="mobile"
                          placeholder="10-digit Number"
                          type="number"
                          required
                        />
                        {errors.mobile && errors.mobile.type !== "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Valid Mobile No.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Email">Email</label>
                        <Input
                          id="Email"
                          defaultValue={userData.email}
                          innerRef={register({ required: true })}
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter email
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Status">Status</label>
                        <Input
                          id="Status"
                          defaultValue={userData.status}
                          innerRef={register({ required: true })}
                          name="status"
                          placeholder="Status"
                          type="select"
                          required
                        >
                          <option value={1}>Active</option>
                          <option value={0}>Inactive</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <label htmlFor="Select Training">Select Training</label>

                      {batchList && (
                        <ReactMultiSelectCheckboxes
                        id="Select Training"
                          value={selectedBatchList || ""}
                          styles={customStyles}
                          onChange={(e) => {
                            setSelectedBatchList(e);
                            // setSelectedBatch(e.map((item) => item.value));
                          }}
                          options={batchList}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row></Row>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      title="Update"
                      color="info"
                      type="submit"
                      style={{ marginRight: 5 }}
                    >
                      Update
                    </Button>

                    <Button title="Back" onClick={onClick}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  editTraineeRes: state.editTrainee,
  getTraineeRes: state.getTrainee,
  getBatchRes: state.getBatch,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editTrainee: (payload) => {
    dispatch(editTraineeAction(payload));
  },
  getTraineeList: () => {
    dispatch(getTraineesAction());
  },
  getBatchList: () => {
    dispatch(getBatchAction());
  },
  clearRes: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditStudent);
