import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getFilesAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_FILES,
      payload: {},
      isLoading: true,
    });
    let url = 'api/fileManager';
    // if (payload) url = url + `?q=${payload}`;

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_FILES_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_FILES_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteFilesAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_FILE,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_FILE_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_FILE_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
