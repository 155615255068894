import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-chat-widget/lib/styles.css";
import firebase from "firebase";
import MarkUnreadChatAltIcon from "@material-ui/icons/Textsms";
import ChatMsgRendererAdmin from "./ChatMsgRendererAdmin";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
// import { ChatApi } from "../../apis/chats/chatApis";
import { ListItem } from "@material-ui/core";
import { async } from "regenerator-runtime";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MicNoneIcon from "@material-ui/icons/MicNone";
import StopCircleIcon from "@material-ui/icons/Stop";
import SendIcon from "@material-ui/icons/Send";
import LibraryMusicOutlinedIcon from "@material-ui/icons/LibraryMusicOutlined";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOff";
import { merge } from "lodash";
import UserIcon from "../../assets/img/default-avatar.png";
import { useRef } from "react";
import { useCurrentChats } from "./useCurrentChats";
import NotificationAlert from "react-notification-alert";
import { notify } from "Utilities/common/commonFn";
function ChatBot({
  setIsChatWidget = () => null,

  chatUsers,
  isPanel,
  status,
  startRecording,
  stopRecording,
  mediaBlobUrl,
}) {
  const [chatMessages, setChatMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [currentUser, setCurrentUser] = useState({});
  const [currentTeacher, setCurrentTeacher] = useState({});
  const [typing, setTyping] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const chatBoxRef = React.useRef();
  const [unreadId, setUnreadId] = useState();
  const [isChat, setIsChat] = useState(false);
  const [audio, setAudio] = useState();
  const [chatsUser, setChatsUser] = useState();
  const audioType = "audio/*";

  const [isRecording, setIsRecording] = useState(false);
  const history = useHistory();
  const [teachers, setTeachers] = useState(
    isPanel ? chatUsers : chatUsers?.data
  );
  const notifi = useRef();
  const hiddenFileInput = useRef();
  const [currentChatId, setCurrentChatId] = useState();
  const onSubmit = (e) => {
    e.preventDefault();
    if (message === "" && !audio) return;
    handleNewUserMessage(message, audio);
    addUnreadMessage(currentTeacher);
    setChatMessages((prev) => {
      setMessage("");
      return [
        {
          sentBy: isPanel ? "Teacher" : "Admin",
          text: message,
          type: "msg",
          file: audio ? audio : "",
        },
        ...prev,
      ];
    });
  };
  useEffect(() => {
    // Subscribe to query with onSnapshot
    const query = db.collection("TeachersChat");
    const unsubscribe = query.onSnapshot((querySnapshot) => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const userChatData = data.filter((val) => {
        return val?.members?.includes(
          JSON.parse(localStorage.getItem("user"))?.personalData?.firebaseId
        );
      });
      if (userChatData.length > 0) {
        const isUnreadNewMessage = userChatData.filter((val) => {
          return (
            val?.unreadMessage !==
              JSON.parse(localStorage.getItem("user"))?.personalData
                ?.firebaseId && val?.unreadMessage !== "0"
          );
        });
        if (isUnreadNewMessage.length > 0) {
          setIsNewMessage(true);
        } else {
          setIsNewMessage(false);
        }
        console.log("querySnapshot.docsisUnreadNewMessage", isUnreadNewMessage);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (mediaBlobUrl) {
      const reader = new FileReader();
      fetch(mediaBlobUrl)
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            setAudio(reader.result?.toString());
          };
        });
    }
  }, [mediaBlobUrl]);
  const addUnreadMessage = (currentTeacher) => {
    db.collection("TeachersChat").doc(currentChatId).update({
      unreadMessage: currentUser?.personalData?.firebaseId,
    });
  };

  const handleNewUserMessage = (newMessage, audio) => {
    setTyping(true);
    if (currentChatId && currentUser?.personalData?.firebaseId) {
      db.collection("TeachersChat")
        .doc(currentChatId)
        .collection("allMessages")
        .add({
          sentBy: isPanel ? "Teacher" : "Admin",
          userID: currentUser?.personalData?.firebaseId,
          text: newMessage,
          file: audio ? audio : "",
          id: new Date().getTime().toString(),
          email: currentUser?.personalData?.email,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          user: {
            _id: currentUser?.personalData?.firebaseId,
            id: currentUser?.personalData?.firebaseId,
            name: currentUser?.personalData?.email,
          },
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTyping(false);
          console.log("Finally");
          // getChats()
        });
      setAudio("");
    }
    // db.collection("Chats2")

    // document.getElementById("messageOut").play();
  };

  useEffect(() => {
    let userData = localStorage.getItem("user");
    setCurrentUser(JSON.parse(userData));

    // getTeachers(JSON.parse(userData).personalData.firebaseId);
  }, []);
  useEffect(() => {
    if (currentUser) {
      currentUserChats();
    }
  }, [currentUser, isChat, isNewMessage]);
  useEffect(() => {
    if (currentChatId !== undefined) {
      db.collection("TeachersChat")
        .doc(currentChatId)
        .collection("allMessages")
        .orderBy("createdAt", "desc")
        .limit(10)
        .onSnapshot((snapshot) => {
          let reverseData = snapshot.docs.reverse();
          let data = reverseData.map((doc) => {
            return doc.data();
          });
          if (reverseData.length !== chatMessages.length) {
            setChatMessages(data.reverse());
          }
        });
    }
  });

  const stopRecordings = () => {
    stopRecording();
    setIsRecording(false);
  };
  const startRecordings = async () => {
    startRecording();
    setIsRecording(true);
  };
  const currentUserChats = () => {
    db.collection("TeachersChat")
      .get()
      .then((query) => {
        let reverseData = query.docs;
        let data = reverseData.filter((val) => {
          return val
            ?.data()
            .members?.includes(currentUser?.personalData?.firebaseId);
        });
        if (data.length > 0) {
          let newChat = data.map((val) => {
            return val.data();
          });
          console.log("Current chat: ", newChat);
          setChatsUser(newChat);
          setUnreadId(newChat[0].unreadMessage);
        }
      });
  };
  const getChats = (teacherId, teacher) => {
    let latestId;
    const UserData = {
      email: currentUser?.personalData?.email,
      firstName: currentUser?.personalData?.firstName,
      lastName: currentUser?.personalData?.lastName,
      profilePic: currentUser?.personalData?.profileImage,
      firebaseId: currentUser?.personalData?.firebaseId,
    };
    db.collection("TeachersChat")
      .get()
      .then((query) => {
        let reverseData = query.docs;
        let data = reverseData.filter((val) => {
          return val
            ?.data()
            .members?.includes(currentUser?.personalData.firebaseId);
        });
        if (data.length > 0) {
          const currentChat = data.filter((val) => {
            return val?.data().members?.includes(teacherId);
          });
          console.log("currentChatfromgetChat", currentChat);
          if (currentChat.length > 0) {
            let latestChat = currentChat.map((val) => {
              db.collection("TeachersChat")
                .doc(val.id)
                .collection("allMessages")
                .orderBy("createdAt", "desc")
                .limit(10)
                .get()
                .then((query) => {
                  let reverseData = query.docs.reverse();

                  let data = reverseData.map((doc) => {
                    return doc.data();
                  });

                  setChatMessages(data.reverse());
                });
              latestId = val.id;
              setCurrentChatId(val.id);

              return val.data();
            });
            markUnread(latestId);
          } else {
            db.collection("TeachersChat")
              .add({
                user: currentUser?.personalData,
                receiver: teacher,
                unreadMessage: "0",
                members: [currentUser?.personalData.firebaseId, teacherId],
                role: "admin",
              })
              .then((docRef) => {
                setCurrentChatId(docRef.id);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setTyping(false);
                console.log("Finally Added!");
              });
          }
        } else {
          db.collection("TeachersChat")
            .add({
              user: currentUser?.personalData,
              receiver: teacher,
              unreadMessage: "0",
              members: [currentUser?.personalData.firebaseId, teacherId],
              role: "admin",
            })
            .then((docRef) => {
              setCurrentChatId(docRef.id);
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
        }
      });
  };
  const markUnread = (openId) => {
    // let getOpenChat = chats.filter((ch) => ch.members.includes(currentUser?.personalData.firebaseId));
    db.collection("TeachersChat").doc(openId).update({
      unreadMessage: "0",
    });
    console.log("chats-getOpenChat", openId);
  };
  // const addFile = (e) => {
  //   if (e.target.files[0]) {
  //     let file = e.target.files[0];
  //     getBase64(file)
  //       .then((result) => {
  //         file["base64"] = result;
  //         setAudio(result);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     // setAudio(URL.createObjectURL(e.target.files[0]));
  //     // console.log("e.target.files[0]", result);
  //   }
  // };
  // const getBase64 = (file) => {
  //   return new Promise((resolve) => {
  //     let fileInfo;
  //     let baseURL = "";
  //     // Make new FileReader
  //     let reader = new FileReader();

  //     // Convert the file to base64 text
  //     reader.readAsDataURL(file);

  //     // on reader load somthing...
  //     reader.onload = () => {
  //       // Make a fileInfo Object
  //       // console.log("Called", reader);
  //       baseURL = reader.result;
  //       // console.log(baseURL);
  //       resolve(baseURL);
  //     };
  //   });
  // };
  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  const scrollToTop = () => {
    chatBoxRef.current.scrollTo({
      left: 0,
      top: chatBoxRef.current.clientHeight,
      behavior: "smooth",
    });

    const newChatFocus = chatBoxRef?.current?.focus();
    setTimeout(() => {
      const newChatFocus1 = document
        ?.getElementById("myChat")
        ?.focus({ focusVisible: true });
      const newChatFocus2 = document?.getElementById("myChat")?.click();
      // debugger
      // document.querySelector("myChat").addEventListener("mousedown", function () {
      //   console.log("mousedown", document.activeElement.id);
      // })

      const focus = document?.getElementById("myChat").focus();
      console.log("focusDiv click", document.activeElement);
      //  document.activeElement.blur()
      return document?.getElementById("myChat")?.click();
    }, 1000);
  };
  const handleRedirectChat = (chatlines) => {
    // console.log("chatlines");
    if (chatlines.receiver.firebaseId === currentUser.personalData.firebaseId) {
      handleList(chatlines?.user, chatlines);
    } else {
      handleList(chatlines?.receiver, chatlines);
    }
    //
  };
  const handelNewChatList = (t) => {
    // console.log("chatNewTeacher", t);
    if (t?.firebaseId === currentUser.personalData.firebaseId) {
      notify("User is not available for chat", true, notifi);
    } else {
      handleList(t);
    }
  };

  const handleList = async (item, chatLines) => {
    // console.log("itemDaaya", item);
    setCurrentTeacher(item);
    if (item?.firebaseId && currentUser.personalData.firebaseId) {
      setIsChat(true);
      getChats(item?.firebaseId, item);
    } else {
      notify("User is not available for chat", true, notifi);
    }
  };
  const handleBackMessage = () => {
    setIsChat(false);
  };
  const handleRemoveFile = () => {
    setAudio("");
  };
  useEffect(() => {
    // console.log("focusDiv chatBoxRef",chatBoxRef);
    // document.getElementById("myChat")?.click();
    scrollToTop();
    return document?.getElementById("myChat").focus();
  }, [chatBoxRef]);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chatMessages]);

  return (
    <>
      <div
        className="react-notification-alert-container"
        style={{ zIndex: 99 }}
      >
        <NotificationAlert ref={notifi} />
      </div>

      <div
        className="chatBotWidget_Conversation"
        /*       style={{ height: "88vh" }} */
        aria-live="polite"
      >
        <div
          className="chatBotWidget-header"
          style={{
            flexDirection: !isChat ? "column" : "row",
            border: "1px solid #999999",
            borderRadius: "5px",
          }}
        >
          {!isChat ? null : (
            <button
              onClick={handleBackMessage}
              title="Back"
              aria-label="Back"
              style={{
                paddingTop: 0,
                marginLeft: 5,
                height: 20,
                border: "none",
                borderRadius: "5%",
                marginTop: "7px",
                backgroundColor: "#cccccc",
                color: "#666666",
              }}
            >
              Back
            </button>
          )}

          <div>
            <h4
              className="rcw-title"
              style={{ paddingTop: 7, marginLeft: isChat ? 117 : 0 }}
            >
              {!isChat
                ? isPanel
                  ? "Please Select a Student/Admin"
                  : "Please Select a Student/Teacher"
                : "Welcome"}
            </h4>
            <span></span>
          </div>
        </div>
        <div
          className={isChat ? "chatBotWidget-msg_Container" : ""}
          style={{
            height: isChat ? "56vh" : " 63vh",
            maxHeight: isChat ? "56vh" : "66vh",
            backgroundColor: isChat ? "" : "aliceblue",
            display: isChat ? "" : "grid",
            overflowY: "auto",
            borderBottomLeftRadius: isChat ? "" : "13px",
            borderBottomRightRadius: "13px",
          }}
        >
          {!isChat ? (
            <>
              <div
                style={{
                  backgroundColor: "#007bff",
                  height: 30,
                  fontWeight: "bold",
                  justifyContent: "center",
                }}
              >
                Current Chats
              </div>
              <div
                className="scrollView"
                style={{
                  maxHeight: 230,
                  height: 50,
                  overflowY: "scroll",
                  display: "contents",
                }}
                // ref={chatBoxRef}
              >
                {chatsUser?.length > 0 ? (
                  <>
                    {chatsUser?.map((chatlines, index) => {
                      return (
                        <ListItem
                          key={index}
                          onClick={() => handleRedirectChat(chatlines)}
                          style={{ borderBottom: "1px solid black" }}
                        >
                          {console.log("chatlines", chatlines)}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              height: 40,
                            }}
                          >
                            <div>
                              <img
                                src={UserIcon}
                                class="chatBotWidget-avatar"
                                alt="profile"
                                aria-hidden="true"
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {chatlines?.receiver.firebaseId ===
                              currentUser?.personalData?.firebaseId
                                ? ` ${chatlines?.user?.firstName} ${chatlines?.user?.lastName}`
                                : ` ${chatlines?.receiver?.firstName} ${chatlines?.receiver?.lastName}`}
                            </div>
                            {chatlines?.unreadMessage !==
                              currentUser?.personalData?.firebaseId &&
                            chatlines?.unreadMessage !== "0" ? (
                              <MarkUnreadChatAltIcon
                                style={{
                                  position: "absolute",
                                  color: "red",
                                  right: 10,
                                  top: 10,
                                }}
                              />
                            ) : null}
                          </div>
                        </ListItem>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      height: 40,
                      justifyContent: "center",
                      alignItems: "center",
                    }}

                    // tabIndex="-1"
                  >
                    No Chats Found Please Start A New Conversation
                  </div>
                )}
              </div>
              <div
                style={{
                  backgroundColor: "#007bff",
                  height: 30,
                  fontWeight: "bold",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                ref={chatBoxRef}
                id="myChat"
                tabIndex="-1"
              >
                Select Student/Admin to Start New Chat
              </div>

              {teachers?.length > 0 ? (
                teachers?.map((t, index) => {
                  return (
                    <ListItem
                      key={index}
                      onClick={() => handelNewChatList(t)}
                      component="div"
                      style={{
                        cursor: "pointer",
                        borderBottom: "1px solid gray",
                        minHeight: 40,
                      }}
                    >
                      {`${t?.firstName} ${t?.lastName}`}
                    </ListItem>
                  );
                })
              ) : (
                <div>loading ...</div>
              )}
            </>
          ) : (
            <>
              {chatMessages?.map((item, i) => {
                return (
                  <ChatMsgRendererAdmin key={i} item={item} isPanel={isPanel} />
                );
              })}
            </>
          )}
        </div>

        {isChat ? (
          <form className="chatBotWidget-sender" onSubmit={onSubmit}>
            {audio ? (
              <div style={{ marginRight: 10 }}>
                <HighlightOffRoundedIcon
                  size={10}
                  style={{
                    position: "absolute",
                    right: 120,
                    bottom: 25,
                    zIndex: 2,
                  }}
                  onClick={(e) => handleRemoveFile()}
                />
                <audio
                  src={mediaBlobUrl}
                  style={{ borderRadius: 0, height: 20, width: "240px" }}
                  controls
                  controlsList="nodownload"
                />
              </div>
            ) : (
              <input
                type="text"
                className="chatBotWidget-new-message"
                name="message"
                autocomplete="off"
                placeholder="Type a message..."
                value={message}
                autoFocus
                onChange={(e) => setMessage(e.target.value)}
              />
            )}
            {/* <AttachFileIcon onClick={handleClick} /> */}
            {isRecording ? (
              // <StopCircleIcon onClick={stopRecordings} />
              <button
                onClick={stopRecordings}
                title="stop"
                aria-label="stop"
                style={{
                  background: "#cccccc",
                  color: "#666666",
                  border: "1px solid #999999",
                  borderRadius: "5px",
                  width: 60,
                  marginRight: "5px",
                }}
              >
                stop
              </button>
            ) : (
              // <MicNoneIcon onClick={startRecordings} />
              <button
                title="record"
                aria-label="record"
                onClick={startRecordings}
                style={{
                  background: "#cccccc",
                  color: "#666666",
                  border: "1px solid #999999",
                  borderRadius: "5px",
                  width: 60,
                  marginRight: "5px",
                }}
              >
                record
              </button>
            )}
            {/* <input
            type="file"
            accept="audio/*"
            ref={hiddenFileInput}
            onChange={addFile}
            hidden
          /> */}
            <button
              type="submit"
              style={{
                background: message === "" && !audio ? "#cccccc" : "#1d8cf8",
                color: message === "" && !audio ? "#666666" : "#fff",
                border: "1px solid #999999",
                borderRadius: "5px",
              }}
            >
              {message === "" && !audio ? "Send" : "Send"}
            </button>
          </form>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ChatBot;
