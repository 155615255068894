import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getSecurityGroupAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_SECURITY_GROUP,
      payload: {},
      isLoading: true,
    });
    let url = 'api/admin/securityGroup';
    if (payload) url = url + `?q=${payload}`;

    getRequest(url)
      .then((res) => {
    
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_SECURITY_GROUP_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.GET_SECURITY_GROUP_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const createSecurityGroupAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_SECURITY_GROUP,
      payload: {},
      isLoading: true,
    });

    postRequest('api/admin/securityGroup', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_SECURITY_GROUP_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.CREATE_SECURITY_GROUP_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editSecurityGroupAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_SECURITY_GROUP,
      payload: {},
      isLoading: true,
    });

    putRequest('api/admin/securityGroup', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_SECURITY_GROUP_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
    
        dispatch({
          type: actionTypes.EDIT_SECURITY_GROUP_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteSecurityGroupAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SECURITY_GROUP,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_SECURITY_GROUP_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.DELETE_SECURITY_GROUP_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

// Actions & Functions

export const getActionsAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ACTIONS,
      payload: {},
      isLoading: true,
    });

    postRequest('api/systemAction', payload)
      .then((res) => {
    
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_ACTIONS_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
     
        dispatch({
          type: actionTypes.GET_ACTIONS_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
export const getFunctionsAction = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_FUNCTIONS,
      payload: {},
      isLoading: true,
    });

    getRequest('api/systemFunction')
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_FUNCTIONS_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_FUNCTIONS_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

// export const clearSecurityGroup = () => {
//   return (dispatch) => {
//     dispatch({
//       type: actionTypes.SECURITY_GROUP_RESET,
//       payload: {},
//       isLoading: false,
//     });
//   };
// };
