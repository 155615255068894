import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getRequestReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REQUEST:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        RequestList: action.payload,
      };
    case actionTypes.GET_REQUEST_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_REQUEST_RESET':
      return {};
    default:
      return state;
  }
};

export const handleRequestReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_REQUEST:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.HANDLE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        handleRequestData: action.payload,
      };
    case actionTypes.HANDLE_REQUEST_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'HANDLE_REQUEST_RESET':
      return {};
    default:
      return state;
  }
};
