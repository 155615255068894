import axios from 'axios';
import jwtDecode from 'jwt-decode';

const baseUrl = 'https://atriev-lms.dt.r.appspot.com/';

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.request.use(async (req) => {
  if (localStorage.getItem('user')) {
    const data = JSON.parse(localStorage.getItem('user'));
    const userData = jwtDecode(data.token);
    const rightNow = new Date();
    const tokenValid = (userData.exp - rightNow.getTime() / 1000) / 60 > 1;
    if (userData) {
      req.headers.Authorization = `Bearer ${data.token}`;
    }
    if (tokenValid) {
      return req;
    } else {
      localStorage.removeItem('user');
      window.location.href = '/admin/login';
    }
  } else return req;
});

export const fileInstance = axiosInstance.interceptors.request.use(async (req) => {
  if (localStorage.getItem('user')) {
    const data = JSON.parse(localStorage.getItem('user'));
    const userData = jwtDecode(data.token);
    const rightNow = new Date();
    const tokenValid = (userData.exp - rightNow.getTime() / 1000) / 60 > 1;
    if (userData) {
      req.headers.Authorization = `Bearer ${data.token}`;
      req.headers.ContentType = 'multipart/form-data'
    }
    if (tokenValid) {
      return req;
    } else {
      localStorage.removeItem('user');
      window.location.href = '/admin/login';
    }
  } else return req;
});

export default axiosInstance;
