import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  editTeachingModuleAction,
  getTeachingModuleAction,
} from "store/action/teachingModuleAction/teachingModuleAction";
import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";

import Loader from "components/Loader/Loader";

const EditModule = (props) => {
  const { notify, onClick, data } = props;
  const { register, handleSubmit, errors } = useForm();
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const editModule = (editData) => {
    editData.id = data.id;
    props.editModule(editData);
  };

  useEffect(() => {
    if (props?.editModuleRes?.editModuleData) {
      notify(props?.editModuleRes?.editModuleData?.data?.message, false);
      props.clearResponse("EDIT_MODULE_RESET");
      // props.getModuleList();
      onClick(false);
    }
    if (props.editModuleRes?.error) {
      // notify(props.editModuleRes?.error?.message, true);
      props.clearResponse("EDIT_MODULE_RESET");
    }
  }, [props?.editModuleRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{}}>
          <Col md="8">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform:"capitalize"
                  }}
                >
                  Edit Module
                </h2>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(editModule)}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Title">Title</label>
                        <Input
                          id="Title"
                          defaultValue={data.title}
                          innerRef={register({ required: true })}
                          name="title"
                          placeholder="Title"
                          type="text"
                        />
                        {errors.title && errors.title.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter title
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Description">Description</label>
                        <Input
                          id="Description"
                          defaultValue={data.description}
                          innerRef={register({ required: true })}
                          name="description"
                          placeholder="Description"
                          type="text"
                        />
                        {errors.description &&
                          errors.description.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter Description
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button title="Submit" color="info" type="submit">
                      Submit
                    </Button>

                    <Button title="Back" onClick={() => onClick(false)}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  editModuleRes: state.editModule,
  getModuleRes: state.getModule,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editModule: (payload) => {
    dispatch(editTeachingModuleAction(payload));
  },
  getModuleList: () => {
    dispatch(getTeachingModuleAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EditModule);
