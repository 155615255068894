import { Cancel, ImportExport, CloudUpload } from "@material-ui/icons";
import axios from "axios";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import firebase from "firebase";
// import PDF from '../../components/PDF/PDF';

import Loader from "components/Loader/Loader";

import NotificationAlert from "react-notification-alert";

import { connect } from "react-redux";
import {
  createAssignmentAction,
  getAssignmentAction,
} from "store/action/assignmentAction/assignmentAction";

import { clearResponse } from "store/action/clearResponseAction";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
// import { db } from '../../MY_LOGIN_AND_SIGNUP/db/config'
import { func } from "prop-types";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddAssignment({ onClick, notify, ...props }) {
  const history = useHistory();
  const [bookFileName, setBookFileName] = useState();
  const [bookFileNameMob, setBookFileNameMob] = useState();

  const [bookImage, setBookImage] = useState();
  const [addformVisible, setAddFormVisible] = useState(false);
  const [imageUrl, setimageUrl] = useState("");
  const [bookCover, setBookCover] = useState();
  const [bookFileUrl, setBookFileUrl] = useState();
  const [bookFileUrlMob, setBookFileUrlMob] = useState();
  const [same, setSame] = useState(false);

  const [currentFile, setCurrentFile] = useState({});
  const [currentFileMob, setCurrentFileMob] = useState({});

  const [clickedFile, setClickedFile] = useState("");

  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const { register, handleSubmit, errors } = useForm();

  const location = useLocation();
  const notification = useRef();

  // const notifi = useRef();

  // const notify = (msg, err) => {
  //   let color = err == false ? 'success' : 'danger';
  //   let options = {
  //     type: color,
  //     place: 'tr',
  //     message: (
  //       <div>
  //         <div>
  //           <b>{err == false ? 'Sucess' : 'Error'}</b> -{msg}.
  //         </div>
  //       </div>
  //     ),
  //     icon: 'tim-icons icon-bell-55',
  //     autoDismiss: 6,
  //   };
  //   if (notifi.current) {
  //     notifi.current.notificationAlert(options);
  //   }
  //   // notification(options);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectBookCover = (event) => {};

  const selectBookFile = (event) => {};

  const selectBookFileMob = (event) => {};

  const handleSelectImg = (e) => {
    console.log(e.target.file);
  };

  const handleSubmitAssignment = (data) => {
    const payloadData = new FormData();
    payloadData.append("assignment_file", selectedFile);
    payloadData.append("description", data.description);
    payloadData.append("title", data.title);

    props.createAssignment(payloadData);
  };

  useEffect(() => {
    if (props?.createAssignmentRes?.createAssignmentData?.data) {
      notify("Assignment added successfully", false);
      props.clearRes("CREATE_ASSIGNMENT_RESET");
      props.getAssignmentList();
      onClick();
    }
    if (props?.createAssignmentRes?.error) {
      notify(props?.deleteAssignmentRes?.error?.message, true);
      props.clearRes("CREATE_ASSIGNMENT_RESET");
    }
  }, [props?.createAssignmentRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{}}>
        {/* <div className='react-notification-alert-container'>
          <NotificationAlert ref={notifi} />
        </div> */}
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          {/* <PDF
            handleClose={handleClose}
            clickedFile={clickedFile}
            currentFileMob={currentFileMob}
            currentFile={currentFile}
          /> */}
        </Dialog>
        <Row style={{}}>
          <Col md="11">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <h2
                    className="title"
                    style={{
                      fontSize: font?.fontSize + 2 || "inherit",
                      fontWeight: font?.fontWeight || "inherit",
                      marginRight: "auto",
                      textTransform: "capitalize",
                    }}
                  >
                    Add Assignment
                  </h2>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit((data, e) => {
                    handleSubmitAssignment(data);
                  })}
                >
                  <Row className="pr-md-1" md="1">
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Title">Title</label>
                        <Input
                          id="Title"
                          style={{ margin: "0" }}
                          innerRef={register({ required: true, minLength: 3 })}
                          name="title"
                          placeholder="Title"
                          type="text"
                          // required
                        />

                        {errors.title && errors.title.type === "minLength" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Title should be atleast 3 digits.
                          </span>
                        )}
                        {errors.title && errors.title.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Title is required.
                          </span>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="File">Upload File</label>
                        <Input
                          id="File"
                          style={{ opacity: "1", position: "relative" }}
                          type="file"
                          name="file"
                          accept=".txt, text/plain, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*,.pdf"
                          // value={selectedFile}
                          onChange={(e) => setSelectedFile(e.target.files[0])}
                        />

                        {errors.customFile && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            File required.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6" style={{}}>
                      <FormGroup>
                        <label htmlFor="Description">Description</label>
                        <Input
                          id="Description"
                          style={{
                            margin: "0",
                            minHeight: "118px",
                          }}
                          innerRef={register({ required: true, minLength: 3 })}
                          name="description"
                          placeholder="Description"
                          type="textarea"
                        />

                        {errors.description &&
                          errors.description.type === "minLength" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Description should be atleast 3 digits.
                            </span>
                          )}

                        {errors.description &&
                          errors.description.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Description is required.
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6"></Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 20,
                    }}
                  >
                    <Button
                      title="Submit"
                      color="info"
                      type="submit"
                      style={{ marginRight: 5 }}
                    >
                      Submit
                    </Button>

                    <Button title="Back" onClick={onClick}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  createAssignmentRes: state.createAssignment,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createAssignment: (payload) => {
    dispatch(createAssignmentAction(payload));
  },
  getAssignmentList: () => {
    dispatch(getAssignmentAction());
  },
  clearRes: (type) => {
    dispatch(clearResponse(type));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAssignment);
