import React, { useState } from "react";
import { Button } from "reactstrap";

import * as firebase from "firebase";
import {
  deleteTraineeAction,
  getTraineesAction,
} from "store/action/traineeActions/traineeAction";
import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";
import { useEffect } from "react";

function DeleteModal(props) {
  const { uid, notify, setUserData, setUserArr, onClick } = props;
  // const [visible, setVisible] = useState(false);

  const deleteRecord = () => {
    const url = `api/student?userId=${uid}`;
    props.deleteTrainee(url);
  };

  useEffect(() => {
    if (props?.deleteTraineeRes?.deleteTraineeData) {
      // notify("Data deleted successfully", false);
      props.clearResponse("DELETE_TRAINEE_RESET");
      // props.getTraineeList();
      setUserArr([])
      onClick();
    }
    if (props?.deleteTraineeRes?.error) {
      // notify(props?.deleteTraineeRes?.error?.message, true);
      props.clearResponse("DELETE_TRAINEE_RESET");
      onClick();
    }
  }, [props?.deleteTraineeRes]);

  return (
    <div style={{ padding: 20 }}>
      <p>Do you want to delete this record</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {visible===false ?<Button color='info' onClick={deleteRecord}>Yes</Button>:<Button color='info' onClick={onClick}>Done</Button> } */}

        <Button
          title="Yes"
          color="info"
          onClick={() => {
            deleteRecord();
          }}
        >
          Yes
        </Button>

        {
          <Button title="No" onClick={onClick}>
            No
          </Button>
        }
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteTraineeRes: state.deleteTrainee,
  getTraineeRes: state.getTrainee,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTrainee: (url) => {
    dispatch(deleteTraineeAction(url));
  },
  getTraineeList: () => {
    dispatch(getTraineesAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteModal);
