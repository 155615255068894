import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { connect } from "react-redux";

import {
  editTrainerAction,
  getTrainersAction,
} from "store/action/trainerActions/trainerActions";
import { clearResponse } from "store/action/clearResponseAction";
import { getUserType } from "Utilities/common/commonFn";

import * as firebase from "firebase";
import { db } from "MY_LOGIN_AND_SIGNUP/db/config";

function TrainerEditForm({ notify, userData, setUserData, onClick, ...props }) {
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [indicator, setIndicator] = useState([]);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const userInfo = getUserType();

  const submitData = async (data) => {
    setVisible(true);
    if (userInfo.userType !== "Teacher") {
      if (data.status == "Active") {
        data.status = 1;
      } else {
        data.status = 0;
      }
    } else {
      data.status = 1;
    }
    data.userId =
      userInfo.userType === "Teacher" ? userInfo.userId : userData.id;

    props.editTrainer(data);

    // onClick();
    // if (data.mobile < 4000000000) {
    //   setError('invalid mobile number');
    // } else {
    //   setError('');
    //   const finalData = {
    //     userID: userData._uniqueID,
    //     firstname: data.firstname,
    //     lastname: data.lastname,
    //     mobile: data.mobile,
    //     status: data.status,
    //     address: userData.address,
    //     shift: data.shift,
    //     createdFrom: userData.createdFrom,
    //     disabled: userData.disabled,
    //     email: userData.email,
    //     secondaryEmail: data.secondaryEmail,
    //     password: userData.password,
    //     profilePic: userData.profilePic,
    //     _uniqueID: userData._uniqueID,
    //     lastUpdatedAt:
    //       firebase.firestore.FieldValue.serverTimestamp(),
    //     sendEmail: data.sendEmail,
    //   };

    //   document.querySelector(
    //     '#loading-indicator'
    //   ).style.display = 'flex';
    //   if (data.secondaryEmail) {
    //     let query = await db
    //       .collection('zSystemTrainers')
    //       .where('secondaryEmail', '==', data.secondaryEmail)
    //       .get();

    //     if (query.docs.length > 0) {
    //       if (query.docs.length !== 1) {
    //         document.querySelector(
    //           '#loading-indicator'
    //         ).style.display = 'none';
    //         notify(
    //           'This Secondary email address is already exist',
    //           true
    //         );
    //         return;
    //       }
    //       let email = query.docs.map((doc) => doc.data());

    //       if (email[0]._uniqueID !== userData._uniqueID) {
    //         document.querySelector(
    //           '#loading-indicator'
    //         ).style.display = 'none';
    //         notify(
    //           'This Secondary email address is already exist',
    //           true
    //         );
    //         return;
    //       }
    //     }
    //   }
    //   // if (data.enrollment) {
    //   //   let query = await db
    //   //     .collection('zSystemTrainers')
    //   //     .where('enrollmentNO', '==', data.enrollment)
    //   //     .get();

    //   //   if (query.docs.length > 0) {
    //   //     if (query.docs.length !== 1) {
    //   //       document.querySelector(
    //   //         '#loading-indicator'
    //   //       ).style.display = 'none';
    //   //       notify('This Enrollment No is already exist', true);
    //   //       return;
    //   //     }
    //   //     let email = query.docs.map((doc) => doc.data());

    //   //     if (email[0]._uniqueID !== userData._uniqueID) {
    //   //       document.querySelector(
    //   //         '#loading-indicator'
    //   //       ).style.display = 'none';
    //   //       notify(
    //   //         'This  Enrollment No address is already exist',
    //   //         true
    //   //       );
    //   //       return;
    //   //     }
    //   //   }
    //   // }

    //   const updateStudent =
    //     functions.httpsCallable('updateTrainer');
    //   updateStudent(finalData)
    //     .then((result) => {

    //       if (result.data.hasError === false) {
    //         document.querySelector(
    //           '#loading-indicator'
    //         ).style.display = 'none';

    //         setUserData((prev) =>
    //           prev.map((item) =>
    //             item._uniqueID == userData._uniqueID
    //               ? finalData
    //               : item
    //           )
    //         );
    //         notify(
    //           'Trainer details updated successfully',
    //           false
    //         );
    //       } else {
    //         document.querySelector(
    //           '#loading-indicator'
    //         ).style.display = 'none';

    //         notify(
    //           result.data.message.errorInfo !== undefined
    //             ? result.data.message.errorInfo.message !==
    //                 undefined &&
    //                 result.data.message.errorInfo.message.toString()
    //             : result.data.message.toString() ||
    //                 'Something went wrong',
    //           true
    //         );
    //       }
    //     })
    //     .catch((err) => {
    //       notify(err.toString(), true);
    //       if (
    //         document.querySelector('#loading-indicator') !==
    //         null
    //       )
    //         document.querySelector(
    //           '#loading-indicator'
    //         ).style.display = 'none';

    //     });
    // }
  };

  useEffect(() => {
    if (props?.editTrainerRes?.editTrainerData?.data?.message) {
      // props.getTrainerList();

      // notify(props?.editTrainerRes?.editTrainerData?.data?.message, false);
      props.clearResponse("EDIT_TRAINER_RESET");
      onClick();
    }
    if (props.editTrainerRes?.error) {
      notify(props.editTrainerRes?.error?.message, true);
      props.clearResponse("EDIT_TRAINER_RESET");
    }
  }, [props?.editTrainerRes?.editTrainerData?.data]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{}}>
          <Col md="8">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform: "capitalize",
                  }}
                >
                  Edit Trainer
                </h2>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(submitData)}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="FirstName">First Name</label>
                        <Input
                          id="FirstName"
                          defaultValue={userData.firstName}
                          innerRef={register({ required: true })}
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="LastName">Last Name</label>
                        <Input
                          id="LastName"
                          defaultValue={userData.lastName}
                          innerRef={register}
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Mobile">Mobile</label>
                        <Input
                          id="Mobile"
                          defaultValue={userData.mobile}
                          innerRef={register({
                            minLength: 10,
                            maxLength: 10,
                            required: true,
                          })}
                          name="mobile"
                          placeholder="10-digit Number"
                          type="number"
                          required
                        />
                        {errors.mobile && errors.mobile.type !== "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Valid Mobile No.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Email">Email</label>
                        <Input
                          id="Email"
                          innerRef={register({ required: false })}
                          name="email"
                          placeholder="Email"
                          type="email"
                          defaultValue={userData.email}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Password">Password</label>
                        <Input
                          id="Password"
                          innerRef={register({ minLength: 8 })}
                          name="password"
                          placeholder="New Password"
                          type="password"
                          required={
                            userInfo.userType === "Teacher" ? true : false
                          }
                        />
                        {errors.password &&
                          errors.password.type === "minLength" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Password should be atleast 8 digits.
                            </span>
                          )}
                        {/* {errors.paswd && errors.paswd.type === 'required' && (
                          <span
                            style={{
                              fontSize: '10px',
                              position: 'absolute',
                              bottom: '-20px',
                              color: 'red',
                            }}
                          >
                            Please enter password.
                          </span>
                        )} */}
                      </FormGroup>
                    </Col>
                    {userInfo.userType === "Teacher" ? null : (
                      <Col className="pr-md-1" md="6" style={{}}>
                        <FormGroup>
                          <label htmlFor="Status">Status</label>
                          <Input
                            id="Status"
                            defaultValue={userData.status}
                            innerRef={register({ required: true })}
                            name="status"
                            placeholder="Status"
                            type="select"
                            required
                          >
                            <option>Active</option>
                            <option>Inactive</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      title="Update"
                      color="info"
                      type="submit"
                      style={{ marginRight: 5 }}
                    >
                      Update
                    </Button>

                    <Button title="Back" onClick={onClick}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  editTrainerRes: state.editTrainer,
  getTrainerData: state.getTrainer,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  editTrainer: (payload) => {
    dispatch(editTrainerAction(payload));
  },
  getTrainerList: () => {
    dispatch(getTrainersAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainerEditForm);
