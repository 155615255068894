import React, { useState, useRef } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useForm } from "react-hook-form";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { db } from "./db/config";
import * as firebase from "firebase";
import { ErrorOutlineSharp, Router } from "@material-ui/icons";
import * as yup from "yup";
import { Input, FormGroup, Form } from "reactstrap";
import NotificationAlert from "react-notification-alert";

// redux
import { connect } from "react-redux";
import { adminLoginAction, clearLogin } from "store/action/auth/loginAction";

import { getUserType } from "Utilities/common/commonFn";
import { useEffect } from "react";

import jwtDecode from "jwt-decode";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import ModalComp from "components/ModalComp/ModalComp";
import ForgotPassword from "forgotPassword/forgotPassword";
import Loader from "components/Loader/Loader";
// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
//       <Link color="inherit" href="https://material-ui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  body: {
    width: "50%",
    height: "100%",
    minHeight: "100vh",
  },
  root: {
    marginLeft: theme.spacing(5),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const { url } = props;

  const pathOptions = [
    {
      value: "Dashboard",
      path: "/dashboard",
    },
    {
      value: "User_Management",
      path: "/tables",
    },
    {
      value: "Student_Management",
      path: "/student",
    },
    {
      value: "Book_Management",
      path: "/book",
    },
    {
      value: "Quiz_Management",
      path: "/quiz",
    },
    {
      value: "Package_Management",
      path: "/package",
    },
    // {

    //     value:"Transaction_Management",
    //     path: "/transaction",

    // },
    {
      value: "Chat",
      path: "/chat",
    },
    {
      value: "Reports",
      path: "/",
    },
  ];

  let loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [loginData, setLoginData] = useState({});

  const [isTeacher, setIsTeacher] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmitLogin = () => {
    props.loginReq(url, loginData);
  };

  useEffect(() => {
    if (localStorage.getItem("user")) {
      const userType = getUserType().userType;

      if (userType == "Admin") {
        history.push("admin/tables");
      }
      if (userType == "Teacher") {
        if (
          props?.loginData?.userData?.data[0]?.personalData?.isUpdated === 0
        ) {
          history.push("/trainer/profile");
        } else {
          history.push("/trainer/dashboard");
        }
      }
    } else {
      if (localStorage.getItem("logout")) {
        localStorage.removeItem("logout");
        window.location.reload(false);
      }
    }
  }, []);

  useEffect(() => {
    if (props?.loginData?.userData) {
      localStorage.setItem(
        "user",
        JSON.stringify(props.loginData.userData.data[0])
      );

      const userType = getUserType().userType;

      if (userType == "Admin") {
        history.push("admin/tables");
      }
      if (userType == "Teacher") {
        if (
          props?.loginData?.userData?.data[0]?.personalData?.isUpdated === 0
        ) {
          history.push("/trainer/profile");
        } else {
          history.push("/trainer/dashboard");
        }
      }
    }
    if (props.loginData.error) {
      notify(props.loginData?.error?.message, true);
      props.clearData();
    }
  }, [props.loginData]);

  return (
    <div
      style={{
        overflow: "hidden",
        height: window.innerHeight,
        width: window.innerWidth,
        background: `url(${"https://crimsonbeans-my.sharepoint.com/personal/honey_yadav_crimsonbeans_co_in/Documents/Microsoft%20Teams%20Chat%20Files/back.jpg"})`,
      }}
    >
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notifi} />
      </div>

      <Container
        className={classes.root}
        component="main"
        maxWidth="xs"
        style={{ margin: "auto", marginTop: 150 }}
      >
        <CssBaseline />
        {isForgotPassword ? (
          <ForgotPassword
            isForgotPassword={isForgotPassword}
            setIsForgotPassword={setIsForgotPassword}
            notify={notify}
            setIsLoading={setIsLoading}
          />
        ) : (
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2" style={{ color: "black" }}>
              Sign in
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: " flex-start",
                width: "100%",
                padding: " 0 5%",
                margin: "7% 0 -9%",
              }}
            ></div>
            <Form
              className={classes.form}
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                if (loginData.email && loginData.password) {
                  handleSubmitLogin();
                }
              }}

              // {handleSubmit(
              //   (data) => {

              //   let email = data.email;
              //   let password = data.password;
              //   if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
              //     setEmailError('');
              //     setPasswordError('Email is not in valid format');
              //     return;
              //   } else {
              //     setPasswordError('');
              //     firebase
              //       .auth()
              //       .signInWithEmailAndPassword(email, password)
              //       .then(function (user) {
              //         // Change start
              //         props.history.replace(`/admin/tables}`);

              //         // Change end

              //         db.collection('zSystemUsers')
              //           .doc(user.user.uid)
              //           .get()
              //           .then((querySnapshot) => {
              //             let data = querySnapshot.data();

              //             let currentPermission = pathOptions.filter((item) =>
              //               data.permissions.find((v) => v.value == item.value)
              //             );

              //             if (currentPermission.length !== 0) {

              //               props.history.replace(
              //                 `/admin${currentPermission[0].path}`
              //               );
              //             }

              //           })
              //           .catch((err) => console.log(err));
              //       })
              //       .catch(function (error) {
              //         setEmailError('Wrong email or password');

              //       });
              //   }
              // }
              // )}
            >
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Grid item xs>
                  {emailError !== "" && (
                    <p
                      style={{
                        color: "#ff0066",
                        textAlign: "center",
                        marginTop: 20,
                      }}
                    >
                      {emailError}
                    </p>
                  )}
                </Grid>
              </Grid>
              <FormGroup
                style={{
                  marginBottom: 35,
                  marginTop: emailError === "" ? 25 : 5,
                }}
              >
                <Input
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  type="email"
                  innerRef={register({ required: true })}
                  style={{ color: "black", background: "#fff", marginTop: 20 }}
                />
                {passwordError !== "" && (
                  <p style={{ color: "#ff0066", textAlign: "center" }}>
                    {passwordError}
                  </p>
                )}
                {/* {emailError!==" "&&<p style={{color:'red'}}>{emailError}</p>} */}
              </FormGroup>
              <FormGroup
                style={{
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #2b3553",
                  marginTop: 50,
                  borderRadius: "0.4285rem",
                }}
              >
                <Input
                  name="password"
                  onChange={handleChange}
                  placeholder="Password"
                  type={visible ? "text" : "password"}
                  innerRef={register({ required: true })}
                  style={{ color: "black", background: "#fff", border: "none" }}
                />
                <button
                  style={{
                    background: "transparent",
                    height: "fit-content",
                    width: "fit-content",
                    border: "none",
                  }}
                  type="button"
                  onClick={() => setVisible((prev) => !prev)}
                  // title={visible ? "showPassword" : "hidePassword"}
                  aria-label={visible ? "Show password" : "Hide password"}
                >
                  {visible ? (
                    <VisibilityIcon
                      style={{
                        cursor: "pointer",
                        margin: "auto 0",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </button>

                {/* {passwordError!=="" && <p style={{color:'red'}}>{passwordError}</p>} */}
              </FormGroup>
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <button
                  type="button"
                  title="Forgot Password"
                  aria-label="Forgot Password"
                  style={{
                    border: "none",
                    marginBottom: "5px",
                    cursor: "pointer",
                    background: "inherit",
                  }}
                  onClick={() => setIsForgotPassword(true)}
                >
                  Forgot Password
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <p style={{ color: "grey", margin: "0 8px" }}>Login as a</p>
                <div
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={() => history.push("/admin/login")}
                    style={{ scale: "1.2" }}
                    name="login-option"
                    id="admin"
                    type="radio"
                    defaultChecked={window.location.pathname === "/admin/login"}
                  />

                  <label style={{ margin: "0px" }} htmlFor="admin">
                    Admin
                  </label>
                </div>
                <p style={{ color: "grey", margin: "0 8px" }}>or</p>

                <div
                  style={{
                    display: "flex",
                    gap: "0.25rem",
                    alignItems: "center",
                  }}
                >
                  <input
                    onChange={() => history.push("/trainer/login")}
                    style={{ scale: "1.2" }}
                    name="login-option"
                    id="trainer"
                    type="radio"
                    defaultChecked={
                      window.location.pathname === "/trainer/login"
                    }
                  />

                  <label style={{ margin: "0px" }} htmlFor="trainer">
                    Trainer
                  </label>
                </div>
                {/* <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  margin: "5px 0 0 auto",
                  padding: "8px",
                  borderRadius: "4px",
                }}
                type="button"
                onClick={() => {
                  url === "api/admin/login"
                    ? history.push("/trainer/login")
                    : history.push("/admin/login");
                }}
              >
                {url === "api/admin/login"
                  ? "Login as a Trainer"
                  : "Login as an Admin"}
              </button> */}
              </div>

              <Button
                type="submit"
                title="Sign In"
                aria-label="Sign In"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Form>
          </div>
        )}
      </Container>

      {/* <ModalComp controller={isForgotPassword} title="forgot password">
        <ForgotPassword
          isForgotPassword={isForgotPassword}
          setIsForgotPassword={setIsForgotPassword}
          notify={notify}
          setIsLoading={setIsLoading}
        />
      </ModalComp> */}

      {isLoading ? <Loader /> : null}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  loginData: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  loginReq: (url, payload) => {
    dispatch(adminLoginAction(url, payload));
  },
  clearData: () => {
    dispatch(clearLogin());
  },
  // reset: () => {
  //   dispatch(reset());
  // },
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);

{
  /* <Box mt={8}>
        <Copyright />
        </Box> */
}
{
  /* <Link href="#" variant="body2">
                  Forgot password?
                </Link> */
}

{
  /* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */
}

{
  /* <TextField
              variant="outlined"
              margin="normal"
              inputRef={register({required:true,})}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={errors.email?true:false}
              
            /> */
}
{
  /* {errors.email && errors.email.message} */
}
{
  /* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputRef={register({required:true,minLength:6})}
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={errors.password?true:false}
              />
  
            {errors.password&&<p>{errors.password}</p>} */
}
{
  /* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */
}
