import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getTeachingModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MODULE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        moduleList: action.payload,
      };
    case actionTypes.GET_MODULE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_MODULE_RESET":
      return {};
    default:
      return state;
  }
};
export const createTeachingModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MODULE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.CREATE_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createModuleData: action.payload,
      };
    case actionTypes.CREATE_MODULE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "CREATE_MODULE_RESET":
      return {};
    default:
      return state;
  }
};
export const editTeachingModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.EDIT_MODULE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.EDIT_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
        editModuleData: action.payload,
      };
    case actionTypes.EDIT_MODULE_FAIL:
      return { isLoading: action.isLoading, error: action.payload };
    case "EDIT_MODULE_RESET":
      return {};

    default:
      return state;
  }
};

export const deleteTeachingModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MODULE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteModuleData: action.payload,
      };
    case actionTypes.DELETE_MODULE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "DELETE_MODULE_RESET":
      return {};
    default:
      return state;
  }
};
