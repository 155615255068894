import { ImportExport } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  createTraineeAction,
  getTraineesAction,
} from "store/action/traineeActions/traineeAction";
import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";

import * as firebase from "firebase";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

//

function AddStudent(props) {
  const { notify, setModal } = props;
  const [visible, setVisible] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [shift, setShift] = useState(false);
  const [error, setError] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    setVisible(true);
    data.orgId = 1;
    props.createTrainee(data);
  };

  useEffect(() => {
    if (props?.createTraineeRes?.createTraineeData) {
      props.getTraineeList();
      notify("Trainee added successfully", false);
      props.clearResponse("CREATE_TRAINEE_RESET");
      setModal(false);
    }
    if (props?.createTraineeRes?.error) {
      notify(props?.createTraineeRes?.error?.message, true);
      props.clearResponse("CREATE_TRAINEE_RESET");
    }
  }, [props?.createTraineeRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{}}>
          <Col md="8">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform:'capitalize'
                  }}
                >
                  Add Student
                </h2>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="FirstName">First Name</label>
                        <Input
                          id="FirstName"
                          innerRef={register({ required: true })}
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                        />
                        {errors.firstName &&
                          errors.firstName.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter first name
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="LastName">Last Name</label>
                        <Input
                          id="LastName"
                          innerRef={register({ required: true })}
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                        />
                        {errors.lastName &&
                          errors.lastName.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter last name
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  {/* <Row>
                      <Col className="pr-md-1" md="6">
                        <FormGroup>
                          <label>Email</label>
                          <Input
                          innerRef={register({required:true,})}
                            name='email'
                            placeholder="user@user.com"
                            type="email"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-md-1" md="6">
                        <FormGroup>
                          <label>Password</label>
                          <Input
                            innerRef={register({required:true,minLength:6})}
                            required
                            name='password'
                            type="password"
                            placeholder='Password'
                          />
                        </FormGroup>
                      </Col>
                    </Row> */}

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Email">Email</label>
                        <Input
                          id="Email"
                          innerRef={register({ required: true })}
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter email
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Password">Password</label>
                        <Input
                          id="Password"
                          innerRef={register({ required: true, minLength: 8 })}
                          name="paswd"
                          placeholder="Password"
                          type="password"
                        />
                        {errors.paswd && errors.paswd.type === "minLength" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Password should be atleast 8 digits.
                          </span>
                        )}
                        {errors.paswd && errors.paswd.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter password.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                    {/* <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Secondary Email</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='secondaryEmail'
                          placeholder='Email'
                          type='email'
                        />
                        {errors.secondaryEmail &&
                          errors.secondaryEmail.type === 'required' && (
                            <span
                              style={{
                                fontSize: '10px',
                                position: 'absolute',
                                bottom: '-20px',
                                color: 'red',
                              }}
                            >
                              Please enter secondary email
                            </span>
                          )}
                      </FormGroup>
                    </Col> */}
                    {/* <FormGroup>
                        <label>Enrollment number</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='enrollment'
                          placeholder='Enrollment number'
                          type='text'
                        />

                        {errors.enrollment &&
                          errors.enrollment.type === 'required' && (
                            <span
                              style={{
                                fontSize: '10px',
                                position: 'absolute',
                                bottom: '-20px',
                                color: 'red',
                              }}
                            >
                              Please enter enrollment number
                            </span>
                          )}
                      </FormGroup> */}

                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Mobile">Mobile</label>
                        <Input
                          id="Mobile"
                          innerRef={register({
                            minLength: 10,
                            maxLength: 10,
                            required: true,
                          })}
                          name="mobile"
                          placeholder="10-digit Number"
                          type="number"
                        />
                        {error && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            {error}
                          </span>
                        )}
                        {errors.mobile && errors.mobile.type !== "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Valid Mobile No.
                          </span>
                        )}
                        {errors.mobile && errors.mobile.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Mobile No.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Address</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='address'
                          placeholder='Address'
                          type='textarea'
                        />
                        {errors.address && errors.address.type === 'required' && (
                          <span
                            style={{
                              fontSize: '10px',
                              position: 'absolute',
                              bottom: '-20px',
                              color: 'red',
                            }}
                          >
                            Please enter address
                          </span>
                        )}
                      </FormGroup>
                    </Col> */}
                  </Row>
                  {/* <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Shift</label>
                        <Input
                          innerRef={register({ required: true })}
                          name='shift'
                          type='select'
                          required
                        >
                          <option value=''>Select Shift</option>
                          <option value='morningShift'>Morning Shift</option>
                          <option value='afternoonShift'>
                            Afternoon Shift
                          </option>
                          <option value='eveningShift'>Evening Shift</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row> */}
                  {/* <Row>
                    <Col
                      className='pr-md-1'
                      md='6'
                      style={{ paddingLeft: '70px' }}
                    >
                      <div>
                        <input
                          name='sendEmail'
                          type='checkbox'
                          value={sendEmail}
                          onChange={(e) => setSendEmail(!sendEmail)}
                          style={{ marginRight: '10px' }}
                        />
                        <span
                          style={{
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.6)',
                          }}
                        >
                          Send Invitation mail
                        </span>
                      </div>
                    </Col>

                    <Col
                      className='pr-md-1'
                      md='6'
                      style={{ paddingLeft: '70px' }}
                    >
                      <div>
                        <input
                          name='sendSMS'
                          type='checkbox'
                          value={sendSMS}
                          onChange={(e) => setSendSMS(!sendSMS)}
                          style={{ marginRight: '10px' }}
                        />
                        <span
                          style={{
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.6)',
                          }}
                        >
                          Send Invitation SMS
                        </span>
                      </div>
                    </Col>
                  </Row> */}
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* {visible===false? <Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>Done</Button>}
                  {visible===false && <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}

                    <Button title="Submit" color="info" type="submit">
                      Submit
                    </Button>

                    <Button title="Back" onClick={() => setModal(false)}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  createTraineeRes: state.createTrainee,
  getTraineeRes: state.getTrainee,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createTrainee: (payload) => {
    dispatch(createTraineeAction(payload));
  },
  getTraineeList: () => {
    dispatch(getTraineesAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddStudent);
