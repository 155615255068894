import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import {
  deleteSecurityGroupAction,
  getSecurityGroupAction,
} from "store/action/SecurityGroupAction/SecurityGroupAction";

import { clearResponse } from "store/action/clearResponseAction";

function DeleteSecurityGroup(props) {
  const { onClick, mySecGrp, setSecurityArr, notify } = props;

  const [visible, setVisible] = useState(false);

  // let mystatus = myUser.disabled === false ? 'disable' : 'enable';

  const deleteSecGrp = () => {
    const deleteUrl = `api/admin/securityGroup?id=${mySecGrp.id}`;

    props.deleteSecurityGroup(deleteUrl);
  };

  useEffect(() => {
    if (props.deleteSecurityGroupRes.deleteSecurityGroupData) {
      props.getSecurityGroupList();
      notify("Security group deleted successfully", false);
      props.clearResponse("DELETE_SECURITY_GROUP_RESET");
      setSecurityArr([])
      onClick();
    }

    if (props.deleteSecurityGroupRes?.error) {
      notify(props.deleteSecurityGroupRes?.error?.message, true);
      props.clearResponse("DELETE_SECURITY_GROUP_RESET");

      onClick();
    }
  }, [props?.deleteSecurityGroupRes]);

  return (
    <div>
      <p>Do you want to delete this Security group?</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button title="Yes" color="info" onClick={deleteSecGrp}>
          Yes
        </Button>

        <Button title="No" onClick={onClick}>
          No
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  deleteSecurityGroupRes: state.deleteSecurityGroup,
  getSecurityGroupRes: state.getSecurityGroup,
});

const mapDispatchToProps = (dispatch) => ({
  deleteSecurityGroup: (payload) => {
    dispatch(deleteSecurityGroupAction(payload));
  },
  getSecurityGroupList: () => {
    dispatch(getSecurityGroupAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSecurityGroup);
