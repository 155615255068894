import * as actionTypes from '../actionType';
import axios from 'axios';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
  postFormRequest,
} from 'Utilities/apiService/admin';

export const getAssignmentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });
    let url = 'api/assignment';
    if (payload) url = url + `?q=${payload}`;

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.GET_ASSIGNMENT_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const createAssignmentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });

    // postRequest('api/assignment', payload)
    // const user = localStorage.getItem('user')
    //   ? JSON.parse(localStorage.getItem('user'))
    //   : '';

    // if (user)
    //   axios
    //     .post('https://atriev-lms.dt.r.appspot.com/api/assignment', payload, {
    //       headers: {
    //         'Content-Type': 'multipart/form-data',
    //         Authorization: `Bearer ${user.token}`,
    //       },
    //     })

    postFormRequest('api/assignment', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.CREATE_ASSIGNMENT_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editAssignmentAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });

    postFormRequest('api/assignment/update', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.EDIT_ASSIGNMENT_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteAssignmentAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ASSIGNMENT,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_ASSIGNMENT_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.DELETE_ASSIGNMENT_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
