export const fontAction = () => {
  return (dispatch) => {
    const fontValue = JSON.parse(localStorage.getItem('fnt')) || '';

    if (fontValue)
      dispatch({
        type: 'GET_FONT',
        payload: { data: fontValue },
      });
  };
};
