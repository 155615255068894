import React from "react";
import { Button } from "reactstrap";

function DeleteQuizModal({ setDeleteQuizModal, deleteQuiz }) {
  const deleteRecord = () => {
    // console.log('data...',deleteQuiz)

    deleteQuiz();
    setDeleteQuizModal((prev) => !prev);
  };

  return (
    <div>
      <p>Do you want to delete this Question</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Button title="Yes" color="info" onClick={deleteRecord}>
          Yes
        </Button>

        <Button title="No" onClick={() => setDeleteQuizModal((prev) => !prev)}>
          No
        </Button>
      </div>
    </div>
  );
}

export default DeleteQuizModal;
