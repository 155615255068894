/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Input as MInput,
  withStyles,
  Menu,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
//import { Modal } from '@material-ui/core';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalBody,
  Input,
  FormGroup,
} from "reactstrap";
import { Edit, Delete, Add, Tune, Clear } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import FilterListIcon from "@material-ui/icons/FilterList";
import SortIcon from "@material-ui/icons/Sort";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import GroupIcon from "@material-ui/icons/Group";
import KeyboardArrowLeftSharpIcon from "@material-ui/icons/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";

import AddStudent from "./student_add_and_edit_form";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import EditStudent from "./Student_Edit._Form";
import DeleteModal from "./Delete_Modal";
import NotificationAlert from "react-notification-alert";
import firebase from "firebase";

import { CSVLink } from "react-csv";

import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import AddMaterialStudent from "./AddMaterialStudent";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";

import {
  getTraineesAction,
  traineeBatchRelationAction,
} from "store/action/traineeActions/traineeAction";

import { clearResponse } from "store/action/clearResponseAction";
import { connect } from "react-redux";
import { getBatchAction } from "store/action/batchActions/batchActions";
import Loader from "components/Loader/Loader";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import DropdownForDisplay from "components/DropdownForDisplay/DropdownForDisplay";
import { traineeBulkDeleteAction } from "store/action/bulkDeleteAction/traineeBulkDeleteAction";
import BulkDeleteTrainee from "./BulkDeleteTrainee";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function StudentsTable(props) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };
  const [limit, setLimit] = useState(10);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSort, setAnchorElSort] = React.useState(null);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const [userData, setUserData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [editUser, setEditUser] = useState();
  const [deleteUserVisible, setDeleteUserVisible] = useState(false);
  const [deletedUser, setDeletedUser] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);
  const [userArr, setUserArr] = useState([]);

  const [batchList, setBatchList] = useState([]);

  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
  const [studentSearchValue, setStudentSearchValue] = useState("");

  const [search, setSearch] = useState(false);

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  useEffect(() => {
    document.title = "Trainee Management";
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
  };

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getTraineeList();
    } else {
      setSearch(true);
      props.getTraineeList(studentSearchValue);
    }
  };

  const getUsers = (orderBy) => {};

  useEffect(() => {
    if (props?.getTraineeRes?.traineeList) {
      if (props?.getTraineeRes?.traineeList?.data.length == 0) {
        notify("No data found.", true);
        setUserData("");
      } else {
        setUserData(props?.getTraineeRes?.traineeList?.data);
        props.clearResponse("GET_TRAINEE_RESET");
      }
    }
    if (props?.getTraineeRes?.error) {
      notify(props?.getTraineeRes?.error?.message, true);
      props.clearResponse("GET_TRAINEE_RESET");
    }
  }, [props.getTraineeRes]);

  useEffect(() => {
    if (props?.getTraineeRes?.traineeList) {
      if (props?.getTraineeRes?.traineeList?.data.length !== 0) {
        // notify("Trainee's Data Fetched Successfully", false);
      }
    }
  }, [props.getTraineeRes.traineeList]);

  useEffect(() => {
    if (props?.getBatchRes?.batchList) {
      const data = props?.getBatchRes?.batchList?.data;
      setBatchList(
        data.map((item) => {
          return { label: item.title, value: item.id };
        })
      );
    }
  }, [props.getBatchRes]);

  useEffect(() => {
    if (props?.getBatchRes?.batchList) {
      // notify('Training Data Fetched Successfully', false);
    }
  }, [props?.getBatchRes?.batchList]);

  useEffect(() => {
    if (props?.traineeBatchRelationRes?.traineeBatchRelationData?.data) {
      notify(
        props?.traineeBatchRelationRes?.traineeBatchRelationData?.data?.message,
        false
      );
      props.clearResponse("TRAINEE_BATCH_RELATION_RESET");

      props.getTraineeList();
    }
    if (props?.traineeBatchRelationRes?.error) {
      notify(props?.traineeBatchRelationRes?.error?.message, true);
      props.clearResponse("TRAINEE_BATCH_RELATION_RESET");
    }
  }, [props?.traineeBatchRelationRes]);

  useEffect(() => {
    if (!props?.getBatchRes?.batchList) {
      props.getBatchList();
    }
    if (!props?.getTraineeRes?.traineeList) {
      props.getTraineeList();
    }
  }, []);

  useEffect(() => {
    if (props?.deleteTraineeRes?.deleteTraineeData) {
      notify(props?.deleteTraineeRes?.deleteTraineeData?.data?.message, false);
      props.clearResponse("DELETE_TRAINEE_RESET");
      props.getTraineeList();
      setDeleteUserVisible(false);
    }
    if (props?.deleteTraineeRes?.error) {
      notify(props?.deleteTraineeRes?.error?.message, true);
      props.clearResponse("DELETE_TRAINEE_RESET");
      setDeleteUserVisible(false);
    }
  }, [props?.deleteTraineeRes]);

  useEffect(() => {
    if (props?.editTraineeRes?.editTraineeData?.data) {
      props.clearResponse("EDIT_TRAINEE_RESET");
      notify(props?.editTraineeRes?.editTraineeData?.data?.message, false);
      props.getTraineeList();
      setEditVisible(false);
    }
    if (props?.editTraineeRes?.error) {
      notify(props?.editTraineeRes?.error?.error?.message, true);
      props.clearResponse("EDIT_TRAINEE_RESET");
    }
  }, [props?.editTraineeRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const handleRelation = (e, studentId) => {
    const batchId = e.target.value;
    const data = { batchId: +batchId, studentId: studentId };
    props.traineeBatchRelation(data);
  };

  const emptyStudent = () => {
    getUsers();
  };
  if (!localStorage.getItem("user")) history.push("/login");
  return (
    <>
      <main id="main" className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <h1
                  style={{
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    margin: "0 auto 0 0",
                    textTransform: "capitalize",
                  }}
                >
                  TRAINEE MANAGEMENT
                </h1>

                <Button
                  title="Create Trainee"
                  color="info"
                  // style={{ marginRight: 25 }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Create Trainee <Add />
                </Button>
              </CardHeader>
              <Row style={{ marginLeft: "5px" }}>
                <Col sm={4}>
                  <Input
                    title="Search Student"
                    type="search"
                    name="search"
                    placeholder="Search Student"
                    onChange={(e) => (
                      setStudentSearchValue(e.target.value),
                      e.target.value.length === 0 && emptyStudent(),
                      setSearch(false)
                    )}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    title="Search"
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    onClick={studentSearch}
                  >
                    <SearchIcon />
                  </Button>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  sm={6}
                >
                  {userArr.length > 0 ? (
                    <Button
                      title="Delete Marked"
                      aria-label="Delete Marked"
                      style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                      color="danger"
                      onClick={() => {
                        setBulkDeleteVisible(true);
                      }}
                    >
                      Delete Marked
                    </Button>
                  ) : null}
                </Col>
              </Row>
              <Modal
                isOpen={isModalVisible}
                style={{ display: "flex", flex: 1 }}
                size="lg"
              >
                <ModalBody
                  style={{
                    width: "100%",
                    display: "inline-block",
                    padding: "0px !important",
                    position: "absolute",
                    border: "1px solid white !important",
                  }}
                  size="sm"
                >
                  <AddStudent
                    notify={notify}
                    setModal={(val) => setIsModalVisible(val)}
                  ></AddStudent>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={bulkDeleteVisible}
                color="primary"
                style={{
                  display: "flex",
                  flex: 1,
                  top: 0,
                  width: 250,
                  backgroundColor: "#132639",
                  objectFit: "fill",
                }}
              >
                <ModalBody style={{ width: "100%" }} size="sm">
                  <BulkDeleteTrainee
                    userArr={userArr !== undefined && userArr}
                    setUserArr={setUserArr}
                    notify={notify}
                    onClick={() => {
                      setBulkDeleteVisible(false);
                    }}
                  ></BulkDeleteTrainee>
                </ModalBody>
              </Modal>

              <CardBody>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Trainee Name</th>
                      <th style={{ textAlign: "center" }}>Email</th>
                      <th style={{ textAlign: "center" }}>Mobile</th>
                      <th style={{ textAlign: "center" }}>Training</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.length ? (
                      userData.map((user, i) => {
                        if (
                          user.firstName === undefined &&
                          user.lastNaxme === undefined
                        ) {
                          return null;
                        }
                        return (
                          <tr
                            key={user._uniqueID}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                                color: "white",
                                marginTop: "5px",
                              }}
                            >
                              <Input
                                id={user?.id}
                                defaultChecked={false}
                                type="checkbox"
                                key={user?.id}
                                value={user?.id}
                                onChange={handleCheckbox}
                              />
                            </td>
                            <th>
                              <label
                                style={{
                                  fontSize: font?.fontSize || "inherit",
                                  fontWeight: font?.fontWeight || "inherit",
                                  color: "#FFFFFFB3",
                                }}
                                htmlFor={user?.id}
                              >
                                {user.firstName + " " + user.lastName}
                              </label>
                            </th>
                            <td style={{ textAlign: "center" }}>
                              {user.email}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {user.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {user?.studentBatchRelations?.length !== 0 &&
                              user?.studentBatchRelations[0]?.batch?.title ? (
                                <>
                                  <span>
                                    {/* {user?.studentBatchRelations.length !==
                                    0 ? ( */}
                                    <DropdownForDisplay
                                      arr={user?.studentBatchRelations}
                                      data="batch"
                                      aria-label="Trainings"
                                      title="Trainings"
                                    />
                                    {/* ) : null} */}
                                  </span>
                                </>
                              ) : batchList ? (
                                <FormGroup>
                                  <Input
                                    name="batchId"
                                    type="select"
                                    required
                                    onChange={(e) => {
                                      handleRelation(e, user.id);
                                    }}
                                  >
                                    <option
                                      style={{ textAlign: "center" }}
                                      value={""}
                                    >
                                      Select training
                                    </option>
                                    {batchList &&
                                      batchList.map((item) => {
                                        return (
                                          <option
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        );
                                      })}
                                  </Input>
                                </FormGroup>
                              ) : (
                                <p>No trainings available</p>
                              )}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {user.status == 1 ? "Active" : "Inactive"}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <button
                                title="Edit"
                                aria-label="Edit"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setEditVisible(true);
                                  setEditUser(user);
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>

                              <button
                                title="Delete"
                                aria-label="Delete"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setDeleteUserVisible(true);
                                  setDeletedUser(user);
                                  setDeleteId(user.id);
                                }}
                              >
                                <Delete style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>No data to display</tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={editVisible}
          style={{ display: "flex", flex: 1, top: 0 }}
          size="lg"
        >
          <ModalBody
            style={{
              width: "100%",
              display: "inline-block",
              padding: "0px !important",
              position: "absolute",
              border: "1px solid white !important",
            }}
            size="sm"
          >
            <EditStudent
              notify={notify}
              userData={editUser}
              setUserData={setUserData}
              onClick={() => {
                setEditVisible(false);
              }}
            ></EditStudent>
          </ModalBody>
        </Modal>
        <Modal isOpen={deleteUserVisible} style={{ width: 250 }}>
          <ModalBody style={{ width: "100%", padding: 0 }} size="sm">
            <DeleteModal
              setUserArr={setUserArr}
              notify={notify}
              setUserData={setUserData}
              uid={deleteId}
              onClick={() => {
                setDeleteUserVisible(false);
              }}
            ></DeleteModal>
          </ModalBody>
        </Modal>
      </main>
      {/* )} */}
      {props?.getTraineeRes?.isLoading ||
      props?.createTraineeRes?.isLoading ||
      props?.editTraineeRes?.isLoading ||
      props?.getBatchRes?.isLoading ||
      props?.deleteTraineeRes?.isLoading ||
      props?.traineeBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  getTraineeRes: state.getTrainee,
  createTraineeRes: state.createTrainee,
  editTraineeRes: state.editTrainee,
  deleteTraineeRes: state.deleteTrainee,
  getBatchRes: state.getBatch,
  traineeBatchRelationRes: state.traineeBatchRelation,
  traineeBulkDeleteRes: state.traineeBulkDelete,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getTraineeList: (payload) => {
    dispatch(getTraineesAction(payload));
  },
  getBatchList: () => {
    dispatch(getBatchAction());
  },
  traineeBulkDelete: (payload) => {
    dispatch(traineeBulkDeleteAction(payload));
  },
  traineeBatchRelation: (payload) => {
    dispatch(traineeBatchRelationAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentsTable);
