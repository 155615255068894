import React, { useState, useRef, useEffect } from "react";
import {
	Button,
	Card,
	CardBody,
	Input,
	Row,
	Table,
	Col,
	Progress,
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import firebase from 'firebase';
import CloseIcon from "@material-ui/icons/Close";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";
import SearchIcon from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import {useForm} from 'react-hook-form';
import format from "date-fns/format";
import NotificationAlert from "react-notification-alert";

const useStyles = makeStyles((theme) => ({
	root: {
		"& :hover": {
			//   backgroundColor:'white'
		},
		color: "#FFF",
	},
	dialog: {
		"& .MuiDialogTitle-root": {
			backgroundColor: "#FFF",
			color: "#333333",
		},
		"& .MuiDialogActions-root": {
			backgroundColor: "#FFF",
			color: "#333333",
		},
	},
	dialogBtn: {
		display: "flex",
		justifyContent: "space-around",
		backgroundColor: "#FFF",
	},
	title: {
		"& .MuiDialogTitle-root": {
			color: "#333333",
		},
	},
}));

function MediaDialog({ handleClose, setMediaData, currentData }) {
	console.log('edit curret',currentData)
	const classes = useStyles();
	const [progressVisible, setProgressVisible] = useState(true);
	const [progressValue, setProgressValue] = useState(8);
	const [batchData, setBatchData] = useState([]);
	const [selectedBatch, setSelectedBatch] = useState(currentData.batch || []);
	const [selectedBatchCheckbox, setSelectedBatchCheckbox] = useState({});
	const [batchSearchValue, setBatchSearchValue] = useState("");
	const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);
	const batchSearchIndex = searchClient.initIndex("Batch_Search");
	const [reload, setReload] = useState(false);
	const [batchStudents, setBatchStudents] = useState([]);
    const [title, setTitle] = useState('');
    const [mediaLink, setMediaLink] = useState('');
    const [summary, setSummary] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const {register,handleSubmit,errors} = useForm(); 

	let data = [];

	const notifi = useRef();
	const notify = (msg, err) => {
		// console.log('notify')
		let color = err == false ? "success" : "danger";
		let options = {
			type: color,
			place: "tr",
			message: (
				<div>
					<div>
						<b>{err == false ? "Success" : "Error"}</b> - {msg}.
					</div>
				</div>
			),
			icon: "tim-icons icon-bell-55",
			autoDismiss: 6,
		};
		if (notifi.current) {
			notifi.current.notificationAlert(options);
		}
	};

	const getBatchData = () => {
		db.collection("Batch")
			.where("type", "==", "batch")
			.get()
			.then((querySnapshot) => {
				let data = querySnapshot.docs.map((doc) => doc.data());
				setBatchData(data);
				setProgressVisible(false);
			});
	};

	const removeBatch = (batchId) => {
		setBatchStudents(batchStudents.filter((batch) => batch.id != batchId));
	};

	const getBatchStudents = (batchId) => {
		db.collection("zSystemStudents")
			.where("batch", "array-contains", batchId)
			.get()
			.then((query) => {
				query.docs.map((doc) => data.push(doc.data()));
				setBatchStudents([...batchStudents, { id: batchId, data }]);
			});
	};

	const batchSearch = () => {
		if (batchSearchValue == "") {
			notify("Search field can not be empty", true);
		// } else if (batchSearchValue.length < 3) {
		// 	notify("Search atleast 4 words", true);
		} else {
			document.querySelector("#loading-indicator").style.display = "flex";

			batchSearchIndex
				.search(batchSearchValue)
				.then((result) => {
					if (result.hits.length === 0) {
						document.querySelector("#loading-indicator").style.display = "none";

						notify("No results found", true);
					} else {
						document.querySelector("#loading-indicator").style.display = "none";

						setBatchData(result.hits);
						setReload(true);
					}
				})
				.catch((err) => {
					document.querySelector("#loading-indicator").style.display = "none";
					notify(err.toString(), true);
				});
		}
	};

    const onSubmit = (data) => {
		// console.log(data)
        let finalData = {}
        let res;
        res = db.collection('MediaCenter').doc()

        finalData ={
            title: data.title,
            mediaLink: data.mediaLink,
            summary: data.summary,
            expiryDate: data.expiryDate,
            batch: selectedBatch,
            itemID: res.id,
            type: 'Active',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }

        res.set(finalData).then(() => {
            notify('Media Link Added', false);
        }).catch(err  => notify(err.toString(),true ))

        setMediaData(prev => [finalData, ...prev]);

        handleClose(false)
    }

	const emptyCall = () => {
		getBatchData();
	}

	useEffect(() => {
		getBatchData();
	}, []);

	useEffect(() => {
		if(currentData){
			let temp = {}
			currentData.batch.forEach(item=>{
				temp[item.batchID] = true
			})
			setSelectedBatchCheckbox(temp)
		}
	}, [currentData]);

	return (
		<div
			style={{
				backgroundColor: "#1f1e2e",
				height: "100%",
				overflowY: "scroll",
			}}
		>
			{progressVisible && (
				<Progress color="info" value={progressValue} style={{ height: 4 }} />
			)}
			<div className="react-notification-alert-container">
				<NotificationAlert ref={notifi} />
			</div>
			<div style={{ padding: "5px 20px" }}>
				<Row style={{ justifyContent: "flex-end" }}>
					<IconButton
						edge="start"
						style={{ color: "white" }}
						onClick={() => handleClose(false)}
						aria-label="close"
					>
						<CloseIcon />
					</IconButton>
				</Row>

				<Row>
					<Col md="12" style={{ marginBottom: "100px" }}>
						<Card>
							<CardBody>
								<Row>
								
									<Col
										sm={{ size: 6 }}
										style={{
											display: "flex",
											height: "75vh",
											flexDirection: "column",
											borderRight: "1px solid #3d4356",
											paddingLeft: "6%",
											paddingRight: "6%",
											paddingTop: "1%",
										}}
									>
									<form onSubmit={handleSubmit(onSubmit)} >
										<div style={{ paddingBottom: "10px" }}>
											<h5 className="title">Title</h5>
											<Input
												type="text"
												name="title"
												// onChange={(e) => setTitle(e.target.value)}
												placeholder="Media Title"
												innerRef={register({required:true})}
												defaultValue={currentData.title || ''}
											/>
											{	
											 errors.Title && errors.Title.type === 'required' &&   <span style={{fontSize:'10px',color:'red'}}>
												Please enter title
											</span>
                      						}
										</div>
										<div style={{ paddingBottom: "10px" }}>
											<h5 className="title">Media Link</h5>
											<Input
												type="text"
												name="mediaLink"
												// onChange={(e) => setMediaLink(e.target.value)}
												placeholder="Media link"
												innerRef={register({required:true, 
													pattern: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
												
												})}
												defaultValue={currentData.mediaLink || ''}
											/>
											 {	
											 errors.mediaLink && errors.mediaLink.type === 'required' &&                 						 <span style={{fontSize:'10px',color:'red'}}>
												Please add the link
											</span>
                      						}
											{	
											errors.mediaLink && errors.mediaLink.type === 'pattern' &&                 						 <span style={{fontSize:'10px',color:'red'}}>
												Please add a valid link
											</span>
											}
										</div>
										<div style={{ paddingBottom: "10px" }}>
											<h5 className="title">Expiry Date</h5>
											<Input
												type="datetime-local"
												name="expiryDate"
												// onChange={(e) => setExpiryDate(e.target.value)}
												placeholder="expiryDate"
												innerRef={register({required:true})}
												min={format(new Date(),"yyyy-MM-dd'T'00:00")}
												defaultValue={currentData.expiryDate || new Date(currentData.expiryDate) || new Date()}
											/>
											 {	
											 errors.expiryDate && errors.expiryDate.type === 'required' &&                 						 <span style={{fontSize:'10px',color:'red'}}>
												Please enter expiry date
											</span>
                      						}
											 
										</div>
										<div style={{ paddingBottom: "10px" }}>
											<h5 className="title">Summary</h5>

											<Input
												type="textarea"
												name="summary"
												rows={4}
												innerRef={register({required:true})}
												placeholder="Please Enter Message"
												// onChange={(e) => setSummary(e.target.value)}
												style={{ paddingLeft: 7 }}
												defaultValue={currentData.summary || ''}
											/>
											 {	
											 errors.summary && errors.summary.type === 'required' && <span style={{fontSize:'10px',color:'red'}}>
												Please enter summary
											</span>
                      						}
										</div>
										{
											selectedBatch && selectedBatch.length !== 0 &&
											<Button color="info">
												Send
											</Button>
										}
								</form>
									</Col>
									
									<Col
										sm={{ size: 6 }}
										style={{ borderRight: "1px solid #3d4356" }}
									>
										<div style={{ paddingLeft: 50 }}>
											<h5 className="title">Batches</h5>
											<div style={{ display: "flex" }}>
												<div>
													<Input
														type="text"
														name="search"
														placeholder="Search"
														style={{ marginRight: 0, minWidth: "250px" }}
														onChange={(e) => {
															setBatchSearchValue(e.target.value);
															e.target.value.length === 0 && emptyCall(); 
															 
														}}
													/>
												</div>
												<div style={{ paddingLeft: "0px" }}>
													<Button
														style={{
															margin: 0,
															marginLeft: "10px",
															padding: "6px 10px",
														}}
														color="info"
														onClick={batchSearch}
													>
														<SearchIcon />
													</Button>
												</div>
											</div>
											<div style={{ height: "70vh", overflowY: "scroll" }}>
												<Table className="tablesorter">
													<thead className="text-primary">
														<tr>
															<th style={{ paddingLeft: "30px" }}>Name</th>
														</tr>
													</thead>
													<tbody>
														{batchData.map((item) => {
															return (
																<tr key={item.itemID}>
																	<td>
																		<FormControlLabel
																			control={
																				<Checkbox
																					name="checkBox"
																					color="primary"
																					classes={{ root: classes.root }}
																					defaultChecked={selectedBatchCheckbox[item.itemID] || false}
																					onChange={(e) =>
																						e.target.checked
																							? (setSelectedBatch([
																									...selectedBatch,
																									{batchID: item.itemID, batchName: item.batchName}
																							  ]),
																							  getBatchStudents(item.itemID),setSelectedBatchCheckbox(prev=>({...prev, [item.itemID]:!prev[item.itemID]})))
																							: (setSelectedBatch(
																									selectedBatch.filter(
																										(id) => {
																											// console.log(id)
																											return id.batchID !== item.itemID}
																									)
																							  ),
																							  removeBatch(item.itemID),
																							  setSelectedBatchCheckbox(prev=>({...prev, [item.itemID]:!prev[item.itemID]})))
																					}
																				/>
																			}
																			label={item.batchName}
																		/>
																	</td>
																</tr>
															);
														})}
													</tbody>
												</Table>
											</div>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</div>
	);
}

export default MediaDialog;
