/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Edit, Delete, Add } from "@material-ui/icons";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import SecurityIcon from "@material-ui/icons/Security";
import * as Firebase from "firebase";
import SearchIcon from "@material-ui/icons/Search";

import AddSecurityGroup from "./AddSecurityGroup";

import { connect } from "react-redux";

//import { Modal } from '@material-ui/core';
// import { db } from '../MY_LOGIN_AND_SIGNUP/db/config';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Progress,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";

//added this file

import { getSecurityGroupAction } from "store/action/SecurityGroupAction/SecurityGroupAction";
import DeleteSecurityGroup from "./DeleteSecurityGroup";
import EditSecurityGroup from "./EditSecurityGroup";
import Loader from "components/Loader/Loader";
import { actionBtnStyle } from "Utilities/common/commonStyle";
import BulkDeleteSecurityGroup from "./BulkDeleteSecurityGroup";

function SecurityGroupTable(props) {
  const [progressVisible, setProgressVisible] = useState(true);
  const [progressValue, setProgressValue] = useState(8);
  const [userData, setUserData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [mySecurityGroup, setMySecurityGroup] = useState("");
  const [securityGroupData, setSecurityGroupData] = useState("");
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [securityArr, setSecurityArr] = useState([]);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);
  const [securityGroup, setSecurityGroup] = useState("");

  const history = useHistory();

  useEffect(() => {}, []);

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };
  const emptyStudent = () => {
    getUsers();
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSecurityArr([...securityArr, `id=${value}`]);
    } else {
      setSecurityArr(securityArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  useEffect(() => {
    document.title = "Security Group";
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
  };

  const getUsers = (orderBy) => {};
  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getSecurityGroupList();
    } else {
      setSearch(true);
      props.getSecurityGroupList(studentSearchValue);
    }
  };
  useEffect(() => {
    props.getSecurityGroupList();
  }, []);

  useEffect(() => {
    if (props?.getSecurityGroupRes?.securityGroupList) {
      setSecurityGroupData(props?.getSecurityGroupRes?.securityGroupList?.data);
    }
    if (props.getSecurityGroupRes?.error)
      notify(props.getSecurityGroupRes?.error?.message, true);
  }, [props?.getSecurityGroupRes]);

  useEffect(() => {
    if (props?.getSecurityGroupRes?.securityGroupList) {
      const groupList = props?.getSecurityGroupRes?.securityGroupList?.data;
      if (props?.getSecurityGroupRes.securityGroupList?.data.length == 0)
        notify("No data found.", true);

      setSecurityGroup(groupList);
    }
    if (props.getSecurityGroupRes?.error)
      notify(props.getSecurityGroupRes?.error?.message, true);
  }, [props?.getSecurityGroupRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  if (!localStorage.getItem("user")) history.push("/login");
  return (
    <>
      {/* change for the modal border */}
      {
        // progressVisible ? (
        //   <Progress value={progressValue} color='info' style={{ height: 5 }} />
        // ) :
        <main id="main" className="content">
          <div className="react-notification-alert-container">
            <NotificationAlert ref={notifi} />
          </div>

          <Modal
            isOpen={isModalVisible}
            style={{ display: "flex", flex: 1, top: -100 }}
            size="lg"
          >
            <ModalBody size="sm" style={{ padding: "1px" }}>
              <AddSecurityGroup
                handleClick={() => {
                  setIsModalVisible(false);
                }}
                notify={notify}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={deleteVisible}
            color="primary"
            style={{
              display: "flex",
              flex: 1,
              top: 0,
              width: 250,
              backgroundColor: "#132639",
              objectFit: "fill",
            }}
          >
            <ModalBody style={{ width: "100%" }} size="sm">
              <DeleteSecurityGroup
                onClick={() => {
                  setDeleteVisible(false);
                }}
                notify={notify}
                mySecGrp={mySecurityGroup}
                setSecurityArr={setSecurityArr}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={editVisible}
            style={{ display: "flex", flex: 1, top: -100 }}
            size="lg"
          >
            <ModalBody style={{ padding: "1px" }}>
              <EditSecurityGroup
                handleClick={() => {
                  setEditVisible(false);
                }}
                mySecGrp={mySecurityGroup}
                notify={notify}
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={bulkDeleteVisible}
            color="primary"
            style={{
              display: "flex",
              flex: 1,
              top: 0,
              width: 250,
              backgroundColor: "#132639",
              objectFit: "fill",
            }}
          >
            <ModalBody style={{ width: "100%" }} size="sm">
              <BulkDeleteSecurityGroup
                securityArr={securityArr !== undefined && securityArr}
                setSecurityArr={setSecurityArr}
                notify={notify}
                onClick={() => {
                  setBulkDeleteVisible(false);
                }}
              ></BulkDeleteSecurityGroup>
            </ModalBody>
          </Modal>

          <Row>
            <Col md="12">
              <Card
                style={{
                  fontSize: font?.fontSize || "inherit",
                  fontWeight: font?.fontWeight || "inherit",
                  backgroundColor: colorTheme?.bodyColor || "all",
                }}
              >
                <CardHeader
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h1
                    style={{
                      fontSize: font?.fontSize + 4 || "inherit",
                      fontWeight: font?.fontWeight || "inherit",
                      margin: "0 auto 0 0",
                      textTransform: "capitalize",
                    }}
                  >
                    SECURITY GROUP
                  </h1>

                  <Button
                    title="Create Security Group"
                    color="info"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Create Security Group <Add />
                  </Button>
                </CardHeader>
                <Row style={{ marginLeft: "7px" }}>
                  <Col sm={4}>
                    <Input
                      title="Search Security Group"
                      type="text"
                      name="search"
                      placeholder="Search Security Group"
                      onChange={(e) => (
                        setStudentSearchValue(e.target.value),
                        e.target.value.length === 0 && emptyStudent(),
                        setSearch(false)
                      )}
                    />
                  </Col>
                  <Col style={{ paddingLeft: "0px" }} sm={2}>
                    <Button
                      title="Search"
                      style={{ margin: "0px", padding: "6px 10px" }}
                      color="info"
                      onClick={studentSearch}
                    >
                      <SearchIcon />
                    </Button>
                  </Col>
                  <Col
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    sm={6}
                  >
                    {securityArr.length > 0 ? (
                      <Button
                        title="Delete Marked"
                        aria-label="Delete Marked"
                        style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                        color="danger"
                        onClick={() => {
                          setBulkDeleteVisible(true);
                        }}
                      >
                        Delete Marked
                      </Button>
                    ) : null}
                  </Col>
                </Row>
                <CardBody>
                  <Table className="tablesorter">
                    <thead className="text-primary">
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th style={{ textAlign: "center" }}>Access</th>
                        <th style={{ textAlign: "center" }}>Action Access</th>

                        <th style={{ textAlign: "center" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {securityGroupData?.length ? (
                        securityGroupData.map((securityGroup) => {
                          return (
                            <tr
                              key={securityGroup.id}
                              style={{
                                borderBottom: `1px solid ${
                                  colorTheme?.borderColor ||
                                  "rgba(255, 255, 255, 0.1)"
                                }`,
                              }}
                            >
                              <td
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px",
                                  color: "white",
                                  marginTop: "5px",
                                }}
                              >
                                <Input
                                  id={securityGroup?.id}
                                  defaultChecked={false}
                                  type="checkbox"
                                  key={securityGroup?.id}
                                  value={securityGroup?.id}
                                  onChange={handleCheckbox}
                                />
                              </td>

                              <th>
                                <label
                                  style={{
                                    fontSize: font?.fontSize || "inherit",
                                    fontWeight: font?.fontWeight || "inherit",
                                    color: "#FFFFFFB3",
                                  }}
                                  htmlFor={securityGroup?.id}
                                >
                                  {securityGroup.name}
                                </label>
                              </th>

                              <td style={{ textAlign: "center" }}>
                                <UncontrolledDropdown
                                  aria-label="Page  Access"
                                  title="Page  Access"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <abbr title="User Access">
                                    <DropdownToggle
                                      style={{
                                        margin: "0px",
                                        padding: "6px 10px",
                                      }}
                                      className="nav-link"
                                      color="info"
                                      caret
                                    >
                                      <SecurityIcon />
                                    </DropdownToggle>
                                  </abbr>
                                  <DropdownMenu style={{ left: "42%" }}>
                                    <DropdownItem
                                      style={{ color: "black" }}
                                      header
                                    >
                                      User Access
                                    </DropdownItem>
                                    <DropdownItem>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "250px",
                                          flexWrap: "wrap",
                                          gap: "4px",
                                        }}
                                      >
                                        {securityGroup.securityGroupFunctions.map(
                                          (functions) => (
                                            <p
                                              style={{
                                                color: "black",
                                                background: "#f0f0f0",
                                                padding: "4px",
                                              }}
                                            >
                                              {functions.zSystemFunction.name}
                                            </p>
                                          )
                                        )}
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <UncontrolledDropdown
                                  aria-label="Action  Access"
                                  title="Action  Access"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <abbr title="User Access">
                                    <DropdownToggle
                                      style={{
                                        margin: "0px",
                                        padding: "6px 10px",
                                      }}
                                      className="nav-link"
                                      color="info"
                                      caret
                                    >
                                      <SecurityIcon />
                                    </DropdownToggle>
                                  </abbr>
                                  <DropdownMenu style={{ left: "45%" }}>
                                    <DropdownItem
                                      style={{ color: "black" }}
                                      header
                                    >
                                      User Access
                                    </DropdownItem>
                                    <DropdownItem>
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "350px",
                                          flexWrap: "wrap",
                                          gap: "4px",
                                        }}
                                      >
                                        {securityGroup.securityGroupActions.map(
                                          (actions) => (
                                            <p
                                              style={{
                                                color: "black",
                                                background: "#f0f0f0",
                                                padding: "4px",
                                              }}
                                            >
                                              {actions.zSystemAction.actionName}
                                            </p>
                                          )
                                        )}{" "}
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                              <td
                                style={{
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                {/* <Edit
                                  style={{ color: '#247cf7' }}
                                  onClick={() => {
                                    setMySecurityGroup(securityGroup);
                                    setEditVisible(true);
                                  }}
                                /> */}

                                <button
                                  title="Edit"
                                  aria-label="Edit"
                                  style={actionBtnStyle}
                                  onClick={() => {
                                    setMySecurityGroup(securityGroup);
                                    setEditVisible(true);
                                  }}
                                >
                                  <Edit style={{ color: "#247cf7" }} />
                                </button>

                                <button
                                  title="Delete"
                                  aria-label="Delete"
                                  style={actionBtnStyle}
                                  onClick={() => {
                                    setMySecurityGroup(securityGroup);
                                    setDeleteVisible(true);
                                  }}
                                >
                                  <Delete style={{ color: "red" }} />
                                </button>

                                {/* <Delete
                                  style={{ color: 'red' }}
                                  onClick={() => {
                                
                                    setMySecurityGroup(securityGroup);
                                    setDeleteVisible(true);
                                  }}
                                /> */}
                                {/* {user.disabled === false ? (
                                <Delete
                                  style={{ color: 'red' }}
                                  onClick={() => {
                           
                                    setMySecurityGroup(user);
                                    setDeleteVisible(true);
                                  }}
                                />
                              ) : (
                                <PersonAddDisabledIcon
                                  style={{ color: 'red' }}
                                  onClick={() => {
                               
                                    setMySecurityGroup(user);
                                    setDeleteVisible(true);
                                  }}
                                />
                              )} */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td>No data found</td>
                          <td></td>

                          <td></td>

                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </main>
      }
      {props?.getSecurityGroupRes?.isLoading ||
      props?.createSecurityGroupRes?.isLoading ||
      props?.editSecurityGroupRes?.isLoading ||
      props?.deleteSecurityGroupRes?.isLoading ||
      props?.getFunctionRes?.isLoading ||
      props?.getActionRes?.isLoading ||
      props?.securityGroupBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getSecurityGroupRes: state.getSecurityGroup,
  createSecurityGroupRes: state.createSecurityGroup,
  editSecurityGroupRes: state.editSecurityGroup,
  deleteSecurityGroupRes: state.deleteSecurityGroup,
  securityGroupBulkDeleteRes: state.securityGroupBulkDelete,
  getActionRes: state.getActions,
  getFunctionRes: state.getFunctions,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getSecurityGroupList: (payload) => {
    dispatch(getSecurityGroupAction(payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityGroupTable);
