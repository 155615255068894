/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Input as MInput,
  withStyles,
  Menu,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { clearResponse } from "store/action/clearResponseAction";
import { actionBtnStyle } from "Utilities/common/commonStyle";

//import { Modal } from '@material-ui/core';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Input,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Edit, Delete, Add, Tune } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";

import NotificationAlert from "react-notification-alert";

import { connect } from "react-redux";
import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";

import DeleteModal from "./DeleteModal";
import AddModule from "./AddModule";
import EditModule from "./EditModule";
import Loader from "components/Loader/Loader";
import { moduleBulkDeleteAction } from "store/action/bulkDeleteAction/moduleBulkDeleteAction";
import BulkDeleteTeaching from "./BulkDeleteTeaching";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function TeachingModuleTable(props) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  const [moduleList, setModuleList] = useState("");
  const [dataToEdit, setDataToEdit] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState();
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const [userArr, setUserArr] = useState([]);
  const [bulkDeleteVisible, setBulkDeleteVisible] = useState(false);

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setUserArr([...userArr, `id=${value}`]);
    } else {
      setUserArr(userArr.filter((id, i) => id !== `id=${value}`));
    }
  };

  useEffect(() => {
    document.title = "Module Management";
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", detectKeyDown, true);
    return () => window.removeEventListener("keydown", detectKeyDown);
  });

  const detectKeyDown = (e) => {
    console.log("pressed key", e.key);
    if ((e.metaKey || e.ctrlKey) && e.code === "KeyC") {
      setIsModalVisible(!isModalVisible);
    }
  };

  useEffect(() => {
    if (props?.getModuleRes?.moduleList) {
      if (props?.getModuleRes?.moduleList?.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_MODULE_RESET");
        setModuleList("");
      } else {
        setModuleList(props?.getModuleRes?.moduleList?.data);
      } // notify("Data Fatched Successfully",false)
    }
    if (props.getModuleRes?.error) {
      notify(props.getModuleRes?.error?.message, true);
      props.clearResponse("GET_MODULE_RESET");
    }
  }, [props.getModuleRes]);

  useEffect(() => {
    if (props.getModuleRes.moduleList) {
      if (props.getModuleRes.moduleList?.data.length !== 0) {
        // notify('Data Fetched Successfully', false);
      }
    }
  }, [props.getModuleRes.moduleList]);

  useEffect(() => {
    if (!props.getModuleRes.moduleList) props.getModuleList();
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  if (!localStorage.getItem("user")) history.push("/login");

  const emptyStudent = () => {
    getUsers();
  };

  const getUsers = (orderBy) => {};
  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getModuleList();
    } else {
      setSearch(true);
      props.getModuleList(studentSearchValue);
    }
  };
  return (
    <>
      <main id="main" className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <h1
                  style={{
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    margin: "0 auto 0 0",
                    textTransform: "capitalize",
                  }}
                >
                  MODULE MANAGEMENT
                </h1>

                <Button
                  title="Create Module"
                  color="info"
                  // style={{ marginRight: 25 }}
                  onClick={() => setIsModalVisible(true)}
                >
                  Create Module <Add />
                </Button>
              </CardHeader>
              <Row style={{ marginLeft: "7px" }}>
                <Col sm={4}>
                  <Input
                    title="Search Module"
                    type="search"
                    name="search"
                    placeholder="Search Module"
                    onChange={(e) => (
                      setStudentSearchValue(e.target.value),
                      e.target.value.length === 0 && emptyStudent(),
                      setSearch(false)
                    )}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    title="Search"
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    onClick={studentSearch}
                  >
                    <SearchIcon />
                  </Button>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  sm={6}
                >
                  {userArr.length > 0 ? (
                    <Button
                      title="Delete Marked"
                      aria-label="Delete Marked"
                      style={{ margin: "0px 15px 0 0", padding: "6px 10px" }}
                      color="danger"
                      onClick={() => {
                        setBulkDeleteVisible(true);
                      }}
                    >
                      Delete Marked
                    </Button>
                  ) : null}
                </Col>
              </Row>
              {/* <CardBody style={{ paddingTop: '10px' }}> */}
              <Modal
                isOpen={isModalVisible}
                style={{ display: "flex", flex: 1 }}
                size="lg"
              >
                <ModalBody
                  style={{
                    width: "100%",
                    display: "inline-block",
                    padding: "0px !important",
                    position: "absolute",
                    border: "1px solid white !important",
                  }}
                  size="sm"
                >
                  <AddModule
                    notify={notify}
                    setModal={(val) => setIsModalVisible(val)}
                  ></AddModule>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={bulkDeleteVisible}
                color="primary"
                style={{
                  display: "flex",
                  flex: 1,
                  top: 0,
                  width: 250,
                  backgroundColor: "#132639",
                  objectFit: "fill",
                }}
              >
                <ModalBody style={{ width: "100%" }} size="sm">
                  <BulkDeleteTeaching
                    userArr={userArr !== undefined && userArr}
                    setUserArr={setUserArr}
                    notify={notify}
                    onClick={() => {
                      setBulkDeleteVisible(false);
                    }}
                  ></BulkDeleteTeaching>
                </ModalBody>
              </Modal>

              <CardBody>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Title</th>
                      <th style={{ textAlign: "center" }}>Description</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moduleList?.length ? (
                      moduleList.map((item, i) => {
                        return (
                          <tr
                            key={item.id}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <td
                              style={{
                                display: "flex",
                                paddingLeft: "20px",
                                color: "white",
                                marginTop: "1px",
                              }}
                            >
                              <Input
                                id={item?.id}
                                defaultChecked={false}
                                type="checkbox"
                                key={item?.id}
                                value={item?.id}
                                onChange={handleCheckbox}
                              />
                            </td>
                            <th>
                              <label
                                style={{
                                  fontSize: font?.fontSize || "inherit",
                                  fontWeight: font?.fontWeight || "inherit",
                                  color: "#FFFFFFB3",
                                }}
                                htmlFor={item?.id}
                              >
                                {item.title}
                              </label>
                            </th>

                            <td style={{ textAlign: "center" }}>
                              {item.description}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item.status ? "Active" : null}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {/* <Edit
                                onClick={() => {
                                  setEditVisible(true);
                                  setDataToEdit(item);
                                }}
                                style={{ color: '#247cf7', cursor: 'pointer' }}
                              /> */}

                              <button
                                title="Edit"
                                aria-label="Edit"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setEditVisible(true);
                                  setDataToEdit(item);
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>

                              {/* <Delete
                                style={{ color: 'red', cursor: 'pointer' }}
                                notify={notify}
                                onClick={() => {
                                  setDeleteModalVisible(true);
                                  setDeleteId(item.id);
                                }}
                              /> */}

                              <button
                                title="Delete"
                                aria-label="Delete"
                                style={actionBtnStyle}
                                onClick={() => {
                                  setDeleteModalVisible(true);
                                  setDeleteId(item.id);
                                }}
                              >
                                <Delete style={{ color: "red" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>No data to display</tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
        <Modal
          isOpen={editVisible}
          style={{ display: "flex", flex: 1, top: 0 }}
          size="lg"
        >
          <ModalBody
            style={{
              width: "100%",
              display: "inline-block",
              padding: "0px !important",
              position: "absolute",
              border: "1px solid white !important",
            }}
            size="sm"
          >
            <EditModule
              notify={notify}
              data={dataToEdit}
              onClick={() => {
                setEditVisible(false);
              }}
            ></EditModule>
          </ModalBody>
        </Modal>
        <Modal isOpen={deleteModalVisible} style={{ width: 250 }}>
          <ModalBody style={{ width: "100%", padding: 0 }} size="sm">
            <DeleteModal
              setUserArr={setUserArr}
              notify={notify}
              moduleId={deleteId}
              onClick={() => {
                setDeleteModalVisible(false);
              }}
            ></DeleteModal>
          </ModalBody>
        </Modal>
      </main>
      {props?.getModuleRes?.isLoading ||
      props?.createModuleRes?.isLoading ||
      props?.editModuleRes?.isLoading ||
      props?.deleteModuleRes?.isLoading ||
      props?.moduleBulkDeleteRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getModuleRes: state.getModule,
  createModuleRes: state.createModule,
  editModuleRes: state.editModule,
  deleteModuleRes: state.deleteModule,
  moduleBulkDeleteRes: state.moduleBulkDelete,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getModuleList: (payload) => {
    dispatch(getTeachingModuleAction(payload));
  },
  moduleBulkDelete: (payload) => {
    dispatch(moduleBulkDeleteAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeachingModuleTable);
