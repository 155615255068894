import { Cancel, ImportExport, CloudUpload } from '@material-ui/icons';
import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useLocation } from 'react-router-dom';
import firebase from 'firebase';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
} from 'reactstrap';
// import Button from "@material-ui/core/Button";
// import Dialog from "@material-ui/core/Dialog";
// import Slide from "@material-ui/core/Slide";
import NotificationAlert from 'react-notification-alert';

// import { db } from 'MY_LOGIN_AND_SIGNUP/db/config';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
// import PDF from '../../../components/PDF/PDF';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

function Book_Edit_Step1({
  editBook,
  setBoookData,
  booknotify,
  onClick,
  setVisible,
}) {

  const [bookFileUrl, setBookFileUrl] = useState(editBook.bookFileUrl);
  const [bookFileName, setBookFileName] = useState();
  const [bookFileNameMob, setBookFileNameMob] = useState();

  const [formVisible, setFormVisible] = useState(true);
  const [bookImage, setBookImage] = useState(editBook.bookcover);
  const [imageUrl, setimageUrl] = useState('');
  const [bookCover, setBookCover] = useState();
  const { register, handleSubmit, errors } = useForm();

  const [open, setOpen] = useState(false);

  const [bookFileUrlMob, setBookFileUrlMob] = useState(
    editBook.bookFileUrlMobile
  );
  const [same, setSame] = useState(false);

  const [currentFile, setCurrentFile] = useState(editBook.bookFileUrl);
  const [currentFileMob, setCurrentFileMob] = useState(
    editBook.bookFileUrlMobile
  );
  const [clickedFile, setClickedFile] = useState('');

  const notifi = useRef();

  const notify = (msg, err) => {
    let color = err == false ? 'success' : 'danger';
    let options = {
      type: color,
      place: 'tr',
      message: (
        <div>
          <div>
            <b>{err == false ? 'Success' : 'Error'}</b> - {msg}.
          </div>
        </div>
      ),
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 6,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectBookCover = (event) => {
    if (!event.target.files[0]) {
      return;
    }
    if (
      event.target.files[0].type === 'image/jpeg' ||
      event.target.files[0].type === 'image/png'
    ) {
      try {
        let date = new Date().valueOf();

        var uploadTask = firebase
          .storage()
          .ref()
          .child(`booksCover/${date}${event.target.files[0].name}`)
          .put(event.target.files[0]);
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log('upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log('upload is running');
                break;
            }
          },
          function (error) {
            console.log('this is error', error);
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                console.log('this is cover.......', downloadURL);
                notify('Book cover uploaded successfully', false);
                setimageUrl(downloadURL);
                setBookImage(downloadURL);
              });
          }
        );
      } catch (error) {
        notify(error.toString(), true);

      }
      setBookCover(event.target.files[0].name);
    } else {
      notify('Please select only .jpeg/.png file', true);
    }
  };

  const selectBookFile = (event) => {
    if (!event.target.files[0]) {
      return;
    }
    if (event.target.files[0].type === 'application/pdf') {
      setCurrentFile(event.target.files[0]);
      setBookFileName(event.target.files[0].name);
      try {
        let date = new Date().valueOf();

        var uploadTask = firebase
          .storage()
          .ref()
          .child(`books/${date}${event.target.files[0].name}`)
          .put(event.target.files[0]);
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log('upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log('upload is running');
                break;
            }
          },
          function (error) {
            console.log('this is error', error);
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                notify('Book uploaded successfully', false);

                console.log(' This is file........', downloadURL);
                setBookFileUrl(downloadURL);
              });
          }
        );
      } catch (error) {
        notify(error.toString(), true);

      }
    } else {
      notify('Please select PDF file', true);
    }
  };
  // const selectBookCover=(event)=>{

  //    try {
  //    try {
  //     var uploadTask = firebase.storage().ref().child(event.target.files[0].name).put(event.target.files[0])
  //     var uploadTask = firebase.storage().ref().child(event.target.files[0].name).put(event.target.files[0])
  //     uploadTask.on('state_changed',function(snapshot){
  //     uploadTask.on('state_changed',function(snapshot){
  //       var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
  //       var progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
  //       console.log('upload is '+ progress +'% done');
  //       console.log('upload is '+ progress +'% done');
  //       switch(snapshot.state){
  //       switch(snapshot.state){
  //         case firebase.storage.TaskState.PAUSED:
  //         case firebase.storage.TaskState.PAUSED:
  //           console.log('upload is paused')
  //           console.log('upload is paused')
  //           break;
  //           break;
  //         case firebase.storage.TaskState.RUNNING:
  //         case firebase.storage.TaskState.RUNNING:
  //           console.log('upload is running')
  //           console.log('upload is running')
  //           break
  //           break
  //       }
  //       }
  //     },function(error){
  //     },function(error){
  //       console.log('this is error',error)
  //       console.log('this is error',error)
  //     },function(){
  //     },function(){
  //       uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
  //       uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
  //         console.log('File available at ', downloadURL)
  //         console.log('File available at ', downloadURL)
  //         setimageUrl(downloadURL)
  //         setimageUrl(downloadURL)
  //       })
  //       })
  //     })
  //     })

  //    } catch (error) {
  //    } catch (error) {
  //      console.log('my error',error)
  //      console.log('my error',error)
  //    }
  //    }

  // ref.put(file).then(function(snapshot) {
  // ref.put(file).then(function(snapshot) {
  //   console.log('Uploaded a blob or file!');
  //   console.log('Uploaded a blob or file!');
  // });
  // });

  // try {
  // try {
  //   firebase.storage().ref('images').put(event.target.files[0]).then(function(snapshot){
  //   firebase.storage().ref('images').put(event.target.files[0]).then(function(snapshot){
  //     console.log('image submitted',snapshot)
  //     console.log('image submitted',snapshot)
  //   }).catch(error=>{
  //   }).catch(error=>{
  //     console.log('error occured while updating image ',error)
  //     console.log('error occured while updating image ',error)
  //   })
  //   })
  // } catch (error) {
  // } catch (error) {
  //   console.log(error)

  // }
  // }

  // setBookCover(event.target.files[0].name)
  //}

  const selectBookFileMob = (event) => {
 
    if (!event.target.files[0]) {
      return;
    }
    if (event.target.files[0].type === 'application/pdf') {
      setCurrentFileMob(event.target.files[0]);
      setBookFileNameMob(event.target.files[0].name);
      try {
        let date = new Date().valueOf();

        var uploadTask = firebase
          .storage()
          .ref()
          .child(`books/${date}${event.target.files[0].name}`)
          .put(event.target.files[0]);
        uploadTask.on(
          'state_changed',
          function (snapshot) {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                console.log('upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING:
                console.log('upload is running');
                break;
            }
          },
          function (error) {
            console.log('this is error', error);
          },
          function () {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function (downloadURL) {
                notify('Book uploaded successfully', false);

                console.log('File available at ', downloadURL);
                setBookFileUrlMob(downloadURL);
              })
              .catch((err) => {
                notify(err.toString, true);
              });
          }
        );
      } catch (error) {
        notify(error.toString, true);

      }
    } else {
      notify('Please select PDF file', true);
    }
  };

  return (
    <>
      <div className='content' style={{ minWidth: '100%' }}>
        <div className='react-notification-alert-container'>
          <NotificationAlert ref={notifi} />
        </div>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          {/* <PDF
            editBook={editBook.title}
            handleClose={handleClose}
            clickedFile={clickedFile}
            currentFileMob={currentFileMob}
            currentFile={currentFile}
          /> */}
        </Dialog>
        <Row style={{}}>
          <Col md='12'>
            <Card style={{}}>
              <CardHeader>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  <h4 className='title' style={{ fontWeight: 400 }}>
                    Edit Book
                  </h4>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit((data) => {
                    let array = JSON.parse('[' + data.freePages + ']');
                    let noOfPages = parseInt(data.bookpages);
                    let res = array.every((v) => v <= noOfPages);
                    if (res) {
                      let checkBox =
                        same === false ? bookFileUrlMob : bookFileUrl;

                      if (bookFileUrl && checkBox && bookImage) {
                        setVisible(false);
                        const finalData = {
                          title: data.booktitle,
                          author: data.author,
                          price: parseFloat(data.price),
                          overview: data.bookoverview,
                          aboutAuthor: data.aboutAuthor,
                          bookcover: bookImage,
                          bookFileUrl: bookFileUrl,
                          bookFileUrlMobile: checkBox,

                          mrp: parseFloat(data.bookmrp),
                          bookcode: data.bookcode,
                          bookpages: noOfPages,
                          freePages: array,
                          rating: parseInt(data.rating),
                          lastUpdatedAt:
                            firebase.firestore.FieldValue.serverTimestamp(),
                        };
                        // db.collection('zSystemStore')
                        //   .doc(editBook.itemID)
                        //   .update(finalData)
                        //   .then(function () {
                        //     booknotify('Book updated successfully', false);
                        //     notify('Book updated successfully', false);
                        //     setBoookData((prev) =>
                        //       prev.map((item) =>
                        //         item.itemID === editBook.itemID
                        //           ? { ...finalData, itemID: editBook.itemID }
                        //           : item
                        //       )
                        //     );
                        //     console.log('documnet successfully updated');
                        //     onClick();
                        //   })
                        //   .catch(function (error) {
                        //     notify(error.toString(), true);

                        //   });
                      } else {
                        notify('Please wait for the upload to Complete', true);
                      }
                    } else {
                      notify('Free page exceed the total pages', true);
                    }
                  })}
                >
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Book Title</label>
                        <Input
                          defaultValue={editBook.title}
                          innerRef={register({ required: true, minLength: 4 })}
                          name='booktitle'
                          placeholder='Title of Book'
                          type='text'
                          required
                        />
                        {errors.booktitle &&
                          errors.booktitle.type === 'minLength' && (
                            <span
                              style={{
                                fontSize: '10px',
                                position: 'absolute',
                                bottom: '-20px',
                                color: 'red',
                              }}
                            >
                              Title should be atleast 4 digits.
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Author</label>
                        <Input
                          defaultValue={editBook.author}
                          innerRef={register({ required: true, minLength: 4 })}
                          name='author'
                          placeholder='Name Of Author'
                          type='text'
                        />
                        {errors.author && errors.author.type === 'minLength' && (
                          <span
                            style={{
                              fontSize: '10px',
                              position: 'absolute',
                              bottom: '-20px',
                              color: 'red',
                            }}
                          >
                            Author should be atleast 4 digits.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>MRP</label>
                        <Input
                          defaultValue={parseInt(editBook.price)}
                          innerRef={register({ required: true, min: 0 })}
                          name='price'
                          placeholder='MRP'
                          type='number'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Book Code</label>
                        <Input
                          innerRef={register({ required: false })}
                          name='bookcode'
                          type='number'
                          placeholder='Book Code'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col className="pr-md-1" md="6">
                         <div style={{borderColor:'red',borderWidth:3}}>
                         <FormGroup>
                           <div style={{cursor:'pointer',borderWidth:10,borderColor:'green'}}>
                           <label style={{cursor:'pointer',borderWidth:3,borderColor:"white",color:'white',fontSize:18}}>{bookCover===undefined ?<Button>Upload Book</Button>:<Button>Book Uploaded</Button>}</label>
                           </div>
                           {bookCover!==undefined&&<p style={{color:'green'}}>{bookCover}</p>}
                           <Input
                             defaultValue={editBook.bookcover}
                             innerRef={register({required:true,accept:'.jpeg,.png'})}
                             name='bookcover'
                             placeholder='Upload'
                             onChange={selectBookCover}
                             type='file'
                           />
                           </FormGroup>
                         </div>
                       </Col> */}
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Number Of Pages</label>
                        <Input
                          defaultValue={parseInt(editBook.bookpages)}
                          innerRef={register({ required: true, min: 0 })}
                          name='bookpages'
                          placeholder=''
                          type='number'
                        />
                      </FormGroup>
                    </Col>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Rating</label>
                        <Input
                          defaultValue={editBook.rating}
                          innerRef={register({
                            required: true,
                            min: 0,
                            max: 5,
                          })}
                          name='rating'
                          placeholder='Rating Out of 5'
                          type='number'
                        />

                        {errors.rating && errors.rating.type === 'min' && (
                          <span
                            style={{
                              fontSize: '10px',
                              position: 'absolute',
                              bottom: '-20px',
                              color: 'red',
                            }}
                          >
                            Rating should be out of 5 .
                          </span>
                        )}

                        {errors.rating && errors.rating.type === 'max' && (
                          <span
                            style={{
                              fontSize: '10px',
                              position: 'absolute',
                              bottom: '-20px',
                              color: 'red',
                            }}
                          >
                            Rating should be out of 5 .
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Free Pages</label>
                        <Input
                          defaultValue={editBook.freePages}
                          innerRef={register({
                            required: true,
                            pattern: {
                              value: /^((\d+,)*(\d)+$)/,
                              message: 'Enter in vaild pattern',
                            },
                          })}
                          name='freePages'
                          placeholder='Enter free pages in pattern 1,2,8,9 '
                          type='text'
                        />

                        {errors.freePages &&
                          errors.freePages.type === 'required' && (
                            <span
                              style={{
                                fontSize: '10px',
                                position: 'absolute',
                                bottom: '-20px',
                                color: 'red',
                              }}
                            >
                              {' '}
                              Please Enter free pages .
                            </span>
                          )}

                        {errors.freePages &&
                          errors.freePages.type === 'pattern' && (
                            <span
                              style={{
                                fontSize: '10px',
                                position: 'absolute',
                                bottom: '-20px',
                                color: 'red',
                              }}
                            >
                              Enter in vaild pattern .
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>Book Overview</label>
                        <Input
                          defaultValue={editBook.overview}
                          innerRef={register({ required: false })}
                          name='bookoverview'
                          placeholder='Book Overview'
                          type='textarea'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='pr-md-1' md='6'>
                      <FormGroup>
                        <label>About Author</label>
                        <Input
                          defaultValue={editBook.aboutAuthor}
                          innerRef={register({ required: false })}
                          name='aboutAuthor'
                          placeholder='About author'
                          type='textarea'
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      className='pr-md-1'
                      md='6'
                      style={{ marginTop: 0, overflow: 'hidden' }}
                    >
                      <Row>
                        <Col
                          className='pr-md-1'
                          md='4'
                          style={{ maxWidth: '150px' }}
                        >
                          {/* <Col className='pr-md-1' md='6'> */}
                          <FormGroup>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <label>Upload Book for web </label>
                              <label
                                style={{
                                  cursor: 'pointer',
                                  color: 'white',
                                  fontSize: 16,
                                }}
                              >
                                {bookFileName === undefined ? (
                                  <Button>
                                    <CloudUploadIcon />
                                  </Button>
                                ) : (
                                  <Button>
                                    <CloudDoneIcon />
                                  </Button>
                                )}
                              </label>
                            </div>
                            <Input
                              style={{ cursor: 'pointer' }}
                              innerRef={register({ required: false })}
                              name='bookfile'
                              placeholder='Upload Book'
                              onChange={selectBookFile}
                              type='file'
                              accept='application/pdf'

                              // required
                            />
                            {bookFileName && (
                              <p style={{ color: 'white' }}>{bookFileName}</p>
                            )}
                          </FormGroup>
                        </Col>
                        {bookFileName && (
                          <Col md='6'>
                            <Button
                            title="Preview"
                              style={{
                                position: 'relative',
                                top: '30px',
                                left: '20px',
                              }}
                              onClick={() => {
                                setClickedFile('web');
                                handleClickOpen();
                              }}
                            >
                              Preview
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col md='6'>
                      <Row>
                        <Col className='pr-md-1' md='6'>
                          <FormGroup
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <label>Upload Book Image</label>
                            <label
                              style={{
                                cursor: 'pointer',
                                color: 'white',
                                fontSize: 16,
                              }}
                            >
                              {bookCover === undefined ? (
                                <Button>
                                  <CloudUploadIcon />
                                </Button>
                              ) : (
                                <Button>
                                  <CloudDoneIcon />
                                </Button>
                              )}
                            </label>
                            <Input
                              style={{ cursor: 'pointer' }}
                              innerRef={register({
                                required: false,
                                accept: '.jpeg,.png',
                              })}
                              name='bookcover'
                              placeholder='Upload'
                              onChange={selectBookCover}
                              type='file'
                              accept='.jpeg ,.png'

                              // required
                            />
                          </FormGroup>
                        </Col>

                        <Col
                          className='pr-md-1'
                          md='6'
                          style={{ paddingLeft: 10 }}
                        >
                          {bookImage && (
                            <img
                              src={bookImage}
                              style={{ width: 40, height: 55, marginTop: 15 }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col md='6'>
                      <Row>
                        {same === false && (
                          <>
                            <Col className='pr-md-1' md='4'>
                              {/* <Col className='pr-md-1' md='6'> */}
                              <FormGroup>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <label>Upload Book for Mobile </label>
                                  <label
                                    style={{
                                      cursor: 'pointer',
                                      color: 'white',
                                      fontSize: 16,
                                    }}
                                  >
                                    {bookFileNameMob === undefined ? (
                                      <Button>
                                        <CloudUploadIcon />
                                      </Button>
                                    ) : (
                                      <Button>
                                        <CloudDoneIcon />
                                      </Button>
                                    )}
                                  </label>
                                </div>
                                <Input
                                  style={{ cursor: 'pointer' }}
                                  innerRef={register({ required: false })}
                                  name='bookfile'
                                  placeholder='Upload Book'
                                  onChange={selectBookFileMob}
                                  type='file'
                                  accept='application/pdf'

                                  // required
                                />
                                {bookFileNameMob && (
                                  <p style={{ color: 'white' }}>
                                    {bookFileNameMob}
                                  </p>
                                )}
                              </FormGroup>
                            </Col>
                            {bookFileNameMob && (
                              <Col md='6'>
                                
                                <Button
                                title="Preview"
                                  style={{ position: 'relative', top: '30px' }}
                                  onClick={() => {
                                    setClickedFile('mob');
                                    handleClickOpen();
                                  }}
                                >
                                  Preview
                                </Button>
                              </Col>
                            )}
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: '10px' }}>
                    <Col className='pr-md-1' md='6'>
                      <Col md={4}>
                        <Input
                          id='bookCheck'
                          type='checkbox'
                          onChange={(e) => {
                            setSame(e.target.checked);
                          }}
                        />
                      </Col>
                      <Col md={6}>
                        <label for='bookCheck'>
                          Use same pdf for mobile version
                        </label>
                      </Col>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                  >
                    <Button  title="Update" color='info' type='submit'>
                      Update
                    </Button>
                    <Button
                    title="Back"
                      onClick={() => {
                        setVisible(false);

                        onClick();
                      }}
                    >
                      Back
                    </Button>
                    {/* <Button size={15} onClick={onClick}><p style={{cursor:'pointer'}} onClick={()=>setVisible(false)}>Cancel</p></Button> */}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Book_Edit_Step1;
