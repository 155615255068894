import * as actionTypes from "../../action/actionType";

const intialState = {};

export const getCertificateRequestReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CERTIFICATE_REQUEST:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_CERTIFICATE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        certificateRequestList: action.payload,
      };
    case actionTypes.GET_CERTIFICATE_REQUEST_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "GET_CERTIFICATE_RESET":
      return {};
    default:
      return state;
  }
};
export const handleCertificateRequestReducer = (
  state = intialState,
  action
) => {
  switch (action.type) {
    case actionTypes.HANDLE_CERTIFICATE_REQUEST:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.HANDLE_CERTIFICATE_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        handleCertificateRequestData: action.payload,
      };
    case actionTypes.HANDLE_CERTIFICATE_REQUEST_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case "HANDLE_CERTIFICATE_REQUEST_RESET":
      return {};

    default:
      return state;
  }
};
