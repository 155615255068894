import * as actionTypes from '../../action/actionType';

const intialState = {};

export const getTrainersBatchReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAINERS_BATCH:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_TRAINERS_BATCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trainersBatchList: action.payload,
      };
    case actionTypes.GET_TRAINERS_BATCH_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_TRAINERS_BATCH_RESET':
      return {};
    default:
      return state;
  }
};
export const getTrainersModuleReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAINERS_MODULE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_TRAINERS_MODULE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trainersModuleList: action.payload,
      };
    case actionTypes.GET_TRAINERS_MODULE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_TRAINERS_MODULE_RESET':
      return {};
    default:
      return state;
  }
};
export const getTrainersTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRAINERS_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_TRAINERS_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        trainersTraineeist: action.payload,
      };
    case actionTypes.GET_TRAINERS_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_TRAINERS_TRAINEE_RESET':
      return {};

    default:
      return state;
  }
};
export const updateModprogressReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MODULE_PROGRESS:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.UPDATE_MODULE_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        modProgressData: action.payload,
      };
    case actionTypes.UPDATE_MODULE_PROGRESS_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'UPDATE_MODULE_PROGRESS_RESET':
      return {};
    default:
      return state;
  }
};
export const updateTraineeprogressReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TRAINEE_PROGRESS:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.UPDATE_TRAINEE_PROGRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        traineeProgressData: action.payload,
      };
    case actionTypes.UPDATE_TRAINEE_PROGRESS_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'UPDATE_TRAINEE_PROGRESS_RESET':
      return {};

    default:
      return state;
  }
};
export const updateTraineeGradeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TRAINEE_GRADE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.UPDATE_TRAINEE_GRADE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        traineeGradeData: action.payload,
      };
    case actionTypes.UPDATE_TRAINEE_GRADE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'UPDATE_TRAINEE_GRADE_RESET':
      return {};
    default:
      return state;
  }
};
export const banTraineeReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.BAN_TRAINEE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.BAN_TRAINEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        banTraineeData: action.payload,
      };
    case actionTypes.BAN_TRAINEE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'BAN_TRAINEE_RESET':
      return {};
    default:
      return state;
  }
};

export const deleteGrades = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_GRADE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_GRADE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteGradeData: action.payload,
      };
    case actionTypes.DELETE_GRADE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_GRADE_RESET':
      return {};
    default:
      return state;
  }
};

export const uploadGradesReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_GRADE:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.UPLOAD_GRADE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadGradeData: action.payload,
      };
    case actionTypes.UPLOAD_GRADE_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'UPLOAD_GRADE_RESET':
      return {};
    default:
      return state;
  }
};
export const uploadAudioReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_AUDIO:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.UPLOAD_AUDIO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadAudioData: action.payload,
      };
    case actionTypes.UPLOAD_AUDIO_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'UPLOAD_AUDIO_RESET':
      return {};
    default:
      return state;
  }
};

export const uploadedMaterialReducer = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UPLOADED_MATERIAL:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.GET_UPLOADED_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadedMaterialData: action.payload,
      };
    case actionTypes.GET_UPLOADED_MATERIAL_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'GET_UPLOADED_MATERIAL_RESET':
      return {};
    default:
      return state;
  }
};

export const deleteUploadedMaterial = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_UPLOADED_MATERIAL:
      return { ...state, isLoading: action.isLoading };
    case actionTypes.DELETE_UPLOADED_MATERIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteUploadedMaterialData: action.payload,
      };
    case actionTypes.DELETE_UPLOADED_MATERIAL_FAIL:
      return { isLoading: action.isLoading, error: action.payload.error };
    case 'DELETE_UPLOADED_MATERIAL_RESET':
      return {};
    default:
      return state;
  }
};
