import { ImportExport } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import * as firebase from "firebase";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import {
  createTrainerAction,
  getTrainersAction,
} from "store/action/trainerActions/trainerActions";
import { clearResponse } from "store/action/clearResponseAction";
import { db } from "../../MY_LOGIN_AND_SIGNUP/db/config";

function AddTrainer({ notify, setModal, ...props }) {
  const [visible, setVisible] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [shift, setShift] = useState(false);
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const onSubmit = (data) => {
    setVisible(true);
    data.orgId = 1;

    props.createTrainer(data);

    // if (data.mobile < 4000000000) {
    //   setError('invalid mobile number');
    // } else {
    //   setError('');
    //   db.collection('zSystemTrainers')
    //     .where('mobile', '==', data.mobile)
    //     .get()
    //     .then((doc) => {
    //       if (doc.docs.length > 0) {
    //         notify('Mobile No is already exist', true);
    //       } else {
    //         db.collection('zSystemTrainers')
    //           .where('secondaryEmail', '==', data.secondaryEmail)
    //           .get()
    //           .then((query) => {
    //             if (query.docs.length > 0) {
    //               notify('This Secondary email address is already exist', true);
    //             } else {
    //               const trainerData = {
    //                 firstname: data.firstname,
    //                 lastName: data.lastName,
    //                 mobile: data.mobile,
    //                 address: data.address,
    //                 email: data.email,
    //                 password: data.password,
    //                 sendEmail: sendEmail,
    //                 forceResetPassword: true,
    //                 profilePic: '',
    //                 shift: data.shift,
    //                 secondaryEmail: data.secondaryEmail,
    //                 status: 'Active',
    //                 disabled: false,
    //               };
    //               const addTrainerUser =
    //                 functions.httpsCallable('addTrainerUser');
    //               document.querySelector('#loading-indicator').style.display =
    //                 'flex';
    //               addTrainerUser(trainerData).then((result) => {
    //                 if (result.data.hasError) {
    //                   document.querySelector(
    //                     '#loading-indicator'
    //                   ).style.display = 'none';
    //                   notify(result.data.message.errorInfo.message, true);
    //                 } else {
    //                   const _uniqueID = result.data.user.uid;
    //                   db.collection('zSystemTrainers')
    //                     .doc(_uniqueID.toString())
    //                     .set({
    //                       firstname: data.firstname,
    //                       lastName: data.lastName,
    //                       mobile: data.mobile,
    //                       address: data.address,
    //                       secondaryEmail: data.secondaryEmail,
    //                       email: data.email,
    //                       shift: data.shift,
    //                       password: data.password,
    //                       sendEmail: sendEmail,
    //                       forceResetPassword: true,
    //                       createdFrom: 'Admin',
    //                       profilePic: '',
    //                       status: 'Active',
    //                       _uniqueID,
    //                       disabled: false,
    //                       createdAt:
    //                         firebase.firestore.FieldValue.serverTimestamp(),
    //                       lastUpdatedAt:
    //                         firebase.firestore.FieldValue.serverTimestamp(),
    //                     })
    //                     .then(function () {
    //                       notify('Trainer created successfully', false);
    //                       document.querySelector(
    //                         '#loading-indicator'
    //                       ).style.display = 'none';
    //                       setModal(false);
    //                     })
    //                     .catch(function (error) {
    //                       notify(error.toString(), true);
    //
    //                       document.querySelector(
    //                         '#loading-indicator'
    //                       ).style.display = 'none';
    //                     });
    //
    //                 }
    //               });
    //             }
    //           })
    //           .catch((err) => {
    //             notify(err.toString(), true);
    //             document.querySelector('#loading-indicator').style.display =
    //               'none';
    //
    //           });
    //       }
    //     });
    // }
    // setModal(false);
    // notify('No results found', true);
  };

  useEffect(() => {
    if (props?.createTrainerRes?.createTrainerData?.data) {
      notify("Trainer created successfuly", false);
      props.clearResponse("CREATE_TRAINER_RESET");
      props.getTrainerList();
      setModal(false);
    }
    if (props.createTrainerRes?.error) {
      notify(props.createTrainerRes?.error?.message, true);
      props.clearResponse("CREATE_TRAINER_RESET");
    }
  }, [props?.createTrainerRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{}}>
          <Col md="8">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform:"capitalize"
                  }}
                >
                  Add Trainer
                </h2>
              </CardHeader>
              <CardBody>
                <Form
                  style={{ paddingRight: 10 }}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="FirstName">First Name</label>
                        <Input
                          id="FirstName"
                          innerRef={register({ required: true })}
                          name="firstName"
                          placeholder="First Name"
                          type="text"
                        />
                        {errors.firstName &&
                          errors.firstName.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter first name
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="LastName">Last Name</label>
                        <Input
                          id="LastName"
                          innerRef={register({ required: true })}
                          name="lastName"
                          placeholder="Last Name"
                          type="text"
                        />
                        {errors.lastName &&
                          errors.lastName.type === "required" && (
                            <span
                              style={{
                                fontSize: "10px",
                                position: "absolute",
                                bottom: "-20px",
                                color: "red",
                              }}
                            >
                              Please enter last name
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Email">Email</label>
                        <Input
                          id="Email"
                          innerRef={register({ required: true })}
                          name="email"
                          placeholder="Email"
                          type="email"
                        />
                        {errors.email && errors.email.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter email
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Password">Password</label>
                        <Input
                          id="Password"
                          innerRef={register({ required: true, minLength: 8 })}
                          name="paswd"
                          placeholder="Password"
                          type="password"
                        />
                        {errors.paswd && errors.paswd.type === "minLength" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Password should be atleast 8 digits.
                          </span>
                        )}
                        {errors.paswd && errors.paswd.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please enter password.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col className="pl-md-1" md="4">
                        <FormGroup>
                          <label>
                            Username
                          </label>
                          <Input placeholder="username" type="text" name='username' required innerRef={register({required:true,minLength:6})}/>
                        </FormGroup>
                      </Col> */}
                  </Row>
                  <Row>
                    <Col className="pr-md-1" md="6">
                      <FormGroup>
                        <label htmlFor="Mobile">Mobile</label>
                        <Input
                          id="Mobile"
                          innerRef={register({
                            minLength: 10,
                            maxLength: 10,
                            required: true,
                          })}
                          name="mobile"
                          placeholder="10-digit Number"
                          type="number"
                        />
                        {error && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            {error}
                          </span>
                        )}
                        {errors.mobile && errors.mobile.type !== "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Valid Mobile No.
                          </span>
                        )}
                        {errors.mobile && errors.mobile.type === "required" && (
                          <span
                            style={{
                              fontSize: "10px",
                              position: "absolute",
                              bottom: "-20px",
                              color: "red",
                            }}
                          >
                            Please Enter Mobile No.
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col
                      className='pr-md-1'
                      md='6'
                      style={{ paddingLeft: '70px' }}
                    >
                      <div>
                        <input
                          name='sendEmail'
                          type='checkbox'
                          value={sendEmail}
                          onChange={(e) => setSendEmail(!sendEmail)}
                          style={{ marginRight: '10px' }}
                        />
                        <span
                          style={{
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.6)',
                          }}
                        >
                          Send Invitation mail
                        </span>
                      </div>
                    </Col>

                    <Col
                      className='pr-md-1'
                      md='6'
                      style={{ paddingLeft: '70px' }}
                    >
                      <div>
                        <input
                          name='sendSMS'
                          type='checkbox'
                          value={sendSMS}
                          onChange={(e) => setSendSMS(!sendSMS)}
                          style={{ marginRight: '10px' }}
                        />
                        <span
                          style={{
                            fontSize: '15px',
                            color: 'rgba(255,255,255,0.6)',
                          }}
                        >
                          Send Invitation SMS
                        </span>
                      </div>
                    </Col>
                  </Row> */}
                  <br></br>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {/* {visible===false? <Button className="btn-fill" color="info" type="submit">
                    Submit
                  </Button> : <Button className="btn-fill" color="info" onClick={onClick}>Done</Button>}
                  {visible===false && <Button className="btn-fill"  onClick={onClick}>
                    Cancel
                  </Button>} */}

                    <Button title="Submit" color="info" type="submit">
                      Submit
                    </Button>

                    <Button title="Back" onClick={() => setModal(false)}>
                      Back
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => ({
  createTrainerRes: state.createTrainer,
  getTrainerData: state.getTrainer,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  createTrainer: (payload) => {
    dispatch(createTrainerAction(payload));
  },
  getTrainerList: () => {
    dispatch(getTrainersAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTrainer);
