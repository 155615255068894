import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { securityGroupBulkDeleteAction } from "store/action/bulkDeleteAction/securityGroupBulkDeleteAction";
import { getSecurityGroupAction } from "store/action/SecurityGroupAction/SecurityGroupAction";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";

function BulkDeleteSecurityGroup(props) {
    const { onClick, securityArr, setSecurityArr, notify } = props;

    const bulkDeleteSecurityGroup = () => {
        const bulkDeleteUrl = `/api/admin/securityGroup/bulkDelete?id=''&${securityArr.toString().replaceAll(',', '&')}`;
        props.securityGroupBulkDelete(bulkDeleteUrl);
    };

    useEffect(() => {
        if (props?.securityGroupBulkDeleteRes?.deleteSecurityGroupData) {
            notify("Security Groups deleted successfully", false);
            props.clearResponse("BULK_DELETE_SECURITY_GROUP_RESET");
            props.getSecurityGroupList();
            setSecurityArr([])
            onClick();
        }
        if (props.securityGroupBulkDeleteRes?.error) {
            notify(props.trainingBulkDeleteRes?.error?.message, true);
            props.clearResponse("BULK_DELETE_SECURITY_GROUP_RESET");
        }
    }, [props?.securityGroupBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Security Groups?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteSecurityGroup}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    securityGroupBulkDeleteRes: state.securityGroupBulkDelete,
    getSecurityGroupData: state.getSecurityGroup,
});

const mapDispatchToProps = (dispatch) => ({
    securityGroupBulkDelete: (payload) => {
        dispatch(securityGroupBulkDeleteAction(payload));
    },
    getSecurityGroupList: () => {
        dispatch(getSecurityGroupAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteSecurityGroup);
