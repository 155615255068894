import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Input as MInput,
  withStyles,
  Menu,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { actionBtnStyle } from "Utilities/common/commonStyle";
//import { Modal } from '@material-ui/core';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Input,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Edit, Delete, Add, Tune } from "@material-ui/icons";
import NotificationAlert from "react-notification-alert";
import { connect } from "react-redux";
import {
  getCertificateRequestAction,
  handleCertificateRequestAction,
} from "store/action/adminCertificateAction/adminCertificateAction";

import { clearResponse } from "store/action/clearResponseAction";
import Loader from "components/Loader/Loader";
import ModalComp from "components/ModalComp/ModalComp";
import HandleReqComp from "./HandleReqComp";
import SearchIcon from "@material-ui/icons/Search";
import algoliasearch from "algoliasearch/lite";
import { algoliaAppID, algoliaSearchID } from "../../Utilities/algoliaKeys";
import CalculateDays from "./CalculateDays";
import { btnStyle } from "Utilities/common/commonStyle";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function CertificateModuleTable(props) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#272a3d" : "black",
      padding: 10,
    }),
    control: () => ({
      display: "none",
    }),
  };

  const [open, setOpen] = useState(false);
  const [certificateRequestList, setCertificateRequestList] = useState("");
  //   const [studentSearchValue, setStudentSearchValue] = useState('');

  const [progressValue, setProgressValue] = useState("");
  const [requestId, setRequestId] = useState("");
  const [requestReason, setRequestReason] = useState("");

  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const searchClient = algoliasearch(algoliaAppID, algoliaSearchID);

  const studentSearchIndex = searchClient.initIndex("Student_Search");
  const [studentSearchValue, setStudentSearchValue] = useState("");
  const [search, setSearch] = useState(false);

  const [openCalculateDayModal, setOpenCalculateDayModal] = useState(false);
  const [batchName, setBatchName] = useState("");
  const [dates, setDates] = useState("");
  const [totalDays, setTotalDays] = useState(0);

  const [batchDuration, setBatchDuration] = useState("");
  const [certificateRequestData, setCertificateRequestData] = useState("");

  const history = useHistory();

  const notifi = useRef();
  const notify = (msg, err) => {
    let color = err == false ? "success" : "danger";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err == false ? "Success" : "Error"}</b> - {msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notifi.current) {
      notifi.current.notificationAlert(options);
    }
    // notification(options);
  };

  useEffect(() => {
    document.title = "Certificate Management";
  }, []);

  useEffect(() => {
    if (props?.getCertificateRequestRes?.certificateRequestList) {
      if (
        props?.getCertificateRequestRes?.certificateRequestList.data.length == 0
      ) {
        notify("No data found.", true);
        props.clearResponse("GET_CERTIFICATE_RESET");
        setCertificateRequestList("");
      } else {
        setCertificateRequestList(
          props?.getCertificateRequestRes?.certificateRequestList?.data
        );
      }
    }
    if (props?.getCertificateRequestRes?.error) {
      notify(props?.getCertificateRequestRes?.error?.message, true);
      props.clearResponse("GET_CERTIFICATE_RESET");
    }
  }, [props?.getCertificateRequestRes]);

  useEffect(() => {
    props.getCertificateRequestList();
  }, []);

  useEffect(() => {
    if (progressValue == 0) {
      let payload = {
        id: requestId,
        progress: progressValue,
        reason: requestReason,
      }
      if (payload.id) props.handleCertificateAction(payload);
    } else if(progressValue == 2){
      let payload = {
        id: requestId,
        progress: progressValue,
        reason: requestReason,
        numberOfDays: batchDuration,
      }
      if (payload.id) props.handleCertificateAction(payload);
    }
  }, [progressValue]);

  useEffect(() => {
    if (props?.handleCertificateRes?.handleCertificateRequestData?.data) {
      notify("Updated successfully", false);
      props.clearResponse("HANDLE_CERTIFICATE_REQUEST_RESET");
      props.getCertificateRequestList();
      handleClose();
    }
    if (props.getAdminData?.error) {
      notify(props.getAdminData?.error?.message, true);
      props.clearResponse("HANDLE_CERTIFICATE_REQUEST_RESET");
      handleClose();
    }
  }, [props?.handleCertificateRes]);

  const emptyStudent = () => {
    getUsers();
  };

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  const studentSearch = () => {
    if (studentSearchValue == "") {
      props.getCertificateRequestList();
    } else {
      setSearch(true);
      props.getCertificateRequestList(studentSearchValue);
    }
  };

  const getUsers = (orderBy) => {};
  //   const studentSearch = () => {
  //     if (studentSearchValue == '') {
  //       props.getModuleList();
  //     } else {
  //       setSearch(true);
  //       props.getModuleList(studentSearchValue);
  //     }
  //   };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpenCalculateDays = (batch, date, id) => {
    setTotalDays(0);
    setBatchName(batch);
    setDates(date);
    setRequestId(id);
    setOpenCalculateDayModal(true);
  };

  const handleBackCalculateDays = () => {
    setOpenCalculateDayModal(false);
  };

  const handleSubmitCalculateDays = () => {
    certificateRequestList.map((item) => {
      if (item?.id === requestId) {
        item.duration = totalDays;
      }
    });
    setCertificateRequestList(certificateRequestList);
    notify(
      "Submitted successfully, please approve request to save data",
      false
    );
    setOpenCalculateDayModal(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproove = () => {
    setRequestId(certificateRequestData.id);
    setRequestReason(certificateRequestData.reason);
    if (certificateRequestData.duration) {
      setBatchDuration(certificateRequestData?.duration);
      setProgressValue(2);
    } else {
      notify("Please calculate training duration first", true);
    }
  };
  const handleDecline = () => setProgressValue(0);

  console.log(progressValue, 'progress value')

  if (!localStorage.getItem("user")) history.push("/login");
  return (
    <>
      <main id="main" className="content">
        <div className="react-notification-alert-container">
          <NotificationAlert ref={notifi} />
        </div>

        <Row>
          <Col md="12">
            <Card
              style={{
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <h1
                  style={{
                    marginRight: "auto",
                    fontSize: font?.fontSize + 4 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform: "capitalize",
                  }}
                >
                  CERTIFICATE MANAGEMENT
                </h1>
              </CardHeader>
              <Row style={{ marginLeft: "7px" }}>
                <Col sm={4}>
                  <Input
                    title="Student Name"
                    type="text"
                    name="search"
                    placeholder="Student Name"
                    onChange={(e) => (
                      setStudentSearchValue(e.target.value),
                      e.target.value.length === 0 && emptyStudent(),
                      setSearch(false)
                    )}
                  />
                </Col>
                <Col style={{ paddingLeft: "0px" }} sm={2}>
                  <Button
                    title="Search"
                    style={{ margin: "0px", padding: "6px 10px" }}
                    color="info"
                    onClick={studentSearch}
                  >
                    <SearchIcon />
                  </Button>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  sm={6}
                ></Col>
              </Row>
              <CardBody style={{ paddingTop: "10px" }}>
                <Table className="tablesorter">
                  <thead className="text-primary">
                    <tr>
                      <th>Student Name</th>
                      <th style={{ textAlign: "center" }}>Training Name</th>
                      <th style={{ textAlign: "center" }}>Marks %</th>
                      <th style={{ textAlign: "center" }}>No. of request</th>
                      <th style={{ textAlign: "center" }}>Training duration</th>
                      <th style={{ textAlign: "center" }}>Reason</th>
                      <th style={{ textAlign: "center" }}>Status</th>
                      <th style={{ textAlign: "center" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {certificateRequestList &&
                    certificateRequestList.length !== 0 ? (
                      certificateRequestList.map((item, i) => {
                        return (
                          <tr
                            key={item.id}
                            style={{
                              borderBottom: `1px solid ${
                                colorTheme?.borderColor ||
                                "rgba(255, 255, 255, 0.1)"
                              }`,
                            }}
                          >
                            <th
                              style={{
                                fontSize: font?.fontSize || "inherit",
                                fontWeight: font?.fontWeight || "inherit",
                                color: "#FFFFFFB3",
                              }}
                            >
                              {item.studentName}
                            </th>

                            <td style={{ textAlign: "center" }}>
                              {item.batchName}
                            </td>

                            {item.avgMarks ? (
                              <td style={{ textAlign: "center" }}>
                                {parseInt(item.avgMarks, 10).toFixed(2)} %
                              </td>
                            ) : (
                              <td style={{ textAlign: "center" }}>0</td>
                            )}

                            <td style={{ textAlign: "center" }}>
                              {item.noOfRequest}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item?.duration ? (
                                `${item?.duration} Days`
                              ) : item?.progress === "2" ? (
                                "Already approved"
                              ) : (
                                <button
                                  aria-label="calculate no of days"
                                  title="calculate no of days"
                                  onClick={() =>
                                    handleOpenCalculateDays(
                                      item?.batchName,
                                      {
                                        startDate: item?.startDate?.slice(
                                          0,
                                          10
                                        ),
                                        endDate: item?.endDate?.slice(0, 10),
                                      },
                                      item?.id
                                    )
                                  }
                                  style={btnStyle}
                                >
                                  Calculate
                                </button>
                              )}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item.reason || "-"}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item?.progress === "1"
                                ? "Created"
                                : item?.progress === "2"
                                ? "Approved"
                                : item?.progress === "3"
                                ? "Downloaded"
                                : "Rejected"}
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <button
                                title="handle request"
                                aria-label="handle request"
                                style={actionBtnStyle}
                                onClick={() => {
                                  handleClickOpen();
                                  setRequestId(item.id);
                                  setRequestReason(item.reason);
                                  setCertificateRequestData(item);
                                }}
                              >
                                <Edit style={{ color: "#247cf7" }} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No data found</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalComp controller={open} bodyColor={colorTheme?.bodyColor}>
          <HandleReqComp
            handleClose={handleClose}
            handleApproove={handleApproove}
            handleDecline={handleDecline}
          />
        </ModalComp>
        <ModalComp
          controller={openCalculateDayModal}
          bodyColor={colorTheme?.bodyColor}
        >
          <CalculateDays
            batchName={batchName}
            dateData={dates}
            handleSubmit={handleSubmitCalculateDays}
            handleBack={handleBackCalculateDays}
            notify={notify}
            totalDays={totalDays}
            setTotalDays={setTotalDays}
          />
        </ModalComp>
      </main>
      {props?.getCertificateRequestRes?.isLoading ||
      props?.handleCertificateRes?.isLoading ? (
        <Loader />
      ) : null}
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  getCertificateRequestRes: state.getCertificateRequest,
  handleCertificateRes: state.handleCertificateRequest,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getCertificateRequestList: (payload) => {
    dispatch(getCertificateRequestAction(payload));
  },
  handleCertificateAction: (payload) => {
    dispatch(handleCertificateRequestAction(payload));
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificateModuleTable);
