import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import timeJson from './time.json'

function EditMeetingModal({
  setDialogOpen,
  currentData,
  classesList,
  setClassesList,
}) {
  const { register, handleSubmit, errors } = useForm();
  const [timeError, setTimeError] = useState(-1);

  const submitData = (data) => {
    if (data.startTime && data.endTime && data.startTime >= data.endTime) {
      setTimeError(1);
      return
    }
    setClassesList((prev) => ({
      ...prev,
      [currentData.week]: prev[currentData.week].map((item, i) =>
        i == [currentData.index]
          ? {
              ...item,
              meetingID: data.meetingID,
              meetingPass: data.meetingPass,
              startTime:data.startTime,
              endTime:data.endTime
            }
          : item
      ),
    }));
    setDialogOpen((prev) => !prev)
  };
  return (
    <>
      <div className="content">
        <Row style={{ margin: 0 }}>
          <Card style={{ margin: 0 }}>
            <CardHeader>
              <h5 className="title">Edit Meeting Details</h5>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(submitData)}>
              <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Start Time</label>
                      <Input
                        defaultValue={
                          (currentData &&
                            classesList &&
                            classesList[currentData.week] !== undefined &&
                            classesList[currentData.week][currentData.index] !==
                              undefined &&
                            classesList[currentData.week][currentData.index]
                              .startTime) ||
                          ""
                        }
                        innerRef={register({ required: true })}
                        name="startTime"
                        type="select"
                        >
                          <option value={""}>Select</option>
                          {timeJson.map(item=>{
                            return <option value={item.value}>{item.label}</option>
                          })}
                        </Input>
                      {errors.startTime &&
                        errors.startTime.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter start time
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>End Time</label>
                      <Input
                        defaultValue={
                          (currentData &&
                            classesList &&
                            classesList[currentData.week] !== undefined &&
                            classesList[currentData.week][currentData.index] !==
                              undefined &&
                            classesList[currentData.week][currentData.index]
                              .endTime) ||
                          ""
                        }
                        innerRef={register({ required: true })}
                        name="endTime"
                        type="select"
                        >
                          <option value={""}>Select</option>
                          {timeJson.map(item=>{
                            return <option value={item.value}>{item.label}</option>
                          })}
                        </Input>
                      {errors.endTime &&
                        errors.endTime.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter end time
                          </span>
                        )}
                         {timeError === 1 && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            End time cannot be less than start time
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                
                <Row>
                  <Col className="pr-md-1" md="6">
                    <FormGroup>
                      <label>Meeting Id</label>
                      <Input
                        defaultValue={
                          (currentData &&
                            classesList &&
                            classesList[currentData.week] !== undefined &&
                            classesList[currentData.week][currentData.index] !==
                              undefined &&
                            classesList[currentData.week][currentData.index]
                              .meetingID) ||
                          ""
                        }
                        innerRef={register({ required: true })}
                        name="meetingID"
                        placeholder="Meeting Id"
                        type="number"
                      />
                      {errors.meetingID &&
                        errors.meetingID.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter the meeting id
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label>Meeting Pass</label>
                      <Input
                        defaultValue={
                          (currentData &&
                            classesList &&
                            classesList[currentData.week] !== undefined &&
                            classesList[currentData.week][currentData.index] !==
                              undefined &&
                            classesList[currentData.week][currentData.index]
                              .meetingPass) ||
                          ""
                        }
                        innerRef={register({ required: true })}
                        name="meetingPass"
                        placeholder="Meeting Password"
                        type="text"
                      />
                      {errors.meetingPass &&
                        errors.meetingPass.type === "required" && (
                          <span
                            style={{
                              fontSize: 12,
                              color: "red",
                              top: -20,
                              position: "relative",
                            }}
                          >
                            Please enter meeting password
                          </span>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <Button color="info" type="submit">
                    Submit
                  </Button>
                  <Button onClick={() => setDialogOpen((prev) => !prev)}>
                    Back
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
}

export default EditMeetingModal;
