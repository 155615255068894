import React from 'react';
import { Button } from 'reactstrap';
import UserIcon from '../../assets/img/default-avatar.png';

function ChatMsgRendererAdmin({
  item,
  onReciveButtonClick = () => null,
  isPanel,
}) {
  return (
    <>
      {isPanel && item.sentBy === 'Teacher' ? (
        <div class='chatBotWidget-message'>
          <div class='chatBotWidget-clientMsg'>
            <div class='chatBotWidget-client_txt'>
              <p>{item.text}</p>
              {item?.file && (
                <audio
                  style={{ width: 150, height: 30 }}
                  src={item?.file}
                  controls
                  controlsList='nodownload'
                />
              )}
            </div>
          </div>
        </div>
      ) : !isPanel && item.sentBy === 'Admin' ? (
        <div class='chatBotWidget-message'>
          <div class='chatBotWidget-clientMsg'>
            <div class='chatBotWidget-client_txt'>
              <p>{item.text}</p>
              {item?.file && (
                <audio
                  style={{ width: 150, height: 30 }}
                  src={item?.file}
                  controls
                  controlsList='nodownload'
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div class='chatBotWidget-message'>
          <img
            src={UserIcon}
            class='chatBotWidget-avatar'
            alt='profile'
            aria-hidden='true'
          />
          <div class='chatBotWidget-response'>
            <div class='chatBotWidget-client_txt'>
              <p>{item.text}</p>
              {item?.file && (
                <audio
                  style={{ width: 150, height: 30 }}
                  src={item?.file}
                  controls
                  controlsList='nodownload'
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatMsgRendererAdmin;
