import * as actionTypes from '../actionType';
import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from 'Utilities/apiService/admin';

export const getQuestionAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_QUESTION,
      payload: {},
      isLoading: true,
    });

    let url='api/question'
    if(payload)url=url+`?q=${payload}`

    getRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.GET_QUESTION_SUCCESS,
            payload: { data: res?.data?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.GET_QUESTION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const createQuestionAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CREATE_QUESTION,
      payload: {},
      isLoading: true,
    });

    postRequest('api/question', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.CREATE_QUESTION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
       
        dispatch({
          type: actionTypes.CREATE_QUESTION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const editQuestionAction = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_QUESTION,
      payload: {},
      isLoading: true,
    });

    putRequest('api/question', payload)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.EDIT_QUESTION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
      
        dispatch({
          type: actionTypes.EDIT_QUESTION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};

export const deleteQuestionAction = (url) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_QUESTION,
      payload: {},
      isLoading: true,
    });
    deleteRequest(url)
      .then((res) => {
        if (res.status == 200) {
          dispatch({
            type: actionTypes.DELETE_QUESTION_SUCCESS,
            payload: { data: res?.data },
            isLoading: false,
          });
        } else {
          throw res;
        }
      })
      .catch((err) => {
  
        dispatch({
          type: actionTypes.DELETE_QUESTION_FAIL,
          payload: { error: err.response.data },
          isLoading: false,
        });
      });
  };
};
