import React, { useState, useEffect } from "react";
import { getTeachingModuleAction } from "store/action/teachingModuleAction/teachingModuleAction";
import { getTrainersAction } from "store/action/trainerActions/trainerActions";
import {
  batchRelationAction,
  batchQuizRelationAction,
  batchAssignmentRelationAction,
  getBatchAction,
  editRelationAction,
} from "store/action/batchActions/batchActions";

import { getQuizAction } from "store/action/quizAction/quizAction";

import { getAssignmentAction } from "store/action/assignmentAction/assignmentAction";

import { clearResponse } from "store/action/clearResponseAction";

import TitleIdDropdown from "components/TitleIdDropdown/TitleIdDropdown";

import Loader from "components/Loader/Loader";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import AddRelation from "./AddRelation";
import RelationTable from "./RelationTable";
import AddQuiz from "../TRAINER_MODULE/TaskManagement/AddQuiz";
import AddAssignmentRel from "../TRAINER_MODULE/TaskManagement/AddAssignmentRel";
import EditRelation from "./EditRelation";

const AssignModuleTrainer = ({
  id,
  notify,
  controllModal,
  relationList,
  ...props
}) => {
  const { register, handleSubmit, errors } = useForm();

  const [trainerList, setTrainerList] = useState("");
  const [moduleList, setModuleList] = useState("");
  const [quizList, setQuizList] = useState("");
  const [assignmentList, setAssignmentList] = useState("");

  const [isAddingQuiz, setIsAddingQuiz] = useState(false);
  const [isAddingAssignment, setIsAddingAssignment] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [dataToEdit, setDataToEdit] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});

  const handleEditRelation = (data) => {
    setIsEditing(true);
    setDataToEdit(data);
  };

  useEffect(() => {
    props.getModuleList();
    props.getTrainerList();
    props.getQuizData();
    props.getAssignmentList();
  }, []);

  useEffect(() => {
    if (props?.getTrainerRes?.trainerList)
      setTrainerList(props?.getTrainerRes?.trainerList?.data);
  }, [props?.getTrainerRes?.trainerList]);

  useEffect(() => {
    if (props?.getModuleRes?.moduleList)
      setModuleList(props?.getModuleRes?.moduleList?.data);
  }, [props?.getModuleRes?.moduleList]);

  useEffect(() => {
    if (props?.getQuizRes?.quizList)
      setQuizList(props?.getQuizRes?.quizList?.data);
  }, [props?.getQuizRes?.quizList]);

  useEffect(() => {
    if (props?.getAssignmentRes?.assignmentList)
      setAssignmentList(props?.getAssignmentRes?.assignmentList?.data);
  }, [props?.getAssignmentRes?.assignmentList]);

  useEffect(() => {
    if (props?.batchRelationRes?.batchRelationData) {
      notify("Relation established successfully", false);
      props.clearBatch("BATCH_RELATION_RESET");
      props.getBatchList();
      controllModal(false);
    }

    if (props?.batchRelationRes?.error) {
      notify(props?.batchRelationRes?.error?.message, true);
      props.clearBatch("BATCH_RELATION_RESET");
    }
  }, [props?.batchRelationRes]);
  useEffect(() => {
    if (props?.editRelationRes?.editRelationData) {
      notify("Relation edited successfully", false);
      props.clearBatch("EDIT_RELATION_RESET");
      props.getBatchList();
      controllModal(false);
    }

    if (props?.editRelationRes?.error) {
      notify(props?.editRelationRes?.error?.message, true);
      props.getBatchList();
      props.clearBatch("EDIT_RELATION_RESET");
    }
  }, [props?.editRelationRes]);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <>
      <div className="content" style={{ width: 1150 }}>
        <Row style={{ width: "100%" }}>
          <Col lg="12" md="8" sm="6">
            <Card
              style={{
                border: "2px solid white",
                fontSize: font?.fontSize || "inherit",
                fontWeight: font?.fontWeight || "inherit",
                backgroundColor: colorTheme?.bodyColor || "all",
              }}
            >
              <CardHeader>
                <h2
                  className="title"
                  style={{
                    fontSize: font?.fontSize + 2 || "inherit",
                    fontWeight: font?.fontWeight || "inherit",
                    textTransform:"capitalize"
                  }}
                >
                  Manage Relations
                </h2>
              </CardHeader>
              <CardBody>
                {isEditing ? (
                  <EditRelation
                    moduleList={moduleList}
                    trainerList={trainerList}
                    quizList={quizList}
                    assignmentList={assignmentList}
                    relationData={dataToEdit}
                    handleBack={() => setIsEditing(false)}
                    callApi={(payload) => {
                      props.editRelation(payload);
                    }}
                  />
                ) : (
                  <div style={{ display: "flex" }}>
                    <AddRelation
                      trainerList={trainerList}
                      moduleList={moduleList}
                      controllModal={controllModal}
                      batchId={id}
                      callApi={(payload) => {
                        props.batchRelation(payload);
                      }}
                    />

                    <div
                      style={{
                        flex: "3",
                        paddingLeft: 10,
                      }}
                    >
                      <RelationTable
                        moduleList={moduleList}
                        trainerList={trainerList}
                        quizList={quizList}
                        assignmentList={assignmentList}
                        relationList={relationList}
                        handleEditButton={handleEditRelation}
                      />
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      {props?.getTrainerRes?.isLoading ? <Loader /> : null}
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  getTrainerRes: state.getTrainer,
  batchRelationRes: state.batchRelation,
  batchQuizRelationRes: state.batchQuizRelation,
  editRelationRes: state.editRelation,

  getModuleRes: state.getModule,
  getQuizRes: state.getQuiz,
  getAssignmentRes: state.getAssignment,
  getBatchRes: state.getBatch,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getModuleList: () => {
    dispatch(getTeachingModuleAction());
  },
  getTrainerList: () => {
    dispatch(getTrainersAction());
  },
  batchRelation: (payload) => {
    dispatch(batchRelationAction(payload));
  },
  batchQuizRelation: (payload) => {
    dispatch(batchQuizRelationAction(payload));
  },
  batchAssignmentRelation: (payload) => {
    dispatch(batchAssignmentRelationAction(payload));
  },
  editRelation: (payload) => {
    dispatch(editRelationAction(payload));
  },
  getBatchList: () => {
    dispatch(getBatchAction());
  },
  getQuizData: () => {
    dispatch(getQuizAction());
  },
  getAssignmentList: () => {
    dispatch(getAssignmentAction());
  },
  clearBatch: (act) => {
    dispatch(clearResponse(act));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignModuleTrainer);
