import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CardComp from "components/CardComp/CardComp";
import { Table } from "reactstrap";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";

import { getTrainersBatchAction } from "store/action/trainerActions/trainerProfileActions";
import Loader from "components/Loader/Loader";
import { clearResponse } from "store/action/clearResponseAction";

const TaskManagement = (props) => {
  const [batchList, setBatchList] = useState("");
  const [font, setFont] = useState({});
  const [colorTheme, setColorTheme] = useState({});
  const notification = useRef();

  const notify = (msg, err) => {
    let color = err === true ? "danger" : "success";
    let options = {
      type: color,
      place: "tr",
      message: (
        <div>
          <div>
            <b>{err === true ? "Error" : "Success"}</b> -{msg}.
          </div>
        </div>
      ),
      icon: "tim-icons icon-bell-55",
      autoDismiss: 15,
    };
    if (notification.current) {
      notification.current.notificationAlert(options);
    }
  };

  useEffect(() => {
    document.title = "Task Management"
  }, [])

  useEffect(() => {
    if (props?.getTrainersBatchRes?.trainersBatchList?.data) {
      if (props?.getTrainersBatchRes?.trainersBatchList?.data.length == 0) {
        notify("No data found.", true);
        props.clearResponse("GET_TRAINERS_BATCH_RESET");
      } else {
        setBatchList(props?.getTrainersBatchRes?.trainersBatchList?.data);
      }
    }
    if (props?.getTrainersBatchRes?.error) {
      notify(props?.getTrainersBatchRes?.error?.message, true);
      props.clearResponse("GET_TRAINERS_BATCH_RESET");
    }
  }, [props?.getTrainersBatchRes]);

  useEffect(() => {
    // if (!props?.getTrainersBatchRes?.trainersBatchList?.data)
    props.getTrainersBatchList();
  }, []);

  useEffect(() => {
    if (props?.fontData) setFont(props?.fontData?.fontData?.data);
  }, [props?.fontData]);

  useEffect(() => {
    if (props?.colorData) {
      setColorTheme(props?.colorData?.colorData?.data);
    }
  }, [props?.colorData]);

  return (
    <main id="main" className="content">
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notification} />
      </div>
      <div
        style={{
          padding: "16px",
          background: "#27293C",
          borderRadius: "4px",
          display: "flex",
          flexWrap: "wrap",
          backgroundColor: colorTheme?.bodyColor || "all",
        }}
      >
        <Table
          style={{
            fontSize: font?.fontSize || "inherit",
            fontWeight: font?.fontWeight || "inherit",
          }}
          className="tablesorter"
        >
          <thead className="text-primary">
            <tr>
              <th>Title</th>
              <th style={{}}>From</th>
              <th style={{}}>To</th>
              <th style={{}}>Training Capacity</th>
              <th>Training Details</th>
            </tr>
          </thead>
          <tbody>
            {batchList ? (
              batchList.map((batch) => {
                return (
                  <CardComp
                    font={font}
                    key={batch.id}
                    obj={batch}
                    path="/trainer/task-details"
                  />
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <h3 style={{ textAlign: "center" }}>Data not found</h3>
                </td>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {props.getTrainersBatchRes?.isLoading ? <Loader /> : null}
    </main>
  );
};

const mapStateToProps = (state, ownProps) => ({
  getTrainersBatchRes: state.getTrainersBatch,
  fontData: state.fontValues,
  colorData: state.colorValues,
});

const mapDispatchToProps = (dispatch) => ({
  getTrainersBatchList: () => {
    dispatch(getTrainersBatchAction());
  },
  clearResponse: (action) => {
    dispatch(clearResponse(action));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagement);
