import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import { adminBulkDeleteAction } from "store/action/bulkDeleteAction/adminBulkDeleteAction";
import { getAdminsAction } from "store/action/adminActions/adminActions";
import { clearResponse } from "store/action/clearResponseAction";
import axiosInstance from "axiosInstance/axiosInstance";
import { questionBulkDeleteAction } from "store/action/bulkDeleteAction/questionBulkDeleteAction";
import { getQuestionAction } from "store/action/questionAction/questionAction";

function BulkDeleteQuestion(props) {
    const { onClick, userArr, setUserArr, notify } = props;

    const bulkDeleteQuestion = () => {
        const bulkDeleteUrl = `/api/question/bulkDelete?id=''&${userArr.toString().replaceAll(',', '&')}`;
        props.questionBulkDelete(bulkDeleteUrl);
      };

    useEffect(() => {
      if (props?.questionBulkDeleteRes?.deleteQuestionData) {
        notify("Questions deleted successfully", false);
        props.clearResponse("BULK_DELETE_QUESTION_RESET");
        props.getQuestion();
        setUserArr([])
        onClick();
      }
      if (props.questionBulkDeleteRes?.error) {
        notify(props.questionBulkDeleteRes?.error?.message, true);
        props.clearResponse("BULK_DELETE_QUESTION_RESET");
      }
    }, [props?.questionBulkDeleteRes]);

    return (
        <div>
            <p>Do you want to delete marked Questions?</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Button title="Yes" color="info" onClick={bulkDeleteQuestion}>
                    Yes
                </Button>

                <Button title="No" onClick={onClick}>
                    No
                </Button>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    questionBulkDeleteRes: state.questionBulkDelete,
    getQuestionData: state.getQuestion,
});

const mapDispatchToProps = (dispatch) => ({
    questionBulkDelete: (payload) => {
        dispatch(questionBulkDeleteAction(payload));
    },
    getQuestion: () => {
        dispatch(getQuestionAction());
    },
    clearResponse: (action) => {
        dispatch(clearResponse(action));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDeleteQuestion);
