export const LOGIN_REQ = 'LOGIN_REQ';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_RESET = 'LOGIN_RESET';

// >>> Activity
// Get
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAIL = 'GET_ACTIVITY_FAIL';

// >>> Admin
// Get
export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';

// Create
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';

//Edit
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAIL = 'EDIT_ADMIN_FAIL';

//Delete
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';

//Bulk Delete
export const BULK_DELETE_ADMIN = 'BULK_DELETE_ADMIN';
export const BULK_DELETE_ADMIN_SUCCESS = 'BULK_DELETE_ADMIN_SUCCESS';
export const BULK_DELETE_ADMIN_FAIL = 'BULK_DELETE_ADMIN_FAIL';

// >>> Trainer
// Get
export const GET_TRAINER = 'GET_TRAINER';
export const GET_TRAINER_SUCCESS = 'GET_TRAINER_SUCCESS';
export const GET_TRAINER_FAIL = 'GET_TRAINER_FAIL';

// Create
export const CREATE_TRAINER = 'CREATE_TRAINER';
export const CREATE_TRAINER_SUCCESS = 'CREATE_TRAINER_SUCCESS';
export const CREATE_TRAINER_FAIL = 'CREATE_TRAINER_FAIL';

//Edit
export const EDIT_TRAINER = 'EDIT_TRAINER';
export const EDIT_TRAINER_SUCCESS = 'EDIT_TRAINER_SUCCESS';
export const EDIT_TRAINER_FAIL = 'EDIT_TRAINER_FAIL';

//Delete
export const DELETE_TRAINER = 'DELETE_TRAINER';
export const DELETE_TRAINER_SUCCESS = 'DELETE_TRAINER_SUCCESS';
export const DELETE_TRAINER_FAIL = 'DELETE_TRAINER_FAIL';

//Bulk Delete
export const BULK_DELETE_TRAINER = 'BULK_DELETE_TRAINER';
export const BULK_DELETE_TRAINER_SUCCESS = 'BULK_DELETE_TRAINER_SUCCESS';
export const BULK_DELETE_TRAINER_FAIL = 'BULK_DELETE_TRAINER_FAIL';

//// Trainer's profile
// get
export const GET_TRAINERS_BATCH = 'GET_TRAINERS_BATCH';
export const GET_TRAINERS_BATCH_SUCCESS = 'GET_TRAINERS_BATCH_SUCCESS';
export const GET_TRAINERS_BATCH_FAIL = 'GET_TRAINERS_BATCH_FAIL';

export const GET_TRAINERS_MODULE = 'GET_TRAINERS_MODULE';
export const GET_TRAINERS_MODULE_SUCCESS = 'GET_TRAINERS_MODULE_SUCCESS';
export const GET_TRAINERS_MODULE_FAIL = 'GET_TRAINERS_MODULE_FAIL';

export const GET_TRAINERS_TRAINEE = 'GET_TRAINERS_TRAINEE';
export const GET_TRAINERS_TRAINEE_SUCCESS = 'GET_TRAINERS_TRAINEE_SUCCESS';
export const GET_TRAINERS_TRAINEE_FAIL = 'GET_TRAINERS_TRAINEE_FAIL';

// Put
export const UPDATE_MODULE_PROGRESS = 'UPDATE_MODULE_PROGRESS';
export const UPDATE_MODULE_PROGRESS_SUCCESS = 'UPDATE_MODULE_PROGRESS_SUCCESS';
export const UPDATE_MODULE_PROGRESS_FAIL = 'UPDATE_MODULE_PROGRESS_FAIL';

export const UPDATE_TRAINEE_PROGRESS = 'UPDATE_TRAINEE_PROGRESS';
export const UPDATE_TRAINEE_PROGRESS_SUCCESS =
  'UPDATE_TRAINEE_PROGRESS_SUCCESS';
export const UPDATE_TRAINEE_PROGRESS_FAIL = 'UPDATE_TRAINEE_PROGRESS_FAIL';

export const UPDATE_TRAINEE_GRADE = 'UPDATE_TRAINEE_GRADE';
export const UPDATE_TRAINEE_GRADE_SUCCESS = 'UPDATE_TRAINEE_GRADE_SUCCESS';
export const UPDATE_TRAINEE_GRADE_FAIL = 'UPDATE_TRAINEE_GRADE_FAIL';

export const BAN_TRAINEE = 'BAN_TRAINEE';
export const BAN_TRAINEE_SUCCESS = 'BAN_TRAINEE_SUCCESS';
export const BAN_TRAINEE_FAIL = 'BAN_TRAINEE_FAIL';

// Delete
export const DELETE_GRADE = 'DELETE_GRADE';
export const DELETE_GRADE_SUCCESS = 'DELETE_GRADE_SUCCESS';
export const DELETE_GRADE_FAIL = 'DELETE_GRADE_FAIL';
// Post
export const UPLOAD_GRADE = 'UPLOAD_GRADE';
export const UPLOAD_GRADE_SUCCESS = 'UPLOAD_GRADE_SUCCESS';
export const UPLOAD_GRADE_FAIL = 'UPLOAD_GRADE_FAIL';

export const UPLOAD_AUDIO = 'UPLOAD_AUDIO';
export const UPLOAD_AUDIO_SUCCESS = 'UPLOAD_AUDIO_SUCCESS';
export const UPLOAD_AUDIO_FAIL = 'UPLOAD_AUDIO_FAIL';

// Trainer task management
export const GET_SUBMITTED_ASSIGNMENT = 'GET_SUBMITTED_ASSIGNMENT';
export const GET_SUBMITTED_ASSIGNMENT_SUCCESS =
  'GET_SUBMITTED_ASSIGNMENT_SUCCESS';
export const GET_SUBMITTED_ASSIGNMENT_FAIL = 'GET_SUBMITTED_ASSIGNMENT_FAIL';

// download assignment
export const DOWNLOAD_SUBMITTED_ASSIGNMENT = 'DOWNLOAD_SUBMITTED_ASSIGNMENT';
export const DOWNLOAD_SUBMITTED_ASSIGNMENT_SUCCESS =
  'DOWNLOAD_SUBMITTED_ASSIGNMENT_SUCCESS';
export const DOWNLOAD_SUBMITTED_ASSIGNMENT_FAIL =
  'DOWNLOAD_SUBMITTED_ASSIGNMENT_FAIL';

export const ADD_EDIT_QUIZ = 'ADD_EDIT_QUIZ';
export const ADD_EDIT_QUIZ_SUCCESS = 'ADD_EDIT_QUIZ_SUCCESS';
export const ADD_EDIT_QUIZ_FAIL = 'ADD_EDIT_QUIZ_FAIL';

export const ADD_EDIT_ASSIGNMENT = 'ADD_EDIT_ASSIGNMENT';
export const ADD_EDIT_ASSIGNMENT_SUCCESS = 'ADD_EDIT_ASSIGNMENT_SUCCESS';
export const ADD_EDIT_ASSIGNMENT_FAIL = 'ADD_EDIT_ASSIGNMENT_FAIL';

export const GET_UPLOADED_MATERIAL = 'GET_UPLOADED_MATERIAL';
export const GET_UPLOADED_MATERIAL_SUCCESS = 'GET_UPLOADED_MATERIAL_SUCCESS';
export const GET_UPLOADED_MATERIAL_FAIL = 'GET_UPLOADED_MATERIAL_FAIL';

export const DELETE_UPLOADED_MATERIAL = 'DELETE_UPLOADED_MATERIAL';
export const DELETE_UPLOADED_MATERIAL_SUCCESS =
  'DELETE_UPLOADED_MATERIAL_SUCCESS';
export const DELETE_UPLOADED_MATERIAL_FAIL = 'DELETE_UPLOADED_MATERIAL_FAIL';

// >>> Trainee
// Get
export const GET_TRAINEE = 'GET_TRAINEE';
export const GET_TRAINEE_SUCCESS = 'GET_TRAINEE_SUCCESS';
export const GET_TRAINEE_FAIL = 'GET_TRAINEE_FAIL';

// Create
export const CREATE_TRAINEE = 'CREATE_TRAINEE';
export const CREATE_TRAINEE_SUCCESS = 'CREATE_TRAINEE_SUCCESS';
export const CREATE_TRAINEE_FAIL = 'CREATE_TRAINEE_FAIL';

//Edit
export const EDIT_TRAINEE = 'EDIT_TRAINEE';
export const EDIT_TRAINEE_SUCCESS = 'EDIT_TRAINEE_SUCCESS';
export const EDIT_TRAINEE_FAIL = 'EDIT_TRAINEE_FAIL';

//Delete
export const DELETE_TRAINEE = 'DELETE_TRAINEE';
export const DELETE_TRAINEE_SUCCESS = 'DELETE_TRAINEE_SUCCESS';
export const DELETE_TRAINEE_FAIL = 'DELETE_TRAINEE_FAIL';

//Bulk
export const BULK_DELETE_TRAINEE = 'BULK_DELETE_TRAINEE';
export const BULK_DELETE_TRAINEE_SUCCESS = 'BULK_DELETE_TRAINEE_SUCCESS';
export const BULK_DELETE_TRAINEE_FAIL = 'BULK_DELETE_TRAINEE_FAIL';

//Relation
export const TRAINEE_BATCH_RELATION = 'TRAINEE_BATCH_RELATION';
export const TRAINEE_BATCH_RELATION_SUCCESS = 'TRAINEE_BATCH_RELATION_SUCCESS';
export const TRAINEE_BATCH_RELATION_FAIL = 'TRAINEE_BATCH_RELATION_FAIL';

// >>> Batch
// Get
export const GET_BATCH = 'GET_BATCH';
export const GET_BATCH_SUCCESS = 'GET_BATCH_SUCCESS';
export const GET_BATCH_FAIL = 'GET_BATCH_FAIL';

// Create
export const CREATE_BATCH = 'CREATE_BATCH';
export const CREATE_BATCH_SUCCESS = 'CREATE_BATCH_SUCCESS';
export const CREATE_BATCH_FAIL = 'CREATE_BATCH_FAIL';

//Edit
export const EDIT_BATCH = 'EDIT_BATCH';
export const EDIT_BATCH_SUCCESS = 'EDIT_BATCH_SUCCESS';
export const EDIT_BATCH_FAIL = 'EDIT_BATCH_FAIL';

//Delete
export const DELETE_BATCH = 'DELETE_BATCH';
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS';
export const DELETE_BATCH_FAIL = 'DELETE_BATCH_FAIL';

//Bulk Delete

export const BULK_DELETE_TRAINING = 'BULK_DELETE_TRAINING';
export const BULK_DELETE_TRAINING_SUCCESS = 'BULK_DELETE_TRAINING_SUCCESS';
export const BULK_DELETE_TRAINING_FAIL = 'BULK_DELETE_TRAINING_FAIL';

// Assign trainer and module
export const BATCH_RELATION = 'BATCH_RELATION';
export const BATCH_RELATION_SUCCESS = 'BATCH_RELATION_SUCCESS';
export const BATCH_RELATION_FAIL = 'BATCH_RELATION_FAIL';

// Assign  quiz
export const BATCH_QUIZ_RELATION = 'BATCH_QUIZ_RELATION';
export const BATCH_QUIZ_RELATION_SUCCESS = 'BATCH_QUIZ_RELATION_SUCCESS';
export const BATCH_QUIZ_RELATION_FAIL = 'BATCH_QUIZ_RELATION_FAIL';

// Assign  assignment
export const BATCH_ASSIGNMENT_RELATION = 'BATCH_ASSIGNMENT_RELATION';
export const BATCH_ASSIGNMENT_RELATION_SUCCESS =
  'BATCH_ASSIGNMENT_RELATION_SUCCESS';
export const BATCH_ASSIGNMENT_RELATION_FAIL = 'BATCH_ASSIGNMENT_RELATION_FAIL';

// Edit  relation
export const EDIT_RELATION = 'EDIT_RELATION';
export const EDIT_RELATION_SUCCESS = 'EDIT_RELATION_SUCCESS';
export const EDIT_RELATION_FAIL = 'EDIT_RELATION_FAIL';

// >>> Module
// Get
export const GET_MODULE = 'GET_MODULE';
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_FAIL = 'GET_MODULE_FAIL';

// Create
export const CREATE_MODULE = 'CREATE_MODULE';
export const CREATE_MODULE_SUCCESS = 'CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_FAIL = 'CREATE_MODULE_FAIL';
export const CREATE_MODULE_RESET = 'CREATE_MODULE_RESET';

//Edit
export const EDIT_MODULE = 'EDIT_MODULE';
export const EDIT_MODULE_SUCCESS = 'EDIT_MODULE_SUCCESS';
export const EDIT_MODULE_FAIL = 'EDIT_MODULE_FAIL';
export const EDIT_MODULE_RESET = 'EDIT_MODULE_RESET';

//Delete
export const DELETE_MODULE = 'DELETE_MODULE';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAIL = 'DELETE_MODULE_FAIL';
export const DELETE_MODULE_RESET = 'DELETE_MODULE_RESET';

// Bulk Delete
export const BULK_DELETE_MODULE = 'BULK_DELETE_MODULE';
export const BULK_DELETE_MODULE_SUCCESS = 'BULK_DELETE_MODULE_SUCCESS';
export const BULK_DELETE_MODULE_FAIL = 'BULK_DELETE_MODULE_FAIL';

// >>> Security Group
// Get
export const GET_SECURITY_GROUP = 'GET_SECURITY_GROUP';
export const GET_SECURITY_GROUP_SUCCESS = 'GET_SECURITY_GROUP_SUCCESS';
export const GET_SECURITY_GROUP_FAIL = 'GET_SECURITY_GROUP_FAIL';

// Create
export const CREATE_SECURITY_GROUP = 'CREATE_SECURITY_GROUP';
export const CREATE_SECURITY_GROUP_SUCCESS = 'CREATE_SECURITY_GROUP_SUCCESS';
export const CREATE_SECURITY_GROUP_FAIL = 'CREATE_SECURITY_GROUP_FAIL';

//Edit
export const EDIT_SECURITY_GROUP = 'EDIT_SECURITY_GROUP';
export const EDIT_SECURITY_GROUP_SUCCESS = 'EDIT_SECURITY_GROUP_SUCCESS';
export const EDIT_SECURITY_GROUP_FAIL = 'EDIT_SECURITY_GROUP_FAIL';

//Delete
export const DELETE_SECURITY_GROUP = 'DELETE_SECURITY_GROUP';
export const DELETE_SECURITY_GROUP_SUCCESS = 'DELETE_SECURITY_GROUP_SUCCESS';
export const DELETE_SECURITY_GROUP_FAIL = 'DELETE_SECURITY_GROUP_FAIL';

// Bulk Delete
export const BULK_DELETE_SECURITY_GROUP = 'BULK_DELETE_SECURITY_GROUP';
export const BULK_DELETE_SECURITY_GROUP_SUCCESS =
  'BULK_DELETE_SECURITY_GROUP_SUCCESS';
export const BULK_DELETE_SECURITY_GROUP_FAIL =
  'BULK_DELETE_SECURITY_GROUP_FAIL';

// Actions
export const GET_ACTIONS = 'GET_ACTIONS';
export const GET_ACTIONS_SUCCESS = 'GET_ACTIONS_SUCCESS';
export const GET_ACTIONS_FAIL = 'GET_ACTIONS_FAIL';
// Functions
export const GET_FUNCTIONS = 'GET_FUNCTIONS';
export const GET_FUNCTIONS_SUCCESS = 'GET_FUNCTIONS_SUCCESS';
export const GET_FUNCTIONS_FAIL = 'GET_FUNCTIONS_FAIL';

// >>> Question
// Get
export const GET_QUESTION = 'GET_QUESTION';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAIL = 'GET_QUESTION_FAIL';

// Create
export const CREATE_QUESTION = 'CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAIL = 'CREATE_QUESTION_FAIL';

//Edit
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAIL = 'EDIT_QUESTION_FAIL';

//Delete
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL';

//Bulk Delete
export const BULK_DELETE_QUESTION = 'BULK_DELETE_QUESTION ';
export const BULK_DELETE_QUESTION_SUCCESS = 'BULK_DELETE_QUESTION_SUCCESS';
export const BULK_DELETE_QUESTION_FAIL = 'BULK_DELETE_QUESTION_FAIL';

// >>> Assignment
// Get
export const GET_ASSIGNMENT = 'GET_ASSIGNMENT';
export const GET_ASSIGNMENT_SUCCESS = 'GET_ASSIGNMENT_SUCCESS';
export const GET_ASSIGNMENT_FAIL = 'GET_ASSIGNMENT_FAIL';

// Create
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const CREATE_ASSIGNMENT_SUCCESS = 'CREATE_ASSIGNMENT_SUCCESS';
export const CREATE_ASSIGNMENT_FAIL = 'CREATE_ASSIGNMENT_FAIL';

//Edit
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const EDIT_ASSIGNMENT_SUCCESS = 'EDIT_ASSIGNMENT_SUCCESS';
export const EDIT_ASSIGNMENT_FAIL = 'EDIT_ASSIGNMENT_FAIL';

//Delete
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const DELETE_ASSIGNMENT_SUCCESS = 'DELETE_ASSIGNMENT_SUCCESS';
export const DELETE_ASSIGNMENT_FAIL = 'DELETE_ASSIGNMENT_FAIL';

// Bulk Delete

export const BULK_DELETE_ASSIGNMENT = 'BULK_DELETE_ASSIGNMENT';
export const BULK_DELETE_ASSIGNMENT_SUCCESS = 'BULK_DELETE_ASSIGNMENT_SUCCESS';
export const BULK_DELETE_ASSIGNMENT_FAIL = 'BULK_DELETE_ASSIGNMENT_FAIL';

// >>> Quiz
// Get
export const GET_QUIZ = 'GET_QUIZ';
export const GET_QUIZ_SUCCESS = 'GET_QUIZ_SUCCESS';
export const GET_QUIZ_FAIL = 'GET_QUIZ_FAIL';

// Create
export const CREATE_QUIZ = 'CREATE_QUIZ';
export const CREATE_QUIZ_SUCCESS = 'CREATE_QUIZ_SUCCESS';
export const CREATE_QUIZ_FAIL = 'CREATE_QUIZ_FAIL';

//Edit
export const EDIT_QUIZ = 'EDIT_QUIZ';
export const EDIT_QUIZ_SUCCESS = 'EDIT_QUIZ_SUCCESS';
export const EDIT_QUIZ_FAIL = 'EDIT_QUIZ_FAIL';

//Delete
export const DELETE_QUIZ = 'DELETE_QUIZ';
export const DELETE_QUIZ_SUCCESS = 'DELETE_QUIZ_SUCCESS';
export const DELETE_QUIZ_FAIL = 'DELETE_QUIZ_FAIL';

// Bulk Delete
export const BULK_DELETE_QUIZ = 'BULK_DELETE_QUIZ';
export const BULK_DELETE_QUIZ_SUCCESS = 'BULK_DELETE_QUIZ_SUCCESS';
export const BULK_DELETE_QUIZ_FAIL = 'BULK_DELETE_QUIZ_FAIL';

// >>> Request
// Get
export const GET_REQUEST = 'GET_REQUEST';
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_FAIL = 'GET_REQUEST_FAIL';

// Approve
export const HANDLE_REQUEST = 'HANDLE_REQUEST';
export const HANDLE_REQUEST_SUCCESS = 'HANDLE_REQUEST_SUCCESS';
export const HANDLE_REQUEST_FAIL = 'HANDLE_REQUEST_FAIL';

// >>> Admin ceritificate management
export const GET_CERTIFICATE_REQUEST = 'GET_CERTIFICATE_REQUEST';
export const GET_CERTIFICATE_REQUEST_SUCCESS =
  'GET_CERTIFICATE_REQUEST_SUCCESS';
export const GET_CERTIFICATE_REQUEST_FAIL = 'GET_CERTIFICATE_REQUEST_FAIL';

export const HANDLE_CERTIFICATE_REQUEST = 'HANDLE_CERTIFICATE_REQUEST';
export const HANDLE_CERTIFICATE_REQUEST_SUCCESS =
  'HANDLE_CERTIFICATE_REQUEST_SUCCESS';
export const HANDLE_CERTIFICATE_REQUEST_FAIL =
  'HANDLE_CERTIFICATE_REQUEST_FAIL';
// GET
export const GET_TRAINEE_REPORT = 'GET_TRAINEE_REPORT';
export const GET_TRAINEE_REPORT_SUCCESS = 'GET_TRAINEE_REPORT_SUCCESS';
export const GET_TRAINEE_REPORT_FAIL = 'GET_TRAINEE_REPORT_FAIL';

// >>> Notification
// Get
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';

//Edit
export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const EDIT_NOTIFICATION_SUCCESS = 'EDIT_NOTIFICATION_SUCCESS';
export const EDIT_NOTIFICATION_FAIL = 'EDIT_NOTIFICATION_FAIL';

// Create
// export const CREATE_REQUEST = 'CREATE_REQUEST';
// export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
// export const CREATE_REQUEST_FAIL = 'CREATE_REQUEST_FAIL';

//Edit
// export const EDIT_REQUEST = 'EDIT_REQUEST';
// export const EDIT_REQUEST_SUCCESS = 'EDIT_REQUEST_SUCCESS';
// export const EDIT_REQUEST_FAIL = 'EDIT_REQUEST_FAIL';

//Delete
// export const DELETE_REQUEST = 'DELETE_REQUEST';
// export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
// export const DELETE_REQUEST_FAIL = 'DELETE_REQUEST_FAIL';

// >>> Files
// Get
export const GET_FILES = 'GET_FILES';
export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';

//Delete
export const DELETE_FILE = 'DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';
